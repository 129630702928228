//import { type WizardProps, Wizard, WizardStep } from '@filament/react';
import { Wizard, WizardActions, WizardStep } from '@filament/react';
import React from 'react';
import { SiteData } from '../../../components/Context/SiteContext';
import { Key } from '@filament/react/types';
import SiteImages from './SiteImages';
import SiteDetails from '../AllSites/Components/SiteDetails';
import SiteStructureSelection from '../Site Structure/SiteStructureCreation';


const SiteWizard = ({orgId,country,marker}:{orgId:string|undefined,
    country:string|undefined,
    marker?:{ lat: number; lng: number } | null}) => {
    const {
        SitepreviousRef,
        SitenextRef,
        setSiteValues,
        siteValues,
        mapSiteValues,
        setMapSiteValues,
        setInputSiteValue,
    } = SiteData()

    const renderActions = ({ next, previous }: { next?: () => void; previous?: () => void }) => {
        SitenextRef.current = next || undefined
        SitepreviousRef.current = previous || undefined
        return (
            <div>

            </div>
        );


    };
 
    console.log("orgId",orgId,"country",country)
    const handleWizared = (event: Key) => {

        setSiteValues((prev) => {
            return {
                ...prev,
                wizradState: event as string
            }
        })

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputSiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputSiteValue(value);

        if (value && window.google && window.google.maps) {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                {
                    input: value,
                    location: new window.google.maps.LatLng(mapSiteValues.center.lat, mapSiteValues.center.lng),
                    radius: 50000, // Bias results to 50km around the center
                },
                (predictions, status) => {
                    if (status === "OK" && predictions) {
                        setMapSiteValues((prev) => ({ ...prev, suggestions: predictions }));
                    } else {
                        setMapSiteValues((prev) => ({ ...prev, suggestions: [] }));
                    }
                }
            );
        } else {
            setMapSiteValues((prev) => ({ ...prev, suggestions: [] }));
        }
    };

    return (
        <Wizard id='sitewizard' layout="horizontalCentered" style={{ padding: 0 }}
            selectedKey={siteValues.wizradState}
            onSelectionChange={handleWizared}
        >
            <WizardStep key="Site_Details" title="Site Details" >
                <SiteDetails 
                    editedMarker={marker}
                    handleInputSiteChange={handleInputSiteChange}/>
                <WizardActions>
                    {renderActions}
                </WizardActions>
            </WizardStep>

            <WizardStep key="Upload_Images" title="Upload Images" >
                <SiteImages />
                <WizardActions>
                    {renderActions}
                </WizardActions>
            </WizardStep>

            <WizardStep key="Site_Structure" title="Site Structure">
                <SiteStructureSelection/>
                <WizardActions>
                    {renderActions}
                </WizardActions>
            </WizardStep>
        </Wizard>
    )
}

export default SiteWizard