/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Label,
    FlexBox,
    Item,
    Tabs,
    TabContext,
    TabPanels,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Avatar,
    Text,
    NotificationsQueue,
    ToggleButtonGroup,
} from '@filament/react';
import * as styles from './styles.css';
import { atomicBorderBottom as borderBottom, atomicBorderTop as borderTop } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';
import { useEditUserMutation, useGetUserQuery, UserEditInput } from '../../../../graphql/generated';
import Loading from '../../../../components/Loading';
import { format } from 'date-fns';
import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import { Key } from '@filament/react/types';
import UserRolesPrevilagePreview from './RolesPrevilagePreview';

export const UserInformation = ({ 
    user, 
    organizationId,
    organizationName,
    handleCloseModal, 
    activeTab, 
    setActiveTab, 
    buttonState, 
    setButtonState,
    refetchApi, 
}: {
    user: string;
    organizationId: string;
    organizationName: string;
    handleCloseModal: () => void;
    activeTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    buttonState: boolean;
    setButtonState: React.Dispatch<React.SetStateAction<boolean>>;
    refetchApi: () => void;
}) => {

    const { data, loading, error } = useGetUserQuery({
        variables: { id: user, organizationId: organizationId , limit: 10, offset: 0, sortBy: 'lastModifiedAt', sortOrder: 'desc' },
    });

    const userData = data?.user;

    const [mfaEnabled, setMfaEnabled] = useState(userData?.enableMFA );
    const [formValues, setFormValues] = useState<UserEditInput>({
        firstName: "",
        lastName: "",
        department: "",
        jobTitle: "",
    });

    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [EditUser] = useEditUserMutation();

    // Initialize form values when data is loaded
    useEffect(() => {
        if (data?.user) {
            const userData = data.user;
            setFormValues({
                lastName: userData.lastName || "",
                firstName: userData.firstName || "",
                department: userData.department || "",
                jobTitle: userData.jobTitle || "",
            });
            setMfaEnabled(userData?.enableMFA); // Update mfaEnabled when userData changes
        }
    }, [data]);

    if (loading) return <Loading message={"Loading User data..."}/>;
    if (error) return <div>Error: {error.message}</div>;

    const handleMfaToggle = (key: Key) => {
        const isEnabled = key === 'enabled';
        setMfaEnabled(isEnabled);
        setFormValues(prev => ({
            ...prev,
            enableMFA: isEnabled,
        }));
    };

    const handleInputChange = (field: keyof UserEditInput, value: string) => {
        setFormValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = async () => {
        setDisableSaveButton(true);
        try {
            await EditUser({
                variables: {
                    input: {
                        lastName: formValues.lastName || '-',
                        firstName: formValues.firstName || '-',
                        phoneNumber:formValues.phoneNumber,
                        phoneCode:formValues.phoneCode,
                        preferredLanguage:"English",
                        sites:[],
                        salutation:'',
                        privileges:[],
                        department: formValues.department || '-',
                        jobTitle: formValues.jobTitle || '-',
                        enableMFA: formValues.enableMFA, 
                    },
                    organizationId,
                    id: user
                }
            });
            NotificationsQueue.toast(
                ({ close }) => (
                    <ToastNotification 
                        close={close} 
                        message={'Edit the user'} 
                    />
                ),
                {
                    signal: 'success',
                    orientation: 'horizontal',
                    showCloseButton: true,
                    timeout: 6000
                }
            );

            refetchApi();
            handleCloseModal();
            setButtonState(true);
        } catch (error) {
            NotificationsQueue.toast(
                ({ close }) => (
                    <ToastNotification 
                        close={close} 
                        message={'Please enter the details correctly'} 
                    />
                ),
                {
                    signal: 'error',
                    orientation: 'horizontal',
                    showCloseButton: true,
                    timeout: 6000
                }
            );
            console.error("API call error:", error);
        } finally {
            setDisableSaveButton(false);
        }
    };

    const handleCancel = () => {
        setButtonState(true);
        // Reset form to original values
        if (userData) {
            setFormValues({
                lastName: userData.lastName || "",
                firstName: userData.firstName || "",
                department: userData.department || "",
                jobTitle: userData.jobTitle || "",
            });
        }
    };

    return (
        <Card width={'40%'} height={'100vh'} orientation="vertical"
            style={{ borderRadius: "10px 0 0 10px", position: 'fixed', top: 0, right: 0 }}
        >
            <CardHeader height={84} className={borderBottom} style={{ padding: 0 }}>
                <FlexBox justifyContent='space-between' alignItems={'center'} 
                    style={{ padding: '24px 22px 24px 32px' }}>
                    <Text variant='heading-s' weight="bold">User information</Text>
                    <Button variant={'quiet'} onPress={handleCloseModal}>
                        <Cross width={'24px'} height={'24px'} />
                    </Button>
                </FlexBox>
            </CardHeader>

            <CardBody style={{ padding: 0, gap: 0 }}>
                <FlexBox alignItems="center" style={{ paddingLeft: '24px', paddingTop: '14px', paddingBottom: '8px' }}>
                    <Avatar width={'128px'} height={'128px'} style={{ backgroundColor: 'transparent' }}>
                        <img src={'/images/noprofile.webp'} alt="User Avatar" /> 
                        {/* userdata.avatar.url  should be fetched from profile */}
                    </Avatar>
                    <FlexBox style={{ paddingLeft: '21px', flexDirection: 'column', width: '355px' }}>
                        <Text variant='heading-xs' weight='bold'>
                            {userData?.firstName} {userData?.lastName}
                        </Text>
                        <Text variant='body-s' style={{ color: '#566676', paddingBottom: '14px' }}>
                            {userData?.email}
                        </Text>
                        {/* <Text className={`status status-${userData?.status || 'unknown'}`}>
                            {userData?.status === "pending" && (
                                <>
                                    <img
                                        src="/images/pending.png"
                                        alt="Pending"
                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                    />
                                </>
                            )}
                            {userData?.status
                                ? userData.status.charAt(0).toUpperCase() + userData.status.slice(1)
                                : 'Unknown'}
                        </Text> */}
                        <div style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px 8px",
                            width: "106px",
                            border: "1px solid",
                            borderRadius: "8px",
                            backgroundColor: "#E6F7E9",
                            borderColor: "#0A993A",
                        }}>
                            <Text variant='body-m' style={{ color: "#087D2F" }}>Active</Text>
                        </div>
                    </FlexBox>
                </FlexBox>

                <TabContext selectedKey={activeTab} onSelectionChange={(key) => setActiveTab(key as string)}>
                    <Tabs id='tabs'>
                        <Item key="details"><span color='secondary'>Details</span></Item>
                        <Item key="roles"><span color='secondary'>Roles and Privileges</span></Item>
                    </Tabs>
                    <TabPanels height="calc(100vh - 395px)" className="scrollable-element" style={{ overflow: 'auto' }}>
                        <Item key="details">
                            <form className={styles.grid} style={{ padding: '32px 32px 72px 32px' }}>
                                <Label>Email</Label>
                                <TextField 
                                    aria-label="email" 
                                    value={userData?.email || ' '} 
                                    isReadOnly 
                                    isFullWidth 
                                />

                                <Label>First name</Label>
                                <TextField 
                                    value={formValues.firstName || ' '} 
                                    isReadOnly={buttonState} 
                                    isFullWidth
                                    onChange={(event) => handleInputChange('firstName', event)}
                                    aria-label="first name"
                                />

                                <Label>Last name</Label>
                                <TextField 
                                    value={formValues.lastName || ' '} 
                                    isReadOnly={buttonState} 
                                    isFullWidth
                                    onChange={(event) => handleInputChange('lastName', event)}
                                    aria-label="last name"
                                />

                                <Label>Organisation</Label>
                                <TextField 
                                    aria-label="organization" 
                                    value={organizationName || ' '} 
                                    isReadOnly 
                                    isFullWidth 
                                />

                                <Label>Department</Label>
                                <TextField 
                                    value={formValues.department || ' '} 
                                    isReadOnly={buttonState} 
                                    isFullWidth
                                    onChange={(event) => handleInputChange('department', event)}
                                    aria-label="department"
                                />

                                <Label>Job title</Label>
                                <TextField 
                                    value={formValues.jobTitle || ' '} 
                                    isReadOnly={buttonState} 
                                    isFullWidth
                                    onChange={(event) => handleInputChange('jobTitle', event)}
                                    aria-label="job title"
                                />

                                <Label htmlFor="MFA">MFA</Label>
                                {buttonState ? (
                                    <Button
                                        aria-label="enableMFA"
                                        style={{ 
                                            width: '106px', 
                                            backgroundColor: '#00438A2E', 
                                            borderRadius: '8px' 
                                        }}
                                    >
                                        {mfaEnabled ? 'Enabled' : 'Disabled'}
                                    </Button>
                                ) : (
                                    <ToggleButtonGroup
                                        showAsGroup 
                                        isFullWidth
                                        selectionMode="single"
                                        selectedKeys={new Set([mfaEnabled ? 'enabled' : 'disabled'])}
                                        id="MFA" 
                                        name="MFA" 
                                        width="302px"
                                        onAction={handleMfaToggle}
                                    >
                                        <Item key="disabled">Disabled</Item>
                                        <Item key="enabled">Enabled</Item>
                                    </ToggleButtonGroup>
                                )}

                                <Label>Last login</Label>
                                <TextField
                                    value={userData?.lastLogin && userData?.lastLogin !== '-' 
                                        ? format(new Date(userData.lastLogin), 'HH:mm,dd/MM/yyyy') 
                                        : '-'
                                    }
                                    isReadOnly
                                    isFullWidth
                                    aria-label="lastLogin"
                                />

                                <Label>Created</Label>
                                <TextField
                                    value={userData?.createdAt && userData?.createdAt !== '-'
                                        ? format(new Date(userData.createdAt), 'HH:mm,dd/MM/yyyy')
                                        : '-'
                                    }
                                    isReadOnly
                                    isFullWidth
                                    aria-label="created at"
                                />

                                <Label>Account</Label>
                                <Button
                                    aria-label="account-status"
                                    style={{ 
                                        width: '106px', 
                                        backgroundColor: '#00438A2E', 
                                        borderRadius: '8px' 
                                    }}
                                >
                                    Active
                                </Button>

                                {/* {(buttonState||userData?.status === 'pending') ?
                                    <Text
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            padding: "4px 8px",
                                            height: "40px",
                                            width: '106px',
                                            borderRadius: "8px",
                                            backgroundColor: '#00438A2E',// Background color for disabled
                                            color: "#15191E", // Text color for disabled
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {userData?.status ? userData?.status.charAt(0).toUpperCase() + userData?.status.slice(1) : 'Unknown'}
                                    </Text>
                                    :

                                    <ToggleButtonGroup
                                        showAsGroup isFullWidth
                                        selectionMode={'single'}
                                        selectedKeys={EditUserValues.Account ? new Set([EditUserValues.Account]) : undefined}
                                        id={'MFA'} name={'MFA'} width={'302px'}
                                        onAction={(key) => handleToggleAccount(key)}
                                    >
                                        <Item key={Status.Active}>Active</Item>
                                        <Item key={Status.Inactive}>Inactive</Item>
                                    </ToggleButtonGroup>
                                     
                                } */}
                            </form>
                        </Item>

                        {/* Roles and Privileges Tab */}
                        <Item key="roles">
                            <UserRolesPrevilagePreview data={userData} />
                        </Item>

                    </TabPanels>
                </TabContext>
            </CardBody>

            <CardFooter 
                height={'72px'} 
                className={borderTop} 
                style={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    alignItems: 'center', 
                    padding: '16px' 
                }}
            >
                {activeTab === 'details' && (
                    buttonState ? (
                        <Button 
                            variant="primary" 
                            onPress={() => setButtonState(false)}
                        >
                            <Text variant='body-m' weight='bold' style={{ color: 'white' }}>
                                Edit
                            </Text>
                        </Button>
                    ) : (
                        <FlexBox>
                            <Button 
                                variant="secondary" 
                                onPress={handleCancel} 
                                isDisabled={disableSaveButton}
                            >
                                <Text variant='body-m' weight='bold'>Cancel</Text>
                            </Button>
                            <Button 
                                variant="primary" 
                                onPress={handleSave} 
                                isDisabled={disableSaveButton}
                            >
                                <Text variant='body-m' weight='bold' style={{ color: 'white' }}>
                                    Save
                                </Text>
                            </Button>
                        </FlexBox>
                    )
                )}

                {activeTab === 'roles' && (
                    <Button variant="primary">
                        <FlexBox alignItems="center" gap={8}>
                            <img src='/images/plus.svg' alt="Add" 
                            // onPress={handleOpenModal}
                            />
                            <Text variant='body-m' weight='bold' style={{ color: 'white' }}>
                                Add New Privilege
                            </Text>
                        </FlexBox>
                    </Button>
                )}
                {/* this is where the modal for add new privilege */}
                {/* {isModalOpen && <UserModal setShow={setIsModalOpen} addnewprivilege />} */}
            </CardFooter>
        </Card>
    );
};