import React, { createContext, useState, useContext, ReactNode } from "react";
import { countryFormate } from "../../pages/Organization/AllOrganisations/Components/CountriesList";
import { Area } from "react-easy-crop/types";
import { Key } from "@filament/react/types";
import { OrgEntry } from "../../graphql/generated";
// import { OrgStatus } from "../../graphql/generated";

type OrgInfo = {
    formData: organisationAddressInterface;
    setFormData: React.Dispatch<React.SetStateAction<organisationAddressInterface>>
    orgValues: organisationTypeInterface;
    setOrgValues: React.Dispatch<React.SetStateAction<organisationTypeInterface>>
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    country: Key,
    setCountry: React.Dispatch<React.SetStateAction<Key>>;
    mapValues: {
        center:
        { lat: number, lng: number },
        zoom: number,
        suggestions: google.maps.places.AutocompletePrediction[]
    };
    setMapValues: React.Dispatch<React.SetStateAction<{
        center:
        { lat: number, lng: number },
        zoom: number,
        suggestions: google.maps.places.AutocompletePrediction[]
    }>>,
    valueType: { SelectAddType: OrgEntry },
    setValueType: React.Dispatch<React.SetStateAction<{ SelectAddType: OrgEntry }>>,
    MapsCord: number[],
    setMapsCord: React.Dispatch<React.SetStateAction<number[]>>,
    crop: {
        x: number;
        y: number;
    },
    setCrop: React.Dispatch<React.SetStateAction<{
        x: number;
        y: number;
    }>>,
    zoom: number,
    setZoom: React.Dispatch<React.SetStateAction<number>>,
    initalCrop: {
        x: number;
        y: number;
    },
    setInitalCrop: React.Dispatch<React.SetStateAction<{
        x: number;
        y: number;
    }>>,
    orginitialCropImage: string,
    orgsetInitialCropImage: React.Dispatch<React.SetStateAction<string>>,
    orgIndex:number,
    setOrgIndex:React.Dispatch<React.SetStateAction<number>>



}
// Create SettingsContext
const OrganizationContext = createContext<OrgInfo>({
    formData: {
        organizationName: "",
        organizationAddress: "",
        city: "",
        state: "",
        postcode: "",
        organizationWebsite: "",
        vat: "",
        duns: "",
        placeId: '',

    },
    setFormData: () => { },
    orgValues: {
        orgTypeValue: [],
        disableNext: true,
        disableBack: false,
        displayCancle: true,
        OthersTextArea: "",
        wizardStepper: "",
        file: null,
        phoneNumber: "",
        emailId: "",
        imgurl: "",
        croppedAreaPixels: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        },
        croppedImg: '',
        countryRegex: new RegExp(''),
        validationCountry: { country: '', vatRegex: null },
        countryCode: '',
        region: '',
        country:'',
        // status: OrgStatus.Active,
        
    },
    setOrgValues: () => { },
    inputValue: '',
    setInputValue: () => { },
    country: '',
    setCountry: () => { },
    mapValues: {
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 2,
        suggestions: []
    },
    setMapValues: () => { },
    valueType: { SelectAddType: OrgEntry.Address },
    setValueType: () => { }
    ,
    MapsCord: [0, 0],
    setMapsCord: () => [0, 0],
    crop: {
        x: 0,
        y: 0
    },
    setCrop: () => { },
    zoom: 0,
    setZoom: () => 1,
    initalCrop: {
        x: 0,
        y: 0
    },
    setInitalCrop: () => { },
    orginitialCropImage:'',
    orgsetInitialCropImage:()=>{},
    orgIndex:0,
    setOrgIndex:()=>{}

});
export interface organisationAddressInterface {
    organizationName: string,
    organizationAddress: string,
    city: string,
    state: string,
    postcode: string,
    organizationWebsite: string,
    vat: string,
    duns: string,
    placeId: string
}

export interface organisationTypeInterface {
    orgTypeValue: string[],
    disableNext: boolean,
    displayCancle: boolean,
    disableBack: boolean,
    OthersTextArea: string,
    wizardStepper: string,
    file: File | null,
    croppedAreaPixels: Area,
    imgurl: string,
    croppedImg: string
    phoneNumber: string,
    emailId: string,
    countryRegex: RegExp,
    validationCountry: countryFormate,
    countryCode: string,
    region: string,
    country: string
    // status: OrgStatus

}

export const initalOrgValues: organisationTypeInterface = {
    orgTypeValue: [],
    disableNext: true,
    disableBack: false,
    displayCancle: true,
    OthersTextArea: "",
    wizardStepper: "",
    file: null,
    phoneNumber: "",
    emailId: "",
    imgurl: "",
    croppedAreaPixels: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    },
    croppedImg: '',
    countryRegex: new RegExp(''),
    validationCountry: { country: '', vatRegex: null },
    countryCode: '',
    region: '',
    country: '',
    // status: OrgStatus.Active,

}

// Create SettingsProvider component
export const OrganizationProvider = ({ children }: { children: ReactNode }) => {
    const [formData, setFormData] = useState<organisationAddressInterface>({
        organizationName: "",
        organizationAddress: "",
        city: "",
        state: "",
        postcode: "",
        organizationWebsite: "",
        vat: "",
        duns: "",
        placeId: ''
    });
    const [inputValue, setInputValue] = useState("");
    const [country, setCountry] = useState<Key>('')
    const [valueType, setValueType] = useState({
        SelectAddType: OrgEntry.Address, // No default selection
    });
    const [orgIndex, setOrgIndex] = useState<number>(0)
    
    const [mapValues, setMapValues] = useState<{
        center: { lat: number; lng: number };
        zoom: number;
        suggestions: google.maps.places.AutocompletePrediction[];
    }>({
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 2,
        suggestions: []
    })
    const [orgValues, setOrgValues] = useState<organisationTypeInterface>(initalOrgValues)
    const [MapsCord, setMapsCord] = useState<number[]>([])

    const [zoom, setZoom] = useState<number>(1); // Add zoom state
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [initalCrop, setInitalCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [orginitialCropImage, orgsetInitialCropImage] = useState<string>('');

    return (
        <OrganizationContext.Provider value={{
            formData,
            setFormData,
            orgValues,
            setOrgValues,
            inputValue,
            setInputValue,
            country,
            setCountry,
            mapValues,
            setMapValues,
            valueType,
            setValueType,
            MapsCord,
            setMapsCord,
            crop,
            setCrop,
            zoom,
            setZoom,
            initalCrop,
            setInitalCrop,
            orginitialCropImage,
            orgsetInitialCropImage,
            orgIndex,
            setOrgIndex
        }}>
            {children}
        </OrganizationContext.Provider>
    );
};

// Custom hook to access SettingsContext
export const useOrgData = () => useContext(OrganizationContext);
export default OrganizationContext;