import { GoogleApiKey } from "../config";

export async function getStateFromAddress(address: string): Promise<string> {
    const apiKey = GoogleApiKey; // Replace with your Google Maps API Key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data: {
            status: string;
            results: {
                address_components: { long_name: string; types: string[] }[];
            }[];
        } = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
            const components = data.results[0].address_components;
            const state = components.find(comp => comp.types.includes("administrative_area_level_1"));
            return state ? state.long_name : "State not found";
        } else {
            return "Invalid address";
        }
    } catch (error) {
        console.error("Error fetching state:", error);
        return "Error fetching state";
    }
}

