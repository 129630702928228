import authConfig from "../auth0/authConfig";

export const getUserInfo = async (accessToken: string) => {
    const response = await fetch(`https://${authConfig.domain}/userinfo`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,  // Pass the access token
        },
    });

    return response;
};