/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { FlexBox, Label, TextField } from "@filament/react";
import { useUnifiedContexts } from "../../../../components/Context/Context";

export default function OrgAddress({ isVATValid , isDUNsValid}:
    {isVATValid:()=> boolean; isDUNsValid:()=> boolean}) {
    const {setFormData} = useUnifiedContexts()
    const getPlaceholder = (field: string) => {
        switch (field) {
        
        case "organizationName":
            if (isVATValid()) {
                return `Wade Inc`;
            } else if (isDUNsValid()) {
                return `Test_Wade`;
            }
            return "";

        case "organizationAddress":
            if (isVATValid()) {
                return "Delta House, 5th Floor Bangra Road, Kuloor - Kavoor Rd, Kuloor, Kodikal, Mangaluru, Karnataka 575013, India";
            } else if (isDUNsValid()) {
                return "Delta House, 5th Floor Bangra Road, Kuloor - Kavoor Rd, Kuloor, Kodikal, Mangaluru, Karnataka 575013, India";
            }
            return "";

        case "city":
            if (isVATValid()) {
                return "Rome ";
            } else if (isDUNsValid()) {
                return "Rome ";
            }
            return "";

        case "postcode":
            if (isVATValid()) {
                return "00186 ";
            } else if (isDUNsValid()) {
                return "00186 ";
            }
            return "";

        case "organizationWebsite":
            if (isVATValid()) {
                return "https://www.wadeinc.com ";
            } else if (isDUNsValid()) {
                return "https://www.wadeinc.com ";
            }
            return "";

        default:
            return "Value";
        }
    };

    useEffect(()=>{
        setFormData(prev=>({
            ...prev,
            organizationName: getPlaceholder("organizationName"),
            organizationAddress: getPlaceholder("organizationAddress"),
            city: getPlaceholder("city"),
            postcode: getPlaceholder("postcode"),
            organizationWebsite: getPlaceholder("organizationWebsite")
        }))
    },[isVATValid,isDUNsValid])

    return (
        <>
            <FlexBox style={{ flexDirection: "column", gap: "24px", paddingTop: "24px" }}>
                <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                    <Label>Organization Name</Label>
                    <TextField
                        value={getPlaceholder("organizationName")}
                        placeholder="Organization Name"
                        isFullWidth
                        type="text"
                        aria-label="organization name"
                        isReadOnly
                    />
                </FlexBox>

                <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                    <Label>Organization Address</Label>
                    <TextField
                        value={getPlaceholder("organizationAddress")}
                        placeholder="Organization Address"
                        isFullWidth
                        type="text"
                        aria-label="organization address"
                        isReadOnly
                    />
                </FlexBox>

                <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                    <Label>Postcode</Label>
                    <TextField
                        value={getPlaceholder("postcode")}
                        placeholder="Postcode"
                        // type="number"
                        isFullWidth
                        aria-label="postcode"
                        isReadOnly
                    />
                </FlexBox>

                <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                    <Label>Organization Website</Label>
                    <TextField
                        value={getPlaceholder("organizationWebsite")}
                        placeholder="Organization Website"
                        isFullWidth
                        type="url"
                        aria-label="url"
                        isReadOnly
                    />
                </FlexBox>
            </FlexBox>
        </>
    );
}
