/* eslint-disable react-hooks/exhaustive-deps */
import { FlexBox, Label, Stepper, Text, TextField, Tooltip } from "@filament/react";
import React, { useEffect } from "react";
import { numberToWords } from "../../../../CommonMethods/numberToWords";
import { useUnifiedContexts } from "../../../../components/Context/Context";


export default function LevelValueSetter({
    levels,
    error,
    setError
}:{levels:string[],
    error: {isError: boolean; duplicateLabel: string | null },
    setError:React.Dispatch<React.SetStateAction<{ isError: boolean; duplicateLabel: string | null }>>
}){

   
    const {levelValues,setLevelValues} = useUnifiedContexts()
   
    useEffect(() => {
        setLevelValues(
            levels.map((level) => ({
                label: level.charAt(0).toUpperCase() + level.slice(1), // Assign the level string if needed
                number_of_units: NaN,
            }))
        );
    }, []);

    
    return(
        <FlexBox 
            flexDirection="column"
            gap={20}>
            {levels.map((item,index)=>(<FlexBox gap={20}
                key={index} 
                flexDirection="column">
                <FlexBox 
                    style={{
                        display: "grid",
                        gridTemplateColumns: "120px 1fr", // Fixed width for label, flexible for input
                        gap: "22px", // Reduce space between label & input
                        alignItems: "center",
                        width: "100%" // Ensures full width usage
                    }}
                >
                    <Label 
                        style={{
                            minWidth: "120px",  // Fixed width to prevent movement
                            textAlign: "left",  // Ensures consistent alignment
                            whiteSpace: "nowrap", // Prevents text wrapping
                            overflow: "hidden", 
                            textOverflow: "ellipsis" // Adds "..." if label is too long
                        }}
                    >
                        {`Level ${numberToWords(index + 1)}`}
                    </Label>

                    <div style={{ position: "relative", width: "400px" }}>
                        <TextField 
                            width={400} 
                            style={{ marginLeft: "10px" }}
                            isInvalid={error.duplicateLabel === levelValues?.[index]?.label}
                            onFocus={() => setError({ isError: false, duplicateLabel: null })}
                            placeholder="Enter Level Name"
                            errorMessages={error.duplicateLabel === levelValues?.[index]?.label ? [`Label must be unique!`] : []}
                            value={levelValues?.[index] === undefined ? '' : levelValues[index].label}
                            onChange={(e) => {
                                const newLevelValues = [...levelValues];
                                newLevelValues[index].label = e;
                                setLevelValues(newLevelValues);
                            }}
                            onBlur={() => {
                                const newLevelValues = [...levelValues];
                                newLevelValues[index].label = newLevelValues[index].label.trim(); // Trim only on blur
                                setLevelValues(newLevelValues);
                            }}
                            aria-label="level_label"
                            showClearButton
                        />

                        {levelValues[index]?.label.trim() !== '' && levels[index] !== 'room' && (
                            <FlexBox
                                style={{
                                    backgroundColor: "#00438A2E",
                                    height: "32px",
                                    width: "32px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px",
                                    position: "absolute",
                                    right: "43px", // Adjust to fit inside TextField
                                    top: "12%",
                                    zIndex: "1",
                                    borderRadius: "6px",
                                    border: "1px solid #0052A3",
                                    display: "flex",
                                }}
                            >
                                <p style={{ color: "#0052A3", margin: "0" }}>
                                    {levelValues[index]?.label !== undefined &&
                        levelValues[index]?.label?.charAt(0)?.toUpperCase()}
                                </p>
                            </FlexBox>
                        )}
                    </div>
                </FlexBox>

                <FlexBox 
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto", // Same structure as above
                        alignItems: "center",
                        gap: "10px",
                        width: "100%"
                    }}
                >
                    <Label>No. of {''}
                        {levelValues[index]?.label.trim() === "" ? (
                            item.charAt(0).toUpperCase() + item.slice(1)+'s'
                        ) : levelValues[index]?.label.trim().length<=8?levelValues[index]?.label?.trim()+'s':(
                            <Tooltip
                                content={
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                            wordBreak: "break-word",
                                            overflowWrap: "break-word",
                                            whiteSpace: "normal",
                                        }}
                                    >
                                        {levelValues[index]?.label?.trim()?.replace(/_/g, " ").match(/.{1,100}/g)?.join("\n")+'s'}
                                    </span>
                                }
                                placement="bottom"
                            >
                                <Text>
                                    {levelValues[index]?.label?.trim().replace(/_/g, " ").slice(0, 5) + "...s"}
                                </Text>
                            </Tooltip>
                        )}
                    </Label>
                    <Stepper 
                        aria-label="zones" 
                        placeholder={levels[index]==='room'?'Open - OR- Type a no.':
                            `Enter number of ${levels[index]?.charAt(0)?.toLowerCase() + 
                                levels[index]?.slice(1)}s`}
                        value={levelValues[index]?.number_of_units}
                        onChange={(e)=>{
                            if(Number(e)<=50 && Number(e)>=1){
                                const newLevelValues = [...levelValues];
                                newLevelValues[index].number_of_units = (e===undefined)?NaN:Number(e);
                                setLevelValues(newLevelValues);
                            }
                        }}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>)=>{
                            if(Number(e.target.value)<=50 
                                && Number(e.target.value)>=1){
                                const newLevelValues = [...levelValues];
                                newLevelValues[index].number_of_units = (e.target.value==='')?NaN:Number(e.target.value);
                                setLevelValues(newLevelValues);
                            }
                        }}
                        maxValue={50}
                        minValue={1}
                        style={{paddingLeft:"20px"}} 
                        width={400} />
                </FlexBox>
            </FlexBox>))}
        </FlexBox>
    )
}