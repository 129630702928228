/* eslint-disable max-len */

import { Button, FlexBox, Text } from "@filament/react";

/* eslint-disable react/react-in-jsx-scope */
export default function SuccessScreen() {
    return (
        <FlexBox
            alignItems="center"
            justifyContent="center"
            style={{
                // height: "300px",
                textAlign: "center",
                flexDirection: "column",
                paddingTop: "18%",
            }}
        >
            {/* Icon Section */}
            <FlexBox flexDirection="column" 
                style={{
                    justifyContent: "center",
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',gap: '36px' }}>
                    <img
                        src="/images/Icon.svg" // Replace with your icon path
                        alt="success"
                        width={'128px'}
                        height={'128px'}
                    />

                    <Text style={{ fontSize: '24px', fontWeight: '700', paddingBottom: '30px' }}>Site successfully created!</Text>
                    <Button variant="primary" width={121} style={{
                        paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px'
                    }}>Buy License</Button>
                </div>

            </FlexBox>
        </FlexBox>
    );
}