/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */

import React, { useContext, useRef } from "react"
import {
    Button, TabContext, Tabs, TabPanels, Item, Paragraph,
    FlexBox,
    TextField,
    Label,
    ToggleButtonGroup,
    Dialog,
    DialogActions,
    DialogContainer,
    DialogContent,
    DialogTitle,
    NotificationsQueue,
    NotificationRenderer,
} from "@filament/react";
import { useEffect } from "react";
import { RootState } from "../../../redux/store";
import { StorePrivalgeValue } from "../../../redux/PrivilageTypeState";
import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';
import { ApolloError } from "@apollo/client";
import {
    IsoAlpha2CountryCode,
    OrgType,
    SiteStructurePreset,
    SortOrder,
    useGetOrganizationsQuery,
    useOrganizationCreateMutation,
    useOrganizationLogoCreateMutation,
    useSiteActivateMutation,
    useSiteCreateMutation,
    useUserAddMutation
} from "../../../graphql/generated";
import { handleSaveState } from "../../../redux/UserTabState";
import { storeUserTableTabs } from "../../../redux/userTableState";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import UserModal from "./UserModal";
import UserPrevilagePreview from "./UserPrevilagePreview";
import useDebounce from "./Debounce";
import AddOrganization from "../AllOrganisations/Components/AddOrganization";
import { Key, Selection } from "@filament/react/types";
import { ToastNotification } from "../../../CommonMethods/ToastNotification";
import OrgPreview from "../AllOrganisations/Components/OrgPreview";
import OrgSuccess from "../AllOrganisations/Components/OrgSuccess";
import GlobalContext, { triggerRefetch } from "../../../components/Context/GlobalContext";
import { useUnifiedContexts } from "../../../components/Context/Context";
import { initalOrgValues } from "../../../components/Context/OrganizationContext";
import parsePhoneNumber from 'libphonenumber-js'
import { OrgEntry } from "../../../graphql/generated";
import SiteWizard from "../../Hub/Components/SiteWizard";
import { SiteData } from "../../../components/Context/SiteContext";
import PreviewSite from "../../Hub/AllSites/Components/PreviewSite";
import SuccessScreen from "../../Hub/AllSites/Components/Success";
import { Trie } from "../../Hub/Site Structure/components/TrieDataStructure";
import { ConfigData } from "../../../components/Context/ConfigurationContect";
import { handleOrgIdList, handleSiteIdToActivate } from "../../../redux/persistedStates";
import { getStateFromAddress } from "../../../CommonMethods/getStateFromAddress";
import { privalgeValues } from "./SelectPrevilageModal";

export const Default = () => {
    const { isOpen,
        setIsOpen,
        visibleOrg,
        setVisibleOrg,
        // setLevelValues, 
        formData,
        setFormData,
        orgValues,
        setOrgValues,
        // setInputValue,
        // setCountry,
        setMapValues,
        mapValues,
        valueType,
        setPathOfSelectedLabel,
        setIsNextPreviewButtonCliked,
        setPreviewTableData,
        setPreviewRowSpanMap,
        setPreviewTrie,
        setPreviewPreset,
        setIsCustomEdit,
        setValueType,
        formSiteData,
        setInitalCrop,
        crop,
        setCrop,
        initalCrop,
        selectedPreset,
        siteArrayIndex,
        SetSiteArrayIndex,
        setMapSiteValues, setEditedSiteId,
        orgIndex, setOrgIndex,
        setSelectedPreset, isNextPreviewButtonCliked
    } = useUnifiedContexts()
    const { buttonRef, configTab, } = ConfigData()
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
    const [Email, SetEmail] = useState('')
    const { Tabss, SetTabs } = useContext(GlobalContext)
    const [isSiteLoading,setIsSiteLoading] = useState(false)
    // const [allTasks, setAllTasks] = useState<string[]>([''])
    // needed for vat and duns check
    // const vatPattern: RegExp = /^(?=.*[0-9])[A-Z0-9]+$/;
    const dunPattern: RegExp = /^[1-9][0-9]{8}$/;
    const postPattern: RegExp = /^[A-Za-z0-9][A-Za-z0-9\\-\\s]{0,10}[A-Za-z0-9]$/;

    const [mobileCode, setMobileCode] = useState<Selection>(new Set(['000']));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setIsUploading] = useState(false);
    const organizationIds = useSelector((state: RootState) => state.persistedState.organisationIds)
    const siteActivateId = useSelector((state: RootState) => state.persistedState.siteIdToActivate)
    const { SitenextRef, SitepreviousRef, siteValues, setSiteValues, reInitialize, mapSiteValues } = SiteData()
    const previlageType = useSelector((state: RootState) => state.PrivilageTypeState?.privlageValue)
    const selectPrevilageValues: privalgeValues = useSelector((state: RootState) => state.SelectPrivilageState)
    const selectedRoles = useSelector((state: RootState) => state.PrivilageTypeState.SelectedRoles)
    const saveState = useSelector((state: RootState) => state.userTabState.SaveState)
    const uerTabs = useSelector((state: RootState) => state.userTable.userTableTabs)
    // const [InviteUser] = useInviteUserMutation()
    const [createSite] = useSiteCreateMutation()
    const [activateSite] = useSiteActivateMutation()
    const nextRef = useRef<(() => void) | undefined>(undefined);
    const previousRef = useRef<(() => void) | undefined>(undefined);
    const [phoneNumberorEmailInvalid, setPhoneNumberorEmailInvalid] = useState({
        phoneNumber: false,
        email: false
    })
    const dispatch = useDispatch()

    const debaounceValue = useDebounce(Email, 500)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setShow] = useState(false)
    const [index, setIndex] = useState(-1)
    const [onClickDisable, setOnClickDisable] = useState(false)
    const [selectedKey, setSelectedKey] = useState<string | null>('disable');
    // const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [createUser] = useUserAddMutation();

    // const [inviteLoading, setInvteLoading] = useState(false)
    const dismiss = useCallback(() => {
        setIsOpen(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const orgComponents: JSX.Element[] = [<OrgPreview formData={formData}
        mobileCode={mobileCode} key={1} />, <OrgSuccess key={2} />]


    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1500);
    const [completedSteps, setCompletedSteps] = useState<Key[]>([]);

    const [organizationId, setOrganizationId] = useState<string>(''); // State to store organizationId

    const siteStructurePresetMap: Record<string, SiteStructurePreset> = {
        "Preset 1": SiteStructurePreset.Preset1,
        "Preset 2": SiteStructurePreset.Preset2,
        "Preset 3": SiteStructurePreset.Preset3,
        "Custom site structure": SiteStructurePreset.Custom,
    
    };
    const getMappedPreset = (preset: string | null): SiteStructurePreset => {
        return siteStructurePresetMap[preset || "Custom site structure"]; // Defaulting to "Custom site structure"
    };
    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1500);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        switch (configTab) {
        case 'organisation':
            SetTabs('tab4')
            break;
        case 'site':
            SetTabs('tab2')
            break;
        case 'tab6':
            SetTabs(configTab);
            break;
        case 'hub':
            SetTabs('tab2')
            break;
        case 'configuration':
            SetTabs('tab6')
            break;
        default:
            SetTabs('tab1')

        }

        //over here the isOpen is mentioned because the useEffect should run whenever the modal gets opened or re-opened 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configTab, isOpen])

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (Email.length === 0) {
            setIsEmailValid(true)
            return;
        }

        if (!emailRegex.test(Email)) {
            setIsEmailValid(false)

        }
        else {
            setIsEmailValid(true)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debaounceValue])


    // const privilageAndRoles = useSelector((state: RootState) => state?.PrivilageTypeState?.SelectedRoles)
    const Tasks = useSelector((state: RootState) => state?.PrivilageTypeState?.Tasks)
    const result = Object.entries(Tasks).map(([key, values]) => {
        return {
            key,
            values, // This will contain the array of values for the current key
        };
    });


    useEffect(() => {
        if (result[0].values) {
            // const keys = Object.keys(result[0].values);
            // const allValues = keys.map((key) => result[0].values[key]).flat();
            // const uniqueValues = Array.from(new Set(allValues));
            // setAllTasks([...uniqueValues]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Tasks])

    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

 

    const { data } = useGetOrganizationsQuery({
        variables: {
            input: {
                limit: 10,
                offset: 0,
                sortBy: "name",
                sortOrder: SortOrder.Desc
            }
        }
    });

    // Update organizationId state when data is fetched
    useEffect(() => {
        if (data?.organizations?.items?.[0]?.id) {
            dispatch(handleOrgIdList(data?.organizations?.items?.map(item => item?.id)))
            setOrganizationId(data?.organizations?.items?.[0]?.id);
        }
    }, [data, dispatch]);



   
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        if (event.currentTarget.id === 'userForm') {
            setOnClickDisable(true)
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            //const formEntries = Object.fromEntries(formData.entries())

            const email = formData.get('email') as string
            const firstName = formData.get('firstName') as string
            const lastName = formData.get('lastName') as string
            const jobTitle = formData.get('JobTitle') as string
            const department = formData.get('department') as string
            const enableMFA = selectedKey === 'enable'

            if (!emailRegex.test(email)) {
                setIsEmailValid(false)
                return;
            }


            try {
                const response = await createUser({
                    variables: {
                        input: {
                            organizationId: organizationId, //how to add id as sring
                            firstName: firstName.length === 0 ? "-" : firstName,
                            lastName: lastName.length === 0 ? "-" : lastName,
                            email: email,
                            enableMFA: enableMFA,
                            department: department,
                            jobTitle: jobTitle ? jobTitle : '',
                            preferredLanguage: 'English',
                            expiresAt: selectPrevilageValues.Date,
                            ...(previlageType === 'Select Task'
                                ? { roles: selectedRoles }
                                : { rolePrivileges: previlageType === 'Select Feature' ? selectedRoles : previlageType === 'All Features' ? ["FullAccess"] : [] }
                            )
                        }
                    }
                });

                // Check if response exists and doesn't contain an error
                if (response?.data?.userAdd && !response.errors) {
                    NotificationsQueue.toast(
                        ({ close }) => <ToastNotification close={close} message="User added successfully" />,
                        {
                            signal: 'success',
                            orientation: 'horizontal',
                            showCloseButton: true,
                            timeout: 6000,
                        }
                    );

                    triggerRefetch();

                    // Reset form state
                    setIsEmailValid(true);
                    dispatch(StorePrivalgeValue(''));
                    dispatch(storeUserTableTabs({ tabs: 'tab2', change: !uerTabs.change }));
                    setSelectedKey('disable');
                    setIsOpen(false);

                }

            } catch (err) {
                let errorMessage = 'An error occurred while adding the user.';

                if (err instanceof ApolloError) {
                    if (err.graphQLErrors?.length > 0) {
                        const graphQLError = err.graphQLErrors[0];
                        if (graphQLError.extensions?.code === 'INSUFFICIENT_PERMISSIONS') {
                            errorMessage = 'Insufficient permissions';
                        } else {
                            errorMessage = graphQLError.extensions?.message as string || graphQLError.message;
                        }
                    } else if (err.networkError) {
                        errorMessage = 'Network error occurred. Please check your connection.';
                    } else if (err.message === 'Response not successful: Received status code 409') {
                        errorMessage = 'Error! User already exists';
                    }
                }

                NotificationsQueue.toast(
                    ({ close }) => <ToastNotification close={close} message={errorMessage} />,
                    {
                        signal: 'error',
                        orientation: 'horizontal',
                        showCloseButton: true,
                        timeout: 6000,
                    }
                );

                setOnClickDisable(false)
            } finally {
                setIsEmailValid(true);
                dispatch(StorePrivalgeValue(''));
                dispatch(storeUserTableTabs({ tabs: 'tab2', change: !uerTabs.change }));
                setSelectedKey('disable');
                setIsOpen(false);
            }
        }
    }

    const EmailValidation = () => {

        if (isEmailValid) {
            return undefined

        }

        return "there is a error in the code"
    }


    const handleToggle = (key: Key) => {
        if (key !== selectedKey) {
            setSelectedKey(key.toString());
        }
    }

    const handleClose = () => {
        reInitialize() //its related to the siteConetxt these function is 
        // used to reset the values used in the siteContext so if you wan
        //  to reinitalize any values when click on 
        // close so you can use these function
        SetSiteArrayIndex(0)
        setMapSiteValues(prev => ({ ...prev, center: { lat: 0, lng: 0 }, lat: 0, lng: 0, zoom: 2 }))
        setSelectedPreset(null)
        setSiteValues(prev => ({ ...prev, wizradState: 'Site_Details' }))
        setVisibleOrg(true)
        setOrgIndex(0)
        setFormData({
            organizationName: "",
            organizationAddress: "",
            city: "",
            state: "",
            postcode: "",
            organizationWebsite: "",
            vat: "",
            duns: "",
            placeId: ''
        })
        dispatch(handleSiteIdToActivate(''))
        setOrgValues(initalOrgValues)
        setMobileCode(new Set(['000']))
        setIsOpen(false);
        setIsEmailValid(true);
        setShow(false);
        setIndex(-1)
        // setEmail("");
        SetEmail('')
        // setCountry('')
        // if (Tabss === 'tab5') {
        //     clearInvitationStates()
        // }

        setOrgValues(initalOrgValues)
        setValueType({
            SelectAddType: OrgEntry.Address
        })
        setCompletedSteps([])
        dispatch(handleSaveState(false))
        setSelectedKey('disable')
        setMapValues((prev) => ({ ...prev, zoom: 2 }))
        setSelectedPreset(null)
        setPathOfSelectedLabel([])
        setIsNextPreviewButtonCliked(false)
        setPreviewTableData([])
        setPreviewRowSpanMap([])
        setPreviewTrie(new Trie())
        setPreviewPreset(null)
        setIsCustomEdit(false)

        // setLevelValues([{ label: '', number_of_units: NaN }])

    }
   
    const [createOrg] = useOrganizationCreateMutation()
    const [organizationLogoCreateMutation] = useOrganizationLogoCreateMutation();
    const SiteArray = [<SiteWizard key={0} orgId=
        {data?.organizations?.items?.[0]?.id} country={data?.organizations?.items?.[0]?.country} />, <PreviewSite key={1} />, <SuccessScreen key={2} />]
    const onNextPress = async () => {
        if (Tabss == 'tab2') {

            if (siteValues.wizradState === 'Site_Details') {
                // Basic API integration will be here 
                try {
                    let state: string = ''
                    setLoading(true)
                    getStateFromAddress(data?.organizations?.items?.[0]?.address ?? '').then(resolvedState => state = resolvedState)
                    const { data: siteData, errors } = await createSite({
                        variables: {
                            input: {
                                name: formSiteData.siteName,
                                organizationId: organizationIds?.[0],
                                country: data?.organizations?.items?.[0]?.country?.split(':')[0]  as IsoAlpha2CountryCode,
                                address: formSiteData.siteAddress,
                                latitude: mapSiteValues.center.lat,
                                longitude: mapSiteValues.center.lng,
                                postcode: formSiteData.postcode,
                                city: data?.organizations?.items?.[0]?.city ?? '',
                                shortName: formSiteData.shortName.length > 0 ? formSiteData.shortName : " ",
                                state: state,
                                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

                            }
                        },
                        refetchQueries: ['GetSites'], // Add this if you're using named queries
                        // Alternatively, you can use the following to refetch specific queries
                        // refetchQueries: [{ query: GET_SITES_QUERY, variables: { organizationId } }]
                    });

                    if (siteData?.siteCreate && !errors) {
                        setLoading(false)
                        dispatch(handleSiteIdToActivate(siteData?.siteCreate?.id))

                    }
                } catch (err) {
                    setLoading(false)
                    let errorMessage = 'An error occurred while creating the site.';

                    if (err instanceof ApolloError) {
                        if (err.graphQLErrors?.length > 0) {
                            const graphQLError = err.graphQLErrors[0];
                            if (graphQLError.extensions?.code === 'INSUFFICIENT_PERMISSIONS') {
                                errorMessage = 'You do not have permission to create a site';
                            } else if (graphQLError.extensions?.code === 'SITE_ALREADY_EXISTS') {
                                errorMessage = 'A site with this name already exists';
                            } else {
                                errorMessage = graphQLError.extensions?.message as string || graphQLError.message;
                            }
                        } else if (err.networkError) {
                            errorMessage = 'Network error occurred. Please check your connection.';
                        }
                    }

                    NotificationsQueue.toast(
                        ({ close }) => <ToastNotification close={close} message={errorMessage} />,
                        {
                            signal: 'error',
                            orientation: 'horizontal',
                            showCloseButton: true,
                            timeout: 6000,
                        }
                    );
                }
            }
            if (siteArrayIndex === 1) {
                setIsSiteLoading(true)
                try {
                    const variables: { id: string; siteStructurePreset?: SiteStructurePreset } = {
                        id: siteActivateId,
                        ...(selectedPreset !== "Open Structure" ? { siteStructurePreset: getMappedPreset(selectedPreset) } : {})
                    };
                    const res = await activateSite({
                        variables: variables

                    });
                    if (res) {
                        setIsSiteLoading(false)
                        NotificationsQueue.toast(
                            ({ close }) => <ToastNotification close={close} message="Site created successfully" />,
                            {
                                signal: 'success',
                                orientation: 'horizontal',
                                showCloseButton: true,
                                timeout: 6000,
                            }
                        );

                    }

                } catch (err) {
                    console.error(err)

                } finally {
                    setIsSiteLoading(false)
                    dispatch(handleSiteIdToActivate(''))
                    triggerRefetch();
                    SetSiteArrayIndex(2)
                    // Reset form state
                    setSelectedKey('disable');
                    // setIsOpen(false);
                    setOnClickDisable(false); // Make sure to re-enable the button

                }
            }
            if (siteValues.wizradState === 'Upload_Images' || siteValues.wizradState === 'Site_Details') {
                SitenextRef.current?.()
            }
            else if (siteValues.wizradState === 'Site_Structure' && siteArrayIndex < 2) {
                SetSiteArrayIndex(prev => (prev < 2 ? prev + 1 : prev));
            }

        }

      

       
        else if (!visibleOrg && orgIndex === 0) {
            const codevalue = Array.from(mobileCode)?.[0]?.toString().split(':')[1]?.trim() || '';
            setOrgIndex((prev) => ((prev + 1) % orgComponents.length))
            try {
                const { data } = await createOrg({
                    variables: {
                        input: {
                            name: formData.organizationName,
                            orgType: orgValues.orgTypeValue.map(value => value as OrgType),
                            country: orgValues.country.split(" : ")[1] as IsoAlpha2CountryCode,
                            entryType: valueType.SelectAddType,
                            address: formData.organizationAddress,
                            city: formData.city,
                            postCode: formData.postcode,
                            orgLatitude: mapValues.center.lat,
                            orgLongitude: mapValues.center.lng,
                            website: formData.organizationWebsite,
                            email: orgValues.emailId,
                            phonenumber: orgValues.phoneNumber,
                            phoneCode: codevalue,
                            otherOrgType: orgValues.OthersTextArea,
                        }
                    }
                });

                if (data?.organizationCreate?.id) {

                    if (orgValues.croppedImg) {
                        setIsUploading(true);
                        setTimeout(async () => {
                            try {
                                const logoResponse = await organizationLogoCreateMutation({
                                    variables: {
                                        input: {
                                            id: data?.organizationCreate?.id,
                                        }
                                    }
                                });

                                if (logoResponse.data?.organizationLogoCreate?.url) {
                                    const base64Response = await fetch(orgValues.croppedImg);
                                    const blob = await base64Response.blob();
                                    const uploadResponse = await fetch(logoResponse.data.organizationLogoCreate.url, {
                                        method: 'PUT',
                                        body: blob,
                                        headers: {
                                            'Content-Type': blob.type,
                                        },
                                    });
                                    if (!uploadResponse.ok) {
                                        throw new Error(`Upload failed with status ${uploadResponse.status}`);
                                    }
                                    setOrgValues(prev => ({
                                        ...prev,
                                        imgurl: logoResponse?.data?.organizationLogoCreate.url || '/images/Logo.svg'
                                    }));
                                }
                            } catch (logoError) {
                                console.error("Logo upload failed:", logoError);
                                NotificationsQueue.toast(
                                    ({ close }) => (
                                        <ToastNotification
                                            close={close}
                                            message="Failed to upload organization logo"
                                        />
                                    ),
                                    {
                                        signal: 'error',
                                        orientation: 'horizontal',
                                        showCloseButton: true,
                                        timeout: 6000,
                                    }
                                );
                            } finally {
                                setIsUploading(false);
                            }
                        }, 3000);
                    }

                    dispatch(storeUserTableTabs({ tabs: 'tab1', change: !uerTabs.change }));
                    setTimeout(() => {
                        triggerRefetch();
                    }, 2000);
                } else {
                    console.error("Organization creation response missing ID");
                    dispatch(storeUserTableTabs({ tabs: 'tab1', change: !uerTabs.change }));
                }

            } catch (error) {
                NotificationsQueue.toast(
                    ({ close }) => (
                        <ToastNotification
                            close={close}
                            message="Organization creation failed"
                        />
                    ),
                    {
                        signal: 'error',
                        orientation: 'horizontal',
                        showCloseButton: true,
                        timeout: 6000,
                    }
                );
                handleClose()
                console.error("Organization creation failed:", error);
            }
        }

        else if (orgValues.wizardStepper === 'details') {
            const fullPhoneNumber = `+${Array.from(mobileCode)?.[0]?.toString().split(':')[1]?.
                trim()}${orgValues.phoneNumber}`
            const phoneNumberObj = parsePhoneNumber(fullPhoneNumber)
            if (!phoneNumberObj?.isValid() && orgValues.phoneNumber !== '' && mobileCode.toString() !== '') {
                setPhoneNumberorEmailInvalid((prev) => ({ ...prev, phoneNumber: true }))
            }
            else if (!emailRegex.test(orgValues.emailId)) {
                setPhoneNumberorEmailInvalid((prev) => ({ ...prev, email: true }))
            } else {
                nextRef.current?.()
            }
        }
        else if (orgValues.wizardStepper === 'logo') {
            setInitalCrop({
                x: crop.x,
                y: crop.y
            })

            setVisibleOrg(false)

        }

        else {
            if (Tabss === 'tab4') {
                nextRef.current?.()
            }
        }
    }
    const onCancelPress = () => {

        if (Tabss === 'tab2') {
            if (siteValues.wizradState === 'Site_Structure' && siteArrayIndex > 0) {
                SetSiteArrayIndex((prev) => (prev - 1) % SiteArray.length)


            }
            else {

                SitepreviousRef.current?.()
            }
        }
        else if (Tabss === 'tab5' && index !== -1) {
            setIndex(prev => prev - 1)
        } else {

            if (Tabss === 'tab4' && orgValues.wizardStepper === 'logo' && !visibleOrg) {

                setVisibleOrg(true)
            }
            else {
                previousRef.current?.()
                setCrop({
                    x: initalCrop.x,
                    y: initalCrop.y
                })

            }
        }
    }
    const isNextDisabled: () => boolean = () => {
        if (loading) {
            return true
        }
        
        if (Tabss === 'tab2') {
            if(isSiteLoading){
                return true
            }
            if (siteValues.wizradState === 'Site_Structure') {
                if (selectedPreset === 'Open Structure') { return false }
                if (!isNextPreviewButtonCliked)
                    return true
            }
            else if (siteValues.wizradState === 'Site_Details') {
                if (!formSiteData?.siteName || !formSiteData?.siteAddress || !postPattern.test(formSiteData.postcode)) {
                    return true;
                }
            }
            
        }


      
        else if ((orgValues.wizardStepper === 'Organization') && (orgValues.orgTypeValue.length === 0 || (orgValues.orgTypeValue.includes('Others') && orgValues.OthersTextArea.length === 0))) {
            return true
        }
        else if (orgValues.wizardStepper === 'details') {
            // Check if SelectAddType is invalid
            if (valueType.SelectAddType !== OrgEntry.Duns &&
                valueType.SelectAddType !== OrgEntry.Tin &&
                valueType.SelectAddType !== OrgEntry.Address
            ) {
                return true;
            }

            // Check required fields based on SelectAddType
            if (valueType.SelectAddType === OrgEntry.Duns &&
                (
                    // !formData.duns
                    !formData.duns || // Check if vat is not provided
                    !dunPattern.test(formData.duns) // Validate vat against the pattern
                )
            ) {
                return true; // DUNs is required but not filled
            }

            if (valueType.SelectAddType === OrgEntry.Tin &&
                (
                    // !formData.vat
                    !formData.vat || // Check if vat is not provided
                    !orgValues.countryRegex.test(formData.vat) || // Validate vat against the pattern
                    formData.vat.length < 9
                )
            ) {
                return true; // VAT is required but not filled
            }

            if (
                valueType.SelectAddType === OrgEntry.Address &&
                (
                    !formData.organizationName ||
                    !formData.city ||
                    !formData.state ||
                    !postPattern.test(formData.postcode) ||
                    !formData.organizationWebsite
                )
            ) {
                return true; // Address fields are required but not filled
            }
            if ((orgValues.phoneNumber === '') || (mobileCode.toString() === '') || (phoneNumberorEmailInvalid.phoneNumber)) {
                return true
            }
        }
        return false
    }
    return (
        <>
            <Button onPress={() => { setIsOpen(true); setEditedSiteId(''); setOnClickDisable(false); dispatch(handleSaveState(false)) }}
                variant={'secondary'}
                style={{ fontSize: '16px', fontWeight: 'bold' }} ref={buttonRef}>+ New</Button>

            <div
                style={{
                    transition: "opacity 0.5s ease-in-out", // Fade-in/out effect
                    opacity: isOpen ? 1 : 0,
                    background: 'white'
                }}
            >

                <DialogContainer onDismiss={dismiss} isOpen={isOpen}  >
                    <Dialog
                        id='Dialog'
                        style={{
                            height: "100vh",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: "auto",
                            // margin: 0,
                            position: 'fixed',
                            marginTop: 0,
                            marginBottom: 0,
                            borderRadius: "10px 0 0 10px",
                            width: isOpen ? "45%" : "0px", // Animate width from 0 to 848px
                            // overflow: "hidden", // Prevent content from spilling out during animation
                            transition: "width 0.5s ease-in-out", // Smooth width transition
                            boxShadow: isOpen ? "-2px 0 8px rgba(0, 0, 0, 0.2)" : "none", // Shadow only when open

                        }}

                    >
                        <FlexBox justifyContent='space-between' alignItems={'center'} >
                            <DialogTitle aria-label="dialogTitle" style={{ marginLeft: '16px', marginTop: '28px' }}>
                                New
                            </DialogTitle>
                            <Button style={{ borderRadius: '10px', marginRight: '22px', marginTop: '22px' }}
                                variant={'quiet'} onPress={handleClose}>
                                <Cross />
                            </Button>
                        </FlexBox>

                        <hr className={dividerHorizontal}></hr>


                        <DialogContent height={'100vh'} style={{ padding: '0px', }}
                        // className={'scroll-bar-black'}
                        >

                            <TabContext style={{ padding: 0 }} onSelectionChange={(key) => SetTabs(key as string)} selectedKey={Tabss}>
                                <div
                                >
                                    <Tabs id="tabs" overflowBehavior="dropdown" style={{ position: 'fixed', background: 'white', zIndex: '99' }} >
                                        <Item aria-label="user" key="tab1">User</Item>
                                        <Item aria-label="site" key="tab2">Site</Item>
                                        <Item aria-label="booking" key="tab3">Booking</Item>
                                        <Item aria-label="organization" key={'tab4'}>Organisation</Item>
                                        <Item aria-label="New_user" key={'tab5'}>New User</Item>
                                        <Item aria-label="Configuration" key={'tab6'}>Configuration</Item>
                                    </Tabs>
                                </div>

                                <TabPanels style={{ padding: '32px', paddingTop: '90px', overflowX: 'auto' }}
                                >
                                    <Item key="tab1">

                                        <form id={'userForm'} onSubmit={onSubmit} >
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    columnGap: '0.5rem',
                                                    rowGap: isLargeScreen ? '1.5rem' : '1rem',
                                                    gridTemplateColumns: isLargeScreen ? '220px 1fr' : '1fr',
                                                    alignItems: 'center',
                                                }}
                                            >

                                                <Label htmlFor="email">Email</Label>
                                                <TextField id={'email'} name={'email'}
                                                    placeholder="Enter the email" inputMode={'email'} isFullWidth
                                                    isRequired={true}
                                                    validate={EmailValidation}
                                                    errorMessages={'Enter the valid email Id'}
                                                    aria-label="email"
                                                    validationBehavior={'aria'}
                                                    onChange={(event) => SetEmail(event)}

                                                />
                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="firstName">First name</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField aria-label="first Name" id={'firstName'} name={'firstName'}
                                                    placeholder="Enter the first name" inputMode={'text'} isFullWidth />


                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="lastName">Last name</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField aria-label="last name" id={'lastName'} name={'lastName'}
                                                    placeholder="Enter the last name" inputMode={'text'} isFullWidth />

                                                <Label htmlFor="organization">Organization</Label>
                                                <TextField id={'organization'} aria-label="organization" name={'organization'}
                                                    placeholder="Enter the organization" inputMode={'text'}
                                                    value={data?.organizations?.items?.[0]?.name} isDisabled={true} isFullWidth />


                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="department">Department</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>



                                                <TextField id={'department'} aria-label="department" name={'department'}
                                                    placeholder="Enter the department" inputMode={'text'} isFullWidth />

                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="JobTitle">Job title</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField aria-label="job title" id={'JobTitle'} name={'JobTitle'}

                                                    placeholder="Enter the Job" inputMode={'text'} isFullWidth />

                                                <Label htmlFor="MFA">MFA</Label>

                                                <ToggleButtonGroup
                                                    showAsGroup isFullWidth
                                                    selectionMode={'single'}
                                                    selectedKeys={new Set([selectedKey as Key])}
                                                    aria-label="MFA"
                                                    id={'MFA'} name={'MFA'} width={'53%'}
                                                    onAction={handleToggle}
                                                >

                                                    <Item key={'disable'} >Disabled</Item>
                                                    <Item key={'enable'} >Enabled</Item>
                                                </ToggleButtonGroup>

                                                <Label htmlFor={'Privileges'}>Privileges</Label>
                                                <UserModal setShow={setShow} orgName={data?.organizations?.items?.[0]?.name ?? ''} />
                                            </div>
                                        </form>
                                        {((previlageType.length > 0 && saveState)) && <UserPrevilagePreview />}
                                    </Item>
                                    <Item key="tab2">
                                        {SiteArray[siteArrayIndex]}
                                    </Item>
                                    <Item key="tab3">
                                        <Paragraph aria-label="booking" >tab 3 content</Paragraph>
                                    </Item>
                                    <Item key="tab4">
                                        <Paragraph >
                                            {visibleOrg ?
                                                <AddOrganization nextRef={nextRef}
                                                    previousRef={previousRef}
                                                    completedSteps={completedSteps}
                                                    // countries={countries}
                                                    mobileCode={mobileCode}
                                                    setMobileCode={setMobileCode}
                                                    error={phoneNumberorEmailInvalid.phoneNumber}
                                                    emailError={phoneNumberorEmailInvalid.email}
                                                    setError={setPhoneNumberorEmailInvalid}
                                                // handleInputChange={handleInputChange}
                                                />
                                                : orgComponents[orgIndex]}
                                        </Paragraph>
                                    </Item>
                                    <Item key="tab5">
                                        
                                        New Users
                                    </Item>
                                </TabPanels>
                            </TabContext>

                        </DialogContent>


                        {!(Tabss === 'tab5' && index === 2) && <hr className={dividerHorizontal}></hr>}
                        <DialogActions>
                            <FlexBox justifyContent={'flex-end'} padding={10} gap={12} >
                                {Tabss !== 'tab4' && Tabss !== 'tab5' && Tabss !== 'tab2' ? <>
                                    <Button onPress={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button type={'submit'} variant={'primary'}
                                        onPress={() => {
                                            // Programmatically submit the form
                                            const form = document.getElementById('userForm') as HTMLFormElement;

                                            if (form) {
                                                form.requestSubmit(); // HTML5 method for programmatically submitting forms
                                            }
                                        }}
                                        isDisabled={previlageType.length === 0 || Email.length === 0 ||
                                            (!isEmailValid) || onClickDisable || !saveState}
                                    >Save</Button>
                                </>

                                    : <>
                                        {(Tabss === 'tab5' && index !== 2) ? <><Button onPress={handleClose}>
                                            Cancel
                                        </Button> <Button
                                            variant={'primary'}
                                            onPress={onNextPress}
                                            isDisabled={isNextDisabled()}
                                        >
                                            {index == 1 ? 'Send Invite' : 'Next'}
                                        </Button></> : Tabss !== 'tab5' && index !== 2 && (orgIndex === 1 || (SiteArray.length - 1 === siteArrayIndex) ? <Button onPress={handleClose}>Skip</Button>
                                            : <div>
                                                {(orgValues.wizardStepper === 'Organization' && Tabss === 'tab4') || (siteValues.wizradState === 'Site_Details' && Tabss === 'tab2') ? <Button onPress={handleClose}>
                                                        Cancel
                                                </Button>
                                                    : <Button onPress={onCancelPress}>
                                                            Back
                                                    </Button>}
                                                <Button
                                                    variant={'primary'}
                                                    onPress={onNextPress}
                                                    isDisabled={isNextDisabled()}
                                                    style={{ marginLeft: '16px' }}
                                                >
                                                    {visibleOrg ? 'Next' : 'Submit'}
                                                </Button>
                                            </div>)}
                                    </>


                                }

                            </FlexBox>
                        </DialogActions>
                    </Dialog>
                </DialogContainer>

            </div>
            <NotificationRenderer variant="toast" position="bottom" />
        </>

    );


};