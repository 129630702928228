import { gql } from '@apollo/client';

export const GET_SITE_DATA = gql`
  query GetSiteData($id: String!) {
    site(id: $id) {
       id
        name
        shortName
        address
        city
        state
        country
        postcode
        longitude
        latitude
        siteStructurePreset
        organizationId
        timezone
        wifiSSID
        wifiPassword
        siteImages {
            key
            isUploaded
            url
        }
        logo {
            key
            isUploaded
            url
        }
        siteStructure(searchInput: {}) {
        siteId
        siteStructure {
          hierarchy
          labelFormat
          isEndNode
          isRootNode
          nodes {
            id
            label
          }
        }
      }
        createdAt
        updatedAt
     
    }
  }
`;
