
import {
    Dialog, DialogContainer, Button, DialogContent,
    DialogTitle, Link, DialogActions, Label, RadioButton, RadioGroup,
    Text
} from "@filament/react"

import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux";

import { privalgeValues } from "./SelectPrevilageModal";
// import { FeatureEnum, GetUserOrgByIdQuery, useOrgEditUserMutation } from "../../../graphql/generated";
// import { selectedUserValues } from "./SelectedUserTask";
import { handleSaveState } from "../../../redux/UserTabState";

import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';

import {
    // initialState, 
    StorePrivalgeValue,
    // StorePrivilegeRoles, 
    // StoreTasks 
} from "../../../redux/PrivilageTypeState";

import SelectPrivilageModal from "./SelectPrevilageModal";
import UserPrevilagePreview from "./UserPrevilagePreview";
import { useDispatch } from "react-redux";
import UserPrevilageAndRoles from "./UserPrevilageAndRoles";
// import SelectedUserTasks from "./SelectedUserTask";
import { RootState } from "../../../redux/store";


const UserModal = ({ setShow, orgName, addnewprivilege, userData, editUserStatus, }: {
    setShow?: React.Dispatch<React.SetStateAction<boolean>>,
    addnewprivilege?: boolean,
    orgName?: string,
    // userData?: GetUserOrgByIdQuery["UserOrgById"],
    editUserStatus?: boolean,
}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [radioValue, setRadioValue] = useState<string>("")
    const [index, setIndex] = useState(-1)
    // const [EditUser] = useOrgEditUserMutation()
    const dispatch = useDispatch()
    const apiCallFetch = useSelector((state: RootState) => state.userTable.userTableFetch)

    const [value, setValue] = useState<privalgeValues>({
        ScopeOfPrivilege: orgName ?? '',
        SelectSite: "All",
        ExpiryOfPrivilage: 'Infinite',
        Date: null,
        DelegatePower: 'None',
        NumberOfUsers: 0
    })

    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

    // const [Rolevalue, RolesetValue] = useState<selectedUserValues>({
    //     selectUser: {

    //     }
    // })

    // const [flatdata, setFlatData] = useState<string[]>([''])
    useEffect(() => {
        type DelegatePowerType = "Within-Organization" | "External-Organization" | "None";

        const DelegatePower: DelegatePowerType =
            userData?.delegatePower === "Within-Organization" || userData?.delegatePower === "External-Organization"
                ? userData.delegatePower
                : "None";

        if (userData?.privilegeType) {
            setRadioValue(userData.privilegeType)
        }

        setValue({
            ScopeOfPrivilege: userData?.featureScope || orgName,
            SelectSite: "All",
            ExpiryOfPrivilage: userData?.expiryOfPrivilege ?
                (userData?.expiryOfPrivilege === 'Infinite' ? 'Infinite' : 'Specify-Period') :
                'Infinite',
            Date: userData?.expiryDate && (userData?.expiryOfPrivilege === 'Specify-Period') ?
                new Date(userData.expiryDate) : null,
            DelegatePower: DelegatePower || 'None',
            NumberOfUsers: userData?.numberOfUser || 0
        })

        if (userData?.privilegeType === 'Select Feature') {
            if (userData?.featureSelected) {
                const result = userData.featureSelected.map((value: string) => {
                    if (value === 'user_management')
                        return "user_management"
                    else if (value === 'site_management')
                        return 'site_management'
                    else if (value === 'brand_association')
                        return 'brand_association'
                    else if (value === 'device_management')
                        return 'device_management'
                    else if (value === 'content_management')
                        return 'content_management'
                    else if (value === 'license_management')
                        return 'license_management'
                })
                setSelectedRoles(result as string[])
            }
        }
        else if (userData?.privilegeType === 'Select Task') {
            if (userData?.featureSelected) {
                setSelectedRoles([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // useEffect(() => {
    //     if (Rolevalue) {
    //         const keys = Object.keys(Rolevalue.selectUser);
    //         const allValues = keys.map((key) => Rolevalue.selectUser[key]).flat();
    //         const uniqueValues = Array.from(new Set(allValues));
    //         setFlatData([...uniqueValues]);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [Rolevalue])

    const AllFeatures: JSX.Element[] = [
        <SelectPrivilageModal key="1" value={value} setValue={setValue} />,
        <UserPrevilagePreview key="2" />,
    ];

    const SelectFeatures: JSX.Element[] = [
        <UserPrevilageAndRoles key="1" selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />,
        <SelectPrivilageModal key="2" value={value} setValue={setValue} />,
        <UserPrevilagePreview key="3" />,
    ];


    // const SelectTasks: JSX.Element[] = [
    //     <SelectedUserTasks key="1" value={Rolevalue} setValue={RolesetValue} />,
    //     <SelectPrivilageModal key="2" value={value} setValue={setValue} />,
    //     <UserPrevilagePreview key="3" />,
    // ];

    const SelectTasks: JSX.Element[] = [
        <UserPrevilageAndRoles key="1" selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />,
        <SelectPrivilageModal key="2" value={value} setValue={setValue} />,
        <UserPrevilagePreview key="3" />,
    ];


    const privilageVlaues = useSelector((state: RootState) => state.SelectPrivilageState)
    // const Tasks = useSelector((state: RootState) => state.PrivilageTypeState.Tasks)

    // const result = Object.entries(Tasks).map(([key, values]) => {
    //     return {
    //         key,
    //         values, // This will contain the array of values for the current key
    //         length: values.length
    //     };
    // });
    // const keys = Object.keys(result[0].values);
    // const allValues = keys.map((key) => result[0].values[key]).flat();

    const dismiss = useCallback(() => {
        setIsOpen(false)
    }, [])


    const loadFlow = (radioValue: string) => {
        if (radioValue === "Select Task") {
            return SelectTasks
        }
        else if (radioValue === "Select Feature") {
            return SelectFeatures
        }
        else {
            return AllFeatures
        }
    }

    const flow = loadFlow(radioValue)

    const onNextDisable = (): boolean => {
        if (!radioValue.length) {
            return true
        }
        else if (flow === AllFeatures && (
            index === 0 && (privilageVlaues.ExpiryOfPrivilage === "Specify-Period" && (!privilageVlaues.Date)))) {
            return true
        }
        else if (flow === SelectFeatures && ((index === 0 && selectedRoles.length === 0) ||
            (index === 1 && privilageVlaues.ExpiryOfPrivilage === "Specify-Period" && (!privilageVlaues.Date)))) {
            return true
        }
        else if (flow === SelectTasks && ((index === 0 && selectedRoles.length === 0) ||
                (index === 1 && privilageVlaues.ExpiryOfPrivilage === "Specify-Period" && (!privilageVlaues.Date)))) {
            return true
        }
        return false
    }

    const onCancle = () => {
        setRadioValue("")
        setSelectedRoles([])
        setIndex(-1)
        setIsOpen(false)
        setShow?.(false)
    }

    const length = editUserStatus ? flow.length - 2 : flow.length - 1


    // const apiCall = async () => {
    //     try {
    //         await EditUser({
    //             variables: {
    //                 _id: userData?._id || '',
    //                 lastName: userData?.lastName || '',
    //                 firstName: userData?.firstName || '',
    //                 privilegeType: radioValue,
    //                 expiryOfPrivilege: value.ExpiryOfPrivilage,
    //                 expiryDate: value.Date + '',
    //                 delegatePower: value.DelegatePower,
    //                 numberOfUser: value.NumberOfUsers,
    //                 featureSelected:radioValue==='Select Features'? [...selectedRoles]:[],
    //                 userTasks: radioValue==='Select Tasks'?[...flatdata]:[],
    //                 sub: userData?.sub || '',
    //                 organization_id:"673582adeed3cccc395494a2",
    //             }
    //         })

    //         dispatch(StorePrivilegeRoles([]))
    //         dispatch(StoreTasks(initialState.Tasks))
    //     }
    //     catch (error) {
    //         console.error("api call error in editing the privilage ", error)
    //     }
    // }

    const handleNextClick = () => {
        //const formm = document.getElementById('ModalForm') as HTMLFormElement;
        if (index >= length) {
            if (editUserStatus) {
                // apiCall()
                const reftching = async () => {
                    await apiCallFetch.viewRefetch()
                    await apiCallFetch.refetch()
                }
                setTimeout(() => {
                    reftching()
                }, 2000)
            }
            setIndex(-1)
            dispatch(handleSaveState(true))
            setShow?.(false)
            setIsOpen(false)
        }
        else {
            if (index === -1) {
                dispatch(StorePrivalgeValue(radioValue))
            }
            setIndex((prev) => prev + 1)
        }
    }

    const onBack = () => {
        setIndex((prev) => prev - 1)
    }

    useEffect(() => {
        if (addnewprivilege) {
            setIsOpen(true)
        }
    }, [addnewprivilege])

    return (
        <>
            {!addnewprivilege &&
                <Link onPress={() => setIsOpen(true)}>
                    Select privileges and roles
                </Link>
            }
            <DialogContainer onDismiss={dismiss} isOpen={isOpen}>
                <Dialog style={{ height: '812px', width: '50%' }}>

                    <DialogTitle marginY={'10px'}
                        style={{
                            fontSize: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',

                        }}>
                        <Text variant="heading-xs">
                            Privileges and Roles
                        </Text>
                        <Button variant={'quiet'} style={{ borderRadius: '10px' }} onPress={onCancle}><Cross /></Button>
                    </DialogTitle>
                    <hr className={dividerHorizontal} ></hr>
                    <DialogContent style={{
                        paddingTop: '24px',
                        paddingBottom: '24px',
                        height: '408px',
                        paddingRight: `${index === flow.length - 1 ? '0px' : '24px'}`,
                        paddingLeft: `${index === flow.length - 1 ? '0px' : '24px'}`,
                        overflowY: 'auto',
                        // paddingTop:`${index === flow.length - 1 ? '24pxx' : ''}`,
                    }}  >
                        {index === flow.length - 1 && <Text variant={'heading-xs'} marginX={'20px'}  >
                            Privilege Preview</Text>}
                        {index === -1 &&
                            <>
                                <Label htmlFor="privilageType"
                                    style={{ fontSize: '20px', fontWeight: 'bold' }}>Select Privilege Type</Label>
                                <div style={{ marginTop: '24px' }}>
                                    <RadioGroup orientation={'vertical'} id={'privilageType'} name={'privilageType'}
                                        onChange={(event: string) => {
                                            setSelectedRoles([]);
                                            setRadioValue(event);
                                        }}
                                        value={radioValue}
                                    >
                                        <RadioButton value={'All Features'}>Full Access : All Features</RadioButton>
                                        <RadioButton value={'Select Feature'}>
                                            Full Access : Select Features</RadioButton>
                                        <RadioButton value={'Select Task'}>
                                            Restricted Access : Select Tasks</RadioButton>
                                    </RadioGroup>
                                </div>
                            </>
                        }
                        {flow[index]}
                    </DialogContent>
                    <hr className={dividerHorizontal}></hr>
                    <DialogActions  >
                        {index === -1 ?
                            <Button variant={'secondary'} onPress={onCancle}>Cancel</Button>
                            :
                            <Button variant={'secondary'} onPress={onBack}>Back</Button>
                        }
                        {index === length ?
                            <Button type={'button'} variant={'primary'}
                                onPress={handleNextClick}
                            >
                                Save
                            </Button>
                            :
                            <Button type={'button'} variant={'primary'}
                                onPress={handleNextClick}
                                isDisabled={onNextDisable()}
                            >Next</Button>
                        }
                    </DialogActions>
                </Dialog>
            </DialogContainer>
        </>
    )
}


export default UserModal