
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    FlexBox,
    TabContext,
    Tabs,
    Sidebar,
    Item,
    Text,
} from "@filament/react";
import { atomicBorderBottom as borderBottom } from "@filament/react/styles";
import { atomicBorderRight as borderRight } from "@filament/react/styles";
import { Applications, Connection, Home, PlusShield } from "@filament-icons/react";
import { ConfigData } from "./Context/ConfigurationContect";

export const SideNav = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // State for active tab
    const [activeTab, setActiveTab] = useState<string>("console");
    const {setConfigTab} = ConfigData()

    // Sync activeTab with the current URL
    useEffect(() => {
        const path = location.pathname.split("/")[1]; // Get the first part of the path
        setActiveTab(path || "console"); // Default to "console" if no path
    }, [location]);
    
    useEffect(() => {
        setConfigTab(activeTab)
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [activeTab]);
    

    // Handle tab selection
    const handleTabChange = (key: string) => {
        setActiveTab(key);
        if(key==='hub'){
            navigate(`/${key}?tab=sites`); // Navigate to the corresponding route

        }else{
            navigate(`/${key}`); // Navigate to the corresponding route
        }
    };

    return (
        <TabContext selectedKey={activeTab} onSelectionChange={(key) => handleTabChange(key as string)}>
            <FlexBox height="calc(100vh - 56px)">
                <Sidebar className={borderRight}>
                    <div className={borderBottom} style={{ height: "56px" }}>
                        <FlexBox style={{ paddingTop: "12px", paddingLeft: "12px" }}>
                            <Text
                                style={{
                                    background: "#0B5ED7",
                                    borderRadius: "15px",
                                    color: "white",
                                    paddingTop: "4px",
                                    paddingLeft: "2px",
                                }}
                                height="32px"
                                width="32px"
                                textAlign={"center"}
                            >
                                JD
                            </Text>
                            <div style={{ paddingTop: "9px", paddingLeft: "3px" }}>
                                <img src="/images/Arrow.png" alt="" height="16px" width="16px" />
                            </div>
                        </FlexBox>
                    </div>
                    <Tabs orientation="vertical" iconPosition="top" width={72} noBorder>
                        <Item key="console">
                            <Applications />
                            <span>Console</span>
                        </Item>
                        <Item key="hub">
                            <Home />
                            <span>Hub</span>
                        </Item>
                        <Item key="security">
                            <PlusShield />
                            <span>Security</span>
                        </Item>
                        <Item key="organisation">
                            <Connection />
                            <span>Org</span>
                        </Item>
                    </Tabs>
                </Sidebar>
            </FlexBox>
        </TabContext>
    );
};
