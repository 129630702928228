/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { phoneCodeArray } from "../../../../helpers/phoneNumberCountryCodes";
import {
    Button,
    TextField,
    useMenuTrigger,
    useMenuTriggerState,
    FlexBox,
    Text,
} from "@filament/react";
import { Selection } from '@filament/react/types';
import { ChevronDown } from "@filament-icons/react";
import * as styles from "../../../Authentication/styles/styles.css"
import { atomicIconSmall } from '@filament/styles';

type inputFieldProps<T> = {
    mobileNumber: string;
    setMobileNumber: React.Dispatch<React.SetStateAction<T>>;
    inputValue: Selection;
    setInputValue: React.Dispatch<React.SetStateAction<Selection>>;
    error: boolean | undefined;
    setError: React.Dispatch<React.SetStateAction<{ phoneNumber: boolean, email: boolean }>>;
    isEdit?: boolean;
    selectedCountry: string;
};

const PhoneInput = <T,>({
    mobileNumber,
    setMobileNumber,
    inputValue,
    setInputValue,
    error,
    setError,
    isEdit,
    selectedCountry,
}: inputFieldProps<T>) => {
    const buttonRef = React.useRef(null);
    const state = useMenuTriggerState({});
    const { menuTriggerProps } = useMenuTrigger(
        { type: "menu" },
        state,
        buttonRef
    );

    const largeCodes = ['44-1481', '44-1534', '44-1624'];

    const getCountryCode = React.useCallback((countryKey: string) => {
        const countryCode = countryKey.split(':')[1]?.trim();
        const matchingCode = phoneCodeArray.find(item => {
            const itemCountryCode = item.split(':')[0]?.split(' ')[0]?.trim();
            return itemCountryCode === countryCode;
        });
        return matchingCode;
    }, []);

    const extractCountryCode = (value: Selection): string => {
        const selected = Array.from(value)[0]?.toString() || "000";
        if (selected === "000") return "000";

        if (isEdit && /^\d+(-\d+)?$/.test(selected)) {
            return selected;
        }

        const code = selected.split(":")[1]?.trim() || selected;
        return code;
    };

    React.useEffect(() => {
        if (selectedCountry && !isEdit) {
            const matchingPhoneCode = getCountryCode(selectedCountry);
            if (matchingPhoneCode) {
                setInputValue(new Set([matchingPhoneCode]));
            }
        }
    }, [selectedCountry, setInputValue, getCountryCode, isEdit]);

    const validatePhoneNumber = (number: string) => {
        const phoneNumber = number.replace(/\s/g, '');
        
        const validations: { [key: string]: { minLength: number, maxLength: number }} = {
            'US': { minLength: 10, maxLength: 10 },
            'GB': { minLength: 10, maxLength: 11 },
            'default': { minLength: 8, maxLength: 12 }
        };

        const country = selectedCountry.split(':')[0]?.trim();
        const rules = validations[country] || validations.default;

        return phoneNumber.length >= rules.minLength && 
               phoneNumber.length <= rules.maxLength;
    };

    const handlePhoneNumberChange = (event: string) => {
        let input = event.replace(/\D/g, "");

        if (/^\d*$/.test(input)) {
            if (input.length > 6) {
                input = input.slice(0, 6) + " " + input.slice(6);
            }
            if (input.length > 3) {
                input = input.slice(0, 3) + " " + input.slice(3);
            }

            setMobileNumber((prev: any) => ({
                ...prev,
                phoneNumber: input
            }));

            const isValid = validatePhoneNumber(input);
            setError(prev => ({ ...prev, phoneNumber: !isValid }));
        }
    };

    const displayCode = extractCountryCode(inputValue);
    const isLargeCode = largeCodes.includes(displayCode);

    

    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", zIndex: "999" }}>
                <Button
                    {...menuTriggerProps}
                    isIconOnly
                    style={{
                        background: "none",
                        height: "auto",
                        marginTop: "-15px",
                        cursor: "pointer",
                    }}
                    ref={buttonRef}
                    aria-label="menu-button"
                >
                    <FlexBox
                        style={{
                            marginTop: "13px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: isLargeCode ? "4px" : "6px",
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: "normal",
                                marginLeft: isLargeCode ? "0px" : "6px",
                                marginRight: isLargeCode ? "0px" : "10px",
                                color: displayCode === "000" ? "#566676" : "black",
                            }}
                        >
                            {displayCode !== "000" ? `+ ${displayCode}` : "+ 000"}
                        </Text>
                        <ChevronDown className={atomicIconSmall} />
                        <div className={styles.verticalLine}></div>
                    </FlexBox>
                </Button>
            </div>
            
            <TextField
                aria-label="mobile number"
                placeholder={"000 000 000"}
                onChange={handlePhoneNumberChange}
                onFocus={() => setError((prev) => ({ ...prev, phoneNumber: false }))}
                isInvalid={error}
                errorMessages={error ? "Invalid phone number." : undefined}
                value={mobileNumber}
                isFullWidth
            />
        </div>
    );
};

export default PhoneInput;