/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
// CreateSite.tsx
import React, { useCallback,  useEffect,  useState } from 'react';
import {
    Cell,
    Column,
    DataGrid,
    Pagination,
    Row,
    Table,
    TableBody,
    TableHeader,
    Avatar,
    Text,
    FlexBox,
    // NotificationsQueue,
    NotificationRenderer,
    Button,
} from '@filament/react';
import {ChevronUpDown, Eye, Plus } from '@filament-icons/react';
import { atomicIconSmall as iconSmall } from '@filament/react/styles';
// import DeleteSite from '../Components/DeleteSite';
// import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import MenuTrigger from '../Components/MenuTrigger';
import ViewSite from '../Components/ViewSite';
import { SortOrder, useGetLoggedInUserQuery, useGetOrganizationQuery, useGetUserSitesLazyQuery } from '../../../../graphql/generated';
import  { setRefetch } from '../../../../components/Context/GlobalContext';
import { ConfigData } from '../../../../components/Context/ConfigurationContect';
import EditSite from '../Components/EditSite';
import { getCountryNameByISO } from '../../../../CommonMethods/GetCountryNameByiSOCode';
import { SiteData } from '../../../../components/Context/SiteContext';
import Loading from '../../../../components/Loading';
import UserDrawer from '../../../Organization/Components/UserDrawer';



const CreateSite = () => {
    const { data:userData } = useGetLoggedInUserQuery()   
    const [sortConfig, setSortConfig] = useState({
        field: 'createdAt',
        order: SortOrder.Desc
    });
    const [fetchUserSites, { data ,loading,error,refetch}] = useGetUserSitesLazyQuery();
    const {setSiteValues} = SiteData()
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedSiteId,setSelectedSiteId] = useState('')
    const {buttonRef, setConfigTab } = ConfigData()
    const [onEditClick,setOnEditClick] = useState(false)
    const [selectedViewSite, setSelectedViewSite] = useState(false);

    const { data:organizationData } = useGetOrganizationQuery({
        variables: { id: userData?.user?.organizationId||'' },
        skip: !userData?.user?.organizationId, // This ensures the query runs only when userData is available
    });
   
    const handleSelectionChange = (key: string, 
        id:string
    ) => {
        if (key === 'edit') {
            setOnEditClick(true)
            setSelectedSiteId(id)
        }
        if (key === 'view') {
            setSelectedViewSite(true);
            setSelectedSiteId(id)
        }
    };

    const handlePageSizeChange = useCallback((size: number) => {
        setPageSize(size);
        setCurrentPage(0);
    }, []);

    const handleCurrentPageChange = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        setRefetch(refetch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (userData) {
            fetchUserSites({
                variables: {
                    id: userData?.user?.id || '',
                    organizationId: userData?.user?.organizationId || '',
                    input: {
                        limit: pageSize, // Set page size dynamically
                        offset: currentPage * pageSize, // Calculate offset
                        sortBy: "createdAt",
                        sortOrder: sortConfig.order
                    }
                }
            })
        }
    }, [userData, pageSize, currentPage]); // Refetch when page or size changes
    
   
    const handleViewSiteCloseClick = () => {
        setSelectedViewSite(false);
    };

    const orgData = data?.user?.sites;

    const siteList = data?.user?.sites?.items|| [];
    const totalCount = orgData?.totalCount ;
    if (!loading&&data!==undefined&&totalCount == 0) {
        return (
            <FlexBox
                alignItems="center"
                justifyContent="center"
                style={{
                    height: "300px",
                    textAlign: "center",
                    flexDirection: "column",
                    paddingTop: "15%",
                }}
            >
                <Text> No sites available currently </Text>
                <Text> Click below to create site</Text>

                <Button variant="primary" marginTop={4} style={{padding: '20px'}} onPress={() => {
                    setConfigTab('hub')
                    if (buttonRef.current) {
                        buttonRef.current.click()
                    }
                            
                }}><Plus />Add Site</Button>
                <div style={{display:'none'}}>
                    <UserDrawer />
                </div>
            </FlexBox>
        );
    }
    if (error) {
        if(error?.cause?.extensions?.message==='organizationId is required'){
            return(
                <FlexBox alignItems="center" justifyContent="center" style={{ height: "300px", textAlign: "center", flexDirection: "column", paddingTop: "15%" }}>
                    <Text>Currently you are not part of any organization</Text>
                    <Text>Wait for an invite or Create one</Text>
                    <Button variant="primary" marginTop={4} style={{ padding: '25px' }} onPress={() => {
                        setConfigTab('organisation');
                        buttonRef.current?.click();
                    }}><Plus />Add Organization</Button>
                </FlexBox>)
        }
        return <div>Error: {error.message}</div>;
    }

    const handleSort = (field: string) => {
        setSortConfig(prevConfig => ({
            field,
            order: prevConfig.field === field && prevConfig.order === SortOrder.Desc 
                ? SortOrder.Asc 
                : SortOrder.Desc
        }));
    };
    
    // Helper function to render sort indicator
    const renderSortIndicator = (field: string) => {
        if (sortConfig.field === field) {
            return (
                <ChevronUpDown 
                    className={`${iconSmall} ${sortConfig.order === SortOrder.Asc ? 'rotate-180' : ''}`}
                    style={{ transform: sortConfig.order === SortOrder.Asc ? 'rotate(180deg)' : 'none' }}
                />
            );
        }
        return <ChevronUpDown className={iconSmall} />;
    };
    const handleViewDetailsClick = (org: React.SetStateAction<string>) => {
        setSelectedViewSite(true);
        setSelectedSiteId(org)
        // setActiveTab('details')
    };
    
    const handleAddandViewSite = (id:string,e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        setOnEditClick(true)
        setSelectedSiteId(id)
        setSiteValues((prev)=>({...prev,wizradState:"Site_Structure"}))
        
    }
 

    return (
        <div style={{ position: 'relative' }}>
            <DataGrid>
                <div className="scrollable-element" style={{ height: 'calc(100vh - 288px)', overflow: 'auto' }}>
                    <Table aria-label="Pagination Table" width="100%" hasStickyHeader
                        onRowAction={(key) => handleViewDetailsClick(key as string)}
                        emptyStateComponent={<Loading message={'Loading Site data...'}/>}
                        // onRowAction={handleDisplayClick}
                    >
                        <TableHeader>
                            <Column width={312} key="name">
                                <div onClick={() => handleSort('name')} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <Text color="secondary" variant='body-s'>Site Name</Text>
                                    {renderSortIndicator('name')}
                                </div>
                            </Column>
                            <Column width={220} key="org">
                                <Text color="secondary" variant="body-s"> Organization</Text>
                                <ChevronUpDown className={iconSmall} />
                            </Column>
                            <Column width={220} key="Country">
                                <Text color="secondary" variant="body-s">Country</Text>
                                <ChevronUpDown className={iconSmall} />
                            </Column>
                            <Column width={210} key="Site">
                                <Text color="secondary" variant="body-s">Users</Text>
                                <ChevronUpDown className={iconSmall} />
                            </Column>
                            <Column width={210} key="User">
                                <Text color="secondary" variant="body-s"> Devices</Text>
                                <ChevronUpDown className={iconSmall} />
                            </Column>
                            <Column width={210} key="License">
                                <Text color="secondary" variant="body-s">License</Text>
                                <ChevronUpDown className={iconSmall} />
                            </Column>
                            <Column width={210} key="My Privileges">
                                <Text color="secondary" variant="body-s">Site Structure</Text>
                                <ChevronUpDown className={iconSmall} />
                            </Column>
                            <Column width={150} style={{ position: 'sticky', right: 0, background: 'white', zIndex: 2 }}></Column>
                        </TableHeader>
                        <TableBody>
                            {siteList.map((site) => (
                                <Row key={site.id}>
                                    <Cell>
                                        <FlexBox alignItems="center" gap={8}>
                                            <Avatar width={'40px'} height={'40px'} style={{ backgroundColor: 'transparent' }}>
                                                <img src={site.logo?.url || '/images/Logo.svg'} alt="Avatar" />
                                            </Avatar>
                                            <Text variant="body-m" width={204} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {site.name || '-'}
                                            </Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{ organizationData?.organization?.name || '-'}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{getCountryNameByISO(site.country)}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <Text variant="body-l">{site?.totalUsers || '-'}</Text>
                                    </Cell>
                                    <Cell>
                                        <Text variant="body-l">{site?.totalDevices || '-'}</Text>
                                    </Cell>
                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{site.licenseType || '-'}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <span
                                            aria-label="view and add"
                                            // onClick={(e) => {
                                            //     e.stopPropagation(); // Prevent row action
                                            //     handleViewPrivilegeClick(user.id);
                                            // }}
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "1px",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: site?.siteStructurePreset === null || site?.siteStructurePreset === undefined ? "#FFF5F5" : "#E6F7FF",
                                                borderColor: site?.siteStructurePreset === null || site?.siteStructurePreset === undefined ? "#C53030" : "#3182CE",
                                                color: site?.siteStructurePreset === null || site?.siteStructurePreset === undefined ? "#C53030" : "#3182CE",
                                            }}
                                        >
                                            {site?.siteStructurePreset === null || site?.siteStructurePreset === undefined ? (
                                                <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }} >
                                                    <button     style={{ all: "unset", cursor: "pointer" }}
                                                        onClick={(e)=>handleAddandViewSite(site.id,e)}>
                                                        <Plus style={{ width: '16px', height: '16px' }} />
                                                        <span style={{ padding: "0 2px" }}>Add</span>
                                                    </button>
                                                </FlexBox>

                                                
                                            ) : (
                                                <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }}  >
                                                    <button     style={{ all: "unset", cursor: "pointer" }}
                                                        onClick={(e)=>handleAddandViewSite(site?.id,e)}>
                                                        <Eye style={{ width: '16px', height: '16px' }} />
                                                        <span style={{ padding: "0.0px" }} >View</span>
                                                    </button>
                                                </FlexBox>

                                            )}
                                        </span>
                                    </Cell>
                                    
                                    <Cell style={{ position: 'sticky', 
                                        right: 0, 
                                        zIndex: 2,
                                        background:'white' }}>
                                        <MenuTrigger  data={site?.id??''} onAction={handleSelectionChange} />
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    total={totalCount||0}
                    pageSize={pageSize}
                    pageSteps={[10, 30, 50]}
                    setPageSize={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handleCurrentPageChange}
                />
            </DataGrid>


            {selectedViewSite && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                        zIndex: 1000,
                    }}
                >
                    <ViewSite
                        handleViewSiteCloseClick={handleViewSiteCloseClick}
                        siteId={selectedSiteId}
                    />
                </div>
            )
            }
            {onEditClick&&
                <EditSite 
                    onEditClick={onEditClick}
                    handleViewSiteCloseClick={handleViewSiteCloseClick}
                    setOnEditClick={setOnEditClick}
                    siteId={selectedSiteId??''}     
                />
            }
        
            
            <NotificationRenderer variant="toast" position="bottom" />
        </div>
    );
};

export default CreateSite;