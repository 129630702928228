// MenuTrigger.tsx
import React from 'react';
import { Button, Menu, Item, useMenuTrigger, useMenuTriggerState } from '@filament/react';
import { ThreeDotsVertical } from '@filament-icons/react';



interface MenuTriggerProps {
    data: string; 
    onAction: (key: string, rowData: string) => void;
}

const MenuTrigger = ({ data, onAction }: MenuTriggerProps) => {
    const buttonRef = React.useRef(null);
    const state = useMenuTriggerState({});
    const { menuTriggerProps } = useMenuTrigger(
        { type: 'menu' },
        state,
        buttonRef,
    );

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
            <Button
                {...menuTriggerProps}
                shape="square"
                isIconOnly
                ref={buttonRef}
                aria-label="menu-button"
                style={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                }}
                onPress={() => state.open()}
            >
                <ThreeDotsVertical />
            </Button>
            {state.isOpen && (
                <Menu
                    {...menuTriggerProps}
                    triggerRef={buttonRef}
                    state={state}
                    aria-label="menu"
                    className="scrollable-table"
                    style={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        width: '212px',
                        maxHeight: '176px',
                        overflowY: 'auto',
                    }}
                    onAction={(key) => onAction(key as string, data)}
                >
                    <Item key="view">View</Item>
                    <Item key="edit">Edit</Item>
                    <Item key="delete">Delete</Item>
                </Menu>
            )}
        </div>
    );
};

export default MenuTrigger;