import { SiteStructureOutput } from "../../../../graphql/generated";
import { TableRow } from "../SiteStructure";

export type TrieNode = {
    id:string;
    label: string;
    number_of_units: number;
    children: Record<string, TrieNode>;
    isEndOfWord: boolean;
    isFirstChild?: boolean;
    siblings?: string[];
    isDisplay:boolean;
    collapsedLabel:string;
    apiHierarchyId?:string|string[]
};

type ChildMap = Record<string, string[]>;

// type NodeType = {
//     id: string;
//     siteId: string;
//     label: string;
//     hierarchy: string[];
//     labelFormat: string | null;
//     isEndNode: boolean;
//     isRootNode: boolean;
//     createdAt: string;
//     updatedAt: string;
//     children?: NodeType[];
//   };

export class Trie {
    root: TrieNode;
    generateUID(): string {
        return Math.random().toString(36).substr(2, 9); // Short unique ID
    }
    constructor() {
        const idforSite = this.generateUID()
        this.root = { 
            id:idforSite,
            label: `root-${idforSite}`, 
            number_of_units: 1, 
            isFirstChild:true,
            children: {}, 
            siblings:[],
            isEndOfWord: false,
            isDisplay:true,
            collapsedLabel:''
        };
    }


    insertLevel(levels: { label: string; number_of_units: number }[],totalUnits:number,rootSiteId:string) {
        let currentLevelNodes: TrieNode[] = [this.root];
    
        for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {
            const { label, number_of_units } = levels[levelIndex];
            const nextLevelNodes: TrieNode[] = [];
           
    
            for (const parentNode of currentLevelNodes) {
                const siblingLabels: string[] = [];
    
                if (levelIndex === levels.length - 1 && levels.length > 1) {
                    // Last level: Single node for total number of rooms
                    const uid = this.generateUID();
                    const unitName = `${number_of_units} ${label}${number_of_units > 1 ? 's' : ''}-${uid}`;                    
                    if (!parentNode.children[unitName]) {
                        parentNode.children[unitName] = {
                            id: uid,
                            label: unitName,
                            number_of_units: 1,
                            children: {},
                            isEndOfWord: true,
                            isFirstChild: Object.keys(parentNode.children).length === 0,
                            siblings: [],
                            isDisplay: true,
                            collapsedLabel: ''
                        };
                    }
                    nextLevelNodes.push(parentNode.children[unitName]);
                } else {
                    // Non-final levels: Create individual child nodes
                    for (let i = 1; i <= number_of_units; i++) {
                        const uid = this.generateUID();
                        const unitName = levelIndex === 0 ? `${label}-${uid}` : `${label} ${i}-${uid}`;
                        siblingLabels.push(unitName);
    
                        if (!parentNode.children[unitName]) {
                            if(label==='Site' && levelIndex===0){
                                parentNode.children[unitName] = {
                                    id: uid,
                                    label: unitName,
                                    number_of_units: 1,
                                    children: {},
                                    isEndOfWord: false,
                                    isFirstChild: Object.keys(parentNode.children).length === 0, // First child check
                                    siblings: [], // Will be updated after loop
                                    isDisplay: true, // Default is true, will update later
                                    collapsedLabel: '',
                                    apiHierarchyId:rootSiteId
                                };
                            }else{
                                parentNode.children[unitName] = {
                                    id: uid,
                                    label: unitName,
                                    number_of_units: 1,
                                    children: {},
                                    isEndOfWord: false,
                                    isFirstChild: Object.keys(parentNode.children).length === 0, // First child check
                                    siblings: [], // Will be updated after loop
                                    isDisplay: true, // Default is true, will update later
                                    collapsedLabel: ''
                                };
                            }                            
                        }
                        nextLevelNodes.push(parentNode.children[unitName]);
                    }
    
                    // Assign sibling names to each child
                    for (const sibling of Object.values(parentNode.children)) {
                        sibling.siblings = siblingLabels;
                    }
                    // If total units exceed 27, update `isDisplay`
                    if (totalUnits > 27) {
                        if(levelIndex>1){
                            let firstChildSet = false;
                            for (const sibling of Object.values(parentNode.children)) {
                                if (!firstChildSet) {
                                    sibling.isDisplay = true; // Keep first child visible
                                    sibling.collapsedLabel = 
                                    `${Object.values(parentNode.children).length} ${sibling.label.split(' ').slice(0,-1)}s`;
                                    firstChildSet = true;
                                } else {
                                    sibling.isDisplay = false; // Hide others
                                }
                            }
                        }
                        
                    }
                }
                
            }
    
            currentLevelNodes = nextLevelNodes;
        }
    }
    
    /**
     * Inserts a node at a specific path in the trie.
     * @param path - The path where the new node should be inserted.
     * @param newLabel - The label of the new node.
     * @param numberOfUnits - The number of units associated with the new node.
     */ 
    insertNode(hierarchy: string[], node: { id: string; label: string; apiHierarchyId: string | string[] }, isEndNode: boolean) {
        let currentNode = this.root;
    
        // Traverse Trie using apiHierarchyId
        for (const apiId of hierarchy) {
            // Find the existing node with matching apiHierarchyId
            let foundNode: TrieNode | null = null;
    
            for (const childKey in currentNode.children) {
                if (currentNode.children[childKey].apiHierarchyId === apiId) {
                    foundNode = currentNode.children[childKey];
                    break;
                }
            }
    
            // If node is found, move to it, otherwise create a new placeholder
            if (foundNode) {
                currentNode = foundNode;
            } else {
                const newNodeKey = `${node.label}-${apiId.replace(/-/g, '')}`;
                const isFirstChild = Object.keys(currentNode.children).length === 0; // Check if it's the first child
                currentNode.children[newNodeKey] = {
                    id: apiId,
                    apiHierarchyId: apiId,
                    label: newNodeKey, // Placeholder label
                    number_of_units: 0,
                    children: {},
                    isEndOfWord: false,
                    isDisplay: true,
                    collapsedLabel: '',
                    isFirstChild:isFirstChild,
                    siblings: [] // Initialize sibling array

                };
                currentNode = currentNode.children[newNodeKey]; // Move to the new node
            }
        }
    
        // Ensure children exist before inserting new node
        if (!currentNode.children) {
            currentNode.children = {};
        }
    
        // **Handle End Node Insertion Correctly**
        const newNodeKey = `${node.label}-${node.id.replace(/-/g, '')}`;
        // Insert the final node under the located node
        if (!currentNode.children[newNodeKey]) {
            const isFirstChild = Object.keys(currentNode.children).length === 0; // Check again for first child condition
            currentNode.children[newNodeKey] = {
                id: node.id,
                apiHierarchyId: node.apiHierarchyId,
                label: newNodeKey, // Use correct label
                number_of_units: 0,
                children: {},
                isEndOfWord: isEndNode,
                isDisplay: true,
                collapsedLabel: '',
                isFirstChild: isFirstChild, // Set first child flag
                siblings: [] // Initialize sibling array
            };
        }

        // **Update sibling array for all children of this parent**
        const siblingLabels = Object.values(currentNode.children).map(child => child.label);

        for (const childKey in currentNode.children) {
            currentNode.children[childKey].siblings = siblingLabels; // Assign all sibling labels
        }
    }
    

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    populateFromResponse(response: SiteStructureOutput[]) {
        for (const item of response) {
            if (item.isEndNode) {
                // Step 1: Get all nodes under this end node
                const nodes = item.nodes;
                const nodeCount = nodes.length;
    
                if (nodeCount === 0) continue; // No nodes to process
    
                // Step 2: Get the first node's label and ID
                const firstNode = nodes[0];
                const firstNodeLabel = firstNode.label.split(' ').slice(0,-1).join(' ');
                const firstNodeId = firstNode.id;
    
                // Step 3: Format the aggregated label
                let formattedLabel = `${nodeCount} ${firstNodeLabel}`;
                if (nodeCount > 1) {
                    formattedLabel = formattedLabel+'s'; // Remove 's' if plural
                }
    
                // Step 4: Collect all `apiHierarchyId`s into an array
                const apiHierarchyIds = nodes.map(node => node.id);
    
                // Step 5: Create a new aggregated node and insert it
                const aggregatedNode = {
                    id: firstNodeId,
                    label: formattedLabel, // Use the formatted label
                    apiHierarchyId:apiHierarchyIds
                };
    
                this.insertNode(item.hierarchy, aggregatedNode, true);
            } else {
                // If it's not an end node, insert normally
                for (const node of item.nodes) {
                    this.insertNode(item.hierarchy, {...node,apiHierarchyId:node.id}, false);
                }
            }
        }
    }

    
}

export const isSingleFirstChild = (trie: Trie, path: string[]): boolean  => {
    let current: TrieNode = trie.root; // Use the provided trie instance

    // Traverse the path
    for (const level of path) {
        if (!current.children[level]) {
            return false;
        }
        current = current.children[level];
    }

    // Check conditions: isFirstChild is true AND only one sibling
    return current.isFirstChild === true && (current.siblings?.length === 1||
        current.siblings?.length===0||current.siblings===undefined);
}


export const getPathUpToTarget = (array: string[], target: string): string[]  => {
    const index = array.indexOf(target);
    return index !== -1 ? array.slice(0, index + 1) : [];
}

export const  hasSibling =(rowIsFirstChild: ChildMap[], cell: string): boolean => {
    for (const item of rowIsFirstChild) {
        const key = Object.keys(item)[0]; // Get the first key (e.g., "Building1")
        const valueArray = item[key]; // Get the array (e.g., ["Building2", "Building3", "Building4"])

        if (key === cell) {
            return true; // If it's a parent, it has a vertical line
        }
        if (valueArray.includes(cell)) {
            const cellIndex = valueArray.indexOf(cell);
            return cellIndex !== -1 && cellIndex < valueArray.length - 1; // Ensure it's not the last sibling
        }
    }
    return false;
}


export const insertSiblingsAtPaths = (
    trie: Trie, 
    paths: { path: string[] }[], // Updated type
    siblingLabel: string, 
    numberOfUnits: number, 
    labelFormat: "letters" | "numbers"
) => {
    const filteredPaths = Object.values(
        paths.reduce((acc, curr) => {
            if (curr.path.length <= 1) return acc; // Skip root-level paths
      
            const parentKey = curr.path.slice(0, -1).join("|"); // Parent part of the path
            const lastItem = curr.path[curr.path.length - 1]; // Last item of the path
      
            const isNumberString = /^\d+ [a-zA-Z]+$/.test(lastItem); // "10 rooms"
      
            const uniqueKey = isNumberString ? `${parentKey}|${lastItem}` : parentKey;
      
            acc[uniqueKey] = curr; // Store or overwrite
            return acc;
        }, {} as Record<string, { path: string[] }>)
    );
    for (const { path } of filteredPaths) { // Extract path from each object
        if (path.length === 0) {
            continue;
        }

        // Navigate to the parent node
        let parent: TrieNode = trie.root;
        let grandParent: TrieNode | null = null;
        for (let i = 0; i < path.length - 1; i++) {
            if (!parent.children[path[i]]) {
                continue;
            }
            grandParent = parent;
            parent = parent.children[path[i]];
        }

        const targetLabel = path[path.length - 1];

        // Check if the target node exists
        if (!parent.children[targetLabel]) {
            continue;
        }
        const targetNode = parent.children[targetLabel];
        // If the target node is an end node, modify its label instead of adding siblings
        if (!parent || !targetLabel ||!grandParent) continue;

        if (targetNode.isEndOfWord&&/^\d+\s+\D+-.+$/.test(targetNode.label)) {
            const labelParts = targetNode.label.split(" "); // Assuming format: "10 Rooms"
            const unitCount = parseInt(labelParts[0], 10); // Extract number
            if (!isNaN(unitCount)) {
                const newLabel = `${unitCount + numberOfUnits>200
                    ?200:unitCount + numberOfUnits} ${labelParts.slice(1).join(" ")}${labelParts[0]==='1'?'s':''}`;
                targetNode.label = newLabel
                targetNode.number_of_units = unitCount + numberOfUnits>200?200:unitCount + numberOfUnits;
                // If parent has a `collapsedLabel`
                if (parent.collapsedLabel !== "") {
                    const siblingKeys = Object.keys(grandParent.children).slice(1);
                    // Extract sibling end node labels (ignoring IDs)
                    const siblingEndNodeLabels = siblingKeys.map((sibling) => {
                        return Object.values(grandParent.children[sibling].children)
                            .filter((child) => child.isEndOfWord)
                            .map((endNode) => endNode.label.replace(/-\w+$/, "")); // Remove ID part
                    });
                    // Check if all siblings have identical end node labels
                    const firstSiblingLabels = siblingEndNodeLabels[0];
                    const allSiblingsMatch =
        siblingEndNodeLabels.length > 0 &&
        siblingEndNodeLabels.every((labels) => JSON.stringify(labels) === JSON.stringify(firstSiblingLabels));
                    if (allSiblingsMatch) {
                        for (const nodeLabel of siblingKeys){
                            grandParent.children[nodeLabel].children = {};
                            // Generate a new ID
                            const newId = Math.random().toString(36).substr(2, 9);
                            const updatedLabel = `${newLabel.split('-').slice(0,-1).join('-')}-${newId}`;

                            // Create a new child node
                            const newChildNode: TrieNode = {
                                id: newId,
                                label: updatedLabel,
                                number_of_units: numberOfUnits > 200 ? 200 : numberOfUnits,
                                children: {},
                                isEndOfWord: true,
                                isFirstChild: true,
                                siblings: [],
                                isDisplay: true,
                                collapsedLabel: "",
                            };

                            // Assign the new child to the parent
                            grandParent.children[nodeLabel].children[updatedLabel] = newChildNode;
                            // Update all sibling end node labels
                        }

       
        
                    }
                }
                // Update the parent's children reference
                delete parent.children[targetLabel]; // Remove the old label entry
                parent.children[newLabel] = targetNode; // Assign it to the new label
                // Update siblings for all children of parent
                const updatedSiblingKeys = Object.keys(parent.children);
                updatedSiblingKeys.forEach(key => {
                    parent.children[key].siblings = updatedSiblingKeys;
                });
            }
            continue;
        }
        // Get the existing siblings
        const existingSiblings = Object.values(parent.children).reduce((sum, child) => sum + 
        (child.number_of_units===1?child.number_of_units:0), 0);

        // Check if adding new siblings would exceed the limit (200)
        if (existingSiblings >= 200) {
            continue;
        }
      
        const remainingSlots = existingSiblings + numberOfUnits; // How many more can be added
        let siblingsToAdd = remainingSlots>200?200:remainingSlots

        if(labelFormat === 'letters'){
            siblingsToAdd = remainingSlots>26?26:remainingSlots
        }
        // Generate formatted sibling labels
        const newSiblings: string[] = [];
        for (let i = 1; i <= siblingsToAdd; i++) {
            const uid = Math.random().toString(36).substr(2, 9);
            const formattedLabel =
                labelFormat === "letters"
                    ? `${siblingLabel} ${String.fromCharCode(64 + i)}-${uid}` // "Sibling a-xyz123"
                    : `${siblingLabel} ${i}-${uid}`; // "Sibling 1-xyz123"
            // Extract the base label (before the "-id" part)
            const baseLabel = formattedLabel.split("-")[0];

            // Check if any existing child's label (excluding its ID) matches this base label
            const labelExists = Object.values(parent.children).some(
                (child) => child.label.split("-")[0] === baseLabel
            );
            if (!labelExists) {
                parent.children[formattedLabel] = {
                    id: uid,
                    label: formattedLabel,
                    isFirstChild: existingSiblings === 0 && i === 1,
                    number_of_units: 1,
                    children: {},
                    isEndOfWord: false,
                    siblings: [],
                    isDisplay: parent.children[Object.keys(parent.children)[0]].collapsedLabel!==''?false:true,
                    collapsedLabel: '',
                };
                newSiblings.push(formattedLabel);
            }
        }

        // Update all siblings' lists (including newly added ones)
        const updatedSiblings = Object.keys(parent.children);
        updatedSiblings.forEach(label => {
            parent.children[label].siblings = updatedSiblings;
        });
        if(siblingsToAdd>=5){
            const childrenKeys = Object.keys(parent.children);
            const firstChildKey = childrenKeys[0];
            parent.children[firstChildKey].collapsedLabel = 
        `${siblingsToAdd} ${parent.children[Object.keys(parent.children)[0]].label.split(' ').slice(0,-1).join(' ')}s`
        // Set isDisplay = false for all except the first child
            childrenKeys.forEach((key, index) => {
                parent.children[key].isDisplay = index === 0;
            });
        }
    }
};


export const getSiblingHeight = (
    tableData: TableRow[], // Required to find sibling positions
    rowSpanMap: number[][],
    rowIndex: number,
    colIndex: number,
    rowIsFirstChild: ChildMap[],
    cell: string
): number => {

    const baseHeight = 74; // Default height

    // Find if `cell` has siblings
    const foundEntry = rowIsFirstChild.find(entry => Object.keys(entry)[0] === cell);
    const siblings = foundEntry ? foundEntry[cell] : [];

    let totalHeight = 0;

    // Include the given cell's rowspan in height calculation
    const mainCellRowSpan = rowSpanMap?.[rowIndex]?.[colIndex] || 1;
    totalHeight += mainCellRowSpan * baseHeight;
   
    // Find each sibling's position in the table
    siblings.slice(0,-1).forEach(sibling => {
        for (let row = rowIndex; row < tableData.length; row++) {
            const col = tableData[row].path.indexOf(sibling); // Find sibling's colIndex
            if (col !== -1) {
                // Get rowspan value
                const siblingRowSpan = rowSpanMap[row]?.[col] || 1;
                totalHeight += siblingRowSpan * baseHeight;
                break; // Stop after finding the first occurrence
            }
        }
    });
    return siblings.length===0?0:totalHeight;
};


export const insertChildrenAtNodes = (
    trie: Trie,
    paths: { path: string[] }[], // Updated type
    childBaseLabel: string,
    numberOfUnits: number,
    format: "numbers" | "letters",
    leafNodeLabel?: string,
    leafNodeUnits?: number
) => {
    for (const { path } of paths) {
        let current: TrieNode = trie.root;
        let parent:TrieNode|null = null
        // Traverse the path to reach the selected node
        for (const level of path) {
            if (!current.children[level]) {
                continue;
            }
            parent = current
            current = current.children[level];
        }
        // Ensure the node can accommodate new children (max 200)
        const existingChildren = Object.keys(current.children);
        const existingChildrenCount = existingChildren.reduce((sum, child) => sum +
            (current.children[child].number_of_units===1?current.children[child].number_of_units:0), 0);
        const endChild = Object.values(current.children)[0]
        if(endChild?.isEndOfWord){
            const oldKey = endChild.label;
            const totalUnits =  endChild.number_of_units + numberOfUnits
            endChild.number_of_units = totalUnits
            endChild.label = `${totalUnits} ${endChild.label.split(' ')[1]}${endChild.number_of_units<=1?'s':''}`
            // Update the parent's key: add new key with the updated node, then remove the old key
            current.children[endChild.label] = endChild;
            if (oldKey !== endChild.label) {
                delete current.children[oldKey];
            }
            continue
        }

        const totalAfterAddingForendnode = existingChildren.length + numberOfUnits;
        const totalAfterAdding = existingChildrenCount + numberOfUnits;
        let newChildrenCount = existingChildren[0]?.split(' ')[0]===leafNodeLabel?
            totalAfterAdding > 200 ? 200  : totalAfterAddingForendnode:totalAfterAdding>200?
                200:totalAfterAdding;
        if(format === 'letters'){
            newChildrenCount = existingChildrenCount + numberOfUnits>26?26:existingChildrenCount + numberOfUnits;
        
        }
        if (newChildrenCount <= 0) {
            continue;
        }
        // Generate child node names based on format
        const newChildNames: string[] = [];
        for (let i = 0; i < newChildrenCount; i++) {
            const uid = Math.random().toString(36).substr(2, 9)
            const suffix = format === "numbers" ? (i + 1).toString() : String.fromCharCode(65 + i); // 'A', 'B', 'C'...
            const childLabel = `${childBaseLabel} ${suffix}-${uid}`; // Append rowIndex for uniqueness

            if (!existingChildren.includes(childLabel)) {
                newChildNames.push(childLabel);
            }

        }

        // Add new children
        for (let index = 0; index < newChildNames.length; index++) {
            const newChild = newChildNames[index];
            const isFirstChild = index === 0;
            const firstSiblingHasLabel = parent?.children 
                ? parent.children[Object.keys(parent.children)[0]]?.collapsedLabel!==''
                : false;
            current.children[newChild] = {
                id:newChild.split('-').at(-1)!,
                label: `${newChild}`,
                isFirstChild: existingChildren.length === 0 && index === 0, // First child gets `isFirstChild: true`
                number_of_units: 1,
                children: {},
                isEndOfWord: path.length===4?true:false,
                siblings: [],
                isDisplay: (newChildrenCount >= 5 && isFirstChild) ||
                (parent!==null && firstSiblingHasLabel && isFirstChild) ||
                (newChildrenCount < 5 && parent!==null && !firstSiblingHasLabel),
                collapsedLabel: newChildrenCount>=5&&index===0||(firstSiblingHasLabel&&isFirstChild)?
                    `${newChildrenCount} ${newChild.split(' ').slice(0,-1).join(' ')}s` :'',

            };

            // If a leaf node is specified, add it inside the child
            if (leafNodeLabel && leafNodeUnits !== undefined && path.length < 4) {
                const uid = Math.random().toString(36).substr(2, 9)
                const label = `${leafNodeUnits} ${leafNodeLabel}${leafNodeUnits > 1 ? "s" : ""}`;
                current.children[newChild].children[`${label}-${uid}`] = {
                    id:uid,
                    label: `${label}-${uid}`, // e.g., "3 Rooms"
                    number_of_units: leafNodeUnits,
                    isFirstChild: true,
                    children: {}, // No further children allowed
                    isEndOfWord: true, // Mark as leaf node
                    isDisplay: true,
                    collapsedLabel: "",
                };
            }
        }

        // Update siblings array
        const updatedChildren = Object.keys(current.children);
        for (const child of updatedChildren) {
            current.children[child].siblings = updatedChildren;
        }
    }
};




export const insertEndNodeAtPaths = (
    trie: Trie,
    paths: { path: string[] }[], // Updated type
    endNodeLabel: string,
    numberOfUnits: number
) => {
    for (const { path } of paths) {
        let current: TrieNode = trie.root;

        // Traverse to the selected node
        for (const level of path) {
            if (!current.children[level]) {
                return;
            }
            current = current.children[level];
        }

        // Prevent adding an end node if the selected node is already a leaf
        if (current.isEndOfWord) {
            return;
        }


        // Look for an existing end node that matches the endNodeLabel
        let existingLeafKey: string | undefined;
        for (const key in current.children) {
            const child = current.children[key];
            if (child.isEndOfWord && child.label.includes(endNodeLabel)) {
                existingLeafKey = key;
                break;
            }
        } if (existingLeafKey) {
            // Update the existing end node
            const existingLeaf = current.children[existingLeafKey];
            const newUnits = existingLeaf.number_of_units + numberOfUnits;
            const newLabel = `${newUnits} ${endNodeLabel}${newUnits > 1 ? "s" : ""}`;
      
            // Remove the old entry and update properties
            delete current.children[existingLeafKey];
            existingLeaf.label = newLabel;
            existingLeaf.number_of_units = newUnits;
            // Reinsert with the new label as key
            current.children[newLabel] = existingLeaf;
        } else {
            // Check if this is the first child
            const isFirstChild = Object.keys(current.children).length === 0;
            const uid = Math.random().toString(36).substr(2, 9)
            // Create the end node label, ensuring uniqueness with `rowIndex`
            const leafNodeKey = `${numberOfUnits} ${endNodeLabel}${numberOfUnits > 1 ? "s" : ""}-${uid}`; // e.g., "10 Rooms-2"

            // Insert the end node
            current.children[leafNodeKey] = {
                id:uid,
                label: leafNodeKey,
                number_of_units: numberOfUnits,
                children: {}, // No further children allowed
                isEndOfWord: true, // Mark as a leaf node
                isFirstChild: isFirstChild, // Set isFirstChild
                isDisplay: true,
                collapsedLabel: '',
            };

            // Update sibling references
            const updatedChildren = Object.keys(current.children);
            for (const child of updatedChildren) {
                current.children[child].siblings = updatedChildren;
            }
        }
       
        
    }
};



export const checkIfAnyEndOfWord = (trie: Trie, paths: { path: string[]}[]): boolean => {
    for (const { path } of paths) {
        let current: TrieNode = trie.root;

        // Traverse the Trie using the given path
        for (const level of path) {
            if (!current.children[level]) {
                return false; // Invalid path, continue to next
            }
            current = current.children[level];
        }

        if (current.isEndOfWord) {
            return true; // If any path leads to an end node, return true immediately
        }
    }

    return false; // No paths found leading to an end node
};




export const hideNonFirstChildren = (root: Trie, path: string[], isCollapsed: boolean): Trie => {
    if (path.length === 0) return root;
    let currentNode: TrieNode | undefined = root.root;

    if(!isCollapsed){
        for (const [index,key] of path.slice(0,-1).entries()) {
            if (!currentNode.children[key]) return root; // Invalid path
            currentNode = currentNode.children[key];
            if(index===0)
                break
        }
    }else{
        for (const key of path) {
            if (!currentNode.children[key]) return root; // Invalid path
            currentNode = currentNode.children[key];
        }

    }

    if (!currentNode) return root;

    // Use a queue for better memory efficiency (BFS)
    const queue: TrieNode[] = [currentNode];

    while (queue.length > 0) {
        const node = queue.shift(); // Dequeue

        if (!node) continue;

        if (!isCollapsed) {
            // Reset `collapsedLabel` only for nodes present in `path`
            if (path.includes(node.label)) {
                node.collapsedLabel = ''; 
                
                // Get parent node (by checking which node has this node as a child)
                let parentNode = root.root;
                for (const key of path) {
                    if (!parentNode.children[key]) break;
                    if (parentNode.children[key] === node) {
                        // Found the parent node
                        Object.values(parentNode.children).forEach(child => {
                            child.isDisplay = true; // Make all children of the parent visible
                        });
                        break;
                    }
                    parentNode = parentNode.children[key];
                }
            }
        
            // Check if the node follows the whole path structure
            let currentNode = root.root;
            let followsPath = true;
            
            for (const key of path) {
                if (!currentNode.children[key]) {
                    followsPath = false;
                    break;
                }
                currentNode = currentNode.children[key];
            }
        
            // If the node is a descendant of the last node in the path, make it visible
            if (followsPath && currentNode.children[node.label]) {
                node.isDisplay = true;
                node.collapsedLabel = '';
            }
            // Ensure all nodes **after** the last path element are also visible
            if (followsPath) {
                const queue: TrieNode[] = [currentNode];
                while (queue.length > 0) {
                    const descendant = queue.shift(); // Dequeue
                    if (!descendant) continue;

                    descendant.isDisplay = true;
                    descendant.collapsedLabel = '';

                    // Enqueue all children
                    Object.values(descendant.children).forEach(child => queue.push(child));
                }
            }
        }
        else {
            if (!node.isFirstChild && node.label !== path[path.length - 1]) {
                node.isDisplay = false; // Hide non-first children
            } else {
                if (node.isFirstChild && node.siblings?.length && node.label !== path[path.length - 1]) {
                    node.collapsedLabel = isCollapsed && node.siblings.length !== 1
                        ? `${node.siblings.length} ${node.label.split(' ')[0]}s`
                        : '';
                }
                node.isDisplay = true; // Ensure first children remain visible
            }
        }

        // Push all children to queue
        Object.values(node.children).forEach(child => queue.push(child));
    }

    return root;
};



export const getCollapsedLabel = (root: TrieNode, path: string[],isParent:boolean=false): string => {
    let currentNode: TrieNode | undefined = root;
    let parent: TrieNode | null = root;

    for (const key of path) {
        if (!currentNode.children[key]) return ''; // Path is invalid
        parent = currentNode
        currentNode = currentNode.children[key];
    }

    return isParent?parent.children[Object.keys(parent.children)[0]].collapsedLabel:currentNode?.collapsedLabel
};



export function getSiblingLabelArray(trie: TrieNode, paths: { path: string[] }[]): { path: string[] }[] {
    return paths.flatMap(({ path }) => {
        let currentNode: TrieNode | undefined = trie;
        for (const segment of path) {
            if (!currentNode.children[segment]) {
                return { path: [] }; // Path is invalid, return empty array
            }
            currentNode = currentNode.children[segment];
        }
        const siblings = currentNode.siblings ?? [];
        return siblings.map(sibling => ({ path: [...path.slice(0, -1), sibling] }));
    });
}

export const getSameLevelNodeLabelArray = (
    tableData: TableRow[],
    paths: { path: string[]}[]
): { path: string[] }[] => {
    const updatedPaths: { path: string[] }[] = [];
    // We'll store a unique key per candidate (based on parent portion and last item text)
    const seen = new Set<string>();
  
    for (const { path } of paths) {
        const level = path.length;
        const sameLevelNodes = tableData.filter((row) => row.path.length >= level);
        for (let i = 0; i < sameLevelNodes.length; i++) {
            // Get the last item from the slice of the node's path at this level.
            const lastItem = sameLevelNodes[i].path.slice(0, level)[level - 1];
            // Create the candidate path, where the last element is appended with the index.
            const candidatePath = [...sameLevelNodes[i].path.slice(0, level - 1), `${lastItem}`];
        
            // Create a unique key based on the parent part and the last item (without the index)
            const uniqueKey = JSON.stringify(sameLevelNodes[i].path.slice(0, level - 1)) + '|' + lastItem;
        
            // If we've not added this combination yet, add it.
            if (!seen.has(uniqueKey)) {
                updatedPaths.push({
                    path: candidatePath,
                });
                seen.add(uniqueKey);
            }
        }
    }
  
    return updatedPaths;
};
  

export const extractLastItems = (paths: { path: string[] }[]): string[] => {
    return Array.from(new Set(paths.map(({ path }) => path[path.length - 1])));
};

export const modifyLastItemInPaths = (
    paths: { path: string[]}[]
): { path: string[]}[] => {
    return paths.map(({ path }) => {
        if (path.length === 0) return { path }; // Handle empty paths

        const lastItem = path[path.length - 1];

        // Check if the last item follows the format 'label1-0'
        const modifiedLastItem = lastItem.includes("-") ? lastItem.split("-")[0] : lastItem;

        return {
            path: [...path.slice(0, -1), modifiedLastItem], // Replace last item
   
        };
    });
};



/**
 * Recursively calculates the number of leaf nodes (deepest children) 
 * for a given node by traversing its entire hierarchy.
 */
export const countDeepestDescendants = (node: TrieNode): number => {
    if ((!node.children || Object.keys(node.children).length === 0)) {
        return 1; // Base case: If no children, count itself as 1
    }

    let totalCount = 0;

    for (const childKey in node.children) {
        const childNode = node.children[childKey];
        totalCount += countDeepestDescendants(childNode); // Traverse deeper
    }
    
    return totalCount;
};


export const findFirstChild = (root: TrieNode, nodeLabels: string[]): string | null => {
    let currentNode: TrieNode | undefined = root;

    for (const label of nodeLabels) {
        if (!currentNode?.children[label]) {
            return null; // If the label is not found, return null
        }
        currentNode = currentNode.children[label];
    }

    // Get the first child of the last matched node
    const firstChildKey = Object.keys(currentNode.children)[0];
    return firstChildKey ? currentNode.children[firstChildKey].label : null;
};

export const updateSelectedLabels = (
    trie: Trie,
    selections: { path: string[] }[],
    newLabelBase: string,
    numberOfUnits: number,
    format: "numbers" | "letters",
    endNodeLabel?: string,
    endNodeUnits?: number
) => {
    // Process each selection in the provided array.
    for (const { path } of selections) {
        // Traverse the trie to locate the node and also keep track of its parent.
        let current: TrieNode = trie.root;
        let grandParent: TrieNode | null = null;
        let parent: TrieNode | null = null;
        let keyToReplace: string | null = null;
        for (const level of path) {
            if (!current.children[level]) {
                parent = null;
                break;
            }
            grandParent = parent;
            parent = current;
            keyToReplace = level;
            current = current.children[level];
        }
        const targetLabel = path[path.length - 1];

        // If traversal failed, move to the next selection.
        if (!parent || !keyToReplace||!grandParent) continue;
     
        // If the target node is an end node, modify its label instead of adding siblings
        if (current.isEndOfWord && /^\d+\s+\D+-.+$/.test(current.label)) {
            const labelParts = current.label.split(" "); // Assuming format: "10 Rooms"
            const newLabel = `${numberOfUnits>200
                ?200:numberOfUnits} ${newLabelBase}${labelParts[0]==='1'?'s':''}`;
            current.label = newLabel
            current.number_of_units =  numberOfUnits>200?200: numberOfUnits;
        
            // If parent has a `collapsedLabel`
            if (parent.collapsedLabel !== "") {
                const siblingKeys = Object.keys(grandParent.children).slice(1);
                // Extract sibling end node labels (ignoring IDs)
                const siblingEndNodeLabels = siblingKeys.map((sibling) => {
                    return Object.values(grandParent.children[sibling].children)
                        .filter((child) => child.isEndOfWord)
                        .map((endNode) => endNode.label.replace(/-\w+$/, "")); // Remove ID part
                });
                // Check if all siblings have identical end node labels
                const firstSiblingLabels = siblingEndNodeLabels[0];
                const allSiblingsMatch =
                    siblingEndNodeLabels.length > 0 &&
                    siblingEndNodeLabels.every((labels) => JSON.stringify(labels) === JSON.stringify(firstSiblingLabels));

                if (allSiblingsMatch) {
                    for (const nodeLabel of siblingKeys){
                        grandParent.children[nodeLabel].children = {};
                        // Generate a new ID
                        const newId = Math.random().toString(36).substr(2, 9);
                        const updatedLabel = `${newLabel}-${newId}`;

                        // Create a new child node
                        const newChildNode: TrieNode = {
                            id: newId,
                            label: updatedLabel,
                            number_of_units: numberOfUnits > 200 ? 200 : numberOfUnits,
                            children: {},
                            isEndOfWord: true,
                            isFirstChild: true,
                            siblings: [],
                            isDisplay: true,
                            collapsedLabel: "",
                        };

                        // Assign the new child to the parent
                        grandParent.children[nodeLabel].children[updatedLabel] = newChildNode;
                        // Update all sibling end node labels
                    }

                   
                    
                }
            }

            // Update the parent's children reference
            delete parent.children[targetLabel]; // Remove the old label entry
            parent.children[`${newLabel}-${current.id}`] = current; // Assign it to the new label
            
            continue;
        }
        // Remove the old node from its parent's children.
        delete parent.children[keyToReplace];


  
        // Generate new node labels based on the provided base, format, and number of units.
        // For example, if newLabelBase is "Building", format "numbers", and numberOfUnits is 3,
        // it will generate "Building 1", "Building 2", and "Building 3".
        const newChildNames: string[] = [];
        for (let i = 0; i < numberOfUnits; i++) {
            const uid = Math.random().toString(36).substr(2, 9)
            const suffix = format === "numbers" ? (i + 1).toString() : String.fromCharCode(65 + i);
            newChildNames.push(`${newLabelBase} ${suffix}-${uid}`);
        }

        // Extract existing base labels from parent children in order
        const existingBaseLabels: string[] = Object.values(parent.children)
            .map(child => child.label.split("-").slice(0, -1).join('-')) // Extract base label
            .sort((a, b) => {
                const numA = parseInt(a.split(" ").at(-1) ?? "0", 10); // Extract number part from "Child 1"
                const numB = parseInt(b.split(" ").at(-1) ?? "0", 10);
                return numA - numB;
            });
        // Create and insert the new nodes.
        newChildNames.forEach((newChildLabel, index) => {
            
            const baseLabel = newChildLabel.split("-").slice(0,-1).join('-'); // Extract the base label (without UID)
            // Check if the label already exists
            if (existingBaseLabels.includes(baseLabel)) return;
            // Construct a new node using your TrieNode type.
            const newChildNode: TrieNode = {
                id:newChildLabel.split('-').at(-1)!,
                label: `${newChildLabel}`,
                number_of_units: 1,
                children: {},
                isEndOfWord: false, // default flag; you can adjust this as needed
                isFirstChild: index%numberOfUnits===0,
                isDisplay: ((numberOfUnits>=5&&index===0)?true:(index===0&&
                    (grandParent.children[Object.keys(grandParent.children)[0]].collapsedLabel!==''
                    ))?
                    true:
                    (numberOfUnits<5&&
                        grandParent.children[Object.keys(grandParent.children)[0]].collapsedLabel==='')?true:false),
                collapsedLabel: ((numberOfUnits>=5&&index===0)||(index===0&&
                    grandParent.children[Object.keys(grandParent.children)[0]].collapsedLabel!=='')
                )?
                    `${numberOfUnits} ${newChildLabel.split(' ').slice(0,-1).join(' ')}s` :'',

            };
  
            // If an end node option is provided, add it as a child.
            if (endNodeLabel && endNodeUnits !== undefined) {
                const uid = Math.random().toString(36).substr(2, 9)
                const endLabel = `${endNodeUnits} ${endNodeLabel}${endNodeUnits > 1 ? "s" : ""}`;
                newChildNode.children[`${endLabel}-${uid}`] = {
                    id:uid,
                    label: `${endLabel}-${uid}`,
                    number_of_units: endNodeUnits,
                    children: {},
                    isEndOfWord: true,
                    isFirstChild: true,
                    siblings: [],
                    isDisplay: true,
                    collapsedLabel: "",
                };
            }
            // Store in the ordered object
            parent!.children[`${newChildLabel}`] = newChildNode;
        });

        // Update the siblings array for all children in the parent node.
        const updatedKeys = Object.keys(parent.children);
        updatedKeys.forEach(key => {
        parent!.children[key].siblings = updatedKeys;
        });
        
    }
};
  
export const getParentCollapsedLabelIfMultipleUnits = (
    trie: Trie,
    path: string[]
): string => {
    let current: TrieNode = trie.root;
    let parent: TrieNode | null = null;
    let grandParent: TrieNode | null = null;
    // Traverse the trie along the path, tracking grandparent and parent.
    for (const segment of path) {
        grandParent = parent;
        parent = current;
        if (!(segment in current.children)) {
            return '';
        }
        current = current.children[segment];
    }
    // Now, 'parent' is the node that holds the last element.
    if (parent && parent.collapsedLabel !== '') {
        // Parse the parent's collapsedLabel (assumed format: "2 units")
        const parts = parent.collapsedLabel.split(" ");
        const unitCount = parseInt(parts[0], 10);
        if (!isNaN(unitCount) && unitCount > 1) {
        // If there is a grandparent, check all siblings of 'parent'
            if (grandParent) {
                // Compute sorted list of children keys for the parent.
                const parentChildrenKeys = Object.keys(parent.children).sort().map(key=>key.split('-').slice(0,-1).join('-'))
                // Iterate over each child of the grandparent.
                for (const key in grandParent.children) {
                    const sibling = grandParent.children[key];
                    // Skip the parent node itself.
                    if (sibling === parent) continue;
                    // Get this sibling's children keys.
                    const siblingChildrenKeys = Object.keys(sibling.children).sort().
                        map(key=>key.split('-').slice(0,-1).join('-'));
                    // Compare the arrays (using JSON.stringify for simplicity)
                    if (
                        JSON.stringify(siblingChildrenKeys) !==
              JSON.stringify(parentChildrenKeys)
                    ) {
                        // If any sibling has a different set of children, return empty string.
                        return '';
                    }
                }
            }
            // If either there is no grandparent (no siblings) or all siblings match, return the collapsed label.
            return parent.collapsedLabel;
        }
    }
  
    return '';
};
  
export const hasChildren = (trie: Trie, path: string[]): boolean => {
    let currentNode: TrieNode = trie.root;
  
    // Traverse the trie along the provided path
    for (const segment of path) {
        if (!(segment in currentNode.children)) {
        // If the path doesn't exist, we assume it has no children
            return false;
        }
        currentNode = currentNode.children[segment];
    }
  
    // Check if the node has any children
    return Object.keys(currentNode.children).length > 0;
};

export const getCommonEndNodeLabel = (
    trie: Trie,
    paths: { path: string[]}[]
): string => {
    const foundLabels = new Set<string>();
  
    for (const { path } of paths) {
        let current: TrieNode = trie.root;
  
        // Traverse the trie along the path.
        for (const segment of path) {
            if (!(segment in current.children)) {
                return '';
            }
            current = current.children[segment];
        }
  
        // Check if any child of the current node is an end node.
        for (const key in current.children) {
            const child = current.children[key];
            if (child.isEndOfWord) {
                foundLabels.add(child.label);
            }
        }
    }
    
    if (foundLabels.size === 0) {
        return ''; // No end nodes found
    }
  
    // Helper function: Extract the label text without the number.
    const extractLabelName = (label: string): string => {
        // Assumes the label format is "number labelName"
        const parts = label.split(' ');
        // Return everything except the first part, joined back in case there are multiple words.
        return parts.slice(1).join(' ');
    };
  
    const labelsArray = Array.from(foundLabels).map(label=>label.split('-').slice(0,-1).join('-'));
    if (labelsArray.length === 1) {
        return labelsArray[0]; // Only one unique label, return it.
    } else {
        // There is more than one label.
        // Check if the non-numeric parts are all the same.
        const commonLabelName = extractLabelName(labelsArray[0]);
        const allSame = labelsArray.every(
            (lbl) => extractLabelName(lbl) === commonLabelName
        );
        return allSame ? labelsArray[0] : 'Mixed';
    }
};
  
export const hasChildrenInAnyPath = (
    trie: Trie,
    paths: { path: string[]}[]
): boolean => {
    for (const { path } of paths) {
        let current: TrieNode | undefined = trie.root;
      
        // Traverse the trie along the path.
        for (const segment of path) {
            if (!current.children[segment]) {
                current = undefined;
                break;
            }
            current = current.children[segment];
        }

        // If we reached a valid node and it has children, return true
        if (current && Object.keys(current.children).length > 0) {
            return true;
        }
    }

    return false; // No path end had children
};

const collectAndDelete = (node: TrieNode,deletedNodeIds:string[]) => {
    if (!node) return;
    // Collect the current node's apiHierarchyId before deletion
    if (node.apiHierarchyId) {
        if (Array.isArray(node.apiHierarchyId)) {
            deletedNodeIds.push(...node.apiHierarchyId); // Spread and add each item separately
        } else {
            deletedNodeIds.push(node.apiHierarchyId); // Add single string if not an array
        }    }

    // Recursively collect and delete all child nodes
    Object.values(node.children).forEach((child) => {
        collectAndDelete(child,deletedNodeIds);
    });


};

export const deleteEndNodes = (
    trie: Trie,
    paths: { path: string[] }[]
): string[] => {
    const deletedNodeIds: string[] = [];

    for (const { path } of paths) {
        let current: TrieNode | undefined = trie.root;
        let parent: TrieNode | undefined = undefined;
        let lastKey: string | undefined = undefined;
        let grandParent: TrieNode | undefined = undefined;
        // Traverse to the end node
        for (const segment of path) {
            if (!current.children[segment]) {
                current = undefined;
                break;
            }
            grandParent = parent
            parent = current;
            lastKey = segment;
            current = current.children[segment];
        }

        if (!parent || !lastKey || !parent.children[lastKey]||!grandParent) continue;
        // If last node is `isEndOfWord: true`
        if (current?.isEndOfWord &&/^\d+\s+\D+-.+$/.test(current.label)) {
            // Collect API hierarchy ID before deletion
            collectAndDelete(current,deletedNodeIds);


            // Check if parent has a `collapsedLabel`
            if (parent.collapsedLabel !== "") {
                const siblingKeys = Object.keys(grandParent.children);

                // Extract sibling end nodes (ignoring ID)
                const siblingEndNodeLabels = siblingKeys.map((sibling) => {
                    return Object.values(grandParent.children[sibling].children)
                        .filter((child) => child.isEndOfWord)
                        .map((endNode) => endNode.label.replace(/-\w+$/, "")); // Remove ID part
                });

                // Ensure all siblings have identical end nodes
                const allHaveSameEndNodes =
                    siblingEndNodeLabels.length > 0 &&
                    siblingEndNodeLabels.every(
                        (labels) => JSON.stringify(labels) === JSON.stringify(siblingEndNodeLabels[0])
                    );

                if (allHaveSameEndNodes) {
                    //  Delete all siblings' children with `isEndOfWord: true`
                    siblingKeys.forEach((sibling) => {
                        Object.values(grandParent.children[sibling].children).forEach((child) => {
                            if (child.isEndOfWord) {
                                collectAndDelete(child,deletedNodeIds);
                                delete grandParent.children[sibling].children[child.label];
                            }
                        });
                    });
                }
            }
        }
        if(current!==undefined){
            // Collect API hierarchy ID before deleting the target node
            collectAndDelete(current,deletedNodeIds);

            //  Delete the target node
            delete parent.children[lastKey];
        }
   
        

        //  Update sibling relationships
        const remainingSiblings = Object.keys(parent.children);
        if (remainingSiblings.length > 0) {
            // Update sibling lists
            const updatedSiblings = remainingSiblings.map((key) => parent.children[key].label);
            remainingSiblings.forEach((key) => {
                parent.children[key].siblings = updatedSiblings;
            });

            // Assign new first child if necessary
            if (parent.children[remainingSiblings[0]]) {
                parent.children[remainingSiblings[0]].isFirstChild = true;
            }
        }
    }
    return deletedNodeIds;
 
};


export const getSameLevelNodeLabelArrayFromTrie = (
    trie: TrieNode,
    paths: { path: string[] }[]
): { path: string[] }[] => {
    const updatedPaths: { path: string[] }[] = [];
    const seen = new Set<string>();

    for (const { path } of paths) {
        const level = path.length;

        // Retrieve nodes at the required depth (level)
        const sameLevelNodes = getNodesAtDepth(trie, level);
        for (const node of sameLevelNodes) {
            const lastItem = node.label;
            const parentPath = getParentPath(node, trie);

            const candidatePath = [...parentPath, lastItem];

            // Unique key to prevent duplicates
            const uniqueKey = JSON.stringify(parentPath) + "|" + lastItem;

            if (!seen.has(uniqueKey)) {
                updatedPaths.push({ path: candidatePath });
                seen.add(uniqueKey);
            }
        }
    }

    return updatedPaths;
};

const getNodesAtDepth = (root: TrieNode, depth: number, currentDepth = 0): TrieNode[] => {
    if (currentDepth === depth) return [root];

    let nodes: TrieNode[] = [];
    for (const child of Object.values(root.children)) {
        nodes = nodes.concat(getNodesAtDepth(child, depth, currentDepth + 1));
    }
    return nodes;
};

const getParentPath = (targetNode: TrieNode, root: TrieNode, path: string[] = []): string[] => {
    for (const child of Object.values(root.children)) {
        if (child === targetNode) return path;

        const foundPath = getParentPath(targetNode, child, [...path, child.label]);
        if (foundPath.length > 0) return foundPath;
    }
    return [];
};


export const getFirstChildrenArray = (root:TrieNode,path:string[]):string[][] => {
    let firstNodeLabels:string[][] = []
    let currentNode :TrieNode = root
    for (const key of path){
        if(!currentNode.children[key]){
            return []
        }
        currentNode = currentNode.children[key]
    }
    if(!currentNode) return []
    firstNodeLabels = getDeeperFirstChildren(currentNode,path.slice(0,-1))
    return firstNodeLabels
}

const getDeeperFirstChildren = (node: TrieNode, path: string[] = [], result: string[][] = []): string[][] => {
    // Check if any child meets the condition
    const hasQualifiedChild = Object.values(node.children).some(
        child => child.isFirstChild && (child.siblings?.length ?? 0) > 1
    );

    // If current node has a qualified child, add the full path to result
    if (hasQualifiedChild) {
        result.push([...path, node.label]); // Store the full path
    }

    // Recursively check all children
    for (const childKey in node.children) {
        getDeeperFirstChildren(node.children[childKey], [...path, node.label], result);
    }

    return result;
};


export const findPathToLabel = (node: TrieNode, targetLabel: string, path: string[] = []): string[]  => {
    // Add the current node's label to the path
    path.push(node.label);

    // If the current node is the target, return the path
    if (node.label === targetLabel) {
        return [...path]; // Return a copy to avoid modifying the original
    }

    // Traverse all children
    for (const childKey in node.children) {
        const child = node.children[childKey];
        const result = findPathToLabel(child, targetLabel, path);
        if (result) return result; // Found the target, return the path
    }

    // Backtrack if target wasn't found in this path
    path.pop();
    return [];
};

export const getChildCount = (trie: Trie, path: string[]): number => {
    let current: TrieNode = trie.root;

    for (const level of path) {
        if (!current.children[level]) {
            return 0; // If path is invalid, return 0
        }
        current = current.children[level];
    }

    return Object.keys(current.children).length;
};


export  const findMatchingTerm = (tableData: TableRow[], row: TableRow,path: string[]): string  => {
    // Step 1: Find the matching row in tableData
    const rowIndex = tableData.findIndex(
        (dataRow) => JSON.stringify(dataRow.path) === JSON.stringify(row.path)
    );
  
    // If no match found or no next row, return null
    if (rowIndex === -1 || rowIndex + 1 >= tableData.length) return '';
  
    // Step 2: Get the next row
    const nextRow = tableData[rowIndex + 1];
  
    const matchingTerm = nextRow.path[path.length-1]
  
    return matchingTerm || '';
};


export const getFilteredSameLevelNodesFromTrie = (
    trie: TrieNode,
    paths: { path: string[] }[],
    endId: string
): { path: string[] }[] => {
    const updatedPaths: { path: string[] }[] = [];
    const seen = new Set<string>();

    for (const { path } of paths) {
        const level = path.length;
        const lastTerm = path[path.length - 1]; // Last item in the given path

        // Get only relevant nodes starting from lastTerm and stopping at endId
        const sameLevelNodes = getNodesAtDepth(trie, level);
        const filteredNodes = filterNodesInRange(sameLevelNodes, lastTerm, endId);

        for (const node of filteredNodes) {
            const lastItem = node.label;
            const parentPath = getParentPath(node, trie);

            const candidatePath = [...parentPath, lastItem];

            // Unique key to prevent duplicates
            const uniqueKey = JSON.stringify(parentPath) + "|" + lastItem;

            if (!seen.has(uniqueKey)) {
                updatedPaths.push({ path: candidatePath });
                seen.add(uniqueKey);
            }
        }
    }

    return updatedPaths;
};

const filterNodesInRange = (
    nodes: TrieNode[],
    startTerm: string,
    endTerm: string
): TrieNode[] => {
    const startIndex = nodes.findIndex(node => node.label === startTerm);
    const endIndex = nodes.findIndex(node => node.label === endTerm);

    // Ensure startIndex exists and endIndex is after startIndex
    if (startIndex === -1 || (endIndex !== -1 && endIndex < startIndex)) return [];

    return nodes.slice(startIndex, endIndex === -1 ? undefined : endIndex);
};


export const getHierarchyIds = (rootTrieNode:TrieNode,path:string[]) => {
    let currentNode = rootTrieNode.children[Object.keys(rootTrieNode.children)[0]];  // Start from the root
    const hierarchyIds:string[] = [];
    for (let i = 0; i < path.length; i++) {  // Skip first item (index 0)
        const pathItem = path[i];

        // Find the child node in the current node's children
        const childNode = currentNode.children[pathItem];

        if (childNode.apiHierarchyId) {
            if (Array.isArray(childNode.apiHierarchyId)) {
                hierarchyIds.push(...childNode.apiHierarchyId); // Spread and add each item separately
            } else {
                hierarchyIds.push(childNode.apiHierarchyId); // Add single string if not an array
            }
            currentNode = childNode;  // Move to the next level in Trie
        } else {
            break;  // If a node is missing, stop traversing
        }
    }

    return hierarchyIds;
};


export function getApiHierarchyIds(trie: TrieNode, paths: { path: string[] }[]): string[] {
    const apiHierarchyIds: string[] = [];

    for (const { path } of paths) {
        let currentNode: TrieNode | undefined = trie;
        for (const label of path) {
            if (!currentNode.children[label]) {
                currentNode = undefined;
                break;
            }
            currentNode = currentNode.children[label];
        }
        if (currentNode && currentNode.apiHierarchyId) {
            if(Array.isArray(currentNode.apiHierarchyId)){
                apiHierarchyIds.push(...currentNode.apiHierarchyId);

            }else{
                apiHierarchyIds.push(currentNode.apiHierarchyId);
            }
        }
    }

    return apiHierarchyIds;
}

export const updateTrieWithHierarchy = (
    trie: TrieNode, 
    hierarchy: string[], 
    responseNodes: { id: string; label: string }[]
): void => {
    let currentNode = trie;
    // Traverse the Trie based on apiHierarchyId
    for (const hierarchyId of hierarchy) {
        const matchingChild = Object.values(currentNode.children).find(
            child => child.apiHierarchyId === hierarchyId
        );

        if (matchingChild) {
            currentNode = matchingChild; // Move to the next level in Trie
        } else {
            console.warn(`Hierarchy ID ${hierarchyId} not found in Trie`);
            return; // Stop if hierarchy does not exist
        }
    }
    const firstChildKey = Object.keys(currentNode.children)[0];
    const firstChild = currentNode.children[firstChildKey];
    let updatedLabel = '';
    if (firstChild && currentNode.children[(Object.keys(currentNode.children)[0])].isEndOfWord) {
        const labelParts = firstChild.label.split('-').slice(0,-1); // Split label into parts
        const numberPart = parseInt(labelParts.join('-'), 10); // Extract the numeric part
        let wordPart = labelParts.join('-').split(' ')?.slice(1)?.join(' '); // Extract the word part

        // If number is greater than 1 and word ends with 's', remove 's'
        if (numberPart > 1 && wordPart.endsWith('s')) {
            wordPart = wordPart.slice(0, -1);
        }
        updatedLabel = `${numberPart}-${wordPart}`;
    }

    // If we reached an end node, count the nodes and update label

    if (currentNode.children[(Object.keys(currentNode.children)[0])].isEndOfWord) {
        const nodeCount = responseNodes.length;
      
        const firstNodeLabel = responseNodes[0]?.label.split(' ').slice(0,-1).join(' ') || "Unknown";
        // Create a new label with count + first node label
        if(updatedLabel === `${nodeCount}-${firstNodeLabel}`){
            // Assign apiHierarchyIds as an array of IDs
            currentNode.children[(Object.keys(currentNode.children)[0])].apiHierarchyId = responseNodes.map(node => node.id);
        }    
     
    }else{
        // Now at the last hierarchy node, update children based on response
        Object.values(currentNode.children).forEach((child) => {
            const matchingNode = responseNodes.find(node => 
                node.label === child.label?.split('-')?.slice(0,-1)?.join('-'));
            if (matchingNode) {
                child.apiHierarchyId = matchingNode.id; // Assign API hierarchy ID
            }
        });
    }

    
};

