import React, { ReactNode, useContext, useRef, useState } from "react"
import { arraysEqual, rootPathInArray, TableRow } from "../../pages/Hub/Site Structure/SiteStructure";
import { NextFiledTypes } from "../../pages/Hub/Site Structure/components/AddNextLevelFields";
import { 
    getFirstChildrenArray, 
    hideNonFirstChildren, 
    Trie } from "../../pages/Hub/Site Structure/components/TrieDataStructure";
import { generateTableData } from "../../workers/trieInstance";
import { calculateRowSpans, initializeRowSpanMap } from "../../pages/Hub/Site Structure/components/AddNodeActivities";

interface siteType {
    wizradState: string,
}
interface initalValuesType{
    x:number,
    y:number,
    zoom:number,
    mediaLoader:boolean,
}
export type levelObject = {
    label: string;
    number_of_units: number
};
type siteValues = {
    SitenextRef: React.MutableRefObject<(() => void) | undefined>,
    SitepreviousRef: React.MutableRefObject<(() => void) | undefined>,
    siteValues: siteType,
    setSiteValues: React.Dispatch<React.SetStateAction<siteType>>,
    FileList: File[] | null,
    SetFileList: React.Dispatch<React.SetStateAction<File[] | null>>
    File: File | null,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    imgUrl: string,
    setImgUrl: React.Dispatch<React.SetStateAction<string>>,
    croppedImage: string,
    setCroppedImage: React.Dispatch<React.SetStateAction<string>>,
    crop: {
        x: number;
        y: number;
    },
    setCrop: React.Dispatch<React.SetStateAction<{
        x: number;
        y: number;
    }>>,
    zoom: number,
    setZoom: React.Dispatch<React.SetStateAction<number>>,
    initalCrop: {
        x: number;
        y: number;
    },
    setInitalCrop: React.Dispatch<React.SetStateAction<{
        x: number;
        y: number;
    }>>,

    croppedAreaPixels: {
        width: number;
        height: number;
        x: number;
        y: number;
    },
    setCroppedAreaPixels: React.Dispatch<React.SetStateAction<{
        width: number;
        height: number;
        x: number;
        y: number;
    }>>,
    initalValues:{
        x:number,
        y:number,
        zoom:number,
        mediaLoader:boolean
    },
    setInitalValues:React.Dispatch<React.SetStateAction<initalValuesType>>,
    formSiteData: siteDetailsInterface;
    setFormSiteData: React.Dispatch<React.SetStateAction<siteDetailsInterface>>
    inputSiteValue: string;
    setInputSiteValue: React.Dispatch<React.SetStateAction<string>>;
    mapSiteValues: {
        center:
        { lat: number, lng: number },
        zoom: number,
        suggestions: google.maps.places.AutocompletePrediction[]
    };
    setMapSiteValues: React.Dispatch<React.SetStateAction<{
        center:
        { lat: number, lng: number },
        zoom: number,
        suggestions: google.maps.places.AutocompletePrediction[]
    }>>,
    reInitialize: () => void,
    levelValues: levelObject[],
    setLevelValues: React.Dispatch<React.SetStateAction<levelObject[]>>,
    selectedPreset: 'Preset 1' |
    'Preset 2' |
    'Preset 3' |
    'Open Structure' | 'Custom site structure' | null,
    setSelectedPreset: React.Dispatch<React.SetStateAction<'Preset 1' |
        'Preset 2' |
        'Preset 3' |
        'Open Structure' | 'Custom site structure' | null>>,
    tableData: TableRow[],
    setTableData: React.Dispatch<React.SetStateAction<TableRow[]>>,
    rowSpanMap: number[][],
    setRowSpanMap: React.Dispatch<React.SetStateAction<number[][]>>,
    nextFieldValues: NextFiledTypes,
    setNextFieldValues: React.Dispatch<React.SetStateAction<NextFiledTypes>>,
    pathOfSelectedLabel: { path: string[] }[],
    setPathOfSelectedLabel: React.Dispatch<React.SetStateAction<{ path: string[] }[]>>,
    trie: Trie;
    setTrie: React.Dispatch<React.SetStateAction<Trie>>;
    isNextPreviewButtonCliked: boolean,
    setIsNextPreviewButtonCliked: React.Dispatch<React.SetStateAction<boolean>>,
    selectedLabel: string[],
    setSelectedLabel: React.Dispatch<React.SetStateAction<string[]>>,
    siteStructureTopPath: string[],
    setSiteStructureTopPath: React.Dispatch<React.SetStateAction<string[]>>,
    SiteinitialCropImage: string,
    SitesetInitialCropImage: React.Dispatch<React.SetStateAction<string>>,
    previewTableData: TableRow[],
    setPreviewTableData: React.Dispatch<React.SetStateAction<TableRow[]>>,
    previewRowSpanMap: number[][],
    setPreviewRowSpanMap: React.Dispatch<React.SetStateAction<number[][]>>,
    previewTrie: Trie;
    setPreviewTrie: React.Dispatch<React.SetStateAction<Trie>>;
    isCustomEdit: boolean;
    setIsCustomEdit: React.Dispatch<React.SetStateAction<boolean>>,
    previewPreset: 'Preset 1' |
    'Preset 2' |
    'Preset 3' |
    'Open Structure' | 'Custom site structure' | null;
    setPreviewPreset: React.Dispatch<React.SetStateAction<'Preset 1' |
        'Preset 2' |
        'Preset 3' |
        'Open Structure' | 'Custom site structure' | null>>,
    isLevelsClicked: 'Level'|'Siblings'|'';
    setIsLevelsClicked: React.Dispatch<React.SetStateAction<'Level'|'Siblings'|''>>,
    collapsedNodes:string[][],
    setCollapsedNodes: React.Dispatch<React.SetStateAction<string[][]>>,
    toggleCollapse:(path:string[])=>void,
    previewCollapsedNodes:string[][],
    setPreviewCollapsedNodes:React.Dispatch<React.SetStateAction<string[][]>>,
    siteArrayIndex:number,
    SetSiteArrayIndex:React.Dispatch<React.SetStateAction<number>>,
    editedSiteId:string,
    setEditedSiteId:React.Dispatch<React.SetStateAction<string>>
  
}


const SiteContext = React.createContext<siteValues>({
    SitenextRef: { current: undefined },
    SitepreviousRef: { current: undefined },
    siteValues: {
        wizradState: "",

    },
    setSiteValues: () => { },
    FileList: null,
    SetFileList: () => { },
    File: null,
    setFile: () => { },
    imgUrl: "",
    setImgUrl: () => { },
    crop: {
        x: 0,
        y: 0
    },
    setCrop: () => { },
    zoom: 1,
    setZoom: () => { },
    initalCrop: {
        x: 0,
        y: 0
    },
    setInitalCrop: () => { },
    croppedImage: "",
    setCroppedImage: () => { },
    croppedAreaPixels: {
        width: 0,
        height: 0,
        x: 0,
        y: 0
    },
    setCroppedAreaPixels: () => { },


    formSiteData: {
        siteName: "",
        shortName: "",
        siteAddress: "",
        postcode: "",
        placeId: "",
    },
    setFormSiteData: () => { },
    inputSiteValue: '',
    setInputSiteValue: () => { },
    mapSiteValues: {
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 2,
        suggestions: []
    },
    setMapSiteValues: () => { },
    reInitialize: () => { },
    initalValues:{
        x:0,
        y:0,
        zoom:1,
        mediaLoader:true,
    },
    setInitalValues:()=>{},
    levelValues: [{ label: '', number_of_units: NaN }],
    setLevelValues: () => { },
    selectedPreset: null,
    setSelectedPreset: () => { },
    tableData: [],
    setTableData: () => { },
    rowSpanMap: [],
    setRowSpanMap: () => { },
    nextFieldValues: {
        isEndNodeLabel:false,
        label: '', label_format: '',
        no_of_units: NaN, isEndNodeChecked: false,
        EndNodeLabel: '',
        no_of_rooms: NaN
    },
    setNextFieldValues: () => { },
    pathOfSelectedLabel: [],
    setPathOfSelectedLabel: () => { },
    trie: new Trie(),
    setTrie: () => { },
    isNextPreviewButtonCliked: false,
    setIsNextPreviewButtonCliked: () => { },
    selectedLabel: [],
    setSelectedLabel: () => { },
    siteStructureTopPath: [],
    setSiteStructureTopPath: () => { },
    SiteinitialCropImage: '',
    SitesetInitialCropImage: () => { },
    previewTableData: [],
    setPreviewTableData: () => { },
    previewRowSpanMap: [],
    setPreviewRowSpanMap: () => { },
    previewTrie: new Trie(),
    setPreviewTrie: () => { },
    isCustomEdit: false,
    setIsCustomEdit: () => { },
    previewPreset: null,
    setPreviewPreset: () => { },
    isLevelsClicked: '',
    setIsLevelsClicked: () => { },
    collapsedNodes:[],
    setCollapsedNodes:()=>{},
    previewCollapsedNodes:[],
    setPreviewCollapsedNodes:()=>{},
    toggleCollapse:()=>{},
    siteArrayIndex:0,
    SetSiteArrayIndex:()=>{},
    editedSiteId:'',
    setEditedSiteId:()=>{}

})


export interface siteDetailsInterface {
    siteName: string,
    shortName: string,
    siteAddress: string,
    postcode: string,
    placeId: string,
}
export const removeDuplicateArrays = (arrays: string[][]) => {
    const unique = new Set(arrays.map(arr => JSON.stringify(arr)));
    return Array.from(unique).map(str => JSON.parse(str));
};

export const SiteContextProvider = ({ children }: { children: ReactNode }) => {

    const [formSiteData, setFormSiteData] = useState<siteDetailsInterface>({
        siteName: "",
        shortName: "",
        siteAddress: "",
        postcode: "",
        placeId: "",
    });

    const SitenextRef = useRef<(() => void) | undefined>(undefined);
    const SitepreviousRef = useRef<(() => void) | undefined>(undefined);
    const [siteValues, setSiteValues] = useState<siteType>({
        wizradState: "",

    })
    const [FileList, SetFileList] = useState<File[] | null>(null)
    const [File, setFile] = useState<File | null>(null)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [crop, setCrop] = useState({
        x: 0,
        y: 0
    });
    const [zoom, setZoom] = useState(1);
    const [initalCrop, setInitalCrop] = useState({
        x: 0,
        y: 0
    });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({
        width: 0,
        height: 0,
        x: 0,
        y: 0
    });

    const [trie, setTrie] = useState(new Trie());

    const [inputSiteValue, setInputSiteValue] = useState("");
    const [mapSiteValues, setMapSiteValues] = useState<{
        center: { lat: number; lng: number };
        zoom: number;
        suggestions: google.maps.places.AutocompletePrediction[];
    }>({
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 2,
        suggestions: []
    })

    const [croppedImage, setCroppedImage] = useState<string>('')
    const [initalValues, setInitalValues] = useState<initalValuesType>({
        x: 0,
        y: 0,
        zoom: 1,
        mediaLoader: true
    })

    const reInitialize = () => {
        setImgUrl('')
        setFile(null)
        setCroppedImage('')
        setInitalCrop({
            x: 0,
            y: 0
        })
        setZoom(1)
        setCrop({
            x: 0,
            y: 0
        })
        setCroppedAreaPixels({
            width: 0,
            height: 0,
            x: 0,
            y: 0
        })
        setSiteValues((prev) => ({
            ...prev,
            wizradState: ''
        }));
        SetFileList(null)
        setFormSiteData({
            siteName: "",
            shortName: "",
            siteAddress: "",
            postcode: "",
            placeId: "",
        })
        setInputSiteValue('')
        setMapSiteValues((prev) => ({ ...prev, zoom: 4 }))
        SitesetInitialCropImage('')

    }

    const [levelValues, setLevelValues] = useState<levelObject[]>([{
        label: '',
        number_of_units: NaN
    }])
    const [isNextPreviewButtonCliked, setIsNextPreviewButtonCliked] = useState(false)
    const [selectedPreset, setSelectedPreset] = useState<'Preset 1' |
        'Preset 2' |
        'Preset 3' |
        'Open Structure' | 'Custom site structure' | null>(null)
    const [tableData, setTableData] = useState<TableRow[]>([])
    const [rowSpanMap, setRowSpanMap] = useState<number[][]>([]);
    const [nextFieldValues, setNextFieldValues] = useState<NextFiledTypes>({
        isEndNodeLabel:false,
        label: '',
        label_format: '',
        no_of_units: NaN,
        isEndNodeChecked: false,
        EndNodeLabel: '',
        no_of_rooms: NaN
    })
    const [selectedLabel, setSelectedLabel] = useState<string[]>([]);
    const [pathOfSelectedLabel, setPathOfSelectedLabel] = useState<{ path: string[] }[]>([]);
    const [siteStructureTopPath, setSiteStructureTopPath] = useState<string[]>([])
    const [SiteinitialCropImage, SitesetInitialCropImage] = useState<string>('');
    const [previewTableData, setPreviewTableData] = useState<TableRow[]>([])
    const [previewRowSpanMap, setPreviewRowSpanMap] = useState<number[][]>([]);
    const [previewTrie, setPreviewTrie] = useState(new Trie());
    const [isCustomEdit, setIsCustomEdit] = useState(false)
    const [previewCollapsedNodes, setPreviewCollapsedNodes] = useState<string[][]>([]);
    const [previewPreset, setPreviewPreset] = useState<'Preset 1' |
        'Preset 2' |
        'Preset 3' |
        'Open Structure' | 'Custom site structure' | null>(null)
    const [isLevelsClicked,setIsLevelsClicked] = useState<'Level'|'Siblings'|''>('')
    const [collapsedNodes, setCollapsedNodes] = useState<string[][]>([]);
    const [siteArrayIndex, SetSiteArrayIndex] = useState<number>(0)
    const [editedSiteId,setEditedSiteId] = useState<string>('')
    const toggleCollapse = (path: string[]) => {
        const trimmedPath = path.slice(0, -1);
        const firstChildrenLabels = getFirstChildrenArray(trie.root,trimmedPath)
        const isCollapsed = collapsedNodes?.some(arr => arraysEqual(arr, trimmedPath));
        setCollapsedNodes(prev => {
            if(isCollapsed) {
                return prev.filter(arr => !rootPathInArray(arr,path
                )&&
                !(arr.slice(0, path.length-1).every((val, index) => val === path[index])) // Remove if all but the last match
                )
            }
            else{
                // Merge previous state with unique new paths
                const updatedNodes = [...prev, trimmedPath, ...firstChildrenLabels];
                // Ensure unique arrays using a helper function
                return removeDuplicateArrays(updatedNodes);
            }
        } 
        );

           
          
        const hiddenTrie: Trie = hideNonFirstChildren(trie, trimmedPath, !isCollapsed)
        setTrie(hiddenTrie);
        const tabledata: TableRow[] = [];
        generateTableData(hiddenTrie.root, [], tabledata,[],hiddenTrie.root);
        const rowSpanMap = initializeRowSpanMap(tabledata);
        calculateRowSpans(tabledata, rowSpanMap);
        setTableData(tabledata);
        setRowSpanMap(rowSpanMap);
    };
    
    return (
        <SiteContext.Provider value={{
            formSiteData, setFormSiteData,
            SitenextRef, SitepreviousRef,
            siteValues, setSiteValues,
            FileList, SetFileList,
            File, setFile,
            imgUrl, setImgUrl,
            crop, setCrop,
            zoom, setZoom,
            initalCrop, setInitalCrop,
            croppedImage, setCroppedImage,
            croppedAreaPixels, setCroppedAreaPixels,
            inputSiteValue, setInputSiteValue,
            mapSiteValues, setMapSiteValues,
            initalValues,setInitalValues,
            reInitialize,
            levelValues,
            setLevelValues,
            selectedPreset,
            setSelectedPreset,
            tableData,
            setTableData,
            rowSpanMap,
            setRowSpanMap,
            nextFieldValues,
            setNextFieldValues,
            pathOfSelectedLabel,
            setPathOfSelectedLabel,
            trie,
            setTrie,
            isNextPreviewButtonCliked,
            setIsNextPreviewButtonCliked,
            selectedLabel,
            setSelectedLabel,
            siteStructureTopPath,
            setSiteStructureTopPath,
            SiteinitialCropImage, SitesetInitialCropImage,
            previewTableData,
            setPreviewTableData,
            previewRowSpanMap,
            setPreviewRowSpanMap,
            previewTrie,
            setPreviewTrie,
            isCustomEdit,
            setIsCustomEdit,
            previewPreset,
            setPreviewPreset,
            isLevelsClicked,
            setIsLevelsClicked,
            collapsedNodes,
            setCollapsedNodes,
            toggleCollapse,
            previewCollapsedNodes,
            setPreviewCollapsedNodes,
            siteArrayIndex,
            SetSiteArrayIndex,
            editedSiteId,
            setEditedSiteId
        }}>
            {children}
        </SiteContext.Provider>

    )


}

export const SiteData = () => useContext(SiteContext)

export default SiteContext