/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Outlet, To, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FlexBox, Breadcrumb, Breadcrumbs, Text } from "@filament/react";
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { Default } from "../Organization/Components/Sampletab";
import TopBar from "./Components/TopBar";

export default function Hub() {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams()
    const breadcrumbStyle = {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        }
    };

    const handleBreadcrumbClick = (path: To) => {
        navigate(path);
    };
    useEffect(()=>{
        if(searchParams.get("tab") === null){
            setSearchParams({tab:"sites"})
        }
    },[])

    return (
        <>
            <div style={{ padding: '1.704rem' }}>
                <FlexBox 
                    className={borderBottom}
                    style={{ paddingLeft: '24px', paddingRight: '24px' }}
                    height='56px'
                    position="fixed"
                    top='56px'
                    left='72px'
                    zIndex={1000}
                    justifyContent="space-between"
                    width={`calc(100% - 72px)`}
                >
                    <FlexBox alignItems="center" gap={4}>
                        <img 
                            src='/images/fluent_panel-left-text-28-regular.png' 
                            alt='' 
                            height='24px' 
                            width='24px' 
                        />
                        {location.pathname === "/hub" ? (
                            <Breadcrumbs >
                                <Breadcrumb style={breadcrumbStyle}>
                                    <Text variant="body-m" color="secondary" style={{ textDecoration: 'none' }}>Hub</Text>
                                </Breadcrumb>
                            </Breadcrumbs>
                        ) : (
                            <Breadcrumbs >
                                <Breadcrumb 
                                    onPress={() => handleBreadcrumbClick("/hub")} 
                                    style={breadcrumbStyle}
                                >
                                    <Text variant="body-m" color="secondary" style={{ textDecoration: 'none' }}>
                                        Hub</Text>
                                </Breadcrumb>
                                <Breadcrumb 
                                    onPress={() => handleBreadcrumbClick("/hub?tab=sites")} 
                                    style={breadcrumbStyle}
                                >
                                    <Text variant="body-m" color="secondary" style={{ textDecoration: 'none' }}>
                                        All Sites</Text>
                                </Breadcrumb>
                                <Breadcrumb style={breadcrumbStyle}>
                                    <Text variant="body-m" color="secondary" style={{ textDecoration: 'none' }}>
                                        The Golden Shore</Text>
                                </Breadcrumb>
                            </Breadcrumbs>
                        )}
                    </FlexBox>
                    <div style={{ 
                        paddingTop: '7px', 
                        paddingBottom: '7px', 
                        height: '40px', 
                        width: '100px' 
                    }}>
                        <Default />
                    </div>
                </FlexBox>
            </div>

            {location.pathname === "/hub" && <TopBar />}
            <Outlet />
        </>
    );
}