/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDown, ChevronUp } from "@filament-icons/react";
import { Button, FlexBox, Text, Tooltip } from "@filament/react";
import React, {  useEffect, useRef, useState } from "react";
import { atomicIconSmall } from "@filament/react/styles";
import { TableRow } from "../SiteStructure";
import { useUnifiedContexts } from "../../../../components/Context/Context";
import { checkIfAnyEndOfWord, extractLastItems, 
    findMatchingTerm, 
    getCollapsedLabel, 
    getFilteredSameLevelNodesFromTrie, 
    getParentCollapsedLabelIfMultipleUnits, 
    getPathUpToTarget, getSiblingLabelArray } from "./TrieDataStructure";
import * as styles from '../styles/styles.css'
import { mergeUniqueValues } from "../../../../CommonMethods/mergeUniqueArrays";
export default function SiteStructureLabel({
    value,
    isFirstChild,
    row,
    isHeading,
    toggleCollapse,
    isFromSitePreview,
    customVal,
}: {
    value: string;
    isFirstChild: boolean;
    row?: TableRow;
    isHeading?: boolean;
    toggleCollapse?: (selectedPath:string[]) => void;
    isFromSitePreview?: boolean;
    customVal:string;

}) {
    const labelRef = useRef<HTMLDivElement | null>(null);
    const {setPathOfSelectedLabel,
        selectedLabel,
        setSelectedLabel,
        tableData,
        trie,
    } = useUnifiedContexts()
    const [prevCell,setPrevCell] = useState('')

    const toggleSelectedPath = (
        selectedPaths: { path: string[] }[],
        newPath: { path: string[] }
    ): { path: string[] }[] => {
        const pathIndex = selectedPaths.findIndex(({ path }) => 
            path.length === newPath.path.length && path.every((value, index) => value === newPath.path[index])
        );
    
        if (pathIndex !== -1) {
            // Path already exists, remove it
            return selectedPaths.filter((_, index) => index !== pathIndex);
        } else{
            return [...selectedPaths,newPath];
        }
    };
    useEffect(()=>{
        if(row!==undefined&&(customVal!==''||value===row?.path[row?.path?.length-1])){
            if(prevCell!==''){
                setPrevCell('')
            }
            const parentLabel = getParentCollapsedLabelIfMultipleUnits(trie,getPathUpToTarget(row!.path!,value))
            if(parentLabel!==''){
                setPrevCell(parentLabel?.split(' ')?.[1]?.slice(0,-1))
            }
        }
    },[row])

    const handleLevelAction = (selectedPath:{path: string[]}[],
        isDropdownClicked:boolean) =>{
        const collapsedLabel = getCollapsedLabel(trie.root,selectedPath[0].path.slice(0,-1))
        let labelsSelected:{path: string[]}[] = []    
        if(collapsedLabel===''){
            labelsSelected = getSiblingLabelArray(trie.root,selectedPath) 
        }else{
            const endId = findMatchingTerm(tableData,row!,selectedPath[0].path)
            labelsSelected = getFilteredSameLevelNodesFromTrie(trie.root,selectedPath,endId)
        }
    
        const labelsArray = extractLastItems(isDropdownClicked?[labelsSelected[0]]:labelsSelected);
        const mergedArray = mergeUniqueValues(labelsArray,[])
        setSelectedLabel(mergedArray)
        setPathOfSelectedLabel(isDropdownClicked?[labelsSelected[0]]:labelsSelected)  

    }

    const truncatedVal = 10
    const labelName = value.split('-').slice(0,-1).join('-')
  
    return (
        <FlexBox style={{
            paddingRight: isFromSitePreview ? "" : "45px" ,
        }} ref={labelRef} >     
            <div style={{
                border : ((selectedLabel?.includes(`${value}`))&&!isFromSitePreview ) 
                    ? "2px solid #0072DB" : "none",
                display: "flex",
                borderRadius: "6px",
                padding:"0px",
                margin:((selectedLabel?.includes(`${value}`))&&!isFromSitePreview ) 
                    ? "0px" : "2px",
            }}>
                <Button
                    variant="quiet"
                    className={isFromSitePreview?isHeading?
                        styles.previewLabelHeadingWidth:
                        styles.previewLabelCellWidth: (isFirstChild && toggleCollapse !== undefined && row !== undefined)
                        ? styles.siteStructurecellWidthWithoutButton : styles.siteStructurecellWidthWithButton}
                    shape="square"
                    height="36px"
                    style={{
                        backgroundColor: isFromSitePreview 
                            ? (isHeading ? "#E4E9EC" : selectedLabel?.includes(`${value}`) ? "#00438A2E" : "transparent")
                            : (isHeading ? "transparent" : "#00438A0F"),
                        border : ((selectedLabel?.includes(`${value}`))&&!isFromSitePreview ) 
                            ? "none" : "none",
                        borderBottom: isFromSitePreview 
                            ? "none" 
                            : isHeading 
                                ? "1px solid #000000" 
                                : ((selectedLabel?.includes(`${value}`))&&!isFromSitePreview ) 
                                    ? 'none' : "none", 
                        borderRadius: isFromSitePreview 
                            ? "0px"
                            : isHeading 
                                ? "6px" 
                                : "6px", 
                        fontSize: "16px",
                        fontWeight: "700",
                        paddingTop: "6px",
                        paddingLeft: "3px",
                        marginRight: isFromSitePreview ? "12px" : 
                            (isFirstChild && toggleCollapse !== undefined &&
                         row !== undefined && !isFromSitePreview) ? "2px" : "0px"                }}
                    onPress={() => {
                        if(setSelectedLabel!==undefined&&!isHeading){
                            const isPresent = selectedLabel?.includes(`${value}`)
                            setSelectedLabel(prev=> (isPresent?
                                prev.filter(val=>val!== `${value}`):[`${value}`]));
                            const selectedPath = getPathUpToTarget(row!.path!,value)
                            const pathObject ={path:selectedPath}
                            if(isPresent){
                                setPathOfSelectedLabel(prevPaths => toggleSelectedPath(prevPaths, pathObject))
                            }else{
                                setPathOfSelectedLabel([pathObject])
                            }
                            if(customVal!==''||prevCell!==''){
                                handleLevelAction([pathObject],false)   
                            }
                        }
                    }}
                >
                    <FlexBox
                        alignItems="center"
                        style={{
                            whiteSpace: "nowrap",
                            paddingLeft: "4px",
                            paddingRight: isFromSitePreview ? "10px": "0px",
                            alignItems: "center",
                        }}
                    >
                        {customVal===''
                    &&!isHeading&&labelName?.length> truncatedVal&&row!==undefined
                    &&!checkIfAnyEndOfWord(trie,[{path:row.path}])
                            ?<Tooltip content={<span style={{ fontSize: '16px', fontWeight: 'normal' , paddingTop: '4px',
                                paddingBottom: '4px',
                                wordBreak:"break-word",
                                overflowWrap:"break-word",
                                whiteSpace:"normal"
                            }}>
                                {labelName.match(/.{1,100}/g)?.join('\n')}

                            </span>}  placement="bottom"
                            >
                                <FlexBox>
                                    <Text style={{fontWeight:"700",fontSize:'16px'}}>
                                        {/^\d+$/.test(labelName.split(' ')[0])?labelName.split(' ').slice(1).join(' ')?.length>11?
                                            labelName.slice(0, 9) + '...s':labelName
                                            :labelName.split(' ').slice(0, -1).join(' ')?.length>11
                                                ?labelName.split(' ')[0].slice(0, 9) + '...'+
                                            labelName.split(' ').pop():labelName}
                                    </Text>
                                </FlexBox>
                            </Tooltip>:customVal===''&&value===row?.path[row?.path?.length-1]&&
                        checkIfAnyEndOfWord(trie,[{path:row.path}])
                                ?<Tooltip
                                    content={<span style={{ fontSize: '16px', fontWeight: 'normal' , paddingTop: '4px',
                                        paddingBottom: '4px',
                                        wordBreak:"break-word",
                                        overflowWrap:"break-word",
                                        whiteSpace:"normal"
                                    }}>
                                        {labelName.match(/.{1,100}/g)?.join('\n')}

                                    </span>} // Tooltip for each type
                                    placement="bottom"
                                >
                                    <FlexBox>
                                        <Text style={{fontWeight:"700",fontSize:'16px'}}>
                                            {/^\d+$/.test(labelName.split(' ')[0])?
                                                labelName.split(' ').slice(1).join(' ')?.length>11?
                                                    labelName.slice(0, 9) + '...s ':labelName+'\t'
                                                :labelName.split(' ').slice(0, -1).join(' ')?.length>8
                                                    ?labelName.split(' ')[0].slice(0, 9) + '...'+
                                                labelName.split(' ').pop()+'\t':labelName+'\t'}
                                        </Text>
                                        {prevCell!==''&&<Text style={{ fontWeight: "400", fontSize: '16px', 
                                        
                                        }}>
                                            {`\u00A0(each `}
                                            {prevCell?.split(' ')[0].length<6?
                                                <span>{prevCell.split(' ')[0]+')'}</span>:
                                                <Tooltip
                                                    content={<span style={{ fontSize: '16px', 
                                                        fontWeight: 'normal', padding: '4px' }}>
                                                        {prevCell}
                                                    </span>}
                                                    placement="bottom"
                                                >
                                                    <span style={{ cursor: "pointer" }}>
                                                        {prevCell?.split(' ')[0].slice(0,6)+'..)'}
                                                    </span>
                                                </Tooltip>}
                                        </Text>}
                                    </FlexBox>
                                </Tooltip>
                                :
                                <Text style={{fontWeight:"700",fontSize:'16px'}}>
                                    {(customVal!=='')?
                                        customVal.split(' ')[1].length>7?
                                            <Tooltip
                                                content={<span style={{ fontSize: '16px', 
                                                    fontWeight: 'normal' , paddingTop: '4px',
                                                    paddingBottom: '4px' 
                                                }}>
                                                    {customVal}
                                                </span>} // Tooltip for each type
                                                placement="bottom"
                                            >
                                                <FlexBox style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                    <Text style={{ fontWeight: "700", fontSize: '16px' }}>
                                                        {customVal.split(' ')[0] + ' ' + 
                                                        customVal.split(' ')[1].slice(0, 6) + '...'}
                                                    </Text>
                                                    {prevCell!==''&&
                                                    <Text style={{ fontWeight: "400", fontSize: '16px', 
                                                    }}>
                                                        {`\u00A0(each `}
                                                        {prevCell?.split(' ')[0].length<6?
                                                            <span>{prevCell.split(' ')[0]+')'}</span>:
                                                            <Tooltip
                                                                content={<span style={{ fontSize: '16px', 
                                                                    fontWeight: 'normal', padding: '4px' }}>
                                                                    {prevCell}
                                                                </span>}
                                                                placement="bottom"
                                                            >
                                                                <span style={{ cursor: "pointer" }}>
                                                                    {prevCell?.split(' ')[0].slice(0,6)+'..)'}
                                                                </span>
                                                            </Tooltip>}
                                                    </Text>}
                                                </FlexBox>
                                            </Tooltip>:<FlexBox style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                <Text style={{ fontWeight: "700", fontSize: '16px',
                                                }}>
                                                    {customVal}
                                                </Text>
                                                {prevCell&&<Text style={{ fontWeight: "400", fontSize: '16px',  }}>
                                                    {`\u00A0(each `}
                                                    {prevCell?.split(' ')[0].length<6?
                                                        <span>{prevCell.split(' ')[0]+')'}</span>:
                                                        <Tooltip
                                                            content={<span style=
                                                                {{ fontSize: '16px', fontWeight: 'normal', padding: '4px' }}>
                                                                {prevCell?.split(' ')[0]}
                                                            </span>}
                                                            placement="bottom"
                                                        >
                                                            <span style={{ cursor: "pointer" }}>
                                                                {prevCell?.split(' ')[0].slice(0,6)+'..)'}
                                                            </span>
                                                        </Tooltip>}
                                                </Text>}
                                            </FlexBox>
                                        : labelName}
                                </Text>}
                        {isFirstChild && toggleCollapse !== undefined && row !== undefined && isFromSitePreview && (
                            <Button
                                className={styles.button}
                                shape="square"
                                style={{
                                    border: "none",
                                    boxShadow: "none", // Prevents shadow making it look darker
                                    background:isFromSitePreview?'':
                                        selectedLabel.includes(`${value}`)?"rgb(214, 217, 233)":'white'
                                }}
                                onPress={()=>{if(setSelectedLabel!==undefined&&!isHeading){
                                    const isPresent = selectedLabel?.includes(`${value}`)
                                    setSelectedLabel(prev=> (isPresent?
                                        prev.filter(val=>val!== `${value}`):
                                        [`${value}`]));
                                    const selectedPath = getPathUpToTarget(row!.path!,value)
                                    const pathObject ={path:selectedPath}
                                    if(isPresent){
                                        setPathOfSelectedLabel(prevPaths => toggleSelectedPath(prevPaths, pathObject))
                                    }else{
                                        setPathOfSelectedLabel([pathObject])
                                    }
                                    handleLevelAction([pathObject],customVal===''?false:true)   
                                }
                                setPrevCell('')
                                toggleCollapse(getPathUpToTarget(row!.path!,value))
                                }
                                }
                            
                
                            >
                                {customVal===''?
                                    <ChevronUp  className={atomicIconSmall}/>:
                                    <ChevronDown  className={atomicIconSmall} />}
                            </Button>
                        )}
                    </FlexBox>
                </Button>
            
                {isFirstChild && toggleCollapse !== undefined && row !== undefined && !isFromSitePreview && (
                    <Button
                        className={styles.button}
                        shape="square"
                        style={{
                            border : isFromSitePreview?'':
                                selectedLabel.includes(`${value}`)?"none":'none', 
                            boxShadow: "none", // Prevents shadow making it look darker
                            background:isFromSitePreview?'':
                                selectedLabel.includes(`${value}`)?"#00438A0F":'#00438A0F'
                        }}
                        onPress={()=>{if(setSelectedLabel!==undefined&&!isHeading){
                            const isPresent = selectedLabel?.includes(`${value}`)
                            setSelectedLabel(prev=> (isPresent?
                                prev.filter(val=>val!== `${value}`):
                                [`${value}`]));
                            const selectedPath = getPathUpToTarget(row!.path!,value)
                            const pathObject ={path:selectedPath}
                            if(isPresent){
                                setPathOfSelectedLabel(prevPaths => toggleSelectedPath(prevPaths, pathObject))
                            }else{
                                setPathOfSelectedLabel([pathObject])
                            }
                            handleLevelAction([pathObject],customVal===''?false:true)   
                        }
                        setPrevCell('')
                        toggleCollapse(getPathUpToTarget(row!.path!,value))
                        }
                        }
                            
                
                    >
                        {customVal===''?
                            <ChevronUp  className={atomicIconSmall}/>:
                            <ChevronDown  className={atomicIconSmall} />}
                    </Button>
                )}
            </div> 
        </FlexBox>
    );
}


