export const mergeUniqueValues = <T>(targetArray: T[], sourceArray: T[]): T[] => {
    const set = new Set(targetArray); // Convert target array to a Set for quick lookups
    sourceArray.forEach(item => {
        if (!set.has(item)) {
            targetArray.push(item); // Add only if not present
            set.add(item);
        }
    });
    return targetArray;
};
