/* eslint-disable react/react-in-jsx-scope */

import React, { useState } from 'react';
import {
    Button,
    TextField,
    Label,
    FlexBox,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Avatar,
    Text,
    Tooltip,
} from '@filament/react';
import { atomicBorderBottom as borderBottom, atomicBorderTop as borderTop } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';
import { GoogleApiKey } from "../../../../config";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { A11y, Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  SiteStructureWithSiteId, useGetSiteDataQuery } from '../../../../graphql/generated';
import Loading from '../../../../components/Loading';
import EditSite from './EditSite';
import { getCountryNameByISO } from '../../../../CommonMethods/GetCountryNameByiSOCode';

const mapContainerStyle = {
    width: "100%",
    height: "271px",
    borderRadius: "12px",
};

export default function ViewSite({ handleViewSiteCloseClick,siteId }: { handleViewSiteCloseClick: () => void ,siteId:string}) {

    const { data, loading, error } = useGetSiteDataQuery({
        variables: { id: siteId },
    });
    const [onEditClick,setOnEditClick] = useState(false)
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!, // Replace with your Google Maps API Key
    });

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    const siteViewData = data?.site;

    const center = {
        lat: siteViewData?.latitude || 0,
        lng: siteViewData?.longitude || 0,
    
    };
    
      
    const getUniqueLabels = (siteStructure: SiteStructureWithSiteId | undefined | null): string[] => {
        const uniqueLabels = new Set<string>();
        if (!siteStructure || !siteStructure.siteStructure || siteStructure === null) {
            return []; // Return empty array if siteStructure is null or undefined
        }
        siteStructure?.siteStructure?.forEach(({  nodes }) => {
            if (nodes.length > 0) {
                const label = nodes[0].label;
                const cleanedLabel = label.replace(/(.*)\s\d+$/, "$1").trim(); // Remove only the number after the last space
                uniqueLabels.add(cleanedLabel);
            }
        });
      
        return Array.from(uniqueLabels).slice(1);
    };
          

    if (loading) {
        return <Loading message={"Loading Site data..."}/>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const siteStructureArray = getUniqueLabels(data?.site?.siteStructure)
    return (
        <>
            {onEditClick?<EditSite 
                onEditClick={onEditClick}
                handleViewSiteCloseClick={handleViewSiteCloseClick}
                setOnEditClick={setOnEditClick}
                data={data}
                loading={loading}
            />:<div>
                <Card width={'40%'} height={'100vh'} orientation="vertical"
                    style={{ borderRadius: "10px 0 0 10px", position: 'fixed', top: 0, right: 0 }}
                >
                    <CardHeader height={84} className={borderBottom} style={{ padding: 0 }} >
                        <FlexBox justifyContent='space-between' alignItems={'center'}
                            style={{ paddingTop: '24px', 
                                paddingBottom: '24px', 
                                paddingLeft: '32px', 
                                paddingRight: '22px' }} >
                            <div
                                style={{
                                    width: 300,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer', // Optional: Indicates interactivity
                                }}
                            >
                                <Text variant='heading-s' weight="bold">Site Information</Text>
                            </div>
                            <Button variant={'quiet'} onPress={handleViewSiteCloseClick} >
                                <Cross width={'24px'} height={'24px'} /> </Button>
                        </FlexBox>
                    </CardHeader>

                    <CardBody
                        style={{
                            padding: 0, gap: 0, overflow: 'auto',
                            paddingLeft: '32px', paddingTop: '18px', paddingBottom: '32px', paddingRight: '32px'
                        }}
                        height="calc(100vh - 185px)"
                        className="scrollable-element">

                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '12px' }} >
                            <Swiper
                                modules={[Navigation, A11y, Autoplay]}
                                loop
                                autoplay={{ delay: 2000 }}
                                spaceBetween={8} // Add spacing between slides
                                breakpoints={{
                                    320: { slidesPerView: Math.min(1, siteViewData?.siteImages?.length || 1), spaceBetween: 8 },
                                    640: { slidesPerView: Math.min(2, siteViewData?.siteImages?.length || 1), spaceBetween: 8 },
                                    1085: { slidesPerView: Math.min(3, siteViewData?.siteImages?.length || 1), spaceBetween: 8 },
                                    1440: { slidesPerView: Math.min(4, siteViewData?.siteImages?.length || 1), spaceBetween: 8 },
                                }}
                            >
                                {siteViewData?.siteImages?.map((item) => (
                                    <SwiperSlide key={item.key}>
                                        <Card>
                                            <img
                                                src={item.url || '-'}
                                                alt={item.key}
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    height: '140px',
                                                // objectFit: 'contain'
                                                }}
                                            />
                                        </Card>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>


                        <FlexBox alignItems="center" height={64}
                            style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                            <Avatar width={'48px'} height={'48px'} style={{ backgroundColor: 'transparent', }}>
                                <img src={siteViewData?.logo?.url || '/images/Logo.svg'} alt="Site Avatar" />
                            </Avatar>
                            <FlexBox style={{ paddingLeft: '12px',  gap: '14px'  }}>
                                <Tooltip
                                    content={
                                        <div
                                            style={{
                                                width: '400px',
                                                textAlign: 'center',
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                fontSize: '16px', fontWeight: 'normal'
                                            }}
                                        >
                                            {siteViewData?.name || 'No name available'}
                                        </div>
                                    }
                                    placement="bottom"
                                >
                                    <div
                                        style={{
                                            width: 130,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Text variant='heading-xs' weight='bold'>{siteViewData?.name || '-'}</Text>

                                    </div>
                                </Tooltip>
                                <Tooltip
                                    content={
                                        <div
                                            style={{
                                                width: '400px',
                                                textAlign: 'center',
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                fontSize: '16px', fontWeight: 'normal'
                                            }}
                                        >
                                            {siteViewData?.shortName || 'No name available'}
                                        </div>
                                    }
                                    placement="bottom"
                                >
                                    <div
                                        style={{
                                            width: 120,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer',
                                        }}
                                    > 
                                        <Text style={{ color: '#566676', fontSize: '16px', fontWeight: '350' }}>
                                            {siteViewData?.shortName}
                                        </Text>
                                    </div>
                                </Tooltip>
                            </FlexBox>
                        </FlexBox>

                        <FlexBox style={{ flexDirection: 'column', gap: "16px" }}>
                            <Label >Site Location</Label>
                            {/* add map component here */}
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                zoom={10}
                                center={center}
                                options={{
                                    zoomControl: true,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    keyboardShortcuts: false,
                                    draggable: false,
                                    scrollwheel: false,
                                }}
                            >
                                <MarkerF
                                    position={center}
                                    cursor='default'
                                    icon={{
                                        url: "/images/gis_pin.svg",
                                        scaledSize: new window.google.maps.Size(40, 40),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(20, 40),
                                    }}
                                />
                            </GoogleMap>
                        </FlexBox>

                        <FlexBox style={{ flexDirection: "column", gap: '24px', paddingTop: '24px' }}>
                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Site Address</Label>
                                <TextField
                                    value={siteViewData?.address || '-'}
                                    isFullWidth isReadOnly />
                            </FlexBox>

                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Postcode</Label>
                                <TextField value={siteViewData?.postcode || '-'}
                                    isFullWidth isReadOnly />
                            </FlexBox>

                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Country</Label>
                                <TextField value={getCountryNameByISO(siteViewData?.country || '-')}
                                    isFullWidth isReadOnly />
                            </FlexBox>

                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Site Structure</Label>
                                <FlexBox style={{ gap: '8px' }}>
                                    {
                                        siteStructureArray.length === 0? <Label>Open Structure</Label>:

                                            siteStructureArray.map((item,index)=><span
                                                key={index}
                                                aria-label="view and add"
                                                style={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    gap: '1px',
                                                    padding: '4px 8px',
                                                    borderRadius: '8px',
                                                    border: '1px solid',
                                                    backgroundColor: '#E6F7FF',
                                                    borderColor: '#3182CE',
                                                    color: '#3182CE',
                                                }}
                                            >
                                                <span style={{ padding: '0 2px' }}>{item}</span>
                                            </span>)}

                                </FlexBox>
                            </FlexBox>

                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Device Name Structure</Label>
                                <FlexBox style={{ gap: '8px' }}>
                                    {siteStructureArray?.map((item,index)=><>
                                        <span key={index}
                                            aria-label="view and add"
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                gap: '1px',
                                                padding: '4px 8px',
                                                borderRadius: '8px',
                                                border: '1px solid',
                                                backgroundColor: '#E6F7FF',
                                                borderColor: '#3182CE',
                                                color: '#3182CE',
                                            }}
                                        >
                                            <span style={{ padding: '0 2px' }}>{item}</span>
                                        </span>

                                        <span style={{ alignContent: 'end' }}>_</span>
                                    </>)}
                                    <span
                                        aria-label="view and add"
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '1px',
                                            padding: '4px 8px',
                                            borderRadius: '8px',
                                            border: '1px solid',
                                            borderColor: '#6B8094',
                                            color: '#15191E',
                                        }}
                                    >
                                        <span style={{ padding: '0 2px' }}>Device No</span>
                                    </span>
                                </FlexBox>
                            </FlexBox>

                        </FlexBox>

                    </CardBody>
                    <CardFooter height={'72px'} className={borderTop}
                        style={{
                            display: 'flex', justifyContent: 'flex-end',
                            alignItems: 'center', paddingTop: '16px', paddingBottom: '16px'
                        }} >
                        <Button variant="primary" onPress={()=>{setOnEditClick(true)}}>
                            <Text variant='body-m' weight='bold' style={{ color: 'white' }}>Edit</Text>
                        </Button>

                    </CardFooter>

                </Card >
            </div>}
        </>
    );
}