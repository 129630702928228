/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
    FlexBox,
    Card,
    CardHeader,
    CardBody,
    Avatar,
    Text,
    Label,
    TextField,
    Tooltip,
    // Button,
} from '@filament/react';
import { organisationAddressInterface } from '../../../../components/Context/OrganizationContext';
import { useUnifiedContexts } from '../../../../components/Context/Context';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import * as styles from '../../../Authentication/styles/styles.css';
import { Selection } from '@filament/react/types';
import { OrgEntry } from '../../../../graphql/generated';
// import { OrgStatus } from '../../../../graphql/generated';

export default function OrgPreview({ formData, mobileCode, isEdit }: {
    formData: organisationAddressInterface,
    mobileCode: Selection,
    isEdit?: boolean
}) {
    const { orgValues, mapValues, country, valueType } = useUnifiedContexts()
    const mapContainerStyle = {
        width: "100%",
        height: "271px",
        borderRadius: "12px",
    };
    const largeCodes = ['44-1481', '44-1534', '44-1624'];
    const orgTypes = orgValues.OthersTextArea !== '' ? [...orgValues.orgTypeValue, orgValues.OthersTextArea] : orgValues.orgTypeValue
    return (
        <Card width={"100%"} noBorder  >
            <CardHeader style={{ padding: 0, }} height={10}>
                <FlexBox >
                    <Text variant='heading-xs' weight='bold'>Organization Preview</Text>
                </FlexBox>
            </CardHeader>

            <CardBody style={{ padding: 0, gap: 0 }} >
                <FlexBox height={150} style={{ paddingBottom: '8px', paddingTop: '16px' }}>
                    <Avatar width={'128px'} height={'128px'} style={{ backgroundColor: 'transparent', }}>
                        <img src={orgValues.croppedImg !== '' ? orgValues.croppedImg : '/images/Logo.svg'} alt="Org Logo" />
                    </Avatar>
                    <FlexBox style={{ paddingTop: '34px', paddingLeft: '24px', flexDirection: 'column' }}>
                        <Tooltip
                            content={
                                <div
                                    style={{
                                        width: '400px',
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {formData.organizationName || 'No name available'}
                                </div>
                            }
                            placement="bottom"
                        >
                            <div
                                style={{
                                    width: 300,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer', // Optional: Indicates interactivity
                                }}
                            >
                                <Text variant="heading-m" weight="bold">
                                    {formData.organizationName}
                                </Text>
                            </div>
                        </Tooltip>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    style={{ overflow: 'auto', flexDirection: 'column' }}>
                    <FlexBox>
                        <div
                            style={{
                                display: 'flex',
                                gap: '12px',
                                flexWrap: 'wrap',
                                paddingTop: '16px',
                            }}
                        >
                            {Array.isArray(orgTypes) ? (
                                <>
                                    {Array.from(new Set(orgTypes)) // Remove duplicates
                                        .filter((type) => type?.toLowerCase() !== 'others') // Exclude "others"
                                        .slice(0, 3)
                                        .map((type, index) => (
                                            <Text
                                                key={index}
                                                style={{
                                                    border: '1px solid #6B8094',
                                                    borderRadius: '8px',
                                                    padding: '2px 28px',
                                                }}
                                            >
                                                {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                            </Text>
                                        ))}
                                    {Array.from(new Set(orgTypes)).filter((type) => type?.toLowerCase() !== 'others').length > 3 && (
                                        <Tooltip
                                            content={
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        width: '300px',
                                                        gridTemplateColumns: '1fr',
                                                        rowGap: '0.25rem',
                                                    }}
                                                >
                                                    {Array.from(new Set(orgTypes))
                                                        .filter((type) => type?.toLowerCase() !== 'others')
                                                        .slice(3)
                                                        .map((type, index) => (
                                                            <Label
                                                                key={index}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'normal', // Allows text to wrap
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                            </Label>
                                                        ))}
                                                </div>
                                            }
                                            placement="bottom"
                                        >
                                            <Text
                                                style={{
                                                    border: '1px solid #6B8094',
                                                    borderRadius: '8px',
                                                    paddingTop: '2px',
                                                    paddingBottom: '7px',
                                                    paddingLeft: '8px',
                                                    paddingRight: '8px',
                                                }}
                                            >
                                                ...
                                            </Text>
                                        </Tooltip>
                                    )}
                                </>
                            ) : (
                                <Text
                                    style={{
                                        border: '1px solid #6B8094',
                                        borderRadius: '8px',
                                        padding: '2px 28px',
                                    }}
                                >
                                    {orgTypes || '-'}
                                </Text>
                            )}
                        </div>
                    </FlexBox>

                    {valueType.SelectAddType == OrgEntry.Address && window.google && <FlexBox style={{ flexDirection: 'column', paddingTop: "32px", gap: "16px" }}>
                        <Label >Organization Location</Label>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={mapValues.zoom}
                            center={mapValues.center}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                                keyboardShortcuts: false,
                                draggable: false, // Enable dragging
                                scrollwheel: false, // Enable zooming via scroll
                            }}
                        >
                            <MarkerF
                                position={mapValues.center}
                                icon={{
                                    url: "/images/gis_pin.svg", // Path to your custom marker image
                                    scaledSize: new window.google.maps.Size(40, 40), // Adjust size
                                    origin: new window.google.maps.Point(0, 0),
                                    // Origin point of the image
                                    anchor: new window.google.maps.Point(20, 40),
                                    // Anchor point for positioning
                                }}
                            />
                        </GoogleMap>
                    </FlexBox>}


                    <FlexBox style={{ flexDirection: "column", gap: '24px', paddingTop: '24px' }}>

                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                            <Label>Organization Address</Label>
                            <TextField
                                placeholder="Organization Address"
                                type="text"
                                aria-label='Organization Address'
                                isFullWidth
                                value={formData.organizationAddress}
                                isReadOnly
                            />
                        </FlexBox>

                        {/* Might be asked by the clients so will be commenting it till clarity is given. */}
                        {/* <FlexBox style={{ gap: "24px", width: "100%" }}>
                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>City</Label>
                                <TextField aria-label="city" value={formData.city} isFullWidth isReadOnly />
                            </FlexBox>

                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>State</Label>
                                <TextField aria-label="state" value={formData.state} isFullWidth isReadOnly />
                            </FlexBox>
                        </FlexBox> */}

                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                            <Label>Postcode</Label>
                            <TextField aria-label="postcode" value={formData.postcode} isFullWidth isReadOnly />
                        </FlexBox>

                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>Country</Label>
                            <TextField aria-label="country" value={country.toString()?.split(':')[0]} isFullWidth isReadOnly />
                        </FlexBox>

                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>Organization Website</Label>
                            <TextField aria-label="website" value={formData.organizationWebsite} isFullWidth isReadOnly />
                        </FlexBox>

                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>{formData.vat !== '' ? 'Tax Identification Number' : 'DUNs Number'}</Label>
                            <TextField aria-label="vat"
                                value={formData.duns === '' ?
                                    formData.vat : formData.duns || formData.vat === '' ?
                                        formData.duns : formData.vat || '-'}
                                isFullWidth isReadOnly />
                        </FlexBox>
                        <FlexBox style={{ gap: "24px", width: "100%" }}>
                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Phone Number</Label>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ position: "absolute", zIndex: "999" }}>

                                        <FlexBox
                                            style={{
                                                marginTop: "5px",
                                                marginLeft: "5px",
                                                display: "flex",
                                                alignItems: "center", // Center items vertically
                                                justifyContent: "center", // Center items horizontally (optional)
                                                gap: `${isEdit ? (largeCodes?.includes(Array?.from(mobileCode)?.[0]
                                                    ?.toString())) ?
                                                    "4px" : '6px' : (largeCodes?.includes(Array?.from(mobileCode)?.[0]
                                                    ?.toString()?.split(":")?.[1]?.trim())) ?
                                                    "4px" : '6px'}`, // Add spacing between items if needed
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    marginLeft: `${isEdit === true ?
                                                        (largeCodes?.includes(Array?.from(mobileCode)?.[0]
                                                            ?.toString())) ?
                                                            "0px" : '6px' : (largeCodes?.includes(Array?.from(mobileCode)?.[0]
                                                            ?.toString()?.split(":")?.[1]?.trim())) ?
                                                            "0px" : '6px'}`,
                                                    marginRight: `${isEdit === true ?
                                                        (largeCodes?.includes(Array?.from(mobileCode)?.[0]
                                                            ?.toString())) ?
                                                            "0px" : '10px' : (largeCodes?.includes(Array?.from(mobileCode)?.[0]
                                                            ?.toString()?.split(":")?.[1]?.trim())) ?
                                                            "0px" : '10px'}`,
                                                    color:
                                                        Array.from(mobileCode)[0] === "000" ? "#566676" : "black",
                                                }}
                                            >
                                                {Array.from(mobileCode)[0] !== "000"
                                                    ? "+ " +
                                                    (isEdit === true ? Array.from(mobileCode)?.[0]?.toString() :
                                                        Array.from(mobileCode)?.[0]?.toString().split(":")[1]?.trim())


                                                    : "+ 000"}
                                            </Text>
                                            <div className={styles.verticalLine}></div>
                                        </FlexBox>
                                    </div>
                                    <TextField style={{ marginLeft: "20px" }} placeholder="View Mobile number"
                                        aria-label="phone number" value={orgValues.phoneNumber} isFullWidth isReadOnly />
                                </div>
                            </FlexBox>
                            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                <Label>Email Address</Label>
                                <TextField aria-label="email"
                                    value={orgValues.emailId} isFullWidth isReadOnly />
                            </FlexBox>
                        </FlexBox>

                    </FlexBox>
                    {/* <FlexBox flexDirection="column" marginTop={24} gap={16}>
                        <Label>Account status</Label>
                        <FlexBox gap={10}>
                            {[OrgStatus.Active, OrgStatus.Inactive].map((item) => (
                                <Button
                                    key={item}
                                    shape="square"
                                    isFullWidth
                                    width={'20%'}
                                    style={{
                                        backgroundColor: `${item === orgValues.status ? '#0072DB' : ''}`,
                                        color: `${item === orgValues.status ? 'white' : ''}`,
                                        borderRadius: `${item === OrgStatus.Active ? '8px 0 0 8px' : '0 8px 8px 0'}`,
                                        pointerEvents: 'none',
                                        fontSize: "clamp(10px, 2vw, 16px)",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        whiteSpace: "nowrap",
                                    }}

                                >
                                    {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                                </Button>
                            ))}
                        </FlexBox>

                    </FlexBox> */}
                </FlexBox>
            </CardBody>
        </Card >
    )
}