/* eslint-disable max-len */
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GlobalProvider } from '../components/Context/GlobalContext';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "../redux/store"
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { OrganizationProvider } from '../components/Context/OrganizationContext';
import { SiteContextProvider } from '../components/Context/SiteContext';
import { ConfigContextProvider } from '../components/Context/ConfigurationContect';

// Ensure environment variables are loaded
const API_URL = process.env.REACT_APP_API_URL;
// const API_TOKEN = process.env.REACT_APP_TOKEN;


// Create HTTP Link
const httpLink = new HttpLink({
    uri: API_URL, // Make sure REACT_APP_API_URL is correctly set in .env
});

// Middleware to Attach Authorization Header
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('access_token')
    
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
});

// Create Apollo Client
const client = new ApolloClient({
    link: authLink.concat(httpLink), // Attach authLink before httpLink
    cache: new InMemoryCache(),
});

const AppApolloProvider = ({ children }: { children: React.ReactNode }) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <GlobalProvider>
                    <ConfigContextProvider>
                        <SiteContextProvider>
                            <OrganizationProvider>
                                <ApolloProvider client={client}>
                                    {children}
                                </ApolloProvider>
                            </OrganizationProvider>
                        </SiteContextProvider>
                    </ConfigContextProvider>
                </GlobalProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default AppApolloProvider;
