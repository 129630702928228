import { TrieNode } from "../pages/Hub/Site Structure/components/TrieDataStructure";


interface TableRow {
    path: string[];
    isFirstChild: { [key: string]: string[] }[]; // Track first child and its siblings
}

export const generateTableData = (
    node: TrieNode,
    path: string[] = [],
    table: TableRow[] = [],
    firstChildInfo: { [key: string]: string[] }[] = [],
    parent:TrieNode,

) => {
    // Skip the node if isDisplay is false
    if (!node.isDisplay) return;

    const labelWithIndex = `${node.label}`;

    // If the current node is a first child, add it to the collected info
    if (node.isFirstChild && (node.siblings?.slice(1).length ?? 0) > 0) {
        const visibleSiblings = node.siblings!.slice(1).filter(siblingKey => 
            parent?.children?.[siblingKey]?.isDisplay
        )

        if (visibleSiblings.length > 0) {
            firstChildInfo = [...firstChildInfo, { [labelWithIndex]: visibleSiblings }];
        }else{
            firstChildInfo = [...firstChildInfo, { [labelWithIndex]: [] }];
        }
    }

    // If it's a leaf node, push the full path and all collected first-child info
    if (Object.keys(node.children).length === 0) {
        table.push({ path, isFirstChild: firstChildInfo });
        return;
    }
    // Recursively process children
    for (const [childLabel, child] of Object.entries(node.children)) {
        generateTableData(child, 
            [...path, childLabel], 
            table, 
            firstChildInfo,
            node,   
        )
    }
};

