/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Cell,
    Column,
    DataGrid,
    Pagination,
    Row,
    Table,
    TableBody,
    TableHeader,
    Avatar,
    Text,
    FlexBox,
    Button,
    Item,
    Menu,
    useMenuTrigger,
    useMenuTriggerState,
    NotificationsQueue,
    NotificationRenderer,
   
} from '@filament/react';
import { Plus, Eye, ThreeDotsVertical, ChevronUpDown } from '@filament-icons/react';
import { SortOrder, useGetOrganizationsUsersQuery } from '../../../../graphql/generated';
import {
    // atomicIconLarge as iconLarge,
    atomicIconSmall as iconSmall,
} from '@filament/react/styles';
import { UserInformation } from '../Components/ViewUser';
import Loading from '../../../../components/Loading';
import { format } from 'date-fns';
import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import { setRefetch } from '../../../../components/Context/GlobalContext';
import { ConfigData } from '../../../../components/Context/ConfigurationContect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

export default function UserTable() {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [saveState, setSaveState] = useState(true)
    const [selectedUser, setSelectedUser] = useState(null);
    const [activeTab, setActiveTab] = useState('details'); // State to track active tab
    const { buttonRef,setConfigTab } = ConfigData()
    const organizationIds = useSelector((state:RootState)=>state.persistedState.organisationIds)
    // Add new state for sorting
    const [sortConfig, setSortConfig] = useState({
        field: 'createdAt',
        order: SortOrder.Desc
    });

    const {data: usersData, loading, error, refetch } = useGetOrganizationsUsersQuery({
        variables: {
            orgId:organizationIds[0],
            limit: pageSize,
            offset: currentPage * pageSize,
            // sortBy: "createdAt",
            // sortOrder: SortOrder.Desc
            sortBy: sortConfig.field,
            sortOrder: sortConfig.order
            
        },
    });

    useEffect(() => {
        setRefetch(refetch)
        // dispatch(storeUserTableFetch(refetch))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);

    const handlePageSizeChange = useCallback(
        (size: number) => {
            setPageSize(size);
            setCurrentPage(0);
        },
        []
    );

    const handleCurrentPageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
        },
        []
    );

    // for view roles and privilege
    const handleViewPrivilegeClick = (user: React.SetStateAction<null>) => {
        setSelectedUser(user);
        setActiveTab('roles')
    };

    // for view details 
    const handleViewDetailsClick = (user: React.SetStateAction<null>) => {
        setSelectedUser(user);
        setActiveTab('details')
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleViewDetailsClickMenu = (key : string , userId : any) =>{
        console.log(key, userId);
        
        if(key === 'View'){
            handleViewDetailsClick(userId)
        }else if(key === 'Privileges'){
            handleViewPrivilegeClick(userId)
        }
    }

    //threedot menu 
    const buttonRef1 = React.useRef(null);
    const state = useMenuTriggerState({});
    const { menuTriggerProps } = useMenuTrigger(
        { type: 'menu' },
        state,
        buttonRef1,
    );

    // const handleCloseModal= ()=>{
    //     setSelectedUser(null)
    // }

    const handleCloseModal = (callMessage?: string) => {
        setSelectedUser(null);
        if (callMessage === "ApiCall") {
            NotificationsQueue.toast(({ close }) => <ToastNotification close={close} message={'Edited the User'} />,
                {
                    signal: 'success',
                    orientation: 'horizontal',
                    showCloseButton: true,
                    timeout: 6000
                })
        }
        setSaveState(true)
    };


    const handleSort = (field: string) => {
        setSortConfig(prevConfig => ({
            field,
            order: prevConfig.field === field && prevConfig.order === SortOrder.Desc 
                ? SortOrder.Asc 
                : SortOrder.Desc
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function getOrganizationIdById(id : string, users : any[]): string | '' {
        const user = users.find(user => user.id === id);
        return user ? user.organizationId : '';
    }

    // Helper function to render sort indicator
    const renderSortIndicator = (field: string) => {
        if (sortConfig.field === field) {
            return (
                <ChevronUpDown 
                    className={`${iconSmall} ${sortConfig.order === SortOrder.Asc ? 'rotate-180' : ''}`}
                    style={{ transform: sortConfig.order === SortOrder.Asc ? 'rotate(180deg)' : 'none' }}
                />
            );
        }
        return <ChevronUpDown className={iconSmall} />;
    };

    const orgData = usersData?.organization?.users;
    
    const isOrgEmpty = !orgData || Object.keys(orgData?.items).length === 0;



    const userList = usersData?.organization?.users?.items|| [];
    const totalCount = orgData?.totalCount || 0;

    if(error){
        return(
            <FlexBox alignItems="center" justifyContent="center" style={{ height: "300px", textAlign: "center", flexDirection: "column", paddingTop: "18%" }}>
                {error.message === 'Not authorized' ? (
                    'You are not authorized to view or add a User'
                ) : (
                    <>
                        <img src="/images/Frame 427322365.svg" alt="add organization icon" width="312px" height="290px" />
                        <Button variant="primary" marginTop={4} style={{ padding: '25px' }} onPress={() => {
                            setConfigTab('organisation');
                            buttonRef.current?.click();
                        }}><Plus />Add Organization</Button>
                    </>
                )}
            </FlexBox>
        )
    }
    if(!loading && !error && isOrgEmpty){
        return (
            <FlexBox alignItems="center" justifyContent="center" style={{ height: "300px", textAlign: "center", flexDirection: "column", paddingTop: "15%" }}>
                <Text>Currently you are not part of any organization</Text>
                <Text>Wait for an invite or Create one</Text>
                <Button variant="primary" marginTop={4} style={{ padding: '25px' }} onPress={() => {
                    setConfigTab('organisation');
                    buttonRef.current?.click();
                }}><Plus />Add Organization</Button>
            </FlexBox>
        )
    }
    return (
        <div style={{ position: 'relative' }}>
            <DataGrid>
                <div className={"scrollable-element"} >
                    <Table aria-label="Pagination Table" width="100%" hasStickyHeader 
                        onRowAction={(key) => handleViewDetailsClick(key)} 
                        emptyStateComponent={<Loading message={"Loading User data..."}/>}
                    >
                        <TableHeader >
                            <Column width={243} key="name"><Text color='secondary' variant='body-s'>Name</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={195} key="department">
                                <div onClick={() => handleSort('department')} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <Text color="secondary" variant='body-s'>Department</Text>
                                    {renderSortIndicator('department')}
                                </div>
                            </Column>
                            <Column width={210} key="lastLogin">
                                <div onClick={() => handleSort('lastLogin')} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <Text color="secondary" variant='body-s'>Last Login</Text>
                                    {renderSortIndicator('lastLogin')}
                                </div>
                            </Column>
                            <Column width={210} key="created">
                                <div onClick={() => handleSort('createdAt')} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <Text color="secondary" variant='body-s'>Created</Text>
                                    {renderSortIndicator('createdAt')}
                                </div>
                            </Column>
                            <Column width={196} key="mfa"><Text color="secondary" variant='body-s'>MFA</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={195} key="org"><Text color="secondary" variant='body-s'>Org</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="status"><Text color="secondary" variant='body-s'>Status</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={195} key="privileges"><Text color="secondary" variant='body-s'>Privileges</Text><ChevronUpDown className={iconSmall} /></Column>
                            {/* eslint-disable-next-line react/no-children-prop */}
                            <Column width={95} children={undefined} style={{ position: "sticky", right: 0, background: "white", zIndex: 2, }}></Column>
                        </TableHeader>
                        <TableBody>
                            {userList.map((user) => (
                                <Row key={user.id} >
                                    <Cell >
                                        <FlexBox alignItems="center" gap={8} >
                                            <Avatar width={'40px'} height={'40px'} style={{ backgroundColor: 'transparent', }}>
                                                <img
                                                    src={'/images/noprofile.webp'}
                                                    alt={`${user?.fullName || ''}`}
                                                />
                                            </Avatar>
                                            <Text variant="body-m" width={170} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{` ${user.fullName || '-'}`}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell ><FlexBox><Text variant="body-m" width={190} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{(user.department || '-').slice(0, 20)}</Text></FlexBox></Cell>
                                    <Cell><Text width={200} variant="body-m">
                                        {user?.lastLogin && user?.lastLogin !== '-' ? format(new Date(user?.lastLogin), 'HH:mm,dd/MM/yyyy') : '-'}
                                    </Text></Cell>
                                    <Cell>
                                        <Text width={200} variant="body-m">
                                            {user?.createdAt && user?.createdAt !== '-' ? format(new Date(user?.createdAt), 'HH:mm,dd/MM/yyyy') : '-'}
                                        </Text>
                                    </Cell>
                                    <Cell>
                                        <Text variant="body-m">{user.enableMFA ? 'Enabled' : 'Disabled'}</Text>
                                    </Cell>
                                    <Cell ><FlexBox><Text variant="body-m" width={195} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                        {( usersData?.organization?.name || '-').slice(0, 20)}
                                        {/* OrganizationName */}
                                    </Text></FlexBox></Cell>
                                    <Cell>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                padding: "4px 8px",
                                                width: "106px", // Fixed width for equal alignment
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor:
                                                    user.authStatus === "AUTH0_ACCOUNT_CREATED"
                                                        ? "#E6F7E9"
                                                        : user.authStatus === "AUTH0_ACCOUNT_CREATION_FAILED"
                                                            ? "#FFF5F5"
                                                            : "#F5F5F5",
                                                color:
                                                    user.authStatus === "AUTH0_ACCOUNT_CREATED"
                                                        ? "#41B546"
                                                        : user.authStatus === "AUTH0_ACCOUNT_CREATION_FAILED"
                                                            ? "#D53F3F"
                                                            : "#4A5568",
                                                borderColor:
                                                    user.authStatus === "AUTH0_ACCOUNT_CREATED"
                                                        ? "#0A993A"
                                                        : user.authStatus === "AUTH0_ACCOUNT_CREATION_FAILED"
                                                            ? "#C53030"
                                                            : "#CBD5E0",
                                            }}
                                        >
                                            {user.authStatus === "AUTH0_ACCOUNT_CREATED" && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#41B546",
                                                            borderRadius: "50%",
                                                            width: "8px",
                                                            height: "8px",
                                                            marginRight: "8px",
                                                        }}
                                                    ></span>
                                                    <Text variant='body-m' style={{ color: "#087D2F" }}>Active</Text>
                                                </>
                                            )}
                                            {user.authStatus === "AUTH0_ACCOUNT_CREATION_FAILED" && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#D53F3F",
                                                            borderRadius: "50%",
                                                            width: "8px",
                                                            height: "8px",
                                                            marginRight: "8px",
                                                        }}
                                                    ></span>
                                                    <Text variant='body-m' style={{ color: "#BB460C" }} >Inactive</Text>
                                                </>
                                            )}
                                            {user.authStatus === "AUTH0_ACCOUNT_REQUESTED" && (
                                                <>
                                                    <img
                                                        src="/images/pending.png"
                                                        alt="Pending"
                                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                                    />
                                                    <Text variant='body-m' style={{ color: "#6B6B6B" }}>Pending</Text>
                                                </>
                                            )}
                                        </div>
                                    </Cell>

                                    <Cell>
                                        <span
                                            aria-label="view and add"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent row action
                                                handleViewPrivilegeClick(user?.id);
                                            }}
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "1px",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: (user?.privileges?.length ?? 0) === 0 ? "#FFF5F5" : "#E6F7FF",
                                                borderColor: (user?.privileges?.length ?? 0) === 0 ? "#C53030" : "#3182CE",
                                                color: (user?.privileges?.length ?? 0) === 0 ? "#C53030" : "#3182CE",
                                            }}
                                        >
                                            {(user?.privileges?.length ?? 0) === 0 ? (
                                                <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }} >
                                                    <Plus style={{ width: '16px', height: '16px' }} />
                                                    <span style={{ padding: "0 2px" }}>Add</span>
                                                </FlexBox>
                                            ) : (
                                                <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }}>
                                                    <Eye style={{ width: '16px', height: '16px' }} />
                                                    <span style={{ padding: "0.0px" }} >View</span>
                                                </FlexBox>
                                            )}
                                        </span>
                                    </Cell>
                                    <Cell style={{ position: "sticky", right: 0, background: "white", zIndex: 1 }} >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer',}}>
                                            <Button
                                                {...menuTriggerProps}
                                                shape="square"
                                                isIconOnly
                                                ref={buttonRef1}
                                                aria-label="menu-button"
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <ThreeDotsVertical />
                                            </Button>
                                            {state.isOpen && (
                                                <Menu
                                                    {...menuTriggerProps}
                                                    triggerRef={buttonRef1}
                                                    state={state}
                                                    aria-label="menu"
                                                    onAction={(key) => {
                                                        handleViewDetailsClickMenu(key, user.id);
                                                    }}
                                                    placement='left top'
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        boxShadow: 'none',
                                                        width: '212px', // Set the width of the menu
                                                        maxHeight: '176px', // Optionally limit the height of the menu
                                                        overflowY: 'auto', // Add scroll if content overflows
                                                    }}
                                                >
                                                    <Item key="View">View</Item>
                                                    <Item key="edit">Edit</Item>
                                                    <Item key="Privileges">Privileges</Item>
                                                    <Item key="delete">Delete</Item>
                                                </Menu>
                                            )}
                                        </div>
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    total={totalCount}
                    pageSize={pageSize}
                    pageSteps={[10, 30, 50]}
                    setPageSize={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handleCurrentPageChange}

                />
            </DataGrid >
            {selectedUser && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                        zIndex: 1000,
                    }}
                >
                    <UserInformation
                        user={selectedUser}
                        organizationId={selectedUser ? getOrganizationIdById(selectedUser, orgData?.items || []) : ''}
                        organizationName={usersData?.organization?.name || ''} 
                        handleCloseModal={handleCloseModal}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        buttonState={saveState}
                        setButtonState={setSaveState}
                        refetchApi={refetch}
                    />
                </div>
            )
            }
            <NotificationRenderer variant="toast" position="bottom" />
        </div >
    );
}
