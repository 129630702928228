/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Selection, type Key } from '@filament/react/types';
import { Wizard, WizardActions, WizardStep } from '@filament/react';
import React, { useState } from 'react';
import OrgDetails from './OrgDetails';
import OrganisationType from './OrganizationType';
import { Checkmark } from '@filament-icons/react';
import OrgLogo from './OrgLogo';
import { useUnifiedContexts } from '../../../../components/Context/Context';
export default function AddOrganization(
    { nextRef, previousRef, completedSteps, mobileCode, setMobileCode,
        // handleInputChange,
        error,
        setError,
        emailError
    }:
        {
            nextRef: React.MutableRefObject<(() => void) | undefined>,
            previousRef: React.MutableRefObject<(() => void) | undefined>,
            completedSteps: Key[],
            mobileCode: Selection,
            setMobileCode: React.Dispatch<React.SetStateAction<Selection>>,
            // handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
            error: boolean | undefined;
            setError: React.Dispatch<React.SetStateAction<{ phoneNumber: boolean, email: boolean }>>;
            emailError: boolean | undefined

        }) {
    const [logoState, setLogoState] = useState(true)
    const { orgValues, setOrgValues } = useUnifiedContexts()
    


    const renderActions = ({ next, previous }: { next?: () => void; previous?: () => void }) => {
        nextRef.current = next || undefined
        previousRef.current = previous || undefined
        return (
            <div>

            </div>
        );


    };

    return (
        <Wizard id='wizard' layout="horizontalCentered" style={{ padding: 0 }}
            onSelectionChange={(event) => setOrgValues((prev) => ({
                ...prev,
                wizardStepper: event as string
            }))}
            selectedKey={orgValues.wizardStepper}
            completedSteps={logoState ? undefined : completedSteps}
            onPreviousButtonClick={() => setLogoState(true)}
            onNextButtonClick={() => setLogoState(true)}

        >
            <WizardStep key="Organization" title="Organization Type" completedIcon={Checkmark}  >
                <OrganisationType value={orgValues.orgTypeValue} setValue={setOrgValues} textValue={orgValues.OthersTextArea} />

                <WizardActions>
                    {renderActions}
                </WizardActions>
            </WizardStep>

            <WizardStep key="details" title="Organization Details" completedIcon={Checkmark}>
                <OrgDetails
                    // handleInputChange={handleInputChange}
                    error={error}
                    emailError={emailError}
                    setError={setError}
                    mobileCode={mobileCode}
                    setMobileCode={setMobileCode}
                    Region={orgValues.region}

                />
                <WizardActions>
                    {renderActions}
                </WizardActions>
            </WizardStep>

            <WizardStep key="logo" title="Logo" completedIcon={Checkmark}>
                <OrgLogo
                    File={orgValues.file}
                    croppedImage={orgValues.croppedImg}
                   

                />
                <WizardActions>
                    {renderActions}
                </WizardActions>
            </WizardStep>
        </Wizard>
    );
}