import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FeatureEnum } from '../graphql/generated'

import { selectedUserValues } from '../pages/Organization/Components/SelectedUserTask'

export interface privlage {
    privlageValue: string,
    SelectedRoles: FeatureEnum[]
    Tasks: selectedUserValues
}


export const initialState: privlage = {
    privlageValue: "",
    SelectedRoles: [],
    Tasks: {
        selectUser: {}, // Initialize as an empty object with the correct structure
    }

}

const PrivilageTypeSlice = createSlice({
    name: "PrivilageTypeSlice",
    initialState,
    reducers: {
        StorePrivalgeValue: (state, action: PayloadAction<string>) => {
            state.privlageValue = action.payload

        },
        StorePrivilegeRoles: (state, action: PayloadAction<FeatureEnum[]>) => {
            state.SelectedRoles = [...action.payload]
        },

        StoreTasks: (state, action: PayloadAction<selectedUserValues>) => {
            state.Tasks=action.payload

        },

    }

})

export const { StorePrivalgeValue, StorePrivilegeRoles,StoreTasks} = PrivilageTypeSlice.actions

export default PrivilageTypeSlice.reducer

