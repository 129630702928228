/* eslint-disable max-len */

import React, { useCallback, useEffect, useState } from 'react';
import {
    Cell,
    Column,
    DataGrid,
    Pagination,
    Row,
    Table,
    TableBody,
    TableHeader,
    Avatar,
    Text,
    FlexBox,
    Tooltip,
    Label,
    Button,
} from '@filament/react';
import { ThreeDotsVertical, ChevronUpDown, Eye, Plus } from '@filament-icons/react';
import {
    atomicIconSmall as iconSmall,
} from '@filament/react/styles';
import ViewOrganization from '../Components/ViewOrganization';
import { OrgEntry, SortOrder,useGetOrganizationsQuery } from '../../../../graphql/generated';
import Loading from '../../../../components/Loading';
import { initalOrgValues } from '../../../../components/Context/OrganizationContext';
import { useUnifiedContexts } from '../../../../components/Context/Context';
import { setRefetch } from '../../../../components/Context/GlobalContext';
import { ConfigData } from '../../../../components/Context/ConfigurationContect';
import UserDrawer from '../../Components/UserDrawer';
import { getCountryNameByISO } from '../../../../CommonMethods/GetCountryNameByiSOCode';


const CreateOrganization = () => {
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orgDataLoading,setOrgDataLoading] = useState(true)
    const { setOrgValues,
        setFormData,
        setMapValues,
        setCountry,
        setValueType,
        SetTabs,
        setIsOpen,
        setInputValue } = useUnifiedContexts()
    const { setConfigTab } = ConfigData()

    const { data, loading, error, refetch } = useGetOrganizationsQuery({
        variables: { input: {
            limit: pageSize,
            offset: currentPage * pageSize,
            sortBy: "name",
            sortOrder: SortOrder.Desc
        }  
        },
        onCompleted: () => {
            setOrgDataLoading(false)
        },
        onError:()=>{
            setOrgDataLoading(false)
    
        }
    });
    const handlePageSizeChange = useCallback(
        (size: number) => {
            setPageSize(size);
            setCurrentPage(0);
        },
        []
    );

    const handleCurrentPageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
        },
        []
    );


    // // for view organization details 
    const handleViewDetailsClick = (org: React.SetStateAction<string>) => {
        setSelectedOrganization(org);
        // setActiveTab('details')
    };

    useEffect(() => {
        setRefetch(refetch)
        // dispatch(storeUserTableFetch(refetch))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    // for closing organization view
    const handleViewDetailsCloseClick = () => {
        setSelectedOrganization('');
        setFormData({
            organizationName: "",
            organizationAddress: "",
            city: "",
            state: "",
            postcode: "",
            organizationWebsite: "",
            vat: "",
            duns: "",
            placeId: ''
        })
        setOrgValues(initalOrgValues)
        setMapValues({
            center: {
                lat: 40.7128,
                lng: -74.006,
            },
            zoom: 17,
            suggestions: []
        })
        setCountry('')
        setValueType({ SelectAddType: OrgEntry.Address })
        setInputValue('')
    }


    // Extract user list and total count from data
    const organizationList = data?.organizations?.items || [];

    const totalCount = data?.organizations?.items?.length ?? 0;
   
    if (!loading&&data!==undefined&&totalCount == 0 &&!orgDataLoading)
        return (
            <FlexBox
                alignItems="center"
                justifyContent="center"
                style={{
                    height: "300px",
                    textAlign: "center",
                    flexDirection: "column",
                    paddingTop: "18%",
                }}
            >
                {/* Icon Section */}
                <div
                    style={{
                        position: "relative",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src="/images/Frame 427322365.svg" // Replace with your icon path
                        alt="add organization icon"
                        width="312px"
                        height="290px"
                    />
                </div>

                <Button variant="primary" marginTop={4} style={{padding: '25px'}} onPress={() => {
                    setConfigTab('organisation')
                    SetTabs('tab2')
                    setIsOpen(true) 
                            
                }}><Plus />Add Organization</Button>
                <div style={{display:'none'}}>
                    <UserDrawer />
                </div>
            </FlexBox>
        );
    if(!loading&&data!==undefined&&totalCount === 0){
        return(
            <FlexBox alignItems="center" justifyContent="center"
                style={{
                    height: "300px",
                    textAlign: "center",
                    flexDirection: "column",
                    paddingTop: "18%",
                }}
            >
                {/* Icon Section */}
                <div style={{
                    position: "relative",
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <img src="/images/Frame 427322365.svg" alt="add organization icon" width="312px" height="290px" />
                </div>

                <Button variant="primary" marginTop={4} style={{ padding: '25px' }} onPress={() => {
                    setConfigTab('organisation');
                    SetTabs('tab1')
                    setIsOpen(true)                     }}>
                    <Plus /> Add Organization
                </Button>

                <div style={{ display: 'none' }}>
                    <UserDrawer />
                </div>
            </FlexBox>
        )
    }

    if(error){
        return(
            <FlexBox alignItems="center" justifyContent="center"
                style={{
                    height: "300px",
                    textAlign: "center",
                    flexDirection: "column",
                    paddingTop: "18%",
                }}
            >
                {error.message === 'ORGANIZATION_NOT_FOUND' ? (
                    <>
                        {/* Icon Section */}
                        <div style={{
                            position: "relative",
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                        }}>
                            <img src="/images/Frame 427322365.svg" alt="add organization icon" width="312px" height="290px" />
                        </div>

                        <Button variant="primary" marginTop={4} style={{ padding: '25px' }} onPress={() => {
                            setConfigTab('organisation');
                            SetTabs('tab2')
                            setIsOpen(true) 
                        }}>
                            <Plus /> Add Organization
                        </Button>

                        <div style={{ display: 'none' }}>
                            <UserDrawer />
                        </div>
                    </>
                ) : (
                    <Text>{error.message}</Text>
                )}
            </FlexBox>
        )
    } 

    
       
    return (
        <div style={{ position: 'relative' }}>
            {/* Loading State */}

            {/* Error State */}
            

            {/* No Organizations Found */}
           
            <DataGrid>
                <div className={"scrollable-element"} >
                    <Table aria-label="Pagination Table" width="100%" hasStickyHeader
                        // onRowAction={(key) => handleViewDetailsClick(key)}
                        onRowAction={(key) => handleViewDetailsClick(key as string)}
                        emptyStateComponent={<Loading message={"Loading Organization data..."}/>}
                    >
                        <TableHeader >
                            <Column width={300} key="name"><Text color='secondary' variant='body-s'>Name</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={244} key="Type"><Text color="secondary" variant='body-s'>Type</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={200} key="Country"><Text color="secondary" variant='body-s'>Country</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={200} key="Site"><Text color="secondary" variant='body-s'>Sites</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={200} key="User"><Text color="secondary" variant='body-s'>Users</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="License"><Text color="secondary" variant='body-s'>License</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="My Privileges"><Text color="secondary" variant='body-s'>My Privileges</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="Account Status"><Text color="secondary" variant='body-s'>Account Status</Text><ChevronUpDown className={iconSmall} /></Column>
                            {/* eslint-disable-next-line react/no-children-prop */}
                            <Column width={150} children={undefined} style={{ position: "sticky", right: 0, background: "white", zIndex: 2, }}></Column>
                        </TableHeader>
                        <TableBody>
                            {organizationList.map((org) => (
                                <Row key={org.id}>
                                    <Cell >
                                        <FlexBox alignItems="center" gap={8} >
                                            <Avatar width={'40px'} height={'40px'} style={{ backgroundColor: 'transparent', }}>
                                                <img src={'/images/Logo.svg'} /> 
                                                {/* org.logo?.url || */}
                                            </Avatar>
                                            <Text variant="body-m" width={204} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                {` ${org.name || '-'}`}
                                            </Text>
                                        </FlexBox>
                                    </Cell>

                                    <Cell>
                                        <div style={{ display: 'flex', gap: '12px' }}>
                                            {Array.isArray(org.orgType) ? (
                                                <>
                                                    {Array.from(new Set(org.orgType)) // Remove duplicates
                                                        .filter((type) => type?.toLowerCase() !== 'others') // Exclude "others"
                                                        .slice(0, 1) // Get the first item after filtering
                                                        .map((type, index) => (
                                                            <Tooltip
                                                                key={index}
                                                                content={<span style={{
                                                                    fontSize: '16px', fontWeight: 'normal', paddingTop: '4px',
                                                                    paddingBottom: '4px'
                                                                }}>
                                                                    {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                                </span>} // Tooltip for each type
                                                                placement="bottom"
                                                            >
                                                                <Text
                                                                    style={{
                                                                        border: '1px solid #6B8094',
                                                                        borderRadius: '8px',
                                                                        paddingTop: '2px',
                                                                        paddingBottom: '7px',
                                                                        paddingLeft: '22px',
                                                                        paddingRight: '22px',
                                                                    }}
                                                                >
                                                                    {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').length > 8
                                                                        ? type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').slice(0, 8) + '...'
                                                                        : type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                                </Text>
                                                            </Tooltip>
                                                        ))}
                                                    {Array.from(new Set(org.orgType))
                                                        .filter((type) => type?.toLowerCase() !== 'others').length > 1 && (
                                                        <Tooltip
                                                            content={
                                                                <div
                                                                    style={{
                                                                        display: 'grid',
                                                                        width: '300px',
                                                                        gridTemplateColumns: '1fr',
                                                                        rowGap: '0.25rem',
                                                                    }}
                                                                >
                                                                    {Array.from(new Set(org.orgType))
                                                                        .filter((type) => type?.toLowerCase() !== 'others')
                                                                        .map((type, index) => (
                                                                            <Label
                                                                                key={index}
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'normal', // Allows text to wrap
                                                                                    overflow: 'hidden',
                                                                                }}
                                                                            >
                                                                                {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                                            </Label>
                                                                        ))}
                                                                </div>
                                                            }
                                                            placement="bottom"
                                                        >
                                                            <Text
                                                                style={{
                                                                    border: '1px solid #6B8094',
                                                                    borderRadius: '8px',
                                                                    paddingTop: '2px',
                                                                    paddingBottom: '7px',
                                                                    paddingLeft: '8px',
                                                                    paddingRight: '8px',
                                                                }}
                                                            >
                                                                ...
                                                            </Text>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : (
                                                <Tooltip
                                                    content={org.orgType || '-'}
                                                    placement="bottom"
                                                >
                                                    <Text
                                                        style={{
                                                            border: '1px solid #6B8094',
                                                            borderRadius: '8px',
                                                            paddingTop: '2px',
                                                            paddingBottom: '7px',
                                                            paddingLeft: '22px',
                                                            paddingRight: '22px',
                                                        }}
                                                    >
                                                        {org.orgType || '-'}
                                                    </Text>
                                                </Tooltip>
                                            )}
                                        </div>

                                    </Cell>

                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                {getCountryNameByISO(org.country?.split(':')?.[0]?.toString()) || '-'}
                                            </Text>
                                        </FlexBox>
                                    </Cell>

                                    <Cell>
                                        <Text variant='body-l'>
                                            {org?.sites?.totalCount || '-'}
                                        </Text>
                                    </Cell>

                                    <Cell>
                                        <Text variant='body-l'>
                                            {org?.users?.totalCount || '-'}
                                        </Text>
                                    </Cell>

                                    <Cell>
                                        <Text>
                                            {/* {org.licenseType || ''} */}
                                            Demo 1
                                        </Text>
                                    </Cell>
                                    <Cell>
                                        <span
                                            aria-label="view and add"
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "1px",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: "#E6F7FF",
                                                borderColor: "#3182CE",
                                                color: "#3182CE",
                                            }}
                                        >
                                            <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }}>
                                                <Eye style={{ width: '16px', height: '16px' }} />
                                                <span style={{ padding: "0 2px" }}>View</span>
                                            </FlexBox>
                                        </span>
                                    </Cell>
                                    <Cell>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                padding: "4px 8px",
                                                width: "106px", // Fixed width for equal alignment
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: "#E6F7E9",
                                                color: "#41B546",
                                                borderColor: "#0A993A",
                                            }}
                                        >
                                            <Text variant='body-m' style={{ color: "#087D2F" }}>Active</Text>
                                        </div>
                                    </Cell>
                                    <Cell style={{ position: "sticky", right: 0, background: "white", zIndex: 1 }} >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', }}>
                                            <ThreeDotsVertical />
                                        </div>
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    total={totalCount}
                    pageSize={pageSize}
                    pageSteps={[10, 30, 50]}
                    setPageSize={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handleCurrentPageChange}
                />
            </DataGrid >
            {selectedOrganization && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                        zIndex: 1000,
                    }}
                >
                    <ViewOrganization
                        handleViewDetailsCloseClick={handleViewDetailsCloseClick}
                        org={selectedOrganization}
                    />
                </div>
            )
            }
        </div >


    );
};

export default CreateOrganization;
