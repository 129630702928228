import {
    FlexBox,
    H1,
    H3,
    Paragraph,
    Skeleton,
} from '@filament/react';
import React from 'react';
  

export const ArticleCard = () => (
    <FlexBox flexDirection="column" gap={16} padding={28}>
        <section>
            <H1>{ <Skeleton />}</H1>
            <H3><Skeleton /></H3>
            <Paragraph><Skeleton /></Paragraph>
            <Paragraph variant="reference-s">
                <Skeleton />
            </Paragraph>
        </section>
        <FlexBox flexDirection="column" gap={4}>
            <Skeleton width={200} />
        </FlexBox>
        <FlexBox flexDirection="column" gap={4}>
            <Skeleton width={800} height={300} shape="sharp" />
        </FlexBox>
        <FlexBox flexDirection="column" gap={4}>
            <Paragraph><Skeleton /></Paragraph>
            <Paragraph><Skeleton /></Paragraph>

        </FlexBox>
        <FlexBox flexDirection="row" justifyContent="flex-end" gap={4}>
            <Skeleton width={150} height={40} shape="round" />
            <Skeleton width={150} height={40} shape="round" />
        </FlexBox>
    </FlexBox>
);