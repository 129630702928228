/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/react-in-jsx-scope */

import React, { useEffect, useState } from "react";
import {
    GoogleMap,
    useLoadScript,
    Libraries,
    MarkerF,
} from "@react-google-maps/api";
import { FlexBox, Label, TextField } from "@filament/react";
import { LocationFill, } from '@filament-icons/react';

import { GoogleApiKey } from "../../../../config";
import { SiteData } from "../../../../components/Context/SiteContext";


const libraries: Libraries = ["places"];
const mapContainerStyle = {
    width: "100%",
    height: "271px",
    borderRadius: "12px",
};

export default function SiteDetails({
    editedMarker,
    handleInputSiteChange,
}: {
    editedMarker?:{ lat: number; lng: number } | null,
    handleInputSiteChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!,
        libraries,
    });
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [marker, setMarker] = useState<{ lat: number; lng: number } | null>(null);
    const { formSiteData, setFormSiteData, inputSiteValue, setInputSiteValue, mapSiteValues, setMapSiteValues } = SiteData();
    const handleSiteChange = (field: keyof typeof formSiteData) => (value: string) => {
        setFormSiteData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };
    const onMapClick = (event: any) => {
        if (!window.google || !window.google.maps) return;
    
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const geocoder = new window.google.maps.Geocoder();
    
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results![0]) {
                const placeId = results![0].place_id;
                const addressComponents = results![0].address_components;
                const postalCodeComponent = addressComponents.find((component) =>
                    component.types.includes("postal_code")
                );
    
                setFormSiteData((prev) => ({
                    ...prev,
                    placeId: placeId,
                }));
                if(formSiteData.siteAddress==='')
                    handleSiteChange("siteAddress")(results![0].formatted_address || "");
                handleSiteChange("postcode")(postalCodeComponent ? postalCodeComponent.long_name : "");
                setInputSiteValue(results![0].formatted_address || "");
    
                setMarker({ lat, lng });
                setMapSiteValues((prev) => ({ ...prev, center: { lat, lng }, zoom: 10 }));
               
            }
        });
    
    };
    const handleSuggestionClick = (placeId: string) => {
        setIsSearchVisible(false);

        if (window.google && window.google.maps) {
            setFormSiteData(prev => ({
                ...prev,
                placeId: placeId
            }))
            const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));
            placesService.getDetails({ placeId }, (place, status) => {
                if (status === "OK" && place && place.geometry) {
                    const location = place.geometry.location;
                    if (location && typeof location.lat === "function" && typeof location.lng === "function") {
                        const lat = location.lat();
                        const lng = location.lng();
                        setMapSiteValues((prev) => ({ ...prev, center: { lat: lat, lng: lng } }))
                        setMapSiteValues((prev) => ({ ...prev, zoom: 10 }))
                        setMarker({ lat, lng });
                       

                        const addressComponents = place.address_components;
                        const postalCodeComponent = addressComponents?.find((component) =>
                            component.types.includes("postal_code")
                        );

                        if(formSiteData.siteAddress==='')
                            handleSiteChange("siteAddress")(place.formatted_address || "");
                        handleSiteChange("postcode")(postalCodeComponent ? postalCodeComponent.long_name : "");
                        setInputSiteValue(place.formatted_address || "");
                    }
                }
            });
        }
        setMapSiteValues((prev) => ({ ...prev, suggestions: [] }));
    };

    useEffect(()=>{
        if(editedMarker!==undefined){
            setMarker(editedMarker)
     
        }else{
            setMapSiteValues(prev=>({...prev,zoom:2}))
        }
    },[editedMarker])
    
    const clearInput = () => {
        setInputSiteValue('');
        setFormSiteData((prev) => ({
            ...prev,
            siteAddress: "",
            postcode: ""
        }));
        setMarker(null)
        setMapSiteValues((prev) => ({ ...prev, zoom: 4 }))
        setTimeout(() => handleSuggestionClick(''), 0); // Ensures it runs after state updates
    };

    const handleClearClick = () => {
        if (inputSiteValue) {
            // If there's text, just clear the input
            clearInput();
        } else {
            // If input is empty, hide the search bar
            setIsSearchVisible(false);
            setMapSiteValues((prev) => ({ ...prev, suggestions: [] }));
        }
    };

    const handleSearchIconClick = () => {
        setIsSearchVisible(true);
    };

    useEffect(() => {
        if (isLoaded && formSiteData.placeId) {
            setMarker({ lat: mapSiteValues.center.lat, lng: mapSiteValues.center.lng });
            handleSuggestionClick(formSiteData.placeId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded])

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;
    return (
        <FlexBox style={{ flexDirection: "column", gap: "24px", paddingTop: "24px" }}>
            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Site Name</Label>
                <TextField
                    placeholder="Site Name"
                    isFullWidth
                    type="text"
                    aria-label='site name'
                    value={formSiteData.siteName}
                    onChange={handleSiteChange("siteName")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                    <Label>Short Name</Label>
                    <Label variant={'descriptor'}>(optional)</Label>
                </div>
                <TextField
                    placeholder="Short Name"
                    isFullWidth
                    type="text"
                    aria-label='short name'
                    value={formSiteData.shortName.trim()}
                    onChange={handleSiteChange("shortName")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Site Location</Label>

                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={mapSiteValues.zoom}
                    center={mapSiteValues.center}
                    onClick={onMapClick}
                    // onLoad={onMapLoad}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                    }}

                >
                    <div style={{ position: "relative", paddingTop: '17px', paddingLeft: '24px', paddingRight: '24px' }}>
                        <div style={{ position: 'relative', width: '100%' }}>
                            {isSearchVisible ? (
                                <>
                                    <span style={{
                                        position: 'absolute',
                                        top: '55%',
                                        left: '1px',
                                        transform: 'translateY(-50%)',
                                        fontSize: '16px',
                                    }}>
                                        <img src="/images/searchIcon.svg" alt="search" />
                                    </span>
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        value={inputSiteValue}
                                        onChange={handleInputSiteChange}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            border: '1px solid gray',
                                            borderRadius: '6px',
                                            paddingTop: '7px',
                                            paddingBottom: '7px',
                                            paddingLeft: '52px',
                                            paddingRight: '35px',
                                            outline: 'none',
                                            transition: 'box-shadow 0.2s ease-in-out, border 0.2s ease-in-out',
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.boxShadow = '0 0 0 1px white, 0 0 0 3px rgb(21, 107, 226)';
                                            e.target.style.border = '1px solid gray';
                                        }}
                                        onBlur={(e) => {
                                            e.target.style.boxShadow = 'none';
                                            e.target.style.border = '1px solid gray';
                                        }}
                                    />
                                    <span style={{
                                        position: 'absolute',
                                        top: '55%',
                                        right: '1px',
                                        transform: 'translateY(-50%)',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                    }} onClick={handleClearClick}>
                                        <img src="/images/Clear Button.svg" alt="clear" />
                                    </span>
                                </>
                            ) : (
                                <div 
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        borderRadius: '6px',
                                        border: '1px solid gray',
                                        background: 'white',
                                        marginLeft: 'auto',
                                    }}
                                    onClick={handleSearchIconClick}
                                >
                                    <img src="/images/searchIcon.svg" alt="search" />
                                </div>
                            )}
                        </div>

                        {mapSiteValues.suggestions.length > 0 && (
                            <div style={{
                                position: "absolute",
                                top: "100%",
                                left: '3%',
                                right: '3%',
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                zIndex: 1,
                                fontFamily: "Arial, sans-serif",
                                fontSize: "14px",
                            }}>
                                {mapSiteValues.suggestions.map((suggestion) => (
                                    <div
                                        key={suggestion.place_id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 12px",
                                            cursor: "pointer",
                                            borderBottom: "1px solid #f0f0f0",
                                        }}
                                        onClick={() => handleSuggestionClick(suggestion.place_id)}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f9f9f9"}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#fff"}
                                    >
                                        <span style={{
                                            marginRight: "10px",
                                            color: "#888",
                                        }}>
                                            <LocationFill />
                                        </span>
                                        <span style={{
                                            flex: 1,
                                            color: "#333",
                                        }}>
                                            {suggestion.description}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {marker && (
                        <MarkerF
                            position={{ lat: marker!.lat, lng: marker!.lng }}
                            icon={{
                                url: "/images/gis_pin.svg", // Path to your custom marker image
                                scaledSize: new window.google.maps.Size(40, 40), // Adjust size
                                origin: new window.google.maps.Point(0, 0), // Origin point of the image
                                anchor: new window.google.maps.Point(20, 40), // Anchor point for positioning
                            }}
                        />
                    )}
                </GoogleMap>
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                <Label>Site Address</Label>
                <TextField
                    placeholder="Site Address"
                    type="text"
                    aria-label='site Address'
                    isFullWidth
                    value={formSiteData.siteAddress}
                    onChange={ handleSiteChange("siteAddress")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                <Label>Postcode</Label>
                <TextField
                    placeholder="Postcode"
                    type="text"
                    aria-label='postcode'
                    isFullWidth
                    inputProps={{ pattern: "^[A-Za-z0-9][A-Za-z0-9\\-\\s]{0,10}[A-Za-z0-9]$" }}
                    value={formSiteData.postcode}
                    onChange={handleSiteChange("postcode")}
                />
            </FlexBox>
        </FlexBox>
    );
}