/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { FlexBox } from "@filament/react";
import Navbar from "./Navbar.tsx";
import { SideNav } from "./SideNav.tsx";
import { Outlet, useLocation } from "react-router-dom";
import WelcomeScreen from "./WelcomeScreen.tsx";
import GlobalContext from "./Context/GlobalContext.tsx";
import { getUserInfo } from "../CommonMethods/GetUserInfo.ts";

export default function RootLayout({ isInitialLoad }: { isInitialLoad: boolean }) {
    const [showWelcome, setShowWelcome] = useState(isInitialLoad);

    const {userInfo,setUserInfo} = useContext(GlobalContext)
    const location = useLocation() 
    
    useEffect(() => {
        // if (isInitialLoad) {
        const timer = setTimeout(() => {
            setShowWelcome(false);
        }, 6000); // 8 seconds

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
        // }
    }, [isInitialLoad]);

    useEffect(()=>{
        
        if(location.hash.includes('#access_token')||location.hash.includes('#id_token')||location.hash.includes('code')){
            const hash = window.location.hash;  // Get URL fragment
            const params = new URLSearchParams(hash.substring(1));  
            localStorage.setItem("access_token",params.get('access_token')!)

        }
        
  
    },[])


    useEffect(()=>{    
        try{
            const getUserDetails = async() =>{
                const hash = window.location.hash;  // Get URL fragment
                const params = new URLSearchParams(hash.substring(1));  // Remove the '#' and parse
                if(userInfo.family_name==='' && params.get('access_token') ){
                    const response = await getUserInfo(params.get('access_token')!)
                    if (response.ok) {
                        const userInfo = await response.json();
                        setUserInfo({
                            'family_name':userInfo.family_name,
                            "given_name": userInfo.given_name,
                            "userId": userInfo.sub,
                        })
                    } 
                }
            }
            getUserDetails()
        }catch(e){
            console.error(e)
        }

        
        
    },[])

    return (
        <>
            {showWelcome ? (
                <WelcomeScreen />
            ) : (
                <FlexBox style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Navbar />
                    <FlexBox style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <SideNav />
                        <FlexBox style={{ flex: 1, padding: "16px" }}>
                            <Outlet />
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            )}
        </>
    );
}
