import { TableRow } from "../SiteStructure";
import { Trie } from "./TrieDataStructure";

export const cloneTrie = (trie: Trie) => 
    Object.assign(Object.create(Object.getPrototypeOf(trie)), trie);

export const initializeRowSpanMap = (tableData: TableRow[]) =>{
    const longestPath = tableData.reduce((longest,current)=>(
        (longest.path.length > current.path.length ? longest : current)
    ))
    return Array(tableData.length)
        .fill([])
        .map(() => Array(longestPath?.path?.length || 0).fill(1));
}
export const calculateRowSpans = (tableData: TableRow[], rowSpanMap: number[][]) => {
    const longestPath = tableData.reduce((longest,current)=>(
        (longest.path.length > current.path.length ? longest : current)
    ))
    for (let col = 0; col < longestPath?.path?.length; col++) {
        let previousRow: string | null = null;
        let spanCount = 1;
        for (let row = 0; row < tableData.length; row++) {
            const currentRowPath = tableData[row].path.slice(0, col + 1).join("->");
            if (previousRow === currentRowPath) {
                rowSpanMap[row - spanCount][col] = ++spanCount;
                rowSpanMap[row][col] = 0; // Mark cell as merged

            } else {
                previousRow = currentRowPath;
                spanCount = 1;
            }
        }
    }
};