import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Portal } from "@filament/react";
import AppApolloProvider from "./graphql/ApolloProvider";
const rootElement = document.getElementById("root")
import 'modern-normalize/modern-normalize.css';
import clsx from "clsx";
import { blue, light, medium } from "@filament/react/themes";
import '@filament/react/fonts'

if(rootElement){
    ReactDOM.createRoot(rootElement).render(
        
        <AppApolloProvider>
            <React.StrictMode>
                <div className={clsx(blue,medium,light)}>
                    <Portal>
                        <App />
                    </Portal>
                </div>
            </React.StrictMode> 
        </AppApolloProvider>          
        ,
    );
}else{
    console.error("Root element not found");
}
