/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {  Checkbox, FlexBox, Text } from '@filament/react'
import React from 'react'
import SiteStructureLabel from './SiteStructureLabel';
import { useUnifiedContexts } from '../../../../components/Context/Context';
import { arraysEqual, rootPathInArray, TableRow } from '../SiteStructure';
import { calculateRowSpans, initializeRowSpanMap } from './AddNodeActivities';
import { getCollapsedLabel, getFirstChildrenArray,getPathUpToTarget, hideNonFirstChildren, Trie } from './TrieDataStructure';
import { generateTableData } from '../../../../workers/trieInstance';
import * as styles from '../styles/styles.css'
import { Edit } from '@filament-icons/react';
import { removeDuplicateArrays } from '../../../../components/Context/SiteContext';


const SiteStructurePreview = ({previewPageClicked}:{previewPageClicked:()=>void}) => {
  


    const {
        previewCollapsedNodes,
        setPreviewCollapsedNodes,
        previewRowSpanMap,
        setPreviewRowSpanMap,
        formSiteData,
        previewTrie,
        previewTableData,
        setPreviewTrie,
        setPreviewTableData,
        siteStructureTopPath,
        previewPreset} = useUnifiedContexts()

    const toggleCollapse = (path: string[]) => {
        const trimmedPath = path.slice(0, -1);
        const firstChildrenLabels = getFirstChildrenArray(previewTrie.root,trimmedPath)
        const isCollapsed = previewCollapsedNodes?.some(arr => arraysEqual(arr, trimmedPath));
       
        setPreviewCollapsedNodes(prev => {
            if(isCollapsed) {
                return prev.filter(arr => !rootPathInArray(arr,path
                )&&
                        !(arr.slice(0, path.length-1).every((val, index) => val === path[index])) // Remove if all but the last match
                )
            }
            else{
                // Merge previous state with unique new paths
                const updatedNodes = [...prev, trimmedPath, ...firstChildrenLabels];
        
                // Ensure unique arrays using a helper function
                return removeDuplicateArrays(updatedNodes);
            }
        } 

        );
       
        const hiddenTrie: Trie = hideNonFirstChildren(previewTrie, trimmedPath, !isCollapsed);
        setPreviewTrie(hiddenTrie);
       
        const tabledata: TableRow[] = [];
        generateTableData(hiddenTrie.root, [], tabledata,[],hiddenTrie.root);
        const rowSpanMap = initializeRowSpanMap(tabledata);
        calculateRowSpans(tabledata, rowSpanMap);
           
        setPreviewTableData(tabledata);
        setPreviewRowSpanMap(rowSpanMap);
    };

    return (
        <>
            <div
                style={{
                    height: "fit-content", // Full viewport height
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden", // Prevent body scroll, only internal scrolling
                }}
            >
                <FlexBox style={{justifyContent:"space-between",
                    alignItems:"center",
                    paddingLeft:"20px",
                    paddingRight:"20px",
                    paddingTop:"20px",
                    position: "sticky",
                    top: "0", // Stick to the top
                }}
                >
                    <Checkbox isSelected>
                        <Text style={{ fontSize: '20px', fontWeight: '700', }}>{previewPreset} :Preview</Text>
                    </Checkbox>
                    <Edit onClick={previewPageClicked} style={{cursor:"pointer"}}/>
                </FlexBox>
                <div style={{ 
                    position: "sticky", 
                    bottom: 0, 
                    backgroundColor: "white", 
                    zIndex: 1, 
                    paddingBottom: "10px" 
                }}>

                    <hr style={{ border: "1px solid #CAD2D8" ,marginTop:"20px"}} />
                </div>
                <div className="scrollable-table" style={{ flexGrow: 1, overflowY: "auto", maxHeight: "calc(100vh - 250px)"  // Adjust based on header/footer height
                }}>
                    <table style={{ margin: "0 auto" }}>
                        <thead style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 9 }}>
                            <tr>
                                {[...Array(siteStructureTopPath.length)].map((_, index) => (
                                    <th 
                                        key={index} 
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            backgroundColor: "white",
                                            zIndex: 1000,  // Ensure it stays above other content
                                            padding: "10px",
                                        }}
                                    >
                                        <div 
                                            className={styles.previewHeadingCellWidth}
                                            style={{ display: "flex", marginBottom: "20px", marginRight: "19px" }}
                                        >
                                            <SiteStructureLabel 
                                                value={`Level ${index + 1}-11`} 
                                                isFirstChild={false}
                                                isFromSitePreview={true}
                                                customVal=""
                                                isHeading={true} 
                                            />
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody >
                            {previewTableData.map((row:TableRow, rowIndex:number) => {
                                return (
                                    <tr key={rowIndex}>
                                        {row.path.map((cell, colIndex) => {
                                            const shouldRender = previewRowSpanMap?.[rowIndex]?.[colIndex] > 0;
                                            const isFirstChild = row.isFirstChild.some((entry) => Object.keys(entry)[0] === cell);
                                            return  shouldRender && (<td key={`${cell}-${rowIndex}`}
                                                style={{ verticalAlign: "top" }}
                                                rowSpan={previewRowSpanMap[rowIndex][colIndex]}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: "30px"
                                                }}>{rowIndex === 0 && colIndex === 0 ? 
                                                        (<FlexBox
                                                            alignItems="center"
                                                            style={{
                                                                whiteSpace: "normal",
                                                                wordBreak: "break-word", // Prevent overflow of long words
                                                                paddingLeft: "10px",
                                                                paddingRight: "10px",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Text style={{fontWeight:"700",fontSize:'16px'}}>
                                                                {formSiteData.siteName}
                                                            </Text>
                                                        </FlexBox>
                                                        ) : (
                                                            <FlexBox gap={0} style={{ alignItems: 'center', display: 'flex' }}>
                                                                {<>
                                                                    <span className={styles.previewIconDisplay}
                                                                        aria-label="view and add"
                                                                    >
                                                                        <span style=
                                                                            {{ padding: '0 2px' }}>
                                                                            {colIndex===siteStructureTopPath.length-1?cell.split(' ')[1].charAt(0).toUpperCase():
                                                                                cell.charAt(0).toUpperCase()}</span>
                                                                    </span>
                                                                </>}
                                                                <SiteStructureLabel value={cell}
                                                                    row={row}
                                                                    customVal={previewCollapsedNodes?.some(arr => rootPathInArray(arr, getPathUpToTarget(row.path,cell).slice(0,-1)))
                                                                        ?getCollapsedLabel(previewTrie.root,getPathUpToTarget(row.path,cell)):''}
                                                                    isFirstChild={isFirstChild}
                                                                    toggleCollapse={toggleCollapse}
                                                                    isFromSitePreview={true}
                                                                />                                                        </FlexBox>

                                                        )}
                                                </div>
                                            </td>
                                            )
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div  style={{position: "sticky",
                    top: "0",
                }}>
                    <FlexBox flexDirection='column' gap={12} style={{ paddingTop: '22px',paddingLeft:"20px" }}>
                        <FlexBox flexDirection='column' gap={12}>
                            <Text variant='heading-xs'>Device name preview</Text>
                            <Text variant='body-m'>The device name will follow the structure of the site you’ve selected.</Text>
                        </FlexBox>
                        <FlexBox flexDirection='column' gap={12}>
                            <Text variant='heading-xs'>Example:</Text>
                            <Text color='secondary' variant='body-s'>Z1_F1_00201_1</Text>
                        </FlexBox>
                    </FlexBox>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "20px",
                        }}
                    >
                        
                        <hr
                            style={{
                                border: "1px solid #CAD2D8",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        />
                        <FlexBox
                            style={{
                                backgroundColor: "#00438A0F",
                                height: "32px",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom:"10px",
                                padding: "10px",
                                borderRadius: "6px",
                                border: "1px solid #0072DB",
                                display: "flex",
                            }}
                        >
                            <p style={{ color: "#0072DB", margin: "0" }}>{siteStructureTopPath.length} levels</p>
                        </FlexBox>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SiteStructurePreview