/* eslint-disable react-hooks/exhaustive-deps */
import { Button, 
    Dialog, 
    DialogActions, 
    DialogContainer, 
    DialogContent, 
    DialogTitle, 
    FlexBox,
    NotificationsQueue} from "@filament/react"
import React, { useEffect, useState } from "react"
import SiteWizard from "../../Components/SiteWizard"
import { Cross } from "@filament-icons/react"
import PreviewSite from "./PreviewSite"
import { SiteData } from "../../../../components/Context/SiteContext"
import { GetSiteDataQuery, 
    IsoAlpha2CountryCode, 
    SiteStructurePreset, 
    useGetSiteDataQuery, 
    useSiteUpdateMutation } from "../../../../graphql/generated"
import { Trie } from "../../Site Structure/components/TrieDataStructure"
import { TableRow } from "../../Site Structure/SiteStructure"
import { generateTableData } from "../../../../workers/trieInstance"
import { calculateRowSpans, initializeRowSpanMap } from "../../Site Structure/components/AddNodeActivities"
import { useUnifiedContexts } from "../../../../components/Context/Context"
import { ApolloError } from "@apollo/client"
import { ToastNotification } from "../../../../CommonMethods/ToastNotification"
import { GET_USER_SITES } from "../../../../graphql/queries/getUserSiteDetails"
import { triggerRefetch } from "../../../../components/Context/GlobalContext"
import { ArticleCard } from "../../../../components/Shimmer/EditScreenShimmer"

export default function EditSite ({onEditClick,
    handleViewSiteCloseClick,
    data,
    siteId,
    loading,
    setOnEditClick}:{
    onEditClick:boolean,
    handleViewSiteCloseClick: () => void,
    data?:GetSiteDataQuery|undefined,
    loading?:boolean,
    siteId?:string,
    setOnEditClick:React.Dispatch<React.SetStateAction<boolean>>}) {
    const [marker, setMarker] = useState<{ lat: number; lng: number } | null>(null);

    const SiteArray = [<SiteWizard key={0} orgId='' country='' marker={marker} />, 
        <PreviewSite key={1} />]
    const { data:siteData ,loading:siteLoading } = useGetSiteDataQuery({
        variables: { id: siteId??'' },
        skip: siteId===undefined, // Skips query when siteId is undefined or falsy
    });
    const {setSelectedPreset,
        setIsCustomEdit,
        setPreviewPreset,
        setPreviewTrie,
        setPreviewRowSpanMap,
        setSiteStructureTopPath,
        setPreviewTableData,
        setPathOfSelectedLabel,
        setEditedSiteId,
        setIsNextPreviewButtonCliked} = useUnifiedContexts()
    const {siteValues,SitenextRef, SitepreviousRef,setSiteValues,formSiteData,mapSiteValues,
        setFormSiteData,
        setMapSiteValues} = SiteData()
    const [loadingSiteDetails,setLoadingSiteDetails] = useState(false)
    const [siteArrayIndex, SetSiteArrayIndex] = useState<number>(0)
    const onBackPress = () => {
        if (siteValues.wizradState === 'Site_Structure' && siteArrayIndex > 0) {
            SetSiteArrayIndex((prev) => (prev - 1) % SiteArray.length)
        }else {

            SitepreviousRef.current?.()
        }
    }
    const [updateSite] =  useSiteUpdateMutation()
    const siteStructurePresetMap: Record<SiteStructurePreset,"Preset 1" | 
    "Preset 2" | 
    "Preset 3" | 
    "Custom site structure" > = {
        [SiteStructurePreset.Preset1] : "Preset 1",
        [SiteStructurePreset.Preset2] : "Preset 2",
        [SiteStructurePreset.Preset3] : "Preset 3",
        [SiteStructurePreset.Custom] : "Custom site structure"
        
    };
    // Ensure the function only returns valid values
    const getMappedPreset = (preset: SiteStructurePreset | null | undefined): 
            "Preset 1" | 
            "Preset 2" | 
            "Preset 3" | 
            "Custom site structure" | 
            "Open Structure" | null => {

        if (!preset) return null;  // Handle undefined or null case
        return siteStructurePresetMap[preset] || "Custom site structure"; // Default if unknown
    };
    const onNextPress = async () => {
        if (siteValues.wizradState === 'Site_Structure') {
            SetSiteArrayIndex((prev) => prev + 1)
        }if(siteArrayIndex===1){
            handleClose()
        }
        else if(siteValues.wizradState === 'Site_Details' || siteValues.wizradState === 'Upload_Images'){
            SitenextRef.current?.()
        }
        if(siteArrayIndex === 1){
            try{
                const { data: siteData } = await updateSite({
                    variables: {
                        id:data?.site?.id||siteId||"",
                        name: formSiteData.siteName,
                        shortName: formSiteData.shortName.length > 0 ? formSiteData.shortName : " ",
                        address: formSiteData.siteAddress,
                        city: "",
                        state: "",
                        country: "IN" as IsoAlpha2CountryCode,
                        postcode: formSiteData.postcode,
                        latitude: mapSiteValues.center.lat,
                        longitude: mapSiteValues.center.lng,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        siteStructurePreset:SiteStructurePreset.Custom

                    },
                    refetchQueries: [{query:GET_USER_SITES}], // Add this if you're using named queries
                    // Alternatively, you can use the following to refetch specific queries
                    // refetchQueries: [{ query: GET_SITES_QUERY, variables: { organizationId } }]
                });
                if(siteData){
                    NotificationsQueue.toast(
                        ({ close }) => <ToastNotification close={close} message="Site updated successfully" />,
                        {
                            signal: 'success',
                            orientation: 'horizontal',
                            showCloseButton: true,
                            timeout: 6000,
                        }
                    ); 
                    triggerRefetch()
                }

            }catch(err){
                let errorMessage = 'An error occurred while updating the site.';

                if (err instanceof ApolloError) {
                    if (err.graphQLErrors?.length > 0) {
                        const graphQLError = err.graphQLErrors[0];
                        if (graphQLError.extensions?.code === 'INSUFFICIENT_PERMISSIONS') {
                            errorMessage = 'You do not have permission to update a site';
                        } else if (graphQLError.extensions?.code === 'SITE_ALREADY_EXISTS') {
                            errorMessage = 'A site with this name already exists';
                        } else {
                            errorMessage = graphQLError.extensions?.message as string || graphQLError.message;
                        }
                    } else if (err.networkError) {
                        errorMessage = 'Network error occurred. Please check your connection.';
                    }
                }

                NotificationsQueue.toast(
                    ({ close }) => <ToastNotification close={close} message={errorMessage} />,
                    {
                        signal: 'error',
                        orientation: 'horizontal',
                        showCloseButton: true,
                        timeout: 6000,
                    }
                );
            }
        }
    }

    const handleClose = () => {
        setOnEditClick(false);
        handleViewSiteCloseClick()
        setFormSiteData((prev)=>({
            ...prev,
            siteName:'',
            shortName:'',
            siteAddress:'',
            postcode:'',
            placeId:''
        }))
        setMapSiteValues((prev)=>({
            ...prev,
            lat:0,
            lng:0,
            center:{
                lat:0,
                lng:0
            }
        }))
        setSiteValues(prev=>({...prev,wizradState:''}))
        setSelectedPreset(null)
        setPathOfSelectedLabel([])
        setIsNextPreviewButtonCliked(false)
        setPreviewTableData([])
        setPreviewRowSpanMap([])
        setPreviewTrie(new Trie())
        setPreviewPreset(null)
        setIsCustomEdit(false)
    }

    useEffect(() => {
        const siteInfo = data?.site ?? siteData?.site;
        setEditedSiteId(siteInfo?.id??'')
        if (siteInfo) {
            setLoadingSiteDetails(true)
            setFormSiteData((prev) => ({
                ...prev,
                siteName: siteInfo.name ?? '',
                shortName: siteInfo.shortName ?? '',
                siteAddress: siteInfo.address ?? '',
                postcode: siteInfo.postcode ?? '',
            }));
            setMarker({ lat: siteInfo.latitude ?? 0, lng: siteInfo.longitude ?? 0 });
            setMapSiteValues((prev) => ({
                ...prev,
                lat: siteInfo.latitude ?? 0,
                lng: siteInfo.longitude ?? 0,
                center: {
                    lat: siteInfo.latitude ?? 0,
                    lng: siteInfo.longitude ?? 0,
                },
                zoom:10
            }));
            const tt = new Trie()
            const tableData: TableRow[] = [];
            tt.populateFromResponse(siteInfo?.siteStructure?.siteStructure??[]);
            generateTableData(tt.root, [], tableData,[],tt.root)
            const rowSpanMap = initializeRowSpanMap(tableData);
            calculateRowSpans(tableData, rowSpanMap);
            setPreviewTrie(tt)
            setSelectedPreset(getMappedPreset(siteInfo.siteStructurePreset))
            setIsCustomEdit(true)
            if(siteInfo.siteStructurePreset === undefined || siteInfo.siteStructurePreset===null){
                setPreviewPreset('Open Structure')
                setIsCustomEdit(false)
                setSelectedPreset('Open Structure')
            }
            setPreviewPreset(getMappedPreset(siteInfo.siteStructurePreset))
            const longestPath = tableData.reduce((longest, row) =>
                row.path.length > longest.path.length ? row : longest, tableData[0]
            );
            setPreviewTableData(tableData)
            
            setPreviewRowSpanMap(rowSpanMap)
            setSiteStructureTopPath(longestPath.path);
            setIsNextPreviewButtonCliked(true)
            setLoadingSiteDetails(false)

        }

    }, [data, siteData]);

    return(
        <>
            <DialogContainer onDismiss={()=>{setOnEditClick(false)}}  isOpen={onEditClick}>
                <Dialog
                    id='Dialog'
                    style={{
                        height: "100vh",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: "auto",            
                        position: 'fixed',
                        marginTop: 0,
                        marginBottom: 0,
                        borderRadius: "10px 0 0 10px",
                        width: onEditClick ? "44%" : "0px", // Animate width from 0 to 848px
                        // overflow: "hidden", // Prevent content from spilling out during animation
                        transition: "width 0.5s ease-in-out", // Smooth width transition
                        boxShadow: onEditClick ? "-2px 0 8px rgba(0, 0, 0, 0.2)" : "none", // Shadow only when open
                
                    }}
                
                >
                    <FlexBox justifyContent='space-between' alignItems={'center'} >
                        <DialogTitle aria-label="dialogTitle" style={{ marginLeft: '16px', marginTop: '28px' }}>
                            Edit Site
                        </DialogTitle>
                        <Button style={{ borderRadius: '10px', marginRight: '22px', marginTop: '22px' }}
                            variant={'quiet'} onPress={handleClose}>
                            <Cross />
                        </Button>
                    </FlexBox>
                    {loading||siteLoading||loadingSiteDetails?<ArticleCard/>:<>
                        <DialogContent height={'100vh'} style={{ padding: '20px', }}>
                            {SiteArray[siteArrayIndex]}
                        </DialogContent>

                        <DialogActions>
                            <Button variant="quiet" onPress={onBackPress}>
                                Back
                            </Button>
                            <Button variant="primary" onPress={onNextPress}>
                                Next
                            </Button>
                        </DialogActions>
                    </>}
                </Dialog>
            </DialogContainer>
        </>
    )
}