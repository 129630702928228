import { gql } from "@apollo/client";

export const GET_USER_SITES = gql`
  query GetUserSites($id: String!,$organizationId:String!,$input:GetSitesInput!) {
    user(
      id: $id,
      organizationId: $organizationId,
    ) {
      sites(
        input: $input
      ) {
        totalCount
        items {
          id
          name
          shortName
          address
          country
          organizationId
          siteStructurePreset
          timezone
          logo {
                    key
                    isUploaded
                    url
                }
  
          licenseType
          totalDevices
          totalUsers
        }
      }
    }
  }
`;
