/* eslint-disable max-len */
import { Checkbox, CheckboxGroup, FlexBox, Text, TextArea } from "@filament/react";
import React from "react";
import { OrgType } from "../../../../graphql/generated";

interface OrganisationTypeProps<T> {
    value: string[];
    setValue: React.Dispatch<React.SetStateAction<T>>;
    textValue: string;
    isEdit?: boolean;
}

const OrganisationType = <T,>({ value, setValue, textValue, isEdit }: OrganisationTypeProps<T>) => {
    const filteredOrgType = isEdit 
        ? Object.fromEntries(Object.entries(OrgType).filter(([key]) => key !== "Others")) 
        : OrgType;
    
    // Transform OrgType values to replace " Or " with " / "
    const transformedOrgType = Object.fromEntries(
        Object.entries(filteredOrgType).map(([key, val]) => [key, val.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')])
    );

    return (
        <>
            <FlexBox flexDirection={'column'} gap={32} marginTop={'32px'}>
                {(isEdit === undefined || isEdit !== true) && (
                    <Text color="secondary" aria-label="text for organization type">
                        *Select the Function of your Organization,
                        so we can customize your WAVE experience where possible.
                    </Text>
                )}
                <CheckboxGroup
                    aria-label="organization type"
                    value={[...value]}
                    onChange={(event) => setValue((prev) => ({
                        ...prev,
                        orgTypeValue: [...event]
                    }))}
                >
                    {Object.entries(transformedOrgType).map(([key, val]) => (
                        <Checkbox key={key} value={key}>
                            {val}
                        </Checkbox>
                    ))}
                </CheckboxGroup>
                {!isEdit && value.includes("Others") && (
                    <FlexBox flexDirection="column">
                        <TextArea 
                            placeholder="mention your organization function here" 
                            width={'50%'}
                            onChange={(event) => {
                                if (event.length <= 255) {
                                    setValue((prev) => ({
                                        ...prev,
                                        OthersTextArea: event,
                                    }));
                                }
                            }}
                            maxLength={255}
                            value={textValue}
                        />
                        <span>{textValue.length}/255 characters</span>
                    </FlexBox>
                )}
            </FlexBox>
        </>
    );
};

export default OrganisationType;
