/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react"
import { personalDeatilstype } from "../../../Authentication/CreateAccount"
import { Avatar, FileUploadArea, FileUploadButton, FlexBox, Label, Button, RangeSlider, Text } from "@filament/react"

import { Edit, Trashcan } from '@filament-icons/react';
import { organisationTypeInterface } from "../../../../components/Context/OrganizationContext"
import Cropper, { Area } from 'react-easy-crop'
import getCroppedImg from "./GetCroppedImage"
import { useUnifiedContexts } from "../../../../components/Context/Context";


const OrgLogo = ({ File,
    croppedImage,
    isEdit,
    setFile,
  
}:
    {
        File: File | boolean | null,
        croppedImage: string,
        isEdit?: boolean,
        setFile?: React.Dispatch<React.SetStateAction<boolean>>
       
    }) => {
    const [details, setDetails] = useState<personalDeatilstype>({
        email: "",
        mobile_number: "",
        first_name: "",
        last_name: "",
        isChecked: true,

    })
    const { orgValues, setOrgValues, crop, setCrop, zoom, setZoom, initalCrop, setInitalCrop,orginitialCropImage,orgsetInitialCropImage } = useUnifiedContexts()


    const croppedAreaPixels = orgValues.croppedAreaPixels
    const [imgurl, setImgUrl] = useState<string | null>(null)
    const normalizeSlider = (event: number | number[]): number => Array.isArray(event) ? event[1] : event

    useEffect(() => {
        if (isEdit === undefined) {
            setOrgValues((prev: organisationTypeInterface) => ({
                ...prev,
                phoneNumber: details.mobile_number,
                imgurl: imgurl!,
                croppedAreaPixels: croppedAreaPixels
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.mobile_number, imgurl])
    useEffect(() => {
        setDetails((prev) => ({
            ...prev,
            mobile_number: orgValues.phoneNumber
        }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (File && typeof File === "object" && "name" in File) {

            setImgUrl(URL.createObjectURL(File))


        } if (orgValues.file) {
            setImgUrl(URL.createObjectURL(orgValues.file))

        }


    }, [File, orgValues.file])


    const showCroppedImage = async () => {
        try {
            let imageSource: string = '';

            // Determine the image source
            if (File && typeof File === 'object' && 'name' in File) {
                // Use the File object and create an Object URL
                imageSource = URL.createObjectURL(File);
            } else if (imgurl) {
                // Use the provided image URL if available
                imageSource = imgurl;
            } else if (orgValues.imgurl) {
                // Fallback to the organization's image URL
                imageSource = orgValues.imgurl;
            }

            if (imageSource && croppedAreaPixels) {

                // Call getCroppedImg with the determined image source
                const croppedImage = await getCroppedImg(imageSource, croppedAreaPixels, 0);

                // Update state with the cropped image
                setOrgValues((prev: organisationTypeInterface) => ({
                    ...prev,
                    croppedImg: croppedImage as string,
                }));
            } else {
                console.warn("No valid image source or cropped area pixels available.");
            }
        } catch (error) {
            console.error("Error while cropping the image:", error);
        }
    };
    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setOrgValues((prev: organisationTypeInterface) => ({
            ...prev,

            croppedAreaPixels: croppedAreaPixels
        }))

    }


    const onFileUpload = (file: FileList | null) => {
        if (file && file.length > 0) {
            if (file && file.length > 0) {
                const selectedFile = file[0];

                // Allowed file types
                const allowedTypes = ['image/svg+xml', 'image/jpeg', 'image/png'];
                // File size limit in bytes (5 MB)
                const maxSize = 5 * 1024 * 1024;

                // Check file type and size
                if (!allowedTypes.includes(selectedFile.type)) {
                    alert('Only SVG, JPG, and PNG files are allowed.');
                    return;
                }

                if (selectedFile.size > maxSize) {
                    alert('File size must be less than 5 MB.');
                    return;
                }

                // Update state if the file is valid
                setOrgValues((prev: organisationTypeInterface) => ({
                    ...prev,
                    file: selectedFile,
                }));
                if (setFile !== undefined) { setFile(true) }

            }

        }

    }

    const handleRemoveLogo = (value: string) => {
        if (setFile !== undefined) { setFile(false) }
        if (orginitialCropImage && orginitialCropImage.length !== 0 && value === 'Cancel') {
            setOrgValues((prev: organisationTypeInterface) => ({
                ...prev,
                croppedImg: orginitialCropImage,
            }))

        }
        else {
            setOrgValues((prev: organisationTypeInterface) => ({
                ...prev,
                file: null,
                croppedImg: '',
                imgurl: ''
            }))
            orgsetInitialCropImage('')
        }

    }


    const handleMediaLoaded = (mediaSize: { naturalWidth: number; naturalHeight: number }) => {



        const cropWidth = 200; // Assume crop area width
        const cropHeight = 200; // Assume crop area height

        const imageWidth = mediaSize.naturalWidth;
        const imageHeight = mediaSize.naturalHeight;

        // Center the image
        const xOffset = (imageWidth - cropWidth) / 2;
        const yOffset = (imageHeight - cropHeight) / 2;

        if (crop.x === initalCrop.x && crop.y === initalCrop.y) {
            setCrop({
                x: initalCrop.x,
                y: initalCrop.y,
            });
        }
        else {
            setCrop({
                x: -xOffset / zoom,
                y: -yOffset / zoom,
            });
            setInitalCrop({
                x: -xOffset / zoom,
                y: -yOffset / zoom,
            });

        }


        let multiplyValue = 1
        if (imageWidth > 50) {
            multiplyValue = 2
        } else {
            multiplyValue = 1.5
        }


        // Optionally adjust zoom if needed
        setZoom(Math.max(cropWidth / imageWidth, cropHeight / imageHeight) * multiplyValue);


    };

    return (
        <FlexBox flexDirection={'column'} gap={24} paddingTop={File ? 10 : 24}>
            {File ? <FlexBox alignItems="center" justifyContent={croppedImage ? 'flex-start' : 'center'} width={'100%'} gap={24}  >
                <div style={{ marginTop: '1rem' }}>
                    {
                        croppedImage && <Avatar width={'140px'} height={'200px'} style={{ backgroundColor: 'transparent', }}>
                            <img src={croppedImage || ''} alt="hotel logo"
                                style={{ borderRadius: '100%', objectFit: 'contain' }}
                            />

                        </Avatar>}

                    {!croppedImage && <><div className="App" >
                        <div className="crop-container" style={{ minWidth: '400px', }} >
                            <Cropper
                                image={imgurl || orgValues.imgurl}
                                crop={crop}
                                rotation={0}
                                zoom={zoom}
                                cropSize={{ width: 200, height: 200 }}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                zoomWithScroll={true}
                                cropShape="round"
                                zoomSpeed={0.1}
                                onMediaLoaded={handleMediaLoaded}
                                objectFit="contain"
                                style={{
                                    containerStyle: { width: '100%', },
                                    cropAreaStyle: { color: '#00000033', display: "flex", alignItems: "center", justifyContent: "center", },
                                }}
                            />

                        </div>
                    </div>  <FlexBox flexDirection="column" alignItems="center" padding={24} paddingTop={8}>
                        <FlexBox justifyContent="space-between" width={'100%'}>
                            <Text>Zoom</Text>
                            <Text>{Math.round(zoom)}</Text>
                        </FlexBox>
                        <RangeSlider maxValue={100}
                            minValue={1}
                            onChange={(event) => setZoom((normalizeSlider(event)))}
                            value={[1, zoom]} />
                    </FlexBox>

                    </>
                    }

                </div>
                {croppedImage && <FlexBox flexDirection={'column'} gap={16} >
                    <Button variant="primary" onPress={() => {
                        orgsetInitialCropImage!(croppedImage);
                        setOrgValues((prev: organisationTypeInterface) => ({
                            ...prev,
                            croppedImg: '',
                        }))
                    }}>
                        <Edit />
                        Edit Logo
                    </Button>
                    <Button onPress={() => handleRemoveLogo('Remove')}><Trashcan />Remove Logo</Button>
                </FlexBox>}
            </FlexBox>
                :
                // eslint-disable-next-line react/jsx-indent
                <FlexBox flexDirection="column" gap={16}>
                    <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                        <Label >Upload Logo</Label>
                        <Label variant={'descriptor'}>(Optional)</Label>
                    </div>
                    <FileUploadArea
                        style={{ gap: '0.5rem', textAlign: 'center' }}
                        onChange={onFileUpload}
                        allowMultipleFiles={false}>
                        Drag and drop the file
                        <br />
                        or
                        <br />
                        <FileUploadButton shape="round">Browse</FileUploadButton>
                    </FileUploadArea>
                </FlexBox>
            }
            {(!croppedImage && File) && <FlexBox gap={8} justifyContent="flex-end">
                <Button variant="quiet" onPress={() => handleRemoveLogo('Cancel')}>Cancel</Button>
                <Button onPress={showCroppedImage}>Apply</Button>
            </FlexBox>}

        </FlexBox>
    )


}


export default OrgLogo