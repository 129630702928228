import React from 'react';
import { Paragraph, Spinner } from '@filament/react';

const Loading = ({ message }: { message: string }) => (
    <div style={{
        display: "flex",
        flexDirection: "column", // Stack spinner & text vertically
        alignItems: "center", // Center horizontally
        justifyContent: "center", // Center vertically
        height: "120%", // Ensures full page height
        width: "100%", // Ensures full width
        textAlign: "center",
        position: "absolute", // Ensures it takes full screen space
        bottom: 0,
        left: 0
    }}>
        <Spinner size="normal" />
        <Paragraph style={{ marginTop: "10px" }}>{message}</Paragraph>
    </div>
);

export default Loading;
