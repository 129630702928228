import React from 'react'
import SiteUploadImages from './SiteUploadImages'
import { FlexBox, Label, ListView, Item, Paragraph, Text, Button, Tooltip } from '@filament/react'
import { SiteData } from '../../../components/Context/SiteContext'
import { CheckmarkCircleFill, Trashcan } from '@filament-icons/react';
import SiteLogo from './SiteLogo';

const SiteImages = () => {
    const { FileList, SetFileList } = SiteData()
    // handleFileSize = (File:File) => {
    //     const sizeInMegabytes = useMemo(() => (File.size / 1_000_000).toFixed(2), [File.size]);
    //     return sizeInMegabytes
    // }

    const HandleDelete = (DeleteIndex: number) => {

        SetFileList(prev => (prev ? prev.filter((_, index) => index !== DeleteIndex) : []));
    };
    return (
        <FlexBox flexDirection='column' gap={24}>
            <FlexBox flexDirection={'column'} gap={16}>
                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                    <Label>Upload Logo</Label>
                    <Label variant={'descriptor'}>(Optional)</Label>
                </div>
                <SiteLogo />
            </FlexBox>

            <FlexBox flexDirection={'column'} gap={16}>
                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                    <Label>Upload Site Images</Label>
                    <Label variant={'descriptor'}>(Optional)</Label>
                </div>
                <SiteUploadImages fileList={FileList || []} SetFileList={SetFileList} />

                <ListView aria-label="list-view" >
                    {[...FileList || []]?.map((File: File, index) => {

                        return <Item key={index} textValue='files' >
                            <FlexBox justifyContent='space-between' width={'100%'}>

                                <FlexBox flexDirection='row' alignItems='center' gap={12}>


                                    <Paragraph color="signalSuccess">
                                        <CheckmarkCircleFill />
                                    </Paragraph>


                                    <div>
                                        {<Tooltip
                                            content={
                                                <div
                                                    style={{
                                                        width: '400px',
                                                        textAlign: 'center',
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {File.name}
                                                </div>
                                            }
                                            placement="bottom"
                                        >
                                            <div
                                                style={{
                                                    width: 300,
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    cursor: 'pointer', // Optional: Indicates interactivity
                                                }}
                                            >
                                                <Text style={{ color: '#0072DB' }}>
                                                    {File.name.length > 20 ? File.name.slice(0,20) + "..." :
                                                        File.name}
                                                </Text>
                                            </div>
                                        </Tooltip>}
                                        <br />
                                        <Text variant='reference-m'
                                            weight="light"> {'('}{(File.size / 1_000_000).toFixed(2) + 'MB'}{')'}</Text>
                                    </div>
                                </FlexBox>
                                <Button variant={'quiet'} onPress={() => HandleDelete(index)}>
                                    <Trashcan />
                                </Button>
                            </FlexBox>
                        </Item>

                    })}
                </ListView>

            </FlexBox>
        </FlexBox>
    )
}

export default SiteImages