/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
    TextField,
    Label,
    FlexBox,
    Avatar,
    Text,
    Card,
    Tooltip,
} from '@filament/react';
import { GoogleApiKey } from "../../../../config";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { A11y, Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SiteData } from "../../../../components/Context/SiteContext";
import { useUnifiedContexts } from '../../../../components/Context/Context';



const mapContainerStyle = {
    width: "100%",
    height: "271px",
    borderRadius: "12px",
};

export default function PreviewSite() {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!, 
    });

    const {siteStructureTopPath} = useUnifiedContexts()
    const { formSiteData, mapSiteValues, croppedImage, FileList } = SiteData();

    // Create a ref to store URLs to cleanup
    const urlsRef = React.useRef<string[]>([]);

    // Create image preview URLs safely
    const [previewUrls, setPreviewUrls] = React.useState<Array<{ key: string, value: string }>>([]);

    React.useEffect(() => {
        // Clean up old URLs
        urlsRef.current.forEach(url => URL.revokeObjectURL(url));

        if (!FileList) {
            setPreviewUrls([]);
            return;
        }

        // Create new URLs
        const urls = FileList.map(file => {
            const url = URL.createObjectURL(file);
            urlsRef.current.push(url);
            return {
                key: file.name,
                value: url
            };
        });

        setPreviewUrls(urls);

        // Cleanup on unmount
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            urlsRef.current.forEach(url => URL.revokeObjectURL(url));
        };
    }, [FileList]);


    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;
    return (
        <>
            <div >
                <Text style={{ fontSize: '20px', fontWeight: '700' }}>Preview</Text>
                <div style={{
                    display: 'flex', justifyContent: 'center', width: '100%',
                    paddingBottom: '12px', paddingTop: '24px',
                }} >
                    {previewUrls.length > 0 ? (
                        <Swiper
                            modules={[Navigation, A11y, Autoplay]}
                            loop={previewUrls.length > 1}
                            autoplay={{ delay: 2000 }}
                            spaceBetween={8}
                            breakpoints={{
                                320: { slidesPerView: 1, spaceBetween: 8 },
                                640: { slidesPerView: Math.min(2, previewUrls.length), spaceBetween: 8 },
                                1085: { slidesPerView: Math.min(3, previewUrls.length), spaceBetween: 8 },
                                1440: { slidesPerView: Math.min(4, previewUrls.length), spaceBetween: 8 },
                            }}
                        >
                            {previewUrls.map((item) => (
                                <SwiperSlide key={item.key}>
                                    <Card>
                                        <img
                                            src={item.value}
                                            alt={item.key}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                borderRadius: '12px',
                                                height: '140px',
                                                objectFit: 'cover'
                                            }}
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement;
                                                target.src = '/placeholder-image.png'; // Add a placeholder image
                                                console.error(`Failed to load image: ${item.key}`);
                                            }}
                                        />
                                    </Card>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <Text variant="reference-m" style={{ textAlign: 'center', paddingBottom: '12px'}}>
                            <img src="/images/No Images Uploaded.svg" alt="Placeholder" 
                                style={{ width: '100%', height: '140px', borderRadius: '12px',  }} />
                        </Text>
                    )}
                </div>

                <FlexBox alignItems="center" height={64}
                    style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                    <Avatar width={'48px'} height={'48px'} style={{ backgroundColor: 'transparent', }}>
                        <img src={croppedImage || '/images/Logo.svg'} alt="Site Avatar" />
                    </Avatar>
                    <FlexBox style={{ paddingLeft: '12px', gap: '8px' }}>
                        <Tooltip
                            content={
                                <div
                                    style={{
                                        width: '400px',
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {formSiteData.siteName || 'No name available'}
                                </div>
                            }
                            placement="bottom"
                        >
                            <div
                                style={{
                                    width: 120,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer', // Optional: Indicates interactivity
                                }}
                            >
                                <Text variant='heading-xs' weight='bold'>{formSiteData.siteName}</Text>
                            </div>
                        </Tooltip>
                        <Tooltip
                            content={
                                <div
                                    style={{
                                        width: '400px',
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {formSiteData.shortName || 'No name available'}
                                </div>
                            }
                            placement="bottom"
                        >
                            <div
                                style={{
                                    width: 120,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer', // Optional: Indicates interactivity
                                }}
                            >
                                <Text style={{ color: '#566676', fontSize: '16px', fontWeight: '350' }}>
                                    {formSiteData.shortName}</Text>
                            </div>
                        </Tooltip>
                    </FlexBox>
                </FlexBox>

                <FlexBox style={{ flexDirection: 'column', gap: "16px" }}>
                    <Label >Site Location</Label>
                    {/* add map component here */}
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={mapSiteValues.zoom}
                        center={mapSiteValues.center}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            keyboardShortcuts: false,
                            draggable: false,
                            scrollwheel: false,
                        }}
                    >
                        <MarkerF
                            position={mapSiteValues.center}
                            cursor='default'
                            icon={{
                                url: "/images/gis_pin.svg",
                                scaledSize: new window.google.maps.Size(40, 40),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(20, 40),
                            }}
                        />
                    </GoogleMap>
                </FlexBox>

                <FlexBox style={{ flexDirection: "column", gap: '24px', paddingTop: '24px' }}>
                    <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                        <Label>Site Address</Label>
                        <TextField
                            value={formSiteData.siteAddress}
                            isFullWidth isReadOnly />
                    </FlexBox>

                    <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                        <Label>Postcode</Label>
                        <TextField value={formSiteData.postcode}
                            isFullWidth isReadOnly />
                    </FlexBox>

                    {/* <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                        <Label>Country</Label>
                        <TextField value='India'
                            isFullWidth isReadOnly />
                    </FlexBox> */}

                    <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                        <Label>Site Structure</Label>
                        <FlexBox style={{ gap: '8px' }}>
                            {siteStructureTopPath?.length===0?<span >Open Structure</span>:
                                siteStructureTopPath.slice(1).map((item,index)=>(
                                    <span
                                        key={index}
                                        aria-label="view and add"
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '1px',
                                            padding: '4px 8px',
                                            borderRadius: '8px',
                                            border: '1px solid',
                                            backgroundColor: '#E6F7FF',
                                            borderColor: '#3182CE',
                                            color: '#3182CE',
                                        }}
                                    >
                                        <span style={{ padding: '0 2px' }}>
                                            {item.split('-').slice(0, -1).join("-").split(" ").
                                                filter(word => isNaN(Number(word))).join(" ")
                                            }</span>
                                    </span>))}

                          
                        </FlexBox>
                    </FlexBox>

                    <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                        <Label>Device Name Structure</Label>
                        <FlexBox style={{ gap: '8px' }}>
                            {siteStructureTopPath.slice(1).map((item,index)=>(< span key={index}>
                                <span
                                    aria-label="view and add"
                                    style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '1px',
                                        padding: '4px 8px',
                                        borderRadius: '8px',
                                        border: '1px solid',
                                        backgroundColor: '#E6F7FF',
                                        borderColor: '#3182CE',
                                        color: '#3182CE',
                                    }}
                                >
                                    <span style={{ padding: '0 2px' }}>
                                        {item.split('-').slice(0, -1).join("-").split(" ").
                                            filter(word => isNaN(Number(word))).join(" ")}</span>
                                </span>

                                <span style={{ marginLeft:"4px",alignContent: 'end' }}>_</span>     
                            </span>)) }       

                            <span
                                aria-label="view and add"
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: '1px',
                                    padding: '4px 8px',
                                    borderRadius: '8px',
                                    border: '1px solid',
                                    borderColor: '#6B8094',
                                    color: '#15191E',
                                }}
                            >
                                <span style={{ padding: '0 2px' }}>Device No</span>
                            </span>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </div>
        </>
    );
}