import {createSlice, PayloadAction} from '@reduxjs/toolkit'


export type initialType = {
    isLoggedIn:boolean,
    organisationIds:string[],
    siteIdToActivate:string,
    loggedInUserId:string,
  
}
const initialState :initialType= {
    isLoggedIn:false,
    organisationIds:[],
    siteIdToActivate:'',
    loggedInUserId:''
    
    
}

const persistedSlice = createSlice({
    name:'persistSlice',
    initialState,
    reducers:{
        handleLoggedInState:(state,action:PayloadAction<boolean>)=>{
            state.isLoggedIn=action.payload
        },
        handleOrgIdList:(state,action:PayloadAction<string[]>)=>{
            state.organisationIds=action.payload
    
        },
        handleSiteIdToActivate:(state, action:PayloadAction<string>)=>{
            state.siteIdToActivate=action.payload
        },
        
    }


})

export const {handleLoggedInState,handleOrgIdList,handleSiteIdToActivate} = persistedSlice.actions
export default persistedSlice.reducer
