import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { FlexBox, Heading } from "@filament/react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContainer,
    DialogContent,
    DialogTitle,
} from "@filament/react";
import { Cross } from "@filament-icons/react";
import { atomicDividerHorizontal as dividerHorizontal } from "@filament/react/styles";
import LevelValueSetter from "./LevelValueSetter";
import * as styles from "../styles/styles.css";
import { useUnifiedContexts } from "../../../../components/Context/Context";
import { Trie } from "./TrieDataStructure";

type PresetType = {
    heading: "Preset 1" | "Preset 2" | "Preset 3" | "Open Structure" | 'Custom site structure';
    footer: string;
    templateUnits: string[];
    setIsCustomSiteClicked:React.Dispatch<React.SetStateAction<'Preset 1'| 
    'Preset 2' | 
    'Preset 3' |
    'Open Structure'|
    'Custom site structure'
     | null>>;
    
};

export type PresetRef = {
    setPresetClicked: () => void;
};

const Preset = forwardRef<PresetRef, PresetType>(({ heading, footer, templateUnits, setIsCustomSiteClicked }, ref) => {  
    const {
        levelValues,setSelectedPreset,setIsNextPreviewButtonCliked,setTrie} = useUnifiedContexts()
    const [isPresetClicked,setIsPresetClicked] = useState<'Preset 1'|
     'Preset 2'|
     'Preset 3'|
     'Open Structure'|
     'Custom site structure'|
     null>(null)

    const [error, setError] = useState<{ isError: boolean; duplicateLabel: string | null }>({
        isError: false,
        duplicateLabel: null,
    });

    // Expose setIsPresetClicked to the parent via ref
    useImperativeHandle(ref, () => ({
        setPresetClicked: () => {setIsPresetClicked(null)},
    }));
    const dismiss = useCallback(() => {
        setIsPresetClicked(null);
    }, [setIsPresetClicked]);

    const isNextDisabled = () => {
        return levelValues.some((item) => item.label === "" || isNaN(item.number_of_units))
    };

    const handlePresetClick = 
        (preset: PresetType["heading"]) => {
            if (isPresetClicked !== preset) {
                setIsPresetClicked(preset);
            }
        }

        
    const validateLevels = () => {
        setError({ isError: false, duplicateLabel: null });
        setIsCustomSiteClicked(isPresetClicked);
        setTrie(new Trie());
    }



    return (
        <>
            <div
                onClick={() => {handlePresetClick(heading);
                    setSelectedPreset(heading);if(heading!=='Open Structure')setIsNextPreviewButtonCliked(false)}}
                style={{
                    display: "flex",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <Heading elementType="h6" variant="heading-xs" style={{ paddingLeft: "10px" }}>
                        {heading}
                    </Heading>
                    <hr style={{ border: "1px solid #CAD2D8" }} />
                </div>
                <FlexBox
                    className={`${styles.presetContainer} ${heading === "Custom site structure" ? 
                        styles.customSiteMargin : ""}`}
                    style={{
                        display: "flex",
                        alignItems:`${templateUnits?.[0] === ""?'center':'' }`,
                        flexDirection: "column",
                        height: "255px",
                        justifyContent: "center",
                        flexGrow: 1,
                    }}
                >
                    {templateUnits?.[0] === "" ? (
                        <Heading style={{ color: "#566676" }} elementType="h6" variant="heading-xs">
                            Open Structure
                        </Heading>
                    ) : (
                        templateUnits.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    height: "45px",
                                    paddingLeft: `${index * 28}px`,
                                }}
                            >
                                {index !== 0 && (
                                    <img src={`/images/arrowRight.png`} width="20px" height="20px" alt={item} />
                                )}
                                <img src={`/images/${item}.png`} width="20px" height="20px" alt={item} />
                                <p style={{ textTransform: "capitalize" }}>{item}</p>
                            </div>
                        ))
                    )}
                </FlexBox>

                {/* Bottom Section */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <hr
                        style={{
                            border: "1px solid #CAD2D8",
                            width: "100%",
                            marginBottom: "10px",
                        }}
                    />
                    <FlexBox
                        style={{
                            backgroundColor: "#00438A0F",
                            height: "32px",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                            borderRadius: "6px",
                            border: "1px solid #0072DB",
                            display: "flex",
                        }}
                    >
                        <p style={{ color: "#0072DB", margin: "0" }}>{footer}</p>
                    </FlexBox>
                </div>
            </div>

            {/* Dialog */}
            <DialogContainer onDismiss={dismiss} isOpen={isPresetClicked !== null && 
                isPresetClicked !== "Open Structure"&&isPresetClicked!=='Custom site structure'} >
                <Dialog width={"590px"} height={"fit-content"} >
                    <FlexBox justifyContent="space-between" alignItems={"center"}>
                        <DialogTitle aria-label="dialogTitle" style={{ marginLeft: "16px", marginTop: "28px" }}>
                            Select Values
                        </DialogTitle>
                        <Button
                            style={{ borderRadius: "10px", marginRight: "22px", marginTop: "22px" }}
                            variant={"quiet"}
                            onPress={dismiss}
                        >
                            <Cross />
                        </Button>
                    </FlexBox>
                    <hr className={dividerHorizontal} />
                    <DialogContent
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <LevelValueSetter levels={templateUnits.slice(1)} error={error} setError={setError}/>
                    </DialogContent>
                    <hr className={dividerHorizontal} />
                    <DialogActions>
                        <Button variant="quiet" onPress={() => {setIsPresetClicked(null)}}>
                            Cancel
                        </Button>
                        <Button variant="primary" 
                            isDisabled={isNextDisabled()}
                            onPress={()=>{validateLevels()
                            }}>
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContainer>
        </>
    );
});

Preset.displayName = "Preset";
export default Preset;
