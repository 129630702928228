import React, { useContext, useRef } from "react"
import { ReactNode, useState } from "react"

export type existingTvType={
    SelectOrganization:string,
    SelectSite:string,
    SelectDevice:string,
}

export type predefindTVType={
    SelectConfiguration:string,
    SelectOrganization:string,
    SelectSite:string,
}

type ConfigarationValues = {
    buttonRef: React.MutableRefObject<HTMLButtonElement | null>
    configTab: string
    setConfigTab: React.Dispatch<React.SetStateAction<string>>
}

const ConfigContext = React.createContext<ConfigarationValues>({
    buttonRef: null!,
    configTab:'',
    setConfigTab:()=>{},
})

export const ConfigContextProvider = ({ children }: { children: ReactNode }) => {
    const [configTab,setConfigTab]=useState<string>('')
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    // eslint-disable-next-line react/jsx-indent
    return(<ConfigContext.Provider value={{
        buttonRef,
        configTab,
        setConfigTab,
    }}>
        {children}
    </ConfigContext.Provider >
    )


}

export const ConfigData=()=>useContext(ConfigContext)

export default ConfigContext

