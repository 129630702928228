import { useOrgData } from "./OrganizationContext";
import { useGlobalData } from "./GlobalContext";
import { SiteData } from "./SiteContext";


export const useUnifiedContexts  = () =>{
    const orgData = useOrgData();
    const globalData = useGlobalData();
    const siteData = SiteData()

    return {...orgData,...globalData,...siteData}
}