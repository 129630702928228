import React, {} from 'react';
import { Default } from './Sampletab';

const UserDrawer = () => {
    return (
        <div>
            {(
                <div>                   
                    <Default/>
                </div>
            )}
        </div>
    );
};

export default UserDrawer;
