/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Alert = {
  __typename?: 'Alert';
  /** undefined */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the alert */
  description: Scalars['String']['output'];
  /** Extra info required for alerts */
  extras: AlertExtras;
  /** UUID of the alert */
  id: Scalars['String']['output'];
  /** Timestamp when the alert was sent */
  sentAt: Scalars['DateTime']['output'];
  /** Status of the alert */
  status: AlertStatus;
  /** UUID of the target (USER) */
  targetId: Scalars['String']['output'];
  /** Title of the alert */
  title: Scalars['String']['output'];
  /** Alert Type */
  type: AlertType;
  /** undefined */
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertExtras = {
  __typename?: 'AlertExtras';
  /** undefined */
  invitationId?: Maybe<Scalars['String']['output']>;
  /** undefined */
  invitationStatus?: Maybe<InvitationStatus>;
  /** List of site information */
  sitesInfo?: Maybe<Array<SiteInfo>>;
};

export enum AlertStatus {
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum AlertType {
  General = 'GENERAL',
  InvitationReceived = 'INVITATION_RECEIVED',
  InvitationStatus = 'INVITATION_STATUS'
}

export type AlertsFilterInput = {
  /** Filter alerts by type */
  alertType?: InputMaybe<AlertType>;
  /** Number of alerts to return */
  limit?: Scalars['Int']['input'];
  /** Pagination offset */
  offset?: Scalars['Int']['input'];
  /** Field to sort by */
  sortBy?: Scalars['String']['input'];
  /** Sort order (asc/desc) */
  sortOrder?: SortOrder;
  /** Filter alerts by status (e.g. UNREAD) */
  status?: InputMaybe<AlertStatus>;
};

export type AlertsResult = {
  __typename?: 'AlertsResult';
  /** List of Alerts. */
  items: Array<Alert>;
  /** Total number of matching filters */
  totalCount: Scalars['Int']['output'];
};

export type Assignment = {
  __typename?: 'Assignment';
  /** undefined */
  action?: Maybe<PrivilegeAssignmentAction>;
  /** undefined */
  at?: Maybe<Scalars['DateTime']['output']>;
  /** undefined */
  by?: Maybe<Scalars['String']['output']>;
};

/** The type of user. */
export enum AuthStatus {
  Auth0AccountCreated = 'AUTH0_ACCOUNT_CREATED',
  Auth0AccountCreationFailed = 'AUTH0_ACCOUNT_CREATION_FAILED',
  Auth0AccountRequested = 'AUTH0_ACCOUNT_REQUESTED'
}

export enum ClaimStatus {
  Claimed = 'CLAIMED',
  Pending = 'PENDING'
}

export type Display = {
  __typename?: 'Display';
  /** undefined */
  claimStatus: ClaimStatus;
  /** undefined */
  ctn: Scalars['String']['output'];
  /** undefined */
  displayName: Scalars['String']['output'];
  /** undefined */
  displayNumber: Scalars['Int']['output'];
  /** undefined */
  id: Scalars['String']['output'];
  /** undefined */
  ipAddress: Scalars['String']['output'];
  /** undefined */
  macAddress: Scalars['String']['output'];
  /** undefined */
  model: Scalars['String']['output'];
  /** undefined */
  organizationId: Scalars['String']['output'];
  /** undefined */
  roomId?: Maybe<Scalars['String']['output']>;
  /** undefined */
  serialNumber: Scalars['String']['output'];
  /** undefined */
  siteId: Scalars['String']['output'];
  /** undefined */
  thingId?: Maybe<Scalars['String']['output']>;
};

export type DisplayClaimInput = {
  ctn: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  displayNumber: Scalars['Int']['input'];
  ipAddress: Scalars['String']['input'];
  macAddress: Scalars['String']['input'];
  model: Scalars['String']['input'];
  roomId?: InputMaybe<Scalars['String']['input']>;
  serialNumber: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  thingId: Scalars['String']['input'];
};

export type DisplaysResult = {
  __typename?: 'DisplaysResult';
  /** undefined */
  items: Array<Display>;
  /** undefined */
  totalCount: Scalars['Int']['output'];
};

export type EntityObj = {
  __typename?: 'EntityObj';
  /** undefined */
  entityId?: Maybe<Scalars['String']['output']>;
  /** undefined */
  entityType?: Maybe<EnumEntity>;
};

export enum EnumEntity {
  AuthUser = 'AuthUser',
  Organization = 'Organization',
  Room = 'Room',
  Site = 'Site',
  SiteStructure = 'SiteStructure',
  User = 'User'
}

export type GetOrganizationUserPrivilegesInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  sortBy?: Scalars['String']['input'];
  sortOrder?: SortOrder;
};

export type GetOrganizationUsersInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** Field to sort by */
  sortBy?: Scalars['String']['input'];
  /** Sort order (asc/desc) */
  sortOrder?: SortOrder;
};

export type GetOrganizationsInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** Field to sort by */
  sortBy?: Scalars['String']['input'];
  /** Sort order (asc/desc) */
  sortOrder?: SortOrder;
};

export type GetSiteDisplaysInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** Field to sort by */
  sortBy?: Scalars['String']['input'];
  /** Sort order (asc/desc) */
  sortOrder?: SortOrder;
};

export type GetSitesInput = {
  /** Number of sites to retrieve per page */
  limit?: Scalars['Int']['input'];
  /** Number of sites to skip for pagination */
  offset?: Scalars['Int']['input'];
  /** Field to sort by */
  sortBy?: Scalars['String']['input'];
  /** Sort order (asc/desc) */
  sortOrder?: SortOrder;
};

export type HealthType = {
  __typename?: 'HealthType';
  /** Status of health */
  status: Scalars['String']['output'];
};

/** ISO Alpha-2 Country Codes */
export enum IsoAlpha2CountryCode {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type Invitation = {
  __typename?: 'Invitation';
  /** Delegate power of the invitation */
  delegatePower: InvitationDelegatePower;
  /** Date of expiry of the privileges */
  expiryOfPrivileges?: Maybe<Scalars['String']['output']>;
  /** Type of expiry for the invitation */
  expiryType: InvitationExpiryType;
  /** UUID of the invitation */
  id: Scalars['String']['output'];
  /** Email address of the invitation */
  inviteeEmail: Scalars['String']['output'];
  /** UUID of the Inviter */
  inviterId: Scalars['String']['output'];
  /** No of users */
  numberOfUsers: Scalars['Int']['output'];
  /** UUID of the organization */
  organizationId: Scalars['String']['output'];
  /** Roles of the invitation */
  roles: Array<Role>;
  /** UUID of the sites */
  siteIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Status of the invitation */
  status: InvitationStatus;
};

export type InvitationAcceptResponse = {
  __typename?: 'InvitationAcceptResponse';
  /** undefined */
  status: InvitationStatus;
};

export type InvitationCreateInput = {
  /** Give Access to All Sites. */
  allSites?: InputMaybe<Scalars['Boolean']['input']>;
  delegatePower: InvitationDelegatePower;
  expiryOfPrivileges?: InputMaybe<Scalars['DateTime']['input']>;
  expiryType: InvitationExpiryType;
  inviteeEmail: Scalars['String']['input'];
  numberOfUsers: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  /** Roles to be assigned */
  roles: Array<Role>;
  /** List of sites the user is associated to. */
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum InvitationDelegatePower {
  External = 'EXTERNAL',
  None = 'NONE',
  Within = 'WITHIN'
}

export enum InvitationExpiryType {
  Infinite = 'INFINITE',
  SpecificDate = 'SPECIFIC_DATE'
}

export type InvitationRejectResponse = {
  __typename?: 'InvitationRejectResponse';
  /** undefined */
  status: InvitationStatus;
};

export type InvitationRetriggerResponse = {
  __typename?: 'InvitationRetriggerResponse';
  /** undefined */
  status: InvitationStatus;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED',
  Sent = 'SENT'
}

export type InvitationUpdateInput = {
  delegatePower?: InputMaybe<InvitationDelegatePower>;
  expiryOfPrivileges?: InputMaybe<Scalars['String']['input']>;
  expiryType?: InputMaybe<InvitationExpiryType>;
  /** Id of the invitation */
  id: Scalars['String']['input'];
  numberOfUsers: Scalars['Int']['input'];
  /** Roles of the invitation */
  roles: Array<Role>;
};

export type InvitationsFilterOptions = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
  /** Field to sort by */
  sortBy?: Scalars['String']['input'];
  /** Sort order (asc/desc) */
  sortOrder?: SortOrder;
  status?: InputMaybe<InvitationStatus>;
};

export type InvitationsResult = {
  __typename?: 'InvitationsResult';
  /** List of invitations in the organization */
  items: Array<Invitation>;
  /** Total number of invitations matching the filters */
  totalCount: Scalars['Int']['output'];
};

export type Media = {
  __typename?: 'Media';
  /** Upload status */
  isUploaded: Scalars['Boolean']['output'];
  /** S3 Key of the file */
  key: Scalars['String']['output'];
  /** Signed URL to upload media. */
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** undefined */
  alertsBulkMarkRead: Array<Alert>;
  /** undefined */
  alertsBulkMarkUnread: Array<Alert>;
  /** Claim a display to a site */
  claimDisplay: Display;
  /** undefined */
  invitationAccept: InvitationAcceptResponse;
  /** undefined */
  invitationCreate: Invitation;
  /** undefined */
  invitationRetrigger: InvitationRetriggerResponse;
  /** undefined */
  invitationRevoke: Invitation;
  /** undefined */
  invitationUpdate: Invitation;
  /** undefined */
  invitationreject: InvitationRejectResponse;
  /** undefined */
  organizationCreate: Organization;
  /** undefined */
  organizationDelete: OrganizationDeleteResponse;
  /** Create Logo Upload URL for Organization */
  organizationLogoCreate: Media;
  /** undefined */
  organizationUpdate: Organization;
  /** Assign a privilege to user */
  privilegeAssign: UserPrivilege;
  /** Edit the privilege of a user */
  privilegeEdit: UserPrivilege;
  /** Reinstate a privilege to user */
  privilegeReinstate: UserPrivilege;
  /** Revoke a privilege to user */
  privilegeRevoke: UserPrivilege;
  /** undefined */
  siteActivate: Site;
  /** undefined */
  siteCreate: Site;
  /** undefined */
  siteDelete: SiteDeleteResponse;
  /** Create images for a site */
  siteImagesCreate: Array<Media>;
  /** Create Logo Upload URL for Site */
  siteLogoCreate: Media;
  /** undefined */
  siteStructureCreate: SiteStructureWithSiteId;
  /** This Mutation will delete node and also the child nodes (if any) */
  siteStructureDelete: SiteNodeDeleteResponse;
  /** undefined */
  siteStructureUpdate: Array<SiteStructure>;
  /** undefined */
  siteUpdate: Site;
  /** Add a new user who is not part of Wave to an Organization. */
  userAdd: User;
  /** Create Avatar Upload URL */
  userAvatarCreate: Media;
  /** Edit a user within the organization. */
  userEdit: User;
};


export type MutationAlertsBulkMarkReadArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationAlertsBulkMarkUnreadArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationClaimDisplayArgs = {
  input: DisplayClaimInput;
};


export type MutationInvitationAcceptArgs = {
  id: Scalars['String']['input'];
};


export type MutationInvitationCreateArgs = {
  input: InvitationCreateInput;
};


export type MutationInvitationRetriggerArgs = {
  id: Scalars['String']['input'];
};


export type MutationInvitationRevokeArgs = {
  id: Scalars['String']['input'];
};


export type MutationInvitationUpdateArgs = {
  input: InvitationUpdateInput;
};


export type MutationInvitationrejectArgs = {
  id: Scalars['String']['input'];
};


export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput;
};


export type MutationOrganizationDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationOrganizationLogoCreateArgs = {
  input: OrgnizationLogoCreateInput;
};


export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


export type MutationPrivilegeAssignArgs = {
  input: UserPrivilegeAssignInput;
};


export type MutationPrivilegeEditArgs = {
  input: UserPrivilegeEditInput;
};


export type MutationPrivilegeReinstateArgs = {
  input: UserPrivilegeReinstateInput;
};


export type MutationPrivilegeRevokeArgs = {
  input: UserPrivilegeRevokeInput;
};


export type MutationSiteActivateArgs = {
  id: Scalars['String']['input'];
  siteStructurePreset?: InputMaybe<SiteStructurePreset>;
};


export type MutationSiteCreateArgs = {
  input: SiteCreateInput;
};


export type MutationSiteDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationSiteImagesCreateArgs = {
  input: SiteImagesCreateInput;
};


export type MutationSiteLogoCreateArgs = {
  input: SiteLogoCreateInput;
};


export type MutationSiteStructureCreateArgs = {
  input: SiteStructureInput;
  levelType: SiteLevelType;
  nodeIds: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};


export type MutationSiteStructureDeleteArgs = {
  ids: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};


export type MutationSiteStructureUpdateArgs = {
  input: Array<SiteStructureUpdateInput>;
  siteId: Scalars['String']['input'];
};


export type MutationSiteUpdateArgs = {
  input: SiteUpdateInput;
};


export type MutationUserAddArgs = {
  input: UserCreateInput;
};


export type MutationUserAvatarCreateArgs = {
  input: UserAvatarCreateInput;
};


export type MutationUserEditArgs = {
  id: Scalars['String']['input'];
  input: UserEditInput;
  organizationId: Scalars['String']['input'];
};

export type NodeOutput = {
  __typename?: 'NodeOutput';
  /** undefined */
  id: Scalars['String']['output'];
  /** undefined */
  label: Scalars['String']['output'];
};

/** Organization Entry Type */
export enum OrgEntry {
  Address = 'Address',
  Duns = 'DUNS',
  Tin = 'TIN'
}

/** The type of Organization. */
export enum OrgType {
  Branch = 'Branch',
  ChainOfHotels = 'ChainOfHotels',
  ClaimerOrInstaller = 'ClaimerOrInstaller',
  ContentManager = 'ContentManager',
  DeviceManager = 'DeviceManager',
  DistributerOrReseller = 'DistributerOrReseller',
  Healthcare = 'Healthcare',
  Hotels = 'Hotels',
  Others = 'Others',
  Retail = 'Retail',
  SystemIntegrator = 'SystemIntegrator'
}

export type Organization = {
  __typename?: 'Organization';
  /** Address of the organization */
  address: Scalars['String']['output'];
  /** City where the organization is located */
  city: Scalars['String']['output'];
  /** Country where the organization is located */
  country: IsoAlpha2CountryCode;
  /** undefined */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created this organization. */
  createdBy: UserWithEmail;
  /** The D-U-N-S identifier for the organization. */
  dunsNumber?: Maybe<Scalars['String']['output']>;
  /** Email address of the organization */
  email: Scalars['String']['output'];
  /** Entry type of the organization */
  entryType: OrgEntry;
  /** UUID of the organization */
  id: Scalars['String']['output'];
  /** List of Invitations in the organization */
  invitations: InvitationsResult;
  /** Logo of the organization */
  logo?: Maybe<Media>;
  /** Name of the organization */
  name: Scalars['String']['output'];
  /** undefined */
  orgLatitude?: Maybe<Scalars['Float']['output']>;
  /** undefined */
  orgLongitude?: Maybe<Scalars['Float']['output']>;
  /** Type of the organization */
  orgType: Array<OrgType>;
  /** OrgType incase of Others */
  otherOrgType?: Maybe<Scalars['String']['output']>;
  /** The country code of the phoneNumber. */
  phoneCode: Scalars['String']['output'];
  /** Phone number of the organization */
  phonenumber: Scalars['String']['output'];
  /** Postal code of the organization */
  postCode: Scalars['String']['output'];
  /** List of all role groups and their associated privileges. */
  rolesAndPrivileges: Array<RolesAndPrivileges>;
  /** List of sites in an organization */
  sites?: Maybe<SitesResult>;
  /** The VAT/Tax Identification identifier for the organization. */
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>;
  /** undefined */
  updatedAt: Scalars['DateTime']['output'];
  /** List of users in an organization */
  users?: Maybe<UsersResult>;
  /** Website of the organization */
  website: Scalars['String']['output'];
};


export type OrganizationInvitationsArgs = {
  filters: InvitationsFilterOptions;
};


export type OrganizationSitesArgs = {
  input: GetSitesInput;
};


export type OrganizationUsersArgs = {
  input: GetOrganizationUsersInput;
};

export type OrganizationCreateInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: IsoAlpha2CountryCode;
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  entryType: OrgEntry;
  name: Scalars['String']['input'];
  orgLatitude?: InputMaybe<Scalars['Float']['input']>;
  orgLongitude?: InputMaybe<Scalars['Float']['input']>;
  orgType: Array<OrgType>;
  otherOrgType?: InputMaybe<Scalars['String']['input']>;
  phoneCode: Scalars['String']['input'];
  phonenumber: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
  website: Scalars['String']['input'];
};

export type OrganizationDeleteResponse = {
  __typename?: 'OrganizationDeleteResponse';
  /** The ID of the organization to be deleted. */
  id: Scalars['String']['output'];
};

export type OrganizationUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<IsoAlpha2CountryCode>;
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entryType?: InputMaybe<OrgEntry>;
  /** ID of organization to be updated */
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgLatitude?: InputMaybe<Scalars['Float']['input']>;
  orgLongitude?: InputMaybe<Scalars['Float']['input']>;
  orgType?: InputMaybe<Array<OrgType>>;
  otherOrgType?: InputMaybe<Scalars['String']['input']>;
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  phonenumber?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationsResult = {
  __typename?: 'OrganizationsResult';
  /** List of organizations */
  items: Array<Organization>;
  /** Total number of organizations matching the filters */
  totalCount: Scalars['Int']['output'];
};

export type OrgnizationLogoCreateInput = {
  /** Organization ID */
  id: Scalars['String']['input'];
};

export type Privilege = {
  __typename?: 'Privilege';
  /** undefined */
  assignmentHistory?: Maybe<Array<Assignment>>;
  /** undefined */
  currentPolicyId?: Maybe<Scalars['String']['output']>;
  /** undefined */
  resource?: Maybe<EntityObj>;
  /** undefined */
  role?: Maybe<Role>;
  /** undefined */
  status?: Maybe<PrivilegeStatus>;
};

export enum PrivilegeAssignmentAction {
  Assigned = 'ASSIGNED',
  Invitation = 'INVITATION',
  Modified = 'MODIFIED',
  Pending = 'PENDING',
  Reinstated = 'REINSTATED',
  Revoked = 'REVOKED'
}

export enum PrivilegeStatus {
  Assigned = 'ASSIGNED',
  Expired = 'EXPIRED',
  FailedAssignment = 'FAILED_ASSIGNMENT',
  FailedReinstatement = 'FAILED_REINSTATEMENT',
  FailedRevocation = 'FAILED_REVOCATION',
  InvitationPending = 'INVITATION_PENDING',
  InvitationRejected = 'INVITATION_REJECTED',
  InvitationRevoked = 'INVITATION_REVOKED',
  PendingAssignment = 'PENDING_ASSIGNMENT',
  PendingDeletion = 'PENDING_DELETION',
  PendingReinstatement = 'PENDING_REINSTATEMENT',
  PendingRevocation = 'PENDING_REVOCATION',
  Revoked = 'REVOKED'
}

export type Query = {
  __typename?: 'Query';
  /** Get a display by id */
  display: Display;
  /** Search Org Details based on DUNs */
  duns: Scalars['JSON']['output'];
  /** Returns health of gql server! */
  healthCheck: HealthType;
  /** Organization with ID. */
  organization: Organization;
  /** List of organizations of the current user. */
  organizations: OrganizationsResult;
  /** List of all the roles/privileges available */
  roles?: Maybe<Array<RolesInfo>>;
  /** Get a site by its ID. */
  site: Site;
  /** Get a user by id */
  user: User;
  /** Validate VAT/Tax Identification Number */
  validateVAT: VatInfo;
};


export type QueryDisplayArgs = {
  id: Scalars['String']['input'];
};


export type QueryDunsArgs = {
  number: Scalars['String']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationsArgs = {
  input: GetOrganizationsInput;
};


export type QuerySiteArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidateVatArgs = {
  countryCode: IsoAlpha2CountryCode;
  vatId: Scalars['String']['input'];
};

export enum Role {
  /** Basic user with read and update privileges. */
  Default = 'Default',
  /** Administers organization settings and user invitations. */
  OrganizationAdmin = 'OrganizationAdmin',
  /** Manages organization ownership and transfers. */
  OrganizationOwner = 'OrganizationOwner',
  /** Can view organization ownership details. */
  OrganizationOwnerView = 'OrganizationOwnerView',
  /** Has read access to organization details. */
  OrganizationUser = 'OrganizationUser',
  /** Can unclaim site structures across all resources. */
  Ppds = 'PPDS',
  /** Manages site settings, users, and site-level actions. */
  SiteAdmin = 'SiteAdmin',
  /** Can create new sites within an organization. */
  SiteCreator = 'SiteCreator',
  /** Manages site ownership and transfers. */
  SiteOwner = 'SiteOwner',
  /** Administers site structures, including creation and updates. */
  SiteStructureAdmin = 'SiteStructureAdmin',
  /** Can claim site structures across multiple entities. */
  SiteStructureClaim = 'SiteStructureClaim',
  /** Has read-only access to site structure details. */
  SiteStructureView = 'SiteStructureView',
  /** Has read-only access to site details and ownership information. */
  SiteView = 'SiteView',
  /** Manages users, including creation, updates, and invitations. */
  UserAdmin = 'UserAdmin',
  /** Approves or rejects user invitations. */
  UserInviteApprover = 'UserInviteApprover',
  /** Can invite and revoke users within an organization. */
  UserInviter = 'UserInviter'
}

export type RoleGroup = {
  __typename?: 'RoleGroup';
  /** The name of the role group. */
  name: Scalars['String']['output'];
  /** The list of roles in this group. */
  roles: Array<Role>;
};

export enum RolePrivilege {
  FullAccess = 'FullAccess',
  FullAccessOwnership = 'FullAccessOwnership',
  SiteManagement = 'SiteManagement',
  UserManagement = 'UserManagement'
}

export type RolesAndPrivileges = {
  __typename?: 'RolesAndPrivileges';
  /** The privilege associated with the role group. */
  privilege: RolePrivilege;
  /** The list of role groups associated with this privilege. */
  roleGroups: Array<RoleGroup>;
};

export type RolesInfo = {
  __typename?: 'RolesInfo';
  /** undefined */
  description: Scalars['String']['output'];
  /** undefined */
  name: Scalars['String']['output'];
};

export type Site = {
  __typename?: 'Site';
  /** Address of the site */
  address: Scalars['String']['output'];
  /** City where the site is located */
  city: Scalars['String']['output'];
  /** Country where the site is located */
  country: IsoAlpha2CountryCode;
  /** undefined */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created this Site. */
  createdBy: UserWithEmail;
  /** List of displays under a site */
  displays?: Maybe<DisplaysResult>;
  /** UUID of the site */
  id: Scalars['ID']['output'];
  /** Latitude of the site */
  latitude: Scalars['Float']['output'];
  /** License for this site. */
  licenseType: Scalars['String']['output'];
  /** Logo of the site */
  logo?: Maybe<Media>;
  /** Longitude of the site */
  longitude: Scalars['Float']['output'];
  /** Name of the site */
  name: Scalars['String']['output'];
  /** UUID of the organization to which the site belongs */
  organizationId: Scalars['String']['output'];
  /** Postal code of the site */
  postcode: Scalars['String']['output'];
  /** Short Name of the site */
  shortName?: Maybe<Scalars['String']['output']>;
  /** Images of the site */
  siteImages?: Maybe<Array<Media>>;
  /** Site Structure associated with this site */
  siteStructure?: Maybe<SiteStructureWithSiteId>;
  /** Site Structure Preset info */
  siteStructurePreset?: Maybe<SiteStructurePreset>;
  /** State where the site is located */
  state: Scalars['String']['output'];
  /** Timezone of the site */
  timezone?: Maybe<Scalars['String']['output']>;
  /** Total number of devices this site. */
  totalDevices: Scalars['Int']['output'];
  /** Total number of users this site. */
  totalUsers: Scalars['Int']['output'];
  /** undefined */
  updatedAt: Scalars['DateTime']['output'];
  /** WIFI Password */
  wifiPassword?: Maybe<Scalars['String']['output']>;
  /** WIFI SSID */
  wifiSSID?: Maybe<Scalars['String']['output']>;
};


export type SiteDisplaysArgs = {
  input: GetSiteDisplaysInput;
};


export type SiteSiteStructureArgs = {
  searchInput: SiteStructureSearchInput;
};

export type SiteCreateInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: IsoAlpha2CountryCode;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  shortName?: InputMaybe<Scalars['String']['input']>;
  siteStructurePreset?: InputMaybe<SiteStructurePreset>;
  state: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type SiteDeleteResponse = {
  __typename?: 'SiteDeleteResponse';
  /** The identifier of the site deleted. */
  id: Scalars['String']['output'];
};

export type SiteImagesCreateInput = {
  /** Number of new images to upload */
  count?: InputMaybe<Scalars['Int']['input']>;
  /** Site ID */
  id: Scalars['String']['input'];
  /** Keys to delete/Images to be removed */
  keysToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SiteInfo = {
  __typename?: 'SiteInfo';
  /** undefined */
  address: Scalars['String']['output'];
  /** undefined */
  logo?: Maybe<Scalars['String']['output']>;
  /** undefined */
  name: Scalars['String']['output'];
};

/** At what level nodes to be inserted children/sibling level */
export enum SiteLevelType {
  Children = 'children',
  Siblings = 'siblings'
}

export type SiteLogoCreateInput = {
  /** Site ID */
  id: Scalars['String']['input'];
};

export type SiteNodeDeleteResponse = {
  __typename?: 'SiteNodeDeleteResponse';
  /** The identifiers of the Nodes deleted. */
  ids: Array<Scalars['String']['output']>;
};

export type SiteStructure = {
  __typename?: 'SiteStructure';
  /** undefined */
  createdAt: Scalars['DateTime']['output'];
  /** Parent Id's from top to bottom */
  hierarchy: Array<Scalars['String']['output']>;
  /** The unique identifier for the node */
  id: Scalars['String']['output'];
  /** Is it the room node */
  isEndNode: Scalars['Boolean']['output'];
  /** Is it the Root/Site node */
  isRootNode: Scalars['Boolean']['output'];
  /** Optional label for the child nodes in the structure. */
  label: Scalars['String']['output'];
  /** The format of labels used within the site structure. */
  labelFormat?: Maybe<SiteStructureLabelFormat>;
  /** The identifier for the site to which this structure belongs. */
  siteId: Scalars['String']['output'];
  /** undefined */
  updatedAt: Scalars['DateTime']['output'];
};

export type SiteStructureInput = {
  /** Number of child nodes to create */
  count: Scalars['Int']['input'];
  /** Is it the room/end node */
  isEndNode?: Scalars['Boolean']['input'];
  /** Label for the child nodes in the structure. */
  label: Scalars['String']['input'];
  /** The format of labels used within the site structure. */
  labelFormat: SiteStructureLabelFormat;
};

/** Site Structure Label Format */
export enum SiteStructureLabelFormat {
  Option_1_2_3 = 'Option_1_2_3',
  OptionABC = 'Option_A_B_C'
}

export type SiteStructureOutput = {
  __typename?: 'SiteStructureOutput';
  /** Parent Id's from top to bottom */
  hierarchy: Array<Scalars['String']['output']>;
  /** Is it the room/end node */
  isEndNode: Scalars['Boolean']['output'];
  /** Is it the root/site node */
  isRootNode: Scalars['Boolean']['output'];
  /** The format of labels used within the site structure. */
  labelFormat?: Maybe<SiteStructureLabelFormat>;
  /** undefined */
  nodes: Array<NodeOutput>;
};

/** Site Structure Preset Info */
export enum SiteStructurePreset {
  Custom = 'CUSTOM',
  Preset1 = 'PRESET1',
  Preset2 = 'PRESET2',
  Preset3 = 'PRESET3'
}

export type SiteStructureSearchInput = {
  /** List of ids to search for in hierarchy */
  hierarchy?: InputMaybe<Array<Scalars['String']['input']>>;
  /** IDs needed to be retrived */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SiteStructureUpdateInput = {
  /** Parent Id's from bottom to top */
  hierarchy?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The identifier of the node */
  id: Scalars['String']['input'];
  /** Is it the room node */
  isEndNode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Label for the child nodes in the structure. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** The format of labels used within the site structure. */
  labelFormat?: InputMaybe<SiteStructureLabelFormat>;
};

export type SiteStructureWithSiteId = {
  __typename?: 'SiteStructureWithSiteId';
  /** undefined */
  siteId: Scalars['String']['output'];
  /** undefined */
  siteStructure: Array<SiteStructureOutput>;
};

export type SiteUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<IsoAlpha2CountryCode>;
  /** ID of site to be updated */
  id: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  siteStructurePreset?: InputMaybe<SiteStructurePreset>;
  state?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type SitesResult = {
  __typename?: 'SitesResult';
  /** List of sites in the organization */
  items: Array<Site>;
  /** Total number of sites matching the filters */
  totalCount: Scalars['Int']['output'];
};

/** Enum representing sorting order: descending or ascending */
export enum SortOrder {
  /** Sort in ascending order */
  Asc = 'asc',
  /** Sort in descending order */
  Desc = 'desc'
}

export type User = {
  __typename?: 'User';
  /** undefined */
  alerts: AlertsResult;
  /** The authentication status of the user. */
  authStatus?: Maybe<AuthStatus>;
  /** Avatar of the user */
  avatar?: Maybe<Media>;
  /** undefined */
  createdAt: Scalars['DateTime']['output'];
  /** The department of the user. */
  department?: Maybe<Scalars['String']['output']>;
  /** The email address of the user. */
  email: Scalars['String']['output'];
  /** Does the user needs to enableMFA */
  enableMFA: Scalars['Boolean']['output'];
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** uuid for user */
  id: Scalars['String']['output'];
  /** The job title of the user. */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** The last login time of the user. */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The organization of the user. */
  organizationId?: Maybe<Scalars['String']['output']>;
  /** The country code of the phoneNumber. */
  phoneCode?: Maybe<Scalars['String']['output']>;
  /** The phone number of the user. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The Preferred Language of the user */
  preferredLanguage: Scalars['String']['output'];
  /** List of all privileges the user have, under the organization */
  privileges?: Maybe<Array<UserPrivilege>>;
  /** The salutation of the user. */
  salutation?: Maybe<Scalars['String']['output']>;
  /** List of sites a user has access for. */
  sites?: Maybe<SitesResult>;
  /** The auth0 id from auth service */
  sub?: Maybe<Scalars['String']['output']>;
  /** undefined */
  updatedAt: Scalars['DateTime']['output'];
};


export type UserAlertsArgs = {
  input: AlertsFilterInput;
};


export type UserPrivilegesArgs = {
  input: GetOrganizationUserPrivilegesInput;
};


export type UserSitesArgs = {
  input: GetSitesInput;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserAvatarCreateInput = {
  /** User ID */
  id: Scalars['String']['input'];
};

export type UserCreateInput = {
  /** The department of the user. */
  department?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the new user. An invitation e-mail will be sent to this address. */
  email: Scalars['String']['input'];
  /** Does the user needs to enableMFA */
  enableMFA: Scalars['Boolean']['input'];
  /** Privilege expiry time */
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The first name of the user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The job title of the user. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The orignization of the user. */
  organizationId: Scalars['String']['input'];
  /** The country code of the phoneNumber. */
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the user. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The Preferred Language of the user */
  preferredLanguage: Scalars['String']['input'];
  /** Privileges to be assigned */
  rolePrivileges?: InputMaybe<Array<RolePrivilege>>;
  /** Roles to be assigned */
  roles?: InputMaybe<Array<Role>>;
  /** The salutation of the user. */
  salutation?: InputMaybe<Scalars['String']['input']>;
  /** List of sites the user is associated to. */
  sites?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserEditInput = {
  /** The department of the user. */
  department?: InputMaybe<Scalars['String']['input']>;
  /** Does the user needs to enableMFA */
  enableMFA?: InputMaybe<Scalars['Boolean']['input']>;
  /** The first name of the user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The job title of the user. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The country code of the phoneNumber. */
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the user. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The preferred language of the user. */
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  /** List of privilege for the user. */
  privileges?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The salutation of the user. */
  salutation?: InputMaybe<Scalars['String']['input']>;
  /** List of sites the user is associated to. */
  sites?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** List of all privileges the user have, under the organization */
export type UserPrivilege = {
  __typename?: 'UserPrivilege';
  /** undefined */
  allSites?: Maybe<Scalars['Boolean']['output']>;
  /** undefined */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** undefined */
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** undefined */
  id: Scalars['String']['output'];
  /** undefined */
  invitationId?: Maybe<Scalars['String']['output']>;
  /** undefined */
  organizationId?: Maybe<Scalars['String']['output']>;
  /** undefined */
  organizationName?: Maybe<Scalars['String']['output']>;
  /** undefined */
  privileges?: Maybe<Array<Privilege>>;
  /** undefined */
  rolePrivileges?: Maybe<Array<RolePrivilege>>;
  /** undefined */
  roles: Array<Role>;
  /** undefined */
  siteIds?: Maybe<Array<Scalars['String']['output']>>;
  /** undefined */
  status: PrivilegeAssignmentAction;
  /** undefined */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** undefined */
  userId: Scalars['String']['output'];
  /** undefined */
  userSub: Scalars['String']['output'];
};

export type UserPrivilegeAssignInput = {
  allSites?: InputMaybe<Scalars['Boolean']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizationId: Scalars['String']['input'];
  rolePrivileges?: InputMaybe<Array<RolePrivilege>>;
  roles?: InputMaybe<Array<Role>>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};

export type UserPrivilegeEditInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UserPrivilege ID */
  id: Scalars['String']['input'];
  /** List of privileges to be updated to */
  updatedRolePrivileges?: InputMaybe<Array<RolePrivilege>>;
  /** List of roles to be updated to */
  updatedRoles?: InputMaybe<Array<Role>>;
};

export type UserPrivilegeReinstateInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UserPrivilege ID */
  id: Scalars['String']['input'];
};

export type UserPrivilegeRevokeInput = {
  /** UserPrivilege ID */
  id: Scalars['String']['input'];
};

export type UserRead = {
  __typename?: 'UserRead';
  /** The authentication status of the user. */
  authStatus?: Maybe<AuthStatus>;
  /** undefined */
  createdAt: Scalars['DateTime']['output'];
  /** The department of the user. */
  department?: Maybe<Scalars['String']['output']>;
  /** Does the user needs to enableMFA */
  enableMFA: Scalars['Boolean']['output'];
  /** The full name of the user. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** uuid for user */
  id: Scalars['String']['output'];
  /** The job title of the user. */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** The last login time of the user. */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** The organization of the user. */
  organizationId?: Maybe<Scalars['String']['output']>;
  /** The Preferred Language of the user */
  preferredLanguage: Scalars['String']['output'];
  /** The salutation of the user. */
  salutation?: Maybe<Scalars['String']['output']>;
  /** The auth0 id from auth service */
  sub?: Maybe<Scalars['String']['output']>;
  /** undefined */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserWithEmail = {
  __typename?: 'UserWithEmail';
  /** undefined */
  email: Scalars['String']['output'];
  /** undefined */
  id: Scalars['String']['output'];
};

export type UsersResult = {
  __typename?: 'UsersResult';
  /** List of users in the organization */
  items: Array<UserRead>;
  /** Total number of users matching the filters */
  totalCount: Scalars['Int']['output'];
};

export type VatInfo = {
  __typename?: 'VATInfo';
  /** Entity Address */
  address?: Maybe<Scalars['String']['output']>;
  /** Entity name */
  name?: Maybe<Scalars['String']['output']>;
  /** Is a valid VAT or not */
  valid: Scalars['Boolean']['output'];
};

export type SiteStructureUpdateMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  input: Array<SiteStructureUpdateInput> | SiteStructureUpdateInput;
}>;


export type SiteStructureUpdateMutation = { __typename?: 'Mutation', siteStructureUpdate: Array<{ __typename?: 'SiteStructure', id: string, siteId: string, label: string, hierarchy: Array<string>, labelFormat?: SiteStructureLabelFormat | null, isEndNode: boolean, isRootNode: boolean }> };

export type SiteActivateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  siteStructurePreset?: InputMaybe<SiteStructurePreset>;
}>;


export type SiteActivateMutation = { __typename?: 'Mutation', siteActivate: { __typename?: 'Site', id: string, name: string, createdAt: any, updatedAt: any, licenseType: string, totalDevices: number, totalUsers: number } };

export type OrganizationCreateMutationVariables = Exact<{
  input: OrganizationCreateInput;
}>;


export type OrganizationCreateMutation = { __typename?: 'Mutation', organizationCreate: { __typename?: 'Organization', id: string, name: string, address: string, city: string, country: IsoAlpha2CountryCode, email: string, entryType: OrgEntry, orgLatitude?: number | null, orgLongitude?: number | null, orgType: Array<OrgType>, otherOrgType?: string | null, phoneCode: string, phonenumber: string, postCode: string, website: string, createdBy: { __typename?: 'UserWithEmail', id: string } } };

export type OrganizationLogoCreateMutationVariables = Exact<{
  input: OrgnizationLogoCreateInput;
}>;


export type OrganizationLogoCreateMutation = { __typename?: 'Mutation', organizationLogoCreate: { __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null } };

export type SiteCreateMutationVariables = Exact<{
  input: SiteCreateInput;
}>;


export type SiteCreateMutation = { __typename?: 'Mutation', siteCreate: { __typename?: 'Site', id: string, name: string, shortName?: string | null, address: string, city: string, state: string, country: IsoAlpha2CountryCode, postcode: string, longitude: number, latitude: number, organizationId: string, timezone?: string | null, wifiSSID?: string | null, wifiPassword?: string | null, createdAt: any, updatedAt: any, licenseType: string, totalDevices: number } };

export type SiteImagesCreateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
  keysToRemove?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type SiteImagesCreateMutation = { __typename?: 'Mutation', siteImagesCreate: Array<{ __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null }> };

export type SiteLogoCreateMutationVariables = Exact<{
  input: SiteLogoCreateInput;
}>;


export type SiteLogoCreateMutation = { __typename?: 'Mutation', siteLogoCreate: { __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null } };

export type UserAddMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type UserAddMutation = { __typename?: 'Mutation', userAdd: { __typename?: 'User', id: string } };

export type PrivilegeAssignMutationVariables = Exact<{
  input: UserPrivilegeAssignInput;
}>;


export type PrivilegeAssignMutation = { __typename?: 'Mutation', privilegeAssign: { __typename?: 'UserPrivilege', id: string, userId: string, userSub: string, organizationId?: string | null, siteIds?: Array<string> | null } };

export type SiteStructureCreateMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  nodeIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  levelType: SiteLevelType;
  input: SiteStructureInput;
}>;


export type SiteStructureCreateMutation = { __typename?: 'Mutation', siteStructureCreate: { __typename?: 'SiteStructureWithSiteId', siteId: string, siteStructure: Array<{ __typename?: 'SiteStructureOutput', hierarchy: Array<string>, labelFormat?: SiteStructureLabelFormat | null, isEndNode: boolean, isRootNode: boolean, nodes: Array<{ __typename?: 'NodeOutput', id: string, label: string }> }> } };

export type SiteStructureDeleteMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  siteId: Scalars['String']['input'];
}>;


export type SiteStructureDeleteMutation = { __typename?: 'Mutation', siteStructureDelete: { __typename?: 'SiteNodeDeleteResponse', ids: Array<string> } };

export type SiteUpdateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<IsoAlpha2CountryCode>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  siteStructurePreset?: InputMaybe<SiteStructurePreset>;
}>;


export type SiteUpdateMutation = { __typename?: 'Mutation', siteUpdate: { __typename?: 'Site', id: string, createdBy: { __typename?: 'UserWithEmail', id: string, email: string } } };

export type UpdateOrganizationMutationVariables = Exact<{
  input: OrganizationUpdateInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', organizationUpdate: { __typename?: 'Organization', id: string, orgType: Array<OrgType>, otherOrgType?: string | null, name: string, entryType: OrgEntry, dunsNumber?: string | null, country: IsoAlpha2CountryCode, address: string, orgLatitude?: number | null, orgLongitude?: number | null, city: string, postCode: string, website: string, phonenumber: string, email: string, createdBy: { __typename?: 'UserWithEmail', id: string, email: string }, logo?: { __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null } | null } };

export type EditUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  input: UserEditInput;
}>;


export type EditUserMutation = { __typename?: 'Mutation', userEdit: { __typename?: 'User', id: string } };

export type HealthCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type HealthCheckQuery = { __typename?: 'Query', healthCheck: { __typename?: 'HealthType', status: string } };

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoggedInUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, sub?: string | null, organizationId?: string | null } };

export type GetOrganizationsQueryVariables = Exact<{
  input: GetOrganizationsInput;
}>;


export type GetOrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'OrganizationsResult', totalCount: number, items: Array<{ __typename?: 'Organization', id: string, orgType: Array<OrgType>, otherOrgType?: string | null, name: string, entryType: OrgEntry, dunsNumber?: string | null, country: IsoAlpha2CountryCode, address: string, orgLatitude?: number | null, orgLongitude?: number | null, city: string, postCode: string, website: string, phonenumber: string, phoneCode: string, email: string, createdAt: any, updatedAt: any }> } };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, orgType: Array<OrgType>, otherOrgType?: string | null, name: string, entryType: OrgEntry, dunsNumber?: string | null, country: IsoAlpha2CountryCode, address: string, orgLatitude?: number | null, orgLongitude?: number | null, city: string, postCode: string, website: string, phonenumber: string, phoneCode: string, email: string, createdAt: any, updatedAt: any } };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', roles?: Array<{ __typename?: 'RolesInfo', name: string, description: string }> | null };

export type GetOrganizationSitesQueryVariables = Exact<{
  id: Scalars['String']['input'];
  input: GetSitesInput;
}>;


export type GetOrganizationSitesQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', name: string, sites?: { __typename?: 'SitesResult', totalCount: number, items: Array<{ __typename?: 'Site', id: string, name: string, shortName?: string | null, address: string, city: string, state: string, country: IsoAlpha2CountryCode, postcode: string, longitude: number, latitude: number, organizationId: string, timezone?: string | null, wifiSSID?: string | null, wifiPassword?: string | null, createdAt: any, updatedAt: any, licenseType: string, totalDevices: number, totalUsers: number }> } | null } };

export type SiteRootIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
  searchInput: SiteStructureSearchInput;
}>;


export type SiteRootIdQuery = { __typename?: 'Query', site: { __typename?: 'Site', siteStructure?: { __typename?: 'SiteStructureWithSiteId', siteId: string, siteStructure: Array<{ __typename?: 'SiteStructureOutput', hierarchy: Array<string>, labelFormat?: SiteStructureLabelFormat | null, isEndNode: boolean, isRootNode: boolean, nodes: Array<{ __typename?: 'NodeOutput', id: string, label: string }> }> } | null } };

export type GetSiteDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSiteDataQuery = { __typename?: 'Query', site: { __typename?: 'Site', id: string, name: string, shortName?: string | null, address: string, city: string, state: string, country: IsoAlpha2CountryCode, postcode: string, longitude: number, latitude: number, siteStructurePreset?: SiteStructurePreset | null, organizationId: string, timezone?: string | null, wifiSSID?: string | null, wifiPassword?: string | null, createdAt: any, updatedAt: any, siteImages?: Array<{ __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null }> | null, logo?: { __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null } | null, siteStructure?: { __typename?: 'SiteStructureWithSiteId', siteId: string, siteStructure: Array<{ __typename?: 'SiteStructureOutput', hierarchy: Array<string>, labelFormat?: SiteStructureLabelFormat | null, isEndNode: boolean, isRootNode: boolean, nodes: Array<{ __typename?: 'NodeOutput', id: string, label: string }> }> } | null } };

export type GetOrganizationsUsersQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
}>;


export type GetOrganizationsUsersQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', name: string, users?: { __typename?: 'UsersResult', totalCount: number, items: Array<{ __typename?: 'UserRead', id: string, sub?: string | null, fullName?: string | null, preferredLanguage: string, organizationId?: string | null, jobTitle?: string | null, department?: string | null, salutation?: string | null, enableMFA: boolean, authStatus?: AuthStatus | null, lastLogin?: any | null, createdAt: any, updatedAt: any }> } | null } };

export type GetUserSitesQueryVariables = Exact<{
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  input: GetSitesInput;
}>;


export type GetUserSitesQuery = { __typename?: 'Query', user: { __typename?: 'User', sites?: { __typename?: 'SitesResult', totalCount: number, items: Array<{ __typename?: 'Site', id: string, name: string, shortName?: string | null, address: string, country: IsoAlpha2CountryCode, organizationId: string, siteStructurePreset?: SiteStructurePreset | null, timezone?: string | null, licenseType: string, totalDevices: number, totalUsers: number, logo?: { __typename?: 'Media', key: string, isUploaded: boolean, url?: string | null } | null }> } | null } };

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, sub?: string | null, phoneNumber?: string | null, phoneCode?: string | null, preferredLanguage: string, organizationId?: string | null, jobTitle?: string | null, department?: string | null, salutation?: string | null, enableMFA: boolean, authStatus?: AuthStatus | null, lastLogin?: any | null, createdAt: any, updatedAt: any, privileges?: Array<{ __typename?: 'UserPrivilege', id: string, userId: string, userSub: string, organizationId?: string | null, organizationName?: string | null, siteIds?: Array<string> | null, allSites?: boolean | null, roles: Array<Role>, status: PrivilegeAssignmentAction, rolePrivileges?: Array<RolePrivilege> | null, expiresAt?: any | null, createdAt?: any | null, updatedAt?: any | null }> | null } };


export const SiteStructureUpdateDocument = gql`
    mutation SiteStructureUpdate($siteId: String!, $input: [SiteStructureUpdateInput!]!) {
  siteStructureUpdate(siteId: $siteId, input: $input) {
    id
    siteId
    label
    hierarchy
    labelFormat
    isEndNode
    isRootNode
  }
}
    `;
export type SiteStructureUpdateMutationFn = Apollo.MutationFunction<SiteStructureUpdateMutation, SiteStructureUpdateMutationVariables>;

/**
 * __useSiteStructureUpdateMutation__
 *
 * To run a mutation, you first call `useSiteStructureUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteStructureUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteStructureUpdateMutation, { data, loading, error }] = useSiteStructureUpdateMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteStructureUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SiteStructureUpdateMutation, SiteStructureUpdateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteStructureUpdateMutation, SiteStructureUpdateMutationVariables>(SiteStructureUpdateDocument, options);
}
export type SiteStructureUpdateMutationHookResult = ReturnType<typeof useSiteStructureUpdateMutation>;
export type SiteStructureUpdateMutationResult = Apollo.MutationResult<SiteStructureUpdateMutation>;
export type SiteStructureUpdateMutationOptions = Apollo.BaseMutationOptions<SiteStructureUpdateMutation, SiteStructureUpdateMutationVariables>;
export const SiteActivateDocument = gql`
    mutation SiteActivate($id: String!, $siteStructurePreset: SiteStructurePreset) {
  siteActivate(id: $id, siteStructurePreset: $siteStructurePreset) {
    id
    name
    createdAt
    updatedAt
    licenseType
    totalDevices
    totalUsers
  }
}
    `;
export type SiteActivateMutationFn = Apollo.MutationFunction<SiteActivateMutation, SiteActivateMutationVariables>;

/**
 * __useSiteActivateMutation__
 *
 * To run a mutation, you first call `useSiteActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteActivateMutation, { data, loading, error }] = useSiteActivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      siteStructurePreset: // value for 'siteStructurePreset'
 *   },
 * });
 */
export function useSiteActivateMutation(baseOptions?: Apollo.MutationHookOptions<SiteActivateMutation, SiteActivateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteActivateMutation, SiteActivateMutationVariables>(SiteActivateDocument, options);
}
export type SiteActivateMutationHookResult = ReturnType<typeof useSiteActivateMutation>;
export type SiteActivateMutationResult = Apollo.MutationResult<SiteActivateMutation>;
export type SiteActivateMutationOptions = Apollo.BaseMutationOptions<SiteActivateMutation, SiteActivateMutationVariables>;
export const OrganizationCreateDocument = gql`
    mutation OrganizationCreate($input: OrganizationCreateInput!) {
  organizationCreate(input: $input) {
    id
    name
    address
    city
    country
    email
    entryType
    orgLatitude
    orgLongitude
    orgType
    otherOrgType
    phoneCode
    phonenumber
    postCode
    website
    createdBy {
      id
    }
  }
}
    `;
export type OrganizationCreateMutationFn = Apollo.MutationFunction<OrganizationCreateMutation, OrganizationCreateMutationVariables>;

/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<OrganizationCreateMutation, OrganizationCreateMutationVariables>(OrganizationCreateDocument, options);
}
export type OrganizationCreateMutationHookResult = ReturnType<typeof useOrganizationCreateMutation>;
export type OrganizationCreateMutationResult = Apollo.MutationResult<OrganizationCreateMutation>;
export type OrganizationCreateMutationOptions = Apollo.BaseMutationOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>;
export const OrganizationLogoCreateDocument = gql`
    mutation OrganizationLogoCreate($input: OrgnizationLogoCreateInput!) {
  organizationLogoCreate(input: $input) {
    key
    isUploaded
    url
  }
}
    `;
export type OrganizationLogoCreateMutationFn = Apollo.MutationFunction<OrganizationLogoCreateMutation, OrganizationLogoCreateMutationVariables>;

/**
 * __useOrganizationLogoCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationLogoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationLogoCreateMutation, { data, loading, error }] = useOrganizationLogoCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationLogoCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationLogoCreateMutation, OrganizationLogoCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<OrganizationLogoCreateMutation, OrganizationLogoCreateMutationVariables>(OrganizationLogoCreateDocument, options);
}
export type OrganizationLogoCreateMutationHookResult = ReturnType<typeof useOrganizationLogoCreateMutation>;
export type OrganizationLogoCreateMutationResult = Apollo.MutationResult<OrganizationLogoCreateMutation>;
export type OrganizationLogoCreateMutationOptions = Apollo.BaseMutationOptions<OrganizationLogoCreateMutation, OrganizationLogoCreateMutationVariables>;
export const SiteCreateDocument = gql`
    mutation SiteCreate($input: SiteCreateInput!) {
  siteCreate(input: $input) {
    id
    name
    shortName
    address
    city
    state
    country
    postcode
    longitude
    latitude
    organizationId
    timezone
    wifiSSID
    wifiPassword
    createdAt
    updatedAt
    licenseType
    totalDevices
  }
}
    `;
export type SiteCreateMutationFn = Apollo.MutationFunction<SiteCreateMutation, SiteCreateMutationVariables>;

/**
 * __useSiteCreateMutation__
 *
 * To run a mutation, you first call `useSiteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteCreateMutation, { data, loading, error }] = useSiteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteCreateMutation, SiteCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteCreateMutation, SiteCreateMutationVariables>(SiteCreateDocument, options);
}
export type SiteCreateMutationHookResult = ReturnType<typeof useSiteCreateMutation>;
export type SiteCreateMutationResult = Apollo.MutationResult<SiteCreateMutation>;
export type SiteCreateMutationOptions = Apollo.BaseMutationOptions<SiteCreateMutation, SiteCreateMutationVariables>;
export const SiteImagesCreateDocument = gql`
    mutation SiteImagesCreate($id: String!, $count: Int, $keysToRemove: [String!]) {
  siteImagesCreate(input: {id: $id, count: $count, keysToRemove: $keysToRemove}) {
    key
    isUploaded
    url
  }
}
    `;
export type SiteImagesCreateMutationFn = Apollo.MutationFunction<SiteImagesCreateMutation, SiteImagesCreateMutationVariables>;

/**
 * __useSiteImagesCreateMutation__
 *
 * To run a mutation, you first call `useSiteImagesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteImagesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteImagesCreateMutation, { data, loading, error }] = useSiteImagesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      count: // value for 'count'
 *      keysToRemove: // value for 'keysToRemove'
 *   },
 * });
 */
export function useSiteImagesCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteImagesCreateMutation, SiteImagesCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteImagesCreateMutation, SiteImagesCreateMutationVariables>(SiteImagesCreateDocument, options);
}
export type SiteImagesCreateMutationHookResult = ReturnType<typeof useSiteImagesCreateMutation>;
export type SiteImagesCreateMutationResult = Apollo.MutationResult<SiteImagesCreateMutation>;
export type SiteImagesCreateMutationOptions = Apollo.BaseMutationOptions<SiteImagesCreateMutation, SiteImagesCreateMutationVariables>;
export const SiteLogoCreateDocument = gql`
    mutation SiteLogoCreate($input: SiteLogoCreateInput!) {
  siteLogoCreate(input: $input) {
    key
    isUploaded
    url
  }
}
    `;
export type SiteLogoCreateMutationFn = Apollo.MutationFunction<SiteLogoCreateMutation, SiteLogoCreateMutationVariables>;

/**
 * __useSiteLogoCreateMutation__
 *
 * To run a mutation, you first call `useSiteLogoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteLogoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteLogoCreateMutation, { data, loading, error }] = useSiteLogoCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteLogoCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteLogoCreateMutation, SiteLogoCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteLogoCreateMutation, SiteLogoCreateMutationVariables>(SiteLogoCreateDocument, options);
}
export type SiteLogoCreateMutationHookResult = ReturnType<typeof useSiteLogoCreateMutation>;
export type SiteLogoCreateMutationResult = Apollo.MutationResult<SiteLogoCreateMutation>;
export type SiteLogoCreateMutationOptions = Apollo.BaseMutationOptions<SiteLogoCreateMutation, SiteLogoCreateMutationVariables>;
export const UserAddDocument = gql`
    mutation UserAdd($input: UserCreateInput!) {
  userAdd(input: $input) {
    id
  }
}
    `;
export type UserAddMutationFn = Apollo.MutationFunction<UserAddMutation, UserAddMutationVariables>;

/**
 * __useUserAddMutation__
 *
 * To run a mutation, you first call `useUserAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAddMutation, { data, loading, error }] = useUserAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAddMutation(baseOptions?: Apollo.MutationHookOptions<UserAddMutation, UserAddMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<UserAddMutation, UserAddMutationVariables>(UserAddDocument, options);
}
export type UserAddMutationHookResult = ReturnType<typeof useUserAddMutation>;
export type UserAddMutationResult = Apollo.MutationResult<UserAddMutation>;
export type UserAddMutationOptions = Apollo.BaseMutationOptions<UserAddMutation, UserAddMutationVariables>;
export const PrivilegeAssignDocument = gql`
    mutation PrivilegeAssign($input: UserPrivilegeAssignInput!) {
  privilegeAssign(input: $input) {
    id
    userId
    userSub
    organizationId
    siteIds
  }
}
    `;
export type PrivilegeAssignMutationFn = Apollo.MutationFunction<PrivilegeAssignMutation, PrivilegeAssignMutationVariables>;

/**
 * __usePrivilegeAssignMutation__
 *
 * To run a mutation, you first call `usePrivilegeAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrivilegeAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [privilegeAssignMutation, { data, loading, error }] = usePrivilegeAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrivilegeAssignMutation(baseOptions?: Apollo.MutationHookOptions<PrivilegeAssignMutation, PrivilegeAssignMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<PrivilegeAssignMutation, PrivilegeAssignMutationVariables>(PrivilegeAssignDocument, options);
}
export type PrivilegeAssignMutationHookResult = ReturnType<typeof usePrivilegeAssignMutation>;
export type PrivilegeAssignMutationResult = Apollo.MutationResult<PrivilegeAssignMutation>;
export type PrivilegeAssignMutationOptions = Apollo.BaseMutationOptions<PrivilegeAssignMutation, PrivilegeAssignMutationVariables>;
export const SiteStructureCreateDocument = gql`
    mutation SiteStructureCreate($siteId: String!, $nodeIds: [String!]!, $levelType: SiteLevelType!, $input: SiteStructureInput!) {
  siteStructureCreate(
    siteId: $siteId
    nodeIds: $nodeIds
    levelType: $levelType
    input: $input
  ) {
    siteId
    siteStructure {
      hierarchy
      labelFormat
      isEndNode
      isRootNode
      nodes {
        id
        label
      }
    }
  }
}
    `;
export type SiteStructureCreateMutationFn = Apollo.MutationFunction<SiteStructureCreateMutation, SiteStructureCreateMutationVariables>;

/**
 * __useSiteStructureCreateMutation__
 *
 * To run a mutation, you first call `useSiteStructureCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteStructureCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteStructureCreateMutation, { data, loading, error }] = useSiteStructureCreateMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      nodeIds: // value for 'nodeIds'
 *      levelType: // value for 'levelType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteStructureCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteStructureCreateMutation, SiteStructureCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteStructureCreateMutation, SiteStructureCreateMutationVariables>(SiteStructureCreateDocument, options);
}
export type SiteStructureCreateMutationHookResult = ReturnType<typeof useSiteStructureCreateMutation>;
export type SiteStructureCreateMutationResult = Apollo.MutationResult<SiteStructureCreateMutation>;
export type SiteStructureCreateMutationOptions = Apollo.BaseMutationOptions<SiteStructureCreateMutation, SiteStructureCreateMutationVariables>;
export const SiteStructureDeleteDocument = gql`
    mutation SiteStructureDelete($ids: [String!]!, $siteId: String!) {
  siteStructureDelete(ids: $ids, siteId: $siteId) {
    ids
  }
}
    `;
export type SiteStructureDeleteMutationFn = Apollo.MutationFunction<SiteStructureDeleteMutation, SiteStructureDeleteMutationVariables>;

/**
 * __useSiteStructureDeleteMutation__
 *
 * To run a mutation, you first call `useSiteStructureDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteStructureDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteStructureDeleteMutation, { data, loading, error }] = useSiteStructureDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteStructureDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SiteStructureDeleteMutation, SiteStructureDeleteMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteStructureDeleteMutation, SiteStructureDeleteMutationVariables>(SiteStructureDeleteDocument, options);
}
export type SiteStructureDeleteMutationHookResult = ReturnType<typeof useSiteStructureDeleteMutation>;
export type SiteStructureDeleteMutationResult = Apollo.MutationResult<SiteStructureDeleteMutation>;
export type SiteStructureDeleteMutationOptions = Apollo.BaseMutationOptions<SiteStructureDeleteMutation, SiteStructureDeleteMutationVariables>;
export const SiteUpdateDocument = gql`
    mutation SiteUpdate($id: String!, $name: String, $shortName: String, $address: String, $city: String, $state: String, $country: ISOAlpha2CountryCode, $postcode: String, $longitude: Float, $latitude: Float, $timezone: String, $siteStructurePreset: SiteStructurePreset) {
  siteUpdate(
    input: {id: $id, name: $name, shortName: $shortName, address: $address, city: $city, state: $state, country: $country, postcode: $postcode, longitude: $longitude, latitude: $latitude, timezone: $timezone, siteStructurePreset: $siteStructurePreset}
  ) {
    id
    createdBy {
      id
      email
    }
  }
}
    `;
export type SiteUpdateMutationFn = Apollo.MutationFunction<SiteUpdateMutation, SiteUpdateMutationVariables>;

/**
 * __useSiteUpdateMutation__
 *
 * To run a mutation, you first call `useSiteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteUpdateMutation, { data, loading, error }] = useSiteUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      shortName: // value for 'shortName'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      postcode: // value for 'postcode'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      timezone: // value for 'timezone'
 *      siteStructurePreset: // value for 'siteStructurePreset'
 *   },
 * });
 */
export function useSiteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SiteUpdateMutation, SiteUpdateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SiteUpdateMutation, SiteUpdateMutationVariables>(SiteUpdateDocument, options);
}
export type SiteUpdateMutationHookResult = ReturnType<typeof useSiteUpdateMutation>;
export type SiteUpdateMutationResult = Apollo.MutationResult<SiteUpdateMutation>;
export type SiteUpdateMutationOptions = Apollo.BaseMutationOptions<SiteUpdateMutation, SiteUpdateMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: OrganizationUpdateInput!) {
  organizationUpdate(input: $input) {
    id
    orgType
    otherOrgType
    name
    entryType
    dunsNumber
    country
    address
    orgLatitude
    orgLongitude
    city
    postCode
    website
    phonenumber
    email
    createdBy {
      id
      email
    }
    logo {
      key
      isUploaded
      url
    }
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($id: String!, $organizationId: String!, $input: UserEditInput!) {
  userEdit(id: $id, organizationId: $organizationId, input: $input) {
    id
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const HealthCheckDocument = gql`
    query HealthCheck {
  healthCheck {
    status
  }
}
    `;

/**
 * __useHealthCheckQuery__
 *
 * To run a query within a React component, call `useHealthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthCheckQuery(baseOptions?: Apollo.QueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument, options);
}
export function useHealthCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument, options);
}
export function useHealthCheckSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument, options);
}
export type HealthCheckQueryHookResult = ReturnType<typeof useHealthCheckQuery>;
export type HealthCheckLazyQueryHookResult = ReturnType<typeof useHealthCheckLazyQuery>;
export type HealthCheckSuspenseQueryHookResult = ReturnType<typeof useHealthCheckSuspenseQuery>;
export type HealthCheckQueryResult = Apollo.QueryResult<HealthCheckQuery, HealthCheckQueryVariables>;
export const GetLoggedInUserDocument = gql`
    query GetLoggedInUser {
  user {
    id
    email
    firstName
    lastName
    sub
    organizationId
  }
}
    `;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
}
export function useGetLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
}
export function useGetLoggedInUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
}
export type GetLoggedInUserQueryHookResult = ReturnType<typeof useGetLoggedInUserQuery>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserLazyQuery>;
export type GetLoggedInUserSuspenseQueryHookResult = ReturnType<typeof useGetLoggedInUserSuspenseQuery>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>;
export const GetOrganizationsDocument = gql`
    query GetOrganizations($input: GetOrganizationsInput!) {
  organizations(input: $input) {
    totalCount
    items {
      id
      orgType
      otherOrgType
      name
      entryType
      dunsNumber
      country
      address
      orgLatitude
      orgLongitude
      city
      postCode
      website
      phonenumber
      phoneCode
      email
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables> & ({ variables: GetOrganizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
}
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
}
export function useGetOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
}
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationsSuspenseQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($id: String!) {
  organization(id: $id) {
    id
    orgType
    otherOrgType
    name
    entryType
    dunsNumber
    country
    address
    orgLatitude
    orgLongitude
    city
    postCode
    website
    phonenumber
    phoneCode
    email
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
}
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
}
export function useGetOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSuspenseQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  roles {
    name
    description
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetOrganizationSitesDocument = gql`
    query GetOrganizationSites($id: String!, $input: GetSitesInput!) {
  organization(id: $id) {
    name
    sites(input: $input) {
      totalCount
      items {
        id
        name
        shortName
        address
        city
        state
        country
        postcode
        longitude
        latitude
        organizationId
        timezone
        wifiSSID
        wifiPassword
        createdAt
        updatedAt
        licenseType
        totalDevices
        totalUsers
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationSitesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrganizationSitesQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables> & ({ variables: GetOrganizationSitesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables>(GetOrganizationSitesDocument, options);
}
export function useGetOrganizationSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables>(GetOrganizationSitesDocument, options);
}
export function useGetOrganizationSitesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables>(GetOrganizationSitesDocument, options);
}
export type GetOrganizationSitesQueryHookResult = ReturnType<typeof useGetOrganizationSitesQuery>;
export type GetOrganizationSitesLazyQueryHookResult = ReturnType<typeof useGetOrganizationSitesLazyQuery>;
export type GetOrganizationSitesSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSitesSuspenseQuery>;
export type GetOrganizationSitesQueryResult = Apollo.QueryResult<GetOrganizationSitesQuery, GetOrganizationSitesQueryVariables>;
export const SiteRootIdDocument = gql`
    query SiteRootId($id: String!, $searchInput: SiteStructureSearchInput!) {
  site(id: $id) {
    siteStructure(searchInput: $searchInput) {
      siteStructure {
        hierarchy
        labelFormat
        isEndNode
        isRootNode
        nodes {
          id
          label
        }
      }
      siteId
    }
  }
}
    `;

/**
 * __useSiteRootIdQuery__
 *
 * To run a query within a React component, call `useSiteRootIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteRootIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteRootIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useSiteRootIdQuery(baseOptions: Apollo.QueryHookOptions<SiteRootIdQuery, SiteRootIdQueryVariables> & ({ variables: SiteRootIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<SiteRootIdQuery, SiteRootIdQueryVariables>(SiteRootIdDocument, options);
}
export function useSiteRootIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteRootIdQuery, SiteRootIdQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<SiteRootIdQuery, SiteRootIdQueryVariables>(SiteRootIdDocument, options);
}
export function useSiteRootIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SiteRootIdQuery, SiteRootIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<SiteRootIdQuery, SiteRootIdQueryVariables>(SiteRootIdDocument, options);
}
export type SiteRootIdQueryHookResult = ReturnType<typeof useSiteRootIdQuery>;
export type SiteRootIdLazyQueryHookResult = ReturnType<typeof useSiteRootIdLazyQuery>;
export type SiteRootIdSuspenseQueryHookResult = ReturnType<typeof useSiteRootIdSuspenseQuery>;
export type SiteRootIdQueryResult = Apollo.QueryResult<SiteRootIdQuery, SiteRootIdQueryVariables>;
export const GetSiteDataDocument = gql`
    query GetSiteData($id: String!) {
  site(id: $id) {
    id
    name
    shortName
    address
    city
    state
    country
    postcode
    longitude
    latitude
    siteStructurePreset
    organizationId
    timezone
    wifiSSID
    wifiPassword
    siteImages {
      key
      isUploaded
      url
    }
    logo {
      key
      isUploaded
      url
    }
    siteStructure(searchInput: {}) {
      siteId
      siteStructure {
        hierarchy
        labelFormat
        isEndNode
        isRootNode
        nodes {
          id
          label
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSiteDataQuery__
 *
 * To run a query within a React component, call `useGetSiteDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSiteDataQuery(baseOptions: Apollo.QueryHookOptions<GetSiteDataQuery, GetSiteDataQueryVariables> & ({ variables: GetSiteDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetSiteDataQuery, GetSiteDataQueryVariables>(GetSiteDataDocument, options);
}
export function useGetSiteDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteDataQuery, GetSiteDataQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetSiteDataQuery, GetSiteDataQueryVariables>(GetSiteDataDocument, options);
}
export function useGetSiteDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSiteDataQuery, GetSiteDataQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetSiteDataQuery, GetSiteDataQueryVariables>(GetSiteDataDocument, options);
}
export type GetSiteDataQueryHookResult = ReturnType<typeof useGetSiteDataQuery>;
export type GetSiteDataLazyQueryHookResult = ReturnType<typeof useGetSiteDataLazyQuery>;
export type GetSiteDataSuspenseQueryHookResult = ReturnType<typeof useGetSiteDataSuspenseQuery>;
export type GetSiteDataQueryResult = Apollo.QueryResult<GetSiteDataQuery, GetSiteDataQueryVariables>;
export const GetOrganizationsUsersDocument = gql`
    query GetOrganizationsUsers($orgId: String!, $limit: Int, $offset: Int, $sortBy: String, $sortOrder: SortOrder) {
  organization(id: $orgId) {
    name
    users(
      input: {limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder}
    ) {
      totalCount
      items {
        id
        sub
        fullName
        preferredLanguage
        organizationId
        jobTitle
        department
        salutation
        enableMFA
        authStatus
        lastLogin
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationsUsersQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsUsersQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetOrganizationsUsersQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables> & ({ variables: GetOrganizationsUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables>(GetOrganizationsUsersDocument, options);
}
export function useGetOrganizationsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables>(GetOrganizationsUsersDocument, options);
}
export function useGetOrganizationsUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables>(GetOrganizationsUsersDocument, options);
}
export type GetOrganizationsUsersQueryHookResult = ReturnType<typeof useGetOrganizationsUsersQuery>;
export type GetOrganizationsUsersLazyQueryHookResult = ReturnType<typeof useGetOrganizationsUsersLazyQuery>;
export type GetOrganizationsUsersSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationsUsersSuspenseQuery>;
export type GetOrganizationsUsersQueryResult = Apollo.QueryResult<GetOrganizationsUsersQuery, GetOrganizationsUsersQueryVariables>;
export const GetUserSitesDocument = gql`
    query GetUserSites($id: String!, $organizationId: String!, $input: GetSitesInput!) {
  user(id: $id, organizationId: $organizationId) {
    sites(input: $input) {
      totalCount
      items {
        id
        name
        shortName
        address
        country
        organizationId
        siteStructurePreset
        timezone
        logo {
          key
          isUploaded
          url
        }
        licenseType
        totalDevices
        totalUsers
      }
    }
  }
}
    `;

/**
 * __useGetUserSitesQuery__
 *
 * To run a query within a React component, call `useGetUserSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserSitesQuery(baseOptions: Apollo.QueryHookOptions<GetUserSitesQuery, GetUserSitesQueryVariables> & ({ variables: GetUserSitesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetUserSitesQuery, GetUserSitesQueryVariables>(GetUserSitesDocument, options);
}
export function useGetUserSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSitesQuery, GetUserSitesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetUserSitesQuery, GetUserSitesQueryVariables>(GetUserSitesDocument, options);
}
export function useGetUserSitesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserSitesQuery, GetUserSitesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetUserSitesQuery, GetUserSitesQueryVariables>(GetUserSitesDocument, options);
}
export type GetUserSitesQueryHookResult = ReturnType<typeof useGetUserSitesQuery>;
export type GetUserSitesLazyQueryHookResult = ReturnType<typeof useGetUserSitesLazyQuery>;
export type GetUserSitesSuspenseQueryHookResult = ReturnType<typeof useGetUserSitesSuspenseQuery>;
export type GetUserSitesQueryResult = Apollo.QueryResult<GetUserSitesQuery, GetUserSitesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: String, $organizationId: String!, $limit: Int, $offset: Int, $sortBy: String, $sortOrder: SortOrder) {
  user(id: $id, organizationId: $organizationId) {
    id
    email
    firstName
    lastName
    sub
    phoneNumber
    phoneCode
    preferredLanguage
    organizationId
    jobTitle
    department
    salutation
    enableMFA
    authStatus
    lastLogin
    createdAt
    updatedAt
    privileges(
      input: {organizationId: $organizationId, limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder}
    ) {
      id
      userId
      userSub
      organizationId
      organizationName
      siteIds
      allSites
      roles
      status
      rolePrivileges
      expiresAt
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;