/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/react-in-jsx-scope */

import React, { useEffect, useState } from "react";
import {
    GoogleMap,
    useLoadScript,
    Libraries,
    MarkerF,
} from "@react-google-maps/api";
import { FlexBox, Label, TextField } from "@filament/react";
import { Cross, LocationFill, } from '@filament-icons/react';

import { GoogleApiKey } from "../../../../config";
import { useUnifiedContexts } from "../../../../components/Context/Context";


const libraries: Libraries = ["places"];
const mapContainerStyle = {
    width: "100%",
    height: "271px",
    borderRadius: "12px",
};

export default function Address({
    handleInputChange,
    onMapLoad,
    setIsLocationSelected,
    map,
    selectedCountryBounds,
    country // Add this prop
}: {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onMapLoad: (mapInstance: any) => void,
    setIsLocationSelected: React.Dispatch<React.SetStateAction<boolean>>,
    map: any,
    selectedCountryBounds: any,
    country: string // Add this type
}) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!,
        libraries,
    });

    // Add useEffect to handle country changes
    useEffect(() => {
        // setMarker(null);
        if (map && selectedCountryBounds && !setIsLocationSelected) {
            const latLngBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(selectedCountryBounds.south, selectedCountryBounds.west),
                new window.google.maps.LatLng(selectedCountryBounds.north, selectedCountryBounds.east)
            );

            map.fitBounds(latLngBounds);
            map.setOptions({
                restriction: {
                    latLngBounds,
                    strictBounds: true,
                },
            });
        }
    }, [country, map, selectedCountryBounds, setIsLocationSelected]);

    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [marker, setMarker] = useState<{ lat: number; lng: number } | null>(null);
    const { formData, setFormData, inputValue, setInputValue, mapValues, setMapValues } = useUnifiedContexts()
    const handleChange = (field: keyof typeof formData) => (value: string) => {

        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onMapClick = (event: any) => {
        setIsLocationSelected(true); // Prevent restrictions while selecting
    
        if (!window.google || !window.google.maps) return;
    
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const geocoder = new window.google.maps.Geocoder();
    
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results![0]) {
                const placeId = results![0].place_id;
                const addressComponents = results![0].address_components;
                const cityComponent = addressComponents.find((component) =>
                    component.types.includes("locality")
                );
                const stateComponent = addressComponents.find((component) =>
                    component.types.includes("administrative_area_level_1")
                );
                const postalCodeComponent = addressComponents.find((component) =>
                    component.types.includes("postal_code")
                );
    
                setFormData((prev) => ({
                    ...prev,
                    placeId: placeId,
                }));
    
                
                if(formData.organizationAddress==='')
                    handleChange("organizationAddress")(results![0].formatted_address || "");
                handleChange("city")(cityComponent ? cityComponent.long_name : "");
                handleChange("state")(stateComponent ? stateComponent.long_name : "");
                handleChange("postcode")(postalCodeComponent ? postalCodeComponent.long_name : "");
                setInputValue(results![0].formatted_address || "");
    
                setMarker({ lat, lng });
                setMapValues((prev) => ({ ...prev, center: { lat, lng }, zoom: 21 }));
                map.panTo({ lat, lng });
                map.setZoom(21);
            }
        });
    
        setTimeout(() => setIsLocationSelected(false), 2000);
    };
      
    
    
    const handleSuggestionClick = (placeId: string) => {
        setIsLocationSelected(true); // Indicate a location is being selected
        setIsSearchVisible(false);

        if (window.google && window.google.maps) {
            setFormData(prev => ({
                ...prev,
                placeId: placeId
            }))
            const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));
            placesService.getDetails({ placeId }, (place, status) => {
                if (status === "OK" && place && place.geometry) {
                    const location = place.geometry.location;
                    if (location && typeof location.lat === "function" && typeof location.lng === "function") {
                        const lat = location.lat();
                        const lng = location.lng();
                        setMapValues((prev) => ({ ...prev, center: { lat: lat, lng: lng } }))
                        setMapValues((prev) => ({ ...prev, zoom: 21 }))
                        setMarker({ lat, lng });
                        if (map) {
                            map.panTo(new google.maps.LatLng(lat, lng));
                            map.setZoom(21);
                        } else {
                            console.warn("Map is not initialized yet.");
                        }

                        const addressComponents = place.address_components;
                        const cityComponent = addressComponents?.find((component) =>
                            component.types.includes("locality")
                        );
                        const stateComponent = addressComponents?.find((component) =>
                            component.types.includes("administrative_area_level_1")
                        );
                        const postalCodeComponent = addressComponents?.find((component) =>
                            component.types.includes("postal_code")
                        );

                        if(formData.organizationAddress==='')
                            handleChange("organizationAddress")(place.formatted_address || "")
                        handleChange("city")(cityComponent ? cityComponent.long_name : "");
                        handleChange("state")(stateComponent ? stateComponent.long_name : "");
                        handleChange("postcode")(postalCodeComponent ? postalCodeComponent.long_name : "");
                        setInputValue(place.formatted_address || "");
                    }
                }
            });
        }
        setMapValues((prev) => ({ ...prev, suggestions: [] }));
        setTimeout(() => setIsLocationSelected(false), 2000);
    };


    const clearInput = () => {
        setInputValue('');
        setFormData((prev) => ({
            ...prev,
            organizationAddress: "",
            state: "",
            city: "",
            postcode: ""
        }));
        setMarker(null)
        setMapValues((prev) => ({ ...prev, zoom: 4 }))
        setTimeout(() => handleSuggestionClick(''), 0); // Ensures it runs after state updates
    };

    const handleClearClick = () => {
        if (inputValue) {
            // If there's text, just clear the input
            clearInput();
        } else {
            // If input is empty, hide the search bar
            setIsSearchVisible(false);
            setMapValues((prev) => ({ ...prev, suggestions: [] }));
        }
    };

    const handleSearchIconClick = () => {
        setIsSearchVisible(true);
    };

    useEffect(() => {
        if (isLoaded && formData.placeId) {
            setMarker({ lat: mapValues.center.lat, lng: mapValues.center.lng });
            handleSuggestionClick(formData.placeId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded])

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <FlexBox style={{ flexDirection: "column", gap: "24px", paddingTop: "24px" }}>
            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Organization Name</Label>
                <TextField
                    placeholder="Organization Name"
                    isFullWidth
                    type="text"
                    aria-label='organization name'
                    value={formData.organizationName}
                    onChange={handleChange("organizationName")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Organization Location</Label>

                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={mapValues.zoom}
                    center={mapValues.center}
                    onClick={onMapClick}
                    onLoad={onMapLoad}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                        restriction: selectedCountryBounds ? {
                            latLngBounds: new window.google.maps.LatLngBounds(
                                new window.google.maps.LatLng(selectedCountryBounds.south, selectedCountryBounds.west),
                                new window.google.maps.LatLng(selectedCountryBounds.north, selectedCountryBounds.east)
                            ),
                            strictBounds: true,
                        } : undefined,
                    }}

                >
                    <div style={{ position: "relative", paddingTop: '17px', paddingLeft: '24px', paddingRight: '24px' }}>
                        <div style={{ position: 'relative', width: '100%' }}>
                            {isSearchVisible ? (
                                <>
                                    <span style={{
                                        position: 'absolute',
                                        top: '55%',
                                        left: '1px',
                                        transform: 'translateY(-50%)',
                                        fontSize: '16px',
                                    }}>
                                        <img src="/images/searchIcon.svg" alt="search" />
                                    </span>
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            border: '1px solid gray',
                                            borderRadius: '6px',
                                            paddingTop: '7px',
                                            paddingBottom: '7px',
                                            paddingLeft: '52px',
                                            paddingRight: '35px',
                                            outline: 'none',
                                            transition: 'box-shadow 0.2s ease-in-out, border 0.2s ease-in-out',
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.boxShadow = '0 0 0 1px white, 0 0 0 3px rgb(21, 107, 226)';
                                            e.target.style.border = '1px solid gray';
                                        }}
                                        onBlur={(e) => {
                                            e.target.style.boxShadow = 'none';
                                            e.target.style.border = '1px solid gray';
                                        }}
                                    />
                                    <Cross
                                        size="regular"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleClearClick} />
                                </>
                            ) : (
                                <div 
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        borderRadius: '6px',
                                        border: '1px solid gray',
                                        background: 'white',
                                        marginLeft: 'auto',
                                    }}
                                    onClick={handleSearchIconClick}
                                >
                                    <img src="/images/searchIcon.svg" alt="search" />
                                </div>
                            )}
                        </div>

                        {mapValues.suggestions.length > 0 && (
                            <div style={{
                                position: "absolute",
                                top: "100%",
                                left: '3%',
                                right: '3%',
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                zIndex: 1,
                                fontFamily: "Arial, sans-serif",
                                fontSize: "14px",
                            }}>
                                {mapValues.suggestions.map((suggestion) => (
                                    <div
                                        key={suggestion.place_id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 12px",
                                            cursor: "pointer",
                                            borderBottom: "1px solid #f0f0f0",
                                        }}
                                        onClick={() => handleSuggestionClick(suggestion.place_id)}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f9f9f9"}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#fff"}
                                    >
                                        <span style={{
                                            marginRight: "10px",
                                            color: "#888",
                                        }}>
                                            <LocationFill />
                                        </span>
                                        <span style={{
                                            flex: 1,
                                            color: "#333",
                                        }}>
                                            {suggestion.description}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {marker && (
                        <MarkerF
                            position={{ lat: marker!.lat, lng: marker!.lng }}
                            icon={{
                                url: "/images/gis_pin.svg", // Path to your custom marker image
                                scaledSize: new window.google.maps.Size(40, 40), // Adjust size
                                origin: new window.google.maps.Point(0, 0), // Origin point of the image
                                anchor: new window.google.maps.Point(20, 40), // Anchor point for positioning
                            }}
                        />
                    )}
                </GoogleMap>
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                <Label>Organization Address</Label>
                <TextField
                    placeholder="Organization Address"
                    type="text"
                    aria-label='Organization Address'
                    isFullWidth
                    value={formData.organizationAddress}
                    onChange={ handleChange("organizationAddress")}
                />
            </FlexBox>

            {/* Might be asked by the clients so will be commenting it till clarity is given. */}

            {/* <FlexBox style={{ gap: "24px", width: "100%" }}>
                <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                    <Label>City</Label>
                    <TextField
                        placeholder="City"
                        type="text"
                        aria-label='city'
                        isFullWidth
                        value={formData.city}
                        onChange={handleChange("city")}
                    />
                </FlexBox>

                <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                    <Label>State</Label>
                    <TextField
                        placeholder="State"
                        type="text"
                        aria-label='state'
                        isFullWidth
                        value={formData.state}
                        onChange={handleChange("state")}
                    />
                </FlexBox>
            </FlexBox> */}

            <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                <Label>Postcode</Label>
                <TextField
                    placeholder="Postcode"
                    type="text"
                    aria-label='postcode'
                    isFullWidth
                    inputProps={{ pattern: "^[A-Za-z0-9][A-Za-z0-9\\-\\s]{0,10}[A-Za-z0-9]$" }}
                    value={formData.postcode}
                    onChange={handleChange("postcode")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>

                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                    <Label>Tax Identification Number</Label>
                    <Label variant={'descriptor'}>(optional)</Label>
                </div>
                <TextField
                    placeholder="Tax ID No for US/EU/Australia/VAT for Belgium,Germany etc"
                    isFullWidth
                    aria-label='vat number'
                    type="text"
                    maxLength={18}
                    inputProps={{ pattern: "^[A-Z0-9]+|[0-9]$" }}
                    value={formData.vat}
                    onChange={handleChange("vat")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Organization Website</Label>
                <TextField
                    placeholder="Organization Website"
                    isFullWidth
                    aria-label='organization website'
                    type="url"
                    value={formData.organizationWebsite}
                    onChange={handleChange("organizationWebsite")}
                />
            </FlexBox>
        </FlexBox>
    );
}


