/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { FlexBox, Paragraph, Spinner } from "@filament/react";
import React, { useEffect, useRef, useState } from "react";
import SiteStructureLabel from "./components/SiteStructureLabel";
import { useUnifiedContexts } from "../../../components/Context/Context";
import * as styles from './styles/styles.css'
import { ArrowTailRight } from "@filament-icons/react";
import { atomicIconSmall } from '@filament/styles';
import { getCollapsedLabel, getFirstChildrenArray, getPathUpToTarget, getSiblingHeight, hasSibling, isSingleFirstChild, Trie, updateTrieWithHierarchy } from "./components/TrieDataStructure";
import { levelObject } from "../../../components/Context/SiteContext";
import { SiteLevelType, SiteStructureLabelFormat, useSiteRootIdLazyQuery, useSiteStructureCreateMutation } from "../../../graphql/generated";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const TrieWorker = () => new Worker(new URL("../../../workers/trieWorker", import.meta.url), { type: "module" });

export interface TableRow {
    path: string[];
    isFirstChild: { [key: string]: string[] }[];  // Track first child and its siblings
}

export const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
    return arr1.length === arr2.length && arr1.every((val, index) => val === arr2[index]);
};

export const rootPathInArray = (arr1:string[],arr2:string[]):boolean => {
    return arr1.every((val,index)=> val === arr2[index])
}

export default function SiteStructure({showPreviewTable}:{showPreviewTable:boolean}) {
    const { levelValues ,
        tableData,
        setTableData,
        trie,
        setTrie,
        previewTrie,
        setPathOfSelectedLabel,
        setSelectedLabel,
        previewCollapsedNodes,
        previewTableData,
        previewRowSpanMap,
        toggleCollapse,
        selectedPreset,
        rowSpanMap,
        collapsedNodes,
        editedSiteId,
        setCollapsedNodes,
        setRowSpanMap} = useUnifiedContexts();
    const [loading, setLoading] = useState(true);
    const [rootSiteId,setRootSiteId] = useState('')
    const siteId = useSelector((state:RootState)=>state.persistedState.siteIdToActivate)
    const [fetchSiteRootId] = useSiteRootIdLazyQuery();
    const [createSiteStructure] = useSiteStructureCreateMutation()
    const siteActivateId = useSelector((state:RootState)=>state.persistedState.siteIdToActivate)
    const hasFetchedData = useRef(false); // Track API calls

    const generateLevelArrays = (levels: string[],siteLabel:string,trie:Trie): string[][] => {
        if (levels.length === 0) return [];
        const firstLevelCollapsedNodes =  Array.from({ length: levels.length}, (_, i) => [
            siteLabel, 
            levels[i]
        ]);
        const subsequentCollapsedArray:string[][] = []
        for (const collapsedArray of firstLevelCollapsedNodes){
            const firstChildrenLabels = getFirstChildrenArray(trie.root,collapsedArray.slice(0,-1))
            subsequentCollapsedArray.push(...firstChildrenLabels.filter(item=>item.length!==1))
        }
        return firstLevelCollapsedNodes.concat(subsequentCollapsedArray);

    };
    const fetchRootSiteId = async () => {
        const response = await fetchSiteRootId({
            variables: { id: siteId, searchInput: { ids: null, hierarchy: null } },
        });
    
        // Extract the ID safely
        const rootId = response?.data?.site?.siteStructure?.siteStructure?.[0]?.nodes?.[0]?.id;
        
        // Ensure the result is always a string
        const rootIdString = rootId ? String(rootId) : '';
    
        setRootSiteId(rootIdString);
        return rootIdString; // Always returns a string
    };
    const multiplyUnitsExceptLast = (levels: levelObject[]): number => {
        if (levels.length <= 1) return 1; // If there's only one object, return 1 (or its number_of_units if needed)
    
        return levels.slice(0, -1).reduce((product, obj) => product * obj.number_of_units, 1);
    };

    const createHierarchyRecursively = async (parentIds: string[]|string, 
        levelIndex: number, 
        levels: levelObject[],
        eventTrie:Trie) => {
        if (levelIndex >= levels.length) return; // Stop recursion when all levels are processed
    
        const currentLevel = levels[levelIndex]; // Get the current level details
        const createdNodes: string[] = []; // Store newly created node IDs
    
        const createStructureResponse = await createSiteStructure({
            variables: {
                nodeIds: parentIds, // Attach new nodes to the parent
                siteId: siteActivateId !== '' ? siteActivateId : editedSiteId,
                levelType: SiteLevelType.Children, // Every new level is a child
                input: {
                    count: currentLevel.number_of_units, // Number of units at this level
                    label: currentLevel.label, // Label for this level
                    labelFormat: SiteStructureLabelFormat.Option_1_2_3,
                    isEndNode: levelIndex===levels.length-1?true:false
                }
            }
        });
    
        // Extract IDs of newly created nodes
        if (createStructureResponse?.data?.siteStructureCreate?.siteStructure) {
            const siteStructures = createStructureResponse.data.siteStructureCreate.siteStructure;
            for (const structure of siteStructures) {
                const nodes = structure?.nodes ?? [];
                createdNodes.push(...nodes.map(node => node.id)); // Store new node IDs
                const hierarchy = structure?.hierarchy ?? [];
                const idsToAddTotrie = structure?.nodes?.map(item => ({ id: item.id, label: item.label })) ?? [];
                // Update Trie with each hierarchy/nodes pair
                updateTrieWithHierarchy(eventTrie.root, hierarchy, idsToAddTotrie);
            }
        }
        
    
        // Recursive call for the next level
        if (createdNodes.length > 0) {
            await createHierarchyRecursively(createdNodes, levelIndex + 1, levels,eventTrie);
        }
    };
    
    // **Main function to trigger hierarchy creation**
    const createFullHierarchy = async (rootId:string,eventTrie:Trie) => {
        try {
            // Example user input
            const levels = levelValues
    
            if (levels.length === 0) return; // No hierarchy to create
            await createHierarchyRecursively(rootId, 0, levels,eventTrie);
        } catch (err) {
            console.error("Error in creating hierarchy:", err);
        }
    };
    useEffect(() => {
        if (window.Worker) {
            if(showPreviewTable){
                setLoading(false)
                setTableData(previewTableData)
                setRowSpanMap(previewRowSpanMap)
                setCollapsedNodes(previewCollapsedNodes)
                setPathOfSelectedLabel([{path:[previewTableData[0].path[0]]}])
                setSelectedLabel([previewTableData[0].path[0]])
                setTrie(previewTrie)

            }else{
                const fetchDataAndRunWorker = async () => {
                    if (hasFetchedData.current) return; // Prevent duplicate execution
                    hasFetchedData.current = true; // Mark as called
                    setLoading(true);
                    const worker = TrieWorker();
                    let rootId=''
                    if(rootSiteId===''){
                        const promiseResponse =  await fetchRootSiteId()
                        rootId = promiseResponse
                    }
                    worker.postMessage({ levelValues ,rootSiteId:rootId===''?rootSiteId:rootId});
                    worker.onmessage = (event: MessageEvent<{ tableData: TableRow[],rowSpanMap: number[][],trie:Trie }>) => {
                        setTableData(event.data.tableData);
                        setRowSpanMap(event.data.rowSpanMap);
                        setPathOfSelectedLabel([{path:[event.data.tableData[0].path[0]]}])
                        setSelectedLabel([event.data.tableData[0].path[0]])
                        if(multiplyUnitsExceptLast(levelValues)>27){
                            setCollapsedNodes(generateLevelArrays(event.data.tableData.map(item=>item.path[1]),
                                event.data.tableData[0].path[0],event.data.trie))

                        }   
                        setTrie(event.data.trie)
                        createFullHierarchy(rootId,event.data.trie)
                        setLoading(false);
                        worker.terminate();

                    };
                    return () => worker.terminate();
                }
                fetchDataAndRunWorker();
            }
            
        } else {
            console.warn("Web Workers are not supported in this browser.");
            setLoading(false);
        }
    }, [levelValues]);

    if (loading) {
        return( <div style={{display:"flex",
            flexDirection:"column",
            alignItems:"center",
            margin:'auto'}}>
            <Spinner size="normal" />
            <Paragraph>Loading site structure...</Paragraph>
        </div>)
    }
    return (
        <FlexBox marginTop={"28px"} alignItems="center" justifyContent="center">
            <table style={{ margin: "0 auto" }}>
                <thead>
                    <tr>
                        {[...Array(selectedPreset==='Custom site structure'||selectedPreset==='Preset 1'?5:4)].map((_, index) => (
                            <th key={index}>
                                <div style={{ display: "flex", marginBottom: "20px" }}>
                                    <SiteStructureLabel value={`Level ${index + 1}-11`} 
                                        isFirstChild={false}
                                        customVal=''
                                        toggleCollapse={toggleCollapse}
                                        isHeading={true} />
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row:TableRow, rowIndex:number) => {
                        return (
                            <tr key={rowIndex}>
                                {row.path.map((cell, colIndex) => {
                                    const shouldRender = rowSpanMap?.[rowIndex]?.[colIndex] > 0  ;
                                    const isFirstChild = row.isFirstChild.some((entry) => Object.keys(entry)[0] === cell);
                                    // Check if this node has siblings
                                    const hasSiblings = hasSibling(row.isFirstChild,cell)
                                    const singleFirstChild = isSingleFirstChild(trie!,getPathUpToTarget(row.path,cell))
                                    // Calculate dynamic height based on row span
                                    const siblingHeight = getSiblingHeight(tableData,rowSpanMap, rowIndex, colIndex,row.isFirstChild,cell);
                                    return  shouldRender && (<td key={`${cell}`}
                                        style={{ verticalAlign: "top" }}
                                        rowSpan={rowSpanMap[rowIndex][colIndex]}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: "30px"
                                        }}>{rowIndex === 0 && colIndex === 0 ? 
                                                (<SiteStructureLabel 
                                                    value={cell} 
                                                    row={row} 
                                                    customVal=''
                                                    isFirstChild={isFirstChild} 
                                                />
                                                ) : (
                                                    <div className={`${styles.parentChildArrow} ${hasSiblings ? styles.siblingArrow : ''}`} 
                                                        style={{ "--sibling-height": `${siblingHeight}px`,
                                                            "--parent-child-width": 
                                                                ((isFirstChild)||singleFirstChild) ? "45px" : "30px",
                                                            "--parent-child-left": ((isFirstChild)||singleFirstChild) ? "-50px" : "-35px" ,                
                                                            cursor:"pointer"
                                                        } as React.CSSProperties}>
                                                        <ArrowTailRight style={{ marginLeft: "-15px", color: "#5C6E7F"}} className={atomicIconSmall} />
                                                        <SiteStructureLabel value={cell}
                                                            customVal={collapsedNodes?.some(arr => rootPathInArray(arr, getPathUpToTarget(row.path,cell).slice(0,-1)))
                                                                ?getCollapsedLabel(trie.root,getPathUpToTarget(row.path,cell)):''}
                                                            row={row}
                                                            isFirstChild={isFirstChild}
                                                            toggleCollapse={toggleCollapse}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </td>
                                    )
                                })}
                            </tr>
                        );
                    })}
                </tbody>


            </table>
        </FlexBox>
    );
}
