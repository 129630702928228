
import React from "react"
import { ChevronUpDown, ThreeDotsVertical } from '@filament-icons/react';
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

import { Cell, Column, DataGrid, FlexBox, Row, Table, TableBody, TableHeader, Text, } from "@filament/react"

const UserPrevilagePreview = () => {
    const previlageType = useSelector((state: RootState) => state.PrivilageTypeState.privlageValue)
    const selectPrivilageValues = useSelector((state: RootState) => state.SelectPrivilageState)

    const date = selectPrivilageValues.Date
        ? new Date(selectPrivilageValues.Date)?.toLocaleString()
        : null;

    /*
   style={{
                '&::-webkit-scrollbar': {
                    width: '9px',
                    height:'4px',
                    borderRadius: '8px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
       
                '&::-webkit-scrollbar-thumb': {
        
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
            }}
   
   */
    return (
        <DataGrid width={'100%'} style={{ marginTop: '24px', padding: 0 }}>
            <Table style={{ padding: 0, margin: 0 }}>
                <TableHeader >
                    <Column width={212}>Privilege Scope
                        <ChevronUpDown style={{ height: '16px' }} />
                    </Column>
                    <Column width={212}>Privilege Type
                        <ChevronUpDown style={{ height: '16px' }} />
                    </Column>
                    <Column width={212}>
                        Expiry
                        <ChevronUpDown style={{ height: '16px' }} />
                    </Column>
                    <Column width={212}>Delegation
                        <ChevronUpDown style={{ height: '16px' }} />
                    </Column>
                    <Column width={212}>No of Users
                        <ChevronUpDown style={{ height: '16px' }} />
                    </Column>
                </TableHeader>
                <TableBody>
                    <Row >
                        <Cell >{selectPrivilageValues.ScopeOfPrivilege}</Cell>
                        <Cell>{previlageType}</Cell>
                        <Cell>{selectPrivilageValues.ExpiryOfPrivilage === "Infinite" ? "Infinite" :
                            (date + '').split(',')[0].trim()}</Cell>
                        <Cell>{selectPrivilageValues.DelegatePower.replace(/-/g, ' ')}</Cell>
                        <Cell >
                            <FlexBox justifyContent={'space-between'}>
                                <Text>{selectPrivilageValues.NumberOfUsers}</Text>
                                <ThreeDotsVertical />
                            </FlexBox>
                        </Cell>
                    </Row>
                </TableBody>
            </Table>

        </DataGrid>
    )
}
/**
 * format(parse(date?date:'', 'dd/MM/yyyy, HH:mm:ss', new Date()), 'dd/MM/yyyy')}
 *   <Cell>{selectPrivilageValues.ScopeOfPrivilege}</Cell>
                        <Cell>{previlageType}</Cell>
                        <Cell>{selectPrivilageValues.ExpiryOfPrivilage==="Infinite"?"Infinite":
                            date}</Cell>
                        <Cell>{selectPrivilageValues.DelegatePower}</Cell>
                        <Cell >{selectPrivilageValues.NumberOfUsers}
                            <ThreeDotsVertical/>
                        </Cell>
 * 
 * 
 */

export default UserPrevilagePreview