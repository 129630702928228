/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import {
    FlexBox, Item, Label, RadioButton, RadioGroup, TextField, Text,
    Search
} from "@filament/react";
import OrgAddress from "./OrgAddress";
// import Address from "./Address";
import { countries } from "../../../../helpers/countries";
import { Key, Selection } from "@filament/react/types";
import Address from "./Address";
import { checkRegion, countryFormate } from "./CountriesList";
import { useEffect, useState } from "react";
import React from "react";
import { useUnifiedContexts } from "../../../../components/Context/Context";
import { OrgEntry, 
    // OrgStatus 
} from "../../../../graphql/generated";
import { organisationTypeInterface } from "../../../../components/Context/OrganizationContext";
import PhoneInput from "./PhoneNumber";
import { Cross , ChevronDown } from "@filament-icons/react";

export default function OrgDetails({
    // handleInputChange,
    mobileCode,
    setMobileCode,
    emailError,
    error,
    setError,
    Region,
    isEdit,

}:
    {
        // handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        mobileCode: Selection,
        setMobileCode: React.Dispatch<React.SetStateAction<Selection>>,
        emailError: boolean | undefined,
        error: boolean | undefined;
        setError: React.Dispatch<React.SetStateAction<{ phoneNumber: boolean, email: boolean }>>;
        Region: string,
        isEdit?: boolean

    }) {

    const [vatError, setVatError] = useState<boolean>(false);
    const [map, setMap] = useState<any>(null);
    const [isLocationSelected, setIsLocationSelected] = useState(false);
    const [countryList, setCountryList] = useState<any>(countries);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const { formData,
        setFormData,
        setInputValue,
        country,
        orgValues,
        setCountry,
        setOrgValues,
        valueType,
        setValueType, setMapValues, mapValues, } = useUnifiedContexts();
    const resetFormData = () => {
        setFormData({
            organizationName: "",
            organizationAddress: "",
            city: "",
            state: "",
            postcode: "",
            organizationWebsite: "",
            vat: "",
            duns: "",
            placeId: ''
        });
        setInputValue('');
    };

    const [isTopSectionDisplayed, setIsTopSectionDisplayed] = React.useState(false);
    useEffect(() => {
        if (valueType.SelectAddType === OrgEntry.Address) {
            // Wait for Address section to render and update the state
            setIsTopSectionDisplayed(true);
        } else {
            // Reset if the condition for Address is no longer met
            setIsTopSectionDisplayed(false);
        }
    }, [valueType.SelectAddType]);


    const handleLoad = (mapInstance: any) => {
        setMap(mapInstance);
        const usaBounds = getCountryBounds('');
        if (usaBounds) {
            restrictToCountry(mapInstance, usaBounds);
        }
    };

    const restrictToCountry =
        (mapInstance: {
            fitBounds: (arg0: google.maps.LatLngBounds) => void;
            setOptions: (arg0: {
                restriction: { latLngBounds: google.maps.LatLngBounds; strictBounds: boolean; };
            }) => void;
        },
        bounds: { north: any; south: any; west: any; east: any; }) => {
            if (isLocationSelected) return; // Skip restrictions if a location is selected

            const latLngBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(bounds.south, bounds.west),
                new window.google.maps.LatLng(bounds.north, bounds.east)
            );
            mapInstance.fitBounds(latLngBounds);
            mapInstance.setOptions({
                restriction: {
                    latLngBounds,
                    strictBounds: true,
                },
            });
        };

    const handleCountryChange = (e: { target: { value: Key } }) => {
        const selectedCountry = e.target.value;
        if (typeof selectedCountry === 'string') {
            setCountry(selectedCountry);
            // Clear form data related to address
            setFormData(prev => ({
                ...prev,
                organizationAddress: "",
                city: "",
                state: "",
                postcode: "",
                placeId: ""
            }));
            // Clear map values
            setMapValues(prev => ({
                ...prev,
                center: { lat: 0, lng: 0 },
                zoom: 4,
                suggestions: []
            }));
            // Reset input value
            setInputValue("");
            const countryBounds = getCountryBounds(selectedCountry);
            if (map && countryBounds) {
                restrictToCountry(map, countryBounds);
            }
        }
    };

    const getCountryBounds = (countryKey: Key): { north: number; south: number; west: number; east: number; } | null => {
        if (typeof countryKey !== 'string') {
            return null;  // Return null for non-string keys
        }

        const countryEntry = countries.find((entry) => entry.startsWith(countryKey));
        if (countryEntry) {
            const boundsMatch = countryEntry.match(/North: ([\d.-]+), South: ([\d.-]+), West: ([\d.-]+), East: ([\d.-]+)/);
            if (boundsMatch) {
                return {
                    north: parseFloat(boundsMatch[1]),
                    south: parseFloat(boundsMatch[2]),
                    west: parseFloat(boundsMatch[3]),
                    east: parseFloat(boundsMatch[4]),
                };
            }
        }
        return null;
    };

    const handleSelectAddTypeChange = (selectedType: OrgEntry) => {
        resetFormData(); // Clear formData
        setValueType((prev: any) => ({
            ...prev,
            SelectAddType: selectedType,
        }));
    };

    const handleSelection = (key: Key) => {
        const [country, countryCode] = (key as string).split(':').map(part => part.trim());
        const arraytemp = checkRegion(country);
        const temp: countryFormate = arraytemp.value;
        setOrgValues((prev) => ({
            ...prev,
            countryRegex: new RegExp(temp.vatRegex || ''),
            validationCountry: temp,
            countryCode: country === 'Greece' ? 'EL' : countryCode,
            region: arraytemp.region,
            country: key as string

        }));
        setFormData((prev) => ({
            ...prev,
            vat: ''
        }));

        if (key !== '') {
            setCountry(key);
        }
    };

    // VAT validity check function
    const isVATValid = () => {
        const vatPattern = new RegExp(orgValues.validationCountry.vatRegex || '');
        setVatError(vatPattern.test(formData.vat));
        return vatError && formData.vat !== '';
    };

    // DUNs validity check function
    const isDUNsValid = () => {
        const dunsPattern = /^[1-9][0-9]{8}$/;
        return dunsPattern.test(formData.duns);
    };

    const handleChange = (field: keyof typeof formData) => (value: string) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);

        if (value && window.google && window.google.maps) {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            const countryStr = String(country); // Ensure it's treated as a string
            const countryCode = countryStr.includes(':') ? countryStr.split(':')[1]?.trim().toLowerCase() : '';
            autocompleteService.getPlacePredictions(
                {
                    input: value,
                    location: new window.google.maps.LatLng(mapValues.center.lat, mapValues.center.lng),
                    radius: 50000, // Bias results to 50km around the center
                    componentRestrictions: countryCode ? { country: countryCode } : undefined,
                },
                (predictions, status) => {
                    if (status === "OK" && predictions) {
                        setMapValues((prev) => ({ ...prev, suggestions: predictions }));
                    } else {
                        setMapValues((prev) => ({ ...prev, suggestions: [] }));
                    }
                }
            );
        } else {
            setMapValues((prev) => ({ ...prev, suggestions: [] }));
        }
    };

    const onSearch = (searchText: string) => {
        if (!searchText) {
            setCountryList(countries);
            return;
        }
        const lowerSearchText = searchText.toLowerCase();
        
        const filteredCountries = countries.filter((item) => {
            const countryName = item.split(' : ')[0].trim().toLowerCase();
            return countryName.includes(lowerSearchText);
        });
        // Sort results: countries starting with the search text come first
        filteredCountries.sort((a, b) => {
            const nameA = a.split(' : ')[0].trim().toLowerCase();
            const nameB = b.split(' : ')[0].trim().toLowerCase();
            const aStartsWith = nameA.startsWith(lowerSearchText);
            const bStartsWith = nameB.startsWith(lowerSearchText);
            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;
            // If both start with or both don't start with the search text,
            // sort alphabetically
            return nameA.localeCompare(nameB);
        });
        
        setCountryList(filteredCountries);
    };

    return (
        <>
            {isEdit === true && <FlexBox marginTop={'20px'}>
                <Text variant='heading-xs' weight='bold'>Organization Details</Text>
            </FlexBox>}
            <FlexBox style={{ flexDirection: "column", paddingTop: "24px" }}>
                <Label style={{ paddingBottom: "16px" }}>Country</Label>
                {country === "" ? 
                    <div style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative',
                    }}>
                        <Search
                            inputProps={{
                                style: { cursor: isDropdownOpen ? "text" : "pointer" },
                                className : "search__country__input"
                            }}
                            className="search__country"
                            items={countryList}
                            onInputChange={onSearch}
                            shape="square"
                            placeholder="Select Country"
                            aria-label="destination"
                            isFullWidth
                            menuTrigger="focus"
                            onOpenChange={(isOpen: boolean) => {
                                if (isOpen) {
                                    setIsDropdownOpen(true);
                                }else{
                                    setIsDropdownOpen(false);
                                }
                            }
                            }
                            onSelectionChange={(key: Key | null) => {
                                if (key) {
                                    handleSelection(key);
                                    handleCountryChange({ target: { value: key } });
                                }
                            }}
                        >
                            {countryList.map((country: any) => (
                                <Item textValue={country.split(':')[0]} key={country}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        gap: '15px'
                                    }}>
                                        <div style={{
                                            borderRadius: `${country.split(":")[1]?.trim() === 'XK' ? '0px' : `15px`}`,
                                            overflow: "hidden",
                                            display: "inline-block",
                                        }}>
                                            <img
                                                style={{
                                                    display: "block",
                                                }}
                                                width={'38px'}
                                                src={country.split(":")[1]?.trim() === 'XK' ? 
                                                    '/images/32px-Flag_of_Kosovo.svg.webp' :
                                                    `https://flagsapi.com/${country.split(":")[1]?.trim()}/flat/32.png`}
                                                alt="flag"
                                            />
                                        </div>
                                        <span>
                                            {country.split(":")[0]}
                                        </span>
                                    </div>
                                </Item>
                            ))}
                        </Search>
                        <ChevronDown 
                            size="regular"
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                cursor: 'pointer',
                                transform: `translateY(-50%) 
                                rotate(${isDropdownOpen ? '180deg' : '0deg'})`,
                                zIndex: 1,
                                pointerEvents: isDropdownOpen ? "auto" : "none"
                            }}
                        />
                    </div>
                    : 
                    <div style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px 5px 5px 10px',
                        width: '100%',
                        borderRadius: '8px',
                        border: '1px solid #6B8094',
                        height: '40px',
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: '15px'
                        }}>
                            <span style={{
                                borderRadius: `${String(country).split(":")[1]?.trim() === 'XK' ? '0px' : `15px`}`,
                                overflow: "hidden",
                                display: "inline-block",
                            }}>
                                <img
                                    style={{
                                        display: "block",
                                    }}
                                    width={'38px'}
                                    src={String(country).split(":")[1]?.trim() === 'XK' ? '/images/32px-Flag_of_Kosovo.svg.webp' :
                                        `https://flagsapi.com/${String(country).split(":")[1]?.trim()}/flat/32.png`}
                                    alt="flag"
                                />
                            </span>
                            <span>
                                {String(country).split(":")[0]}
                            </span>
                        </div>
                        <div 
                            onClick={() => {
                                handleSelection("");
                                handleCountryChange({ target: { value: "" } });
                                onSearch("");
                                setIsDropdownOpen(false);
                            }}
                            style={{
                                cursor: 'pointer',
                                padding: "6px",
                                width: "36px",
                                height: "36px",
                            }}>
                            <Cross
                                size="regular"
                                style={{
                                    border: 'none',
                                }}
                            />
                        </div>
                    </div>
                }
            </FlexBox>
            <FlexBox style={{ flexDirection: "column", gap: "4px", paddingTop: '24px' }}>
                <Label htmlFor="SelectAddType">Select Type of Entry</Label>
                <RadioGroup
                    orientation="horizontal"
                    id="SelectAddType"
                    name="SelectAddType"
                    aria-label="SelectAddType"
                    onChange={(event) => handleSelectAddTypeChange(event as OrgEntry)}
                    value={valueType.SelectAddType}
                >
                    <RadioButton aria-label="DUNs" isDisabled={isEdit && valueType.SelectAddType !== OrgEntry.Duns}
                        value={OrgEntry.Duns}>DUNs</RadioButton>
                    <RadioButton aria-label="VAT" isDisabled={isEdit && valueType.SelectAddType !== OrgEntry.Tin}
                        value={OrgEntry.Tin}>Tax Identification Number</RadioButton>
                    <RadioButton aria-label="Address" isDisabled={isEdit && valueType.SelectAddType !== OrgEntry.Address}
                        value={OrgEntry.Address}>Address</RadioButton>
                </RadioGroup>
            </FlexBox>

            {valueType.SelectAddType === OrgEntry.Duns &&
                (<div style={{ paddingTop: '24px' }}>
                    <>
                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>DUNs Number</Label>
                            <TextField placeholder="Enter Duns Number" isFullWidth type='tel' maxLength={9}
                                inputProps={{ pattern: orgValues.validationCountry.vatRegex || '' }}
                                value={formData.duns}
                                aria-label="duns number"
                                onChange={handleChange("duns")} />
                        </FlexBox>
                        <OrgAddress isVATValid={isVATValid} isDUNsValid={isDUNsValid} />
                    </>
                </div>)
            }

            {valueType.SelectAddType === OrgEntry.Tin &&
                (<div style={{ paddingTop: '24px' }}>
                    <>
                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>Tax Identification Nnumber</Label>
                            <TextField placeholder="Tax ID No for US/EU/Australia/VAT for Belgium,Germany etc"
                                isFullWidth type="text" maxLength={18}
                                inputProps={{ pattern: "^(?=.*[0-9])[A-Z0-9]+$" }}
                                value={formData.vat.length === 0 ?
                                    (Region === 'EU' ? orgValues.countryCode.toUpperCase() : '') : formData.vat}
                                aria-label="vat number"
                                onChange={handleChange("vat")}
                                isInvalid={!vatError && formData.vat !== ''}
                                errorMessages={['Enter the valid VAT.']}
                                isDisabled={orgValues.validationCountry.country.length === 0}
                            />
                        </FlexBox>

                        <OrgAddress isVATValid={isVATValid} isDUNsValid={isDUNsValid} />
                    </>
                </div>)
            }

            {valueType.SelectAddType === OrgEntry.Address &&
                (<div>
                    <Address
                        handleInputChange={handleInputChange}
                        onMapLoad={handleLoad}
                        map={map}
                        setIsLocationSelected={setIsLocationSelected}
                        selectedCountryBounds={getCountryBounds(country)}
                        country={String(country)}
                    />
                </div>)
            }
            {(valueType.SelectAddType === OrgEntry.Duns ||
                valueType.SelectAddType === OrgEntry.Tin ||
                (valueType.SelectAddType === OrgEntry.Address && isTopSectionDisplayed)) &&
                <FlexBox marginTop={'24px'} style={{ gap: "20px" }}>
                    <FlexBox flexDirection={'column'} gap={12} width={'50%'}>

                        <Label>Phone Number</Label>

                        <PhoneInput
                            mobileNumber={orgValues.phoneNumber}
                            setMobileNumber={setOrgValues}
                            inputValue={mobileCode}
                            setInputValue={setMobileCode}
                            error={error}
                            setError={setError}
                            isEdit={isEdit}
                            selectedCountry={String(country)}
                        />

                    </FlexBox >

                    <FlexBox flexDirection={'column'} gap={12} width={'50%'}>
                        <Label>Email Address</Label>
                        <TextField type={'email'} placeholder="Email" isFullWidth value={orgValues.emailId} onChange={(event) => {
                            setOrgValues((prev: organisationTypeInterface) => ({
                                ...prev,
                                emailId: event
                            }))

                        }} aria-label="email" isInvalid={emailError} onFocus={
                            () =>
                                setError((prev) => ({ ...prev, email: false }))} errorMessages={emailError ?
                            "Invalid email address." : undefined} />
                    </FlexBox>

                </FlexBox>}
            {/* {isEdit && <FlexBox flexDirection="column" marginTop={24} gap={16}>
                <Label>Account status</Label>
                <FlexBox gap={10}>
                    {[OrgStatus.Active, OrgStatus.Inactive].map((item) => (
                        <Button
                            key={item}
                            onPress={() => {
                                if (item === OrgStatus.Inactive) {
                                    setIsVisibleModal(true)
                                }
                                else {
                                    setIsVisibleModal(false)
                                }
                                setOrgValues((prev) => ({
                                    ...prev,
                                    status: item
                                }))
                            }}
                            shape="square"
                            isFullWidth
                            width={'20%'}
                            style={{
                                backgroundColor: `${item === orgValues.status ? '#0072DB' : ''}`,
                                color: `${item === orgValues.status ? 'white' : ''}`,
                                borderRadius: `${item === OrgStatus.Active ? '8px 0 0 8px' : '0 8px 8px 0'}`,
                                fontSize: "clamp(10px, 2vw, 16px)",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                        </Button>
                    ))}
                </FlexBox>
                {isModalVisible && <OrgDeactivateModal setIsModalVisible={setIsVisibleModal} />}
            </FlexBox>} */}
        </>
    );}
