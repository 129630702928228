export const countries = [
    "Afghanistan : AF : +93 | ",
    "Albania : AL : +355 | : North: 42.6611, South: 39.6447, West: 19.2934, East: 21.0573",
    "Algeria : DZ : +213 | ",
    "Andorra : AD : +376 | : North: 42.656, South: 42.4285, West: 1.4133, East: 1.7864",
    "Angola : AO : +244 | ",
    "Antigua and Barbuda : AG : +1-268 | ",
    "Argentina : AR : +54 | ",
    "Armenia : AM : +374 | ",
    "Australia : AU : +61 | ",
    "Austria : AT : +43 | : North: 49.021, South: 46.3786, West: 9.5308, East: 17.1606",
    "Azerbaijan : AZ : +994 | ",
    "Bahamas : BS : +1-242 | ",
    "Bahrain : BH : +973 | ",
    "Bangladesh : BD : +880 | ",
    "Barbados : BB : +1-246 | ",
    "Belarus : BY : +375 | : North: 56.1679, South: 51.2351, West: 23.1653, East: 32.7746",
    "Belgium : BE : +32 | : North: 51.5051, South: 49.497, West: 2.5447, East: 6.4081",
    "Belize : BZ : +501 | ",
    "Benin : BJ : +229 | ",
    "Bhutan : BT : +975 | ",
    "Bolivia : BO : +591 | ",
    "Bosnia and Herzegovina : BA : +387 | : North: 45.2392, South: 42.5593, West: 15.7287, East: 19.6237",
    "Botswana : BW : +267 | ",
    "Brazil : BR : +55 | ",
    "Brunei : BN : +673 | ",
    "Bulgaria : BG : +359 | : North: 44.2176, South: 41.2354, West: 22.3652, East: 28.6121",
    "Burkina Faso : BF : +226 | ",
    "Burundi : BI : +257 | ",
    "Cabo Verde : CV : +238 | ",
    "Cambodia : KH : +855 | ",
    "Cameroon : CM : +237 | ",
    "Canada : CA : +1 | ",
    "Central African Republic : CF : +236 | ",
    "Chad : TD : +235 | ",
    "Chile : CL : +56 | ",
    "China : CN : +86 | ",
    "Colombia : CO : +57 | ",
    "Comoros : KM : +269 | ",
    "Congo : CG : +242 | ",
    "Costa Rica : CR : +506 | ",
    "Croatia : HR : +385 | : North: 46.555, South: 42.3384, West: 13.4897, East: 19.4271",
    "Cuba : CU : +53 | ",
    "Cyprus : CY : +357 | : North: 35.707, South: 34.5636, West: 32.2727, East: 34.597",
    "Czechia (Czech Republic) : CZ : +420 | : North: 51.0558, South: 48.5569, West: 12.0907, East: 18.8604",
    "Denmark : DK : +45 | : North: 57.7484, South: 54.5625, West: 8.0899, East: 12.6936",
    "Djibouti : DJ : +253 | ",
    "Dominica : DM : +1-767 | ",
    "Dominican Republic : DO : +1-809 | ",
    "Ecuador : EC : +593 | ",
    "Egypt : EG : +20 | ",
    "El Salvador : SV : +503 | ",
    "Equatorial Guinea : GQ : +240 | ",
    "Eritrea : ER : +291 | ",
    "Estonia : EE : +372 | : North: 59.6749, South: 57.5158, West: 21.8021, East: 28.2104",
    "Eswatini : SZ : +268 | ",
    "Ethiopia : ET : +251 | ",
    "Fiji : FJ : +679 | ",
    "Finland : FI : +358 | : North: 70.0923, South: 59.808, West: 19.4505, East: 31.5871",
    "France : FR : +33 | : North: 51.0891, South: 41.367, West: -5.1411, East: 9.5616",
    "Gabon : GA : +241 | ",
    "Gambia : GM : +220 | ",
    "Georgia : GE : +995 | ",
    "Germany : DE : +49 | : North: 55.0815, South: 47.3025, West: 5.8663, East: 15.0419",
    "Ghana : GH : +233 | ",
    "Greece : GR : +30 | : North: 41.7489, South: 34.815, West: 19.3743, East: 26.6042",
    "Grenada : GD : +1-473 | ",
    "Guatemala : GT : +502 | ",
    "Guinea : GN : +224 | ",
    "Guinea-Bissau : GW : +245 | ",
    "Guyana : GY : +592 | ",
    "Haiti : HT : +509 | ",
    "Holy See : VA : +379 | ",
    "Honduras : HN : +504 | ",
    "Hungary : HU : +36 | : North: 48.585, South: 45.737, West: 16.111, East: 22.906",
    "Iceland : IS : +354 | : North: 66.566, South: 63.393, West: -24.544, East: -13.495",
    "India : IN : +91 | : North: 35.5087, South: 6.7535, West: 68.111, East: 97.3956",
    "Indonesia : ID : +62 | ",
    "Iran : IR : +98 | ",
    "Iraq : IQ : +964 | ",
    "Ireland : IE : +353 | : North: 55.386, South: 51.386, West: -10.471, East: -5.999",
    "Israel : IL : +972 | ",
    "Italy : IT : +39 | : North: 47.094, South: 35.496, West: 6.602, East: 18.512",
    "Jamaica : JM : +1-876 | ",
    "Japan : JP : +81 | ",
    "Jordan : JO : +962 | ",
    "Kazakhstan : KZ : +7 | ",
    "Kenya : KE : +254 | ",
    "Kiribati : KI : +686 | ",
    "Korea (North) : KP : +850 | ",
    "Korea (South) : KR : +82 | ",
    "Kosovo : XK : +383 | ",
    "Kuwait : KW : +965 | ",
    "Kyrgyzstan : KG : +996 | ",
    "Laos : LA : +856 | ",
    "Latvia : LV : +371 | : North: 58.084, South: 55.674, West: 20.978, East: 28.243",
    "Lebanon : LB : +961 | ",
    "Lesotho : LS : +266 | ",
    "Liberia : LR : +231 | ",
    "Libya : LY : +218 | ",
    "Liechtenstein : LI : +423 | : North: 47.270, South: 47.048, West: 9.475, East: 9.632",
    "Lithuania : LT : +370 | : North: 56.446, South: 53.905, West: 20.946, East: 26.835",
    "Luxembourg : LU : +352 | : North: 50.182, South: 49.446, West: 5.735, East: 6.543",
    "Madagascar : MG : +261 | ",
    "Malawi : MW : +265 | ",
    "Malaysia : MY : +60 | ",
    "Maldives : MV : +960 | ",
    "Mali : ML : +223 | ",
    "Malta : MT : +356 | : North: 36.075, South: 35.799, West: 14.183, East: 14.567",
    "Marshall Islands : MH : +692 | ",
    "Mauritania : MR : +222 | ",
    "Mauritius : MU : +230 | ",
    "Mexico : MX : +52 | ",
    "Micronesia : FM : +691 | ",
    "Moldova : MD : +373 | : North: 48.491, South: 45.469, West: 26.619, East: 30.134",
    "Monaco : MC : +377 | : North: 43.751, South: 43.719, West: 7.372, East: 7.439",
    "Mongolia : MN : +976 | ",
    "Montenegro : ME : +382 | : North: 43.556, South: 41.849, West: 18.455, East: 20.355",
    "Morocco : MA : +212 | ",
    "Mozambique : MZ : +258 | ",
    "Myanmar (Burma) : MM : +95 | ",
    "Namibia : NA : +264 | ",
    "Nauru : NR : +674 | ",
    "Nepal : NP : +977 | ",
    "Netherlands : NL : +31 | : North: 53.510, South: 50.753, West: 3.331, East: 7.227",
    "New Zealand : NZ : +64 | ",
    "Nicaragua : NI : +505 | ",
    "Niger : NE : +227 | ",
    "Nigeria : NG : +234 | ",
    "North Macedonia : MK : +389 | : North: 42.373, South: 40.862, West: 20.462, East: 23.033",
    "Norway : NO : +47 | : North: 71.184, South: 57.990, West: 4.626, East: 31.079",
    "Oman : OM : +968 | ",
    "Pakistan : PK : +92 | ",
    "Palau : PW : +680 | ",
    "Panama : PA : +507 | ",
    "Papua New Guinea : PG : +675 | ",
    "Paraguay : PY : +595 | ",
    "Peru : PE : +51 | ",
    "Philippines : PH : +63 | ",
    "Poland : PL : +48 | : North: 54.835, South: 49.004, West: 14.123, East: 24.150",
    "Portugal : PT : +351 | : North: 42.154, South: 36.839, West: -9.526, East: -6.189",
    "Qatar : QA : +974 | ",
    "Romania : RO : +40 | : North: 48.265, South: 43.628, West: 20.261, East: 29.693",
    "Russia : RU : +7 | ",
    "Rwanda : RW : +250 | ",
    "Saint Kitts and Nevis : KN : +1-869 | ",
    "Saint Lucia : LC : +1-758 | ",
    "Saint Vincent and the Grenadines : VC : +1-784 | ",
    "Samoa : WS : +685 | ",
    "San Marino : SM : +378 | ",
    "Sao Tome and Principe : ST : +239 | ",
    "Saudi Arabia : SA : +966 | ",
    "Senegal : SN : +221 | ",
    "Serbia : RS : +381 | : North: 46.192, South: 42.231, West: 18.819, East: 23.006",
    "Seychelles : SC : +248 | ",
    "Sierra Leone : SL : +232 | ",
    "Singapore : SG : +65 | ",
    "Slovakia : SK : +421 | : North: 49.607, South: 47.758, West: 16.845, East: 22.565",
    "Slovenia : SI : +386 | : North: 46.877, South: 45.419, West: 13.376, East: 16.606",
    "Solomon Islands : SB : +677 | ",
    "Somalia : SO : +252 | ",
    "South Africa : ZA : +27 | ",
    "South Sudan : SS : +211 | ",
    "Spain : ES : +34 | : North: 43.791, South: 35.947, West: -9.301, East: 3.332",
    "Sri Lanka : LK : +94 | ",
    "Sudan : SD : +249 | ",
    "Suriname : SR : +597 | ",
    "Sweden : SE : +46 | : North: 69.062, South: 55.337, West: 11.109, East: 24.166",
    "Switzerland : CH : +41 | : North: 47.807, South: 45.825, West: 5.956, East: 10.495",
    "Syria : SY : +963 | ",
    "Taiwan : TW : +886 | ",
    "Tajikistan : TJ : +992 | ",
    "Tanzania : TZ : +255 | ",
    "Thailand : TH : +66 | ",
    "Timor-Leste : TL : +670 | ",
    "Togo : TG : +228 | ",
    "Tonga : TO : +676 | ",
    "Trinidad and Tobago : TT : +1-868 | ",
    "Tunisia : TN : +216 | ",
    "Turkey : TR : +90 | ",
    "Turkmenistan : TM : +993 | ",
    "Tuvalu : TV : +688 | ",
    "Uganda : UG : +256 | ",
    "Ukraine : UA : +380 | : North: 52.379, South: 44.388, West: 22.137, East: 40.228",
    "United Arab Emirates : AE : +971 | ",
    "United Kingdom : GB : +44 | : North: 60.860, South: 49.911, West: -8.623, East: 1.766",
    "United States : US : +1 | ",
    "Uruguay : UY : +598 | ",
    "Uzbekistan : UZ : +998 | ",
    "Vanuatu : VU : +678 | ",
    "Vatican City : VA : +379 | ",
    "Venezuela : VE : +58 | ",
    "Vietnam : VN : +84 | ",
    "Yemen : YE : +967 | ",
    "Zambia : ZM : +260 | ",
    "Zimbabwe : ZW : +263 | "
];
  