import { ErrorFill } from "@filament-icons/react";
import { Button, CloseNotification, NotificationActions, NotificationContent, NotificationHeader } from "@filament/react";
import React from "react";

export const ToastNotification = ({ close, message, props }: {
    close: CloseNotification, message: string,
    props?: React.ReactNode
}) => (

    <>
        <NotificationHeader>
            <ErrorFill />
        </NotificationHeader>
        <NotificationContent>
            {message}{props}
        </NotificationContent>
        <NotificationActions>
            {false && <Button variant="quiet" onPress={close}>
                Close
            </Button>}
        </NotificationActions>
    </>
);