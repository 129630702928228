/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Cross, Plus, Trashcan } from "@filament-icons/react"
import { Button, FlexBox, Item, MenuButton } from "@filament/react"
import * as styles from './styles/styles.css'
import React, { useRef, useState } from "react"
import Preset, { PresetRef } from "./components/Presets"
import {
    Dialog,
    DialogActions,
    DialogContainer,
    DialogContent,
    DialogTitle,
} from "@filament/react";
import { atomicDividerHorizontal as dividerHorizontal } from "@filament/react/styles";
import { Key } from "@filament/react/types"
import TableComponent, {   TableRow } from "./SiteStructure"
import { useUnifiedContexts } from "../../../components/Context/Context"
import { generateTableData } from "../../../workers/trieInstance"
import { AddNextLevelFields } from "./components/AddNextLevelFields"
import { checkIfAnyEndOfWord, deleteEndNodes, extractLastItems, findFirstChild, getApiHierarchyIds, getChildCount, getCollapsedLabel, getCommonEndNodeLabel,
    getSameLevelNodeLabelArrayFromTrie, 
    getSiblingLabelArray, 
    hasChildrenInAnyPath, 
    insertChildrenAtNodes, insertEndNodeAtPaths, insertSiblingsAtPaths, updateSelectedLabels,
    updateTrieWithHierarchy, 
} from "./components/TrieDataStructure"
import SiteStructurePreview from "./components/SiteStructurePreview"
import { calculateRowSpans, cloneTrie, initializeRowSpanMap } from "./components/AddNodeActivities"
import { mergeUniqueValues } from "../../../CommonMethods/mergeUniqueArrays"
import { removeDuplicateArrays } from "../../../components/Context/SiteContext"
import {  SiteLevelType, SiteStructureLabelFormat, useSiteStructureDeleteMutation, useSiteStructureUpdateMutation } from "../../../graphql/generated"
import {  useSiteStructureCreateMutation } from "../../../graphql/generated"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { GET_SITE_DATA } from "../../../graphql/queries/getSiteViewDetails.graphql"

export default function SiteStructureSelection() {
    const { selectedPreset,
        setSelectedPreset,
        setTableData,
        trie,
        setTrie,
        pathOfSelectedLabel,
        collapsedNodes,
        setCollapsedNodes,
        setLevelValues,
        setNextFieldValues,
        setRowSpanMap,
        selectedLabel,
        tableData,
        setPreviewTableData,
        setSelectedLabel,
        isCustomEdit,
        setIsCustomEdit,
        setSiteStructureTopPath,
        setIsNextPreviewButtonCliked,
        setPathOfSelectedLabel,
        setPreviewRowSpanMap,
        rowSpanMap,
        previewTableData,
        previewPreset,
        setPreviewPreset,
        setPreviewTrie,
        setPreviewCollapsedNodes,
        isLevelsClicked,
        setIsLevelsClicked,
        editedSiteId,
        nextFieldValues } = useUnifiedContexts()

    const [addNextLevel, setAddNextLevel] = useState('')
    const [isEditClicked, setIsEditClicked] = useState(false)
    const presetRef = useRef<PresetRef>(null);
    const handlePresetClick = () => {
        if (presetRef.current) {
            presetRef.current.setPresetClicked();
        }
    };
    const siteActivateId = useSelector((state:RootState)=>state.persistedState.siteIdToActivate)
    const [errorLabelMessage, setErrorLabelMessage] = useState(false)
    const [createSiteStructure] = useSiteStructureCreateMutation()
    const [siteStructureUpdate] = useSiteStructureUpdateMutation()
    const [deleteSiteStructureNodes] = useSiteStructureDeleteMutation()
    const onClickSave = () => {
        if (isCustomSiteClicked === 'Custom site structure') {
            setSelectedPreset('Custom site structure')
        }
        setPreviewCollapsedNodes(collapsedNodes)
        setIsCustomEdit(true)
        const longestPath = tableData.reduce((longest, row) =>
            row.path.length > longest.path.length ? row : longest, tableData[0]
        );
        handlePresetClick()
        setPreviewPreset(selectedPreset)
        setPreviewTrie(trie)
        setPreviewTableData(tableData)
        setPreviewRowSpanMap(rowSpanMap)
        setSiteStructureTopPath(longestPath.path);
        setIsCustomSiteClicked(null)
        setIsNextPreviewButtonCliked(true)
 
    }

    const [isCustomSiteClicked, setIsCustomSiteClicked] = useState<'Preset 1' |
        'Preset 2' |
        'Preset 3' |
        'Open Structure' | 'Custom site structure' | null>(null)

    const dismiss = () => {
        setIsCustomSiteClicked(null);
        setCollapsedNodes([])
    };

    const dismissNextLevel = () => {
        setIsEditClicked(false)
        setAddNextLevel('')
        setErrorLabelMessage(false)
        setNextFieldValues({
            isEndNodeLabel:false,
            label: '',
            label_format: '',
            no_of_units: NaN,
            isEndNodeChecked: false,
            EndNodeLabel: '',
            no_of_rooms: NaN
        })
    }

    const addToCollapsedNodes = (collapsedNodes: string[][], newNodes: string[][]): string[][] => {
        const collapsedSet = new Set(collapsedNodes.map(arr => JSON.stringify(arr)));
        const newCollapsedNodes = [...collapsedNodes]

        newNodes.forEach(newArr => {
            if(getChildCount(trie,newArr)>=5||(getCollapsedLabel(trie.root,newArr,addNextLevel==='Add child branches'?true:false)!=='')){
                const newArrStr = JSON.stringify(newArr);
                if (!collapsedSet.has(newArrStr)) {
                    newCollapsedNodes.push(newArr);
                    collapsedSet.add(newArrStr);
                }
                
            } 
            if(isEditClicked&&getChildCount(trie,newArr)<5&&(getCollapsedLabel(trie.root,newArr,true)==='') ) {
                // Remove newArr from collapsedSet if it exists
                const newArrStr = JSON.stringify(newArr);
                if (collapsedSet.has(newArrStr)) {
                    // Remove from newCollapsedNodes
                    const index = newCollapsedNodes.findIndex(item => JSON.stringify(item) === newArrStr);
                    if (index !== -1) {
                        newCollapsedNodes.splice(index, 1);
                    }
                    collapsedSet.delete(newArrStr);
                   
                }
            }
        });
        return newCollapsedNodes;
    };

    const handleAction = (action: Key) => {
        setIsEditClicked(false)
        if (action === 'Add End node') {
            const labelName: string | null = findFirstChild(trie.root, pathOfSelectedLabel[0].path)
            if (labelName !== null) {
                setNextFieldValues(prev => ({
                    ...prev,
                    label: /^\d+\s+\D+-.+$/.test(labelName)
                        ? (parseInt(labelName.split(' ')[0], 10) > 1
                            ? labelName.split(' ')[1].slice(0, -1)
                            : labelName.split(' ')[1])
                        : labelName.split(' ')[0],
                    label_format: '1-2-3'
                }))
            } else {
                setNextFieldValues(prev => ({
                    ...prev,
                    isEndNodeLabel:true,
                    label_format: '1-2-3'
                }))
            }


        }
        const isNumber = (str: string): boolean => /^\d+$/.test(str);
        if (action === 'Add siblings') {
            setNextFieldValues(prev => ({
                ...prev,
                label: /^\d+\s+\D+-.+$/.test(selectedLabel[0]) ? selectedLabel[0].split(' ')[1].split('-')[0] : selectedLabel[0].split(' ')[0],
                label_format: /^\d+\s+\D+-.+$/.test(selectedLabel[0]) ? '1-2-3' : isNumber(selectedLabel[0].split(' ')[1].split('-')[0]) ? '1-2-3' : 'a-b-c',
            }))
        } else if (action === 'Add child branches') {
            if (pathOfSelectedLabel.length === 1) {
                const labelName: string | null = findFirstChild(trie.root, pathOfSelectedLabel[0].path)
                if (labelName !== null) {
                    setNextFieldValues(prev => ({
                        ...prev,
                        label: /^\d+\s+\D+-.+$/.test(labelName) ? labelName.split(' ')[1] : labelName.split(' ')[0],
                        label_format: /^\d+\s+\D+-.+$/.test(labelName) ? '1-2-3' : isNumber(labelName.split(' ')[1]) ? '1-2-3' : 'a-b-c'
                    }))
                }
            }

        }
        setAddNextLevel(action.toString())
    };
    const handleLevelAction = (action: Key) => {
        let labelsSelected: { path: string[] }[] = []
        if (action === 'Select siblings') {
            setIsLevelsClicked('Siblings')
            labelsSelected = getSiblingLabelArray(trie.root,pathOfSelectedLabel) 
            
        }
        else if (action === 'Select whole level') {
            setIsLevelsClicked('Level')
            labelsSelected = getSameLevelNodeLabelArrayFromTrie(trie.root, pathOfSelectedLabel)

        } else if (action === 'Unselect All') {
            setSelectedLabel([])
            setPathOfSelectedLabel([])
            return
        }
        const labelsArray = extractLastItems(labelsSelected);
        const mergedArray = mergeUniqueValues(labelsArray, selectedLabel)
        setSelectedLabel(mergedArray)
        const uniqueLabelsSelected = Array.from(
            new Map(labelsSelected.map(obj => [obj.path.join(','), obj])).values()
        );
        setPathOfSelectedLabel(uniqueLabelsSelected)


    }
    const presets = [
        {
            heading: "Open Structure", templateUnits: [""], footer: "No Levels",
            border: selectedPreset === 'Open Structure' ? "2px solid #0072DB" : ''
        },
        {
            heading: "Preset 1", templateUnits: ["site", "zone", "building", "floor", "room"],
            footer: "5 Levels",
            border: selectedPreset === 'Preset 1' ? "2px solid #0072DB" : ''
        },
        {
            heading: "Preset 2", templateUnits: ["site", "floor", "wing", "room"],
            footer: "4 Levels",
            border: selectedPreset === 'Preset 2' ? "2px solid #0072DB" : ''
        },
        {
            heading: "Preset 3", templateUnits: ["site", "tower", "floor", "room"],
            footer: "4 Levels",
            border: selectedPreset === 'Preset 3' ? "2px solid #0072DB" : ''
        }
    ];

    const handleAddNodes = () => {
        if (isEditClicked) {
            handleEdit()
        } else {
            
            if (!addNextLevel) return;

            const newTrie = cloneTrie(trie);
            const tableData: TableRow[] = [];

            if (addNextLevel === 'Add siblings') {
                insertSiblingsAtPaths(
                    newTrie,
                    pathOfSelectedLabel,
                    nextFieldValues.label,
                    nextFieldValues.no_of_units,
                    nextFieldValues.label_format === 'a-b-c' ? "letters" : "numbers"
                );
                if(/^\d+\s+\D+-.+$/.test(selectedLabel[0])) {
                    setSelectedLabel(prev => 
                        [`${nextFieldValues.no_of_units+parseInt(prev[0].split(' ')[0])} ${prev[0].split(' ').slice(1).join(' ')}`])
                }
                const nodesToCollapse = addToCollapsedNodes(collapsedNodes,pathOfSelectedLabel.map(path=>path.path.slice(0,-1)))
                if(nodesToCollapse.length>0){
                    setCollapsedNodes(nodesToCollapse)
                    handleLevelAction("Select siblings")
                }

            } else if (addNextLevel === 'Add child branches') {
                insertChildrenAtNodes(
                    newTrie,
                    pathOfSelectedLabel,
                    nextFieldValues.label,
                    nextFieldValues.no_of_units,
                    nextFieldValues.label_format === 'a-b-c' ? "letters" : "numbers",
                    nextFieldValues.isEndNodeChecked ? nextFieldValues.EndNodeLabel : undefined,
                    nextFieldValues.isEndNodeChecked ? nextFieldValues.no_of_rooms : undefined
                );
 
                const nodesToCollapse = addToCollapsedNodes(collapsedNodes, pathOfSelectedLabel.map(path=>path.path))
                if(nodesToCollapse.length>0){
                    setCollapsedNodes(nodesToCollapse)
                }

            } else if (addNextLevel === 'Add End node') {
                insertEndNodeAtPaths(
                    newTrie,
                    pathOfSelectedLabel,
                    nextFieldValues.label,
                    nextFieldValues.no_of_units
                );
                setNextFieldValues(prev=>({...prev,isEndNodeLabel:true}))
            }

            siteStructureNodeApiCall(addNextLevel)
            generateTableData(newTrie.root, [], tableData, [], newTrie.root);
            const rowSpanMap = initializeRowSpanMap(tableData);
            calculateRowSpans(tableData, rowSpanMap);
            setTableData(tableData);
            setTrie(newTrie);
            setRowSpanMap(rowSpanMap);

            

        }
        setAddNextLevel('');
        setNextFieldValues({
            isEndNodeLabel:false,
            label: '',
            label_format: '',
            no_of_units: NaN,
            isEndNodeChecked: false,
            EndNodeLabel: '',
            no_of_rooms: NaN
        });

    };

    const previewPageClicked = () => {
        setSelectedPreset(previewPreset);
        setIsCustomSiteClicked(previewPreset)
        setIsNextPreviewButtonCliked(true)
    }

    const checkLabels = (labels: string[]): { sameBase: boolean; sameFormat: boolean,no_of_siblings: number } => {
        if (labels.length === 0) {
            // You may decide what to return for an empty array.
            return { sameBase: true, sameFormat: true, no_of_siblings:0 };
        }
        const uniqueLabels = new Set(labels.map(label => label.split("-").slice(0, -1).join("-")));
        // Extract the base label (first word) from the first label.
        const firstParts = labels[0].split(" ");
        const baseLabel = /^\d+\s+\D+-.+$/.test(labels[0])?firstParts[1]:firstParts[0];
        // Determine the format for the first label based on its identifier.
        const firstIdentifier = firstParts[1] || "";
        const firstFormat = /^\d/.test(firstIdentifier) ? "numbers" : "letters";

        // Check each label in the array.
        for (const label of labels) {
            const parts = label.split(" ");
            // Check if the base label is the same.
            if (/^\d+\s+\D+-.+$/.test(labels[0])?
                parts[1].split('-').slice(0,-1).join('-')!==baseLabel.split('-').slice(0,-1).join('-'):parts[0] !== baseLabel) {
                return { sameBase: false, sameFormat: false, no_of_siblings:0 };
            }

            // Determine format for current label.
            const identifier = parts[1] || "";
            const currentFormat = /^\d/.test(identifier) ? "numbers" : "letters";

            // If the format doesn't match the first label's format, return false.
            if (currentFormat !== firstFormat) {
                return { sameBase: true, sameFormat: false ,no_of_siblings:0};
            }
        }
        const maxSiblings = /^\d+\s+\D+-.+$/.test(labels[0])?parseInt(labels[0].split(' ')[0]):uniqueLabels.size

        return { sameBase: true, sameFormat: true ,no_of_siblings:maxSiblings};
    };
    const handleEdit = () =>{
        setIsEditClicked(false)
        const newTrie = cloneTrie(trie);
        const tableData: TableRow[] = [];
        siteStructureNodeEditApiCall()
        updateSelectedLabels(
            newTrie,
            pathOfSelectedLabel,
            nextFieldValues.label,
            nextFieldValues.no_of_units,
            nextFieldValues.label_format === '1-2-3' ? 'numbers' : 'letters',
            nextFieldValues.isEndNodeChecked?nextFieldValues.EndNodeLabel:'',
            nextFieldValues.isEndNodeChecked?nextFieldValues.no_of_rooms:NaN
        )
        const nodesToCollapse = addToCollapsedNodes(collapsedNodes, removeDuplicateArrays(pathOfSelectedLabel.map(path=>path.path.slice(0,-1))))
        setCollapsedNodes(nodesToCollapse)
        

        generateTableData(newTrie.root, [], tableData, [], newTrie.root);
        const rowSpanMap = initializeRowSpanMap(tableData);
        calculateRowSpans(tableData, rowSpanMap);
        setTableData(tableData);
        setTrie(newTrie);
       
        setPathOfSelectedLabel([])
        setSelectedLabel([])
        setRowSpanMap(rowSpanMap);
    }

    const handleOnEditClick = () => {
        setIsEditClicked(true)
        const { sameBase, sameFormat,no_of_siblings } = checkLabels(selectedLabel);
        const endNode = getCommonEndNodeLabel(trie, pathOfSelectedLabel)
        if (sameBase) {
            setNextFieldValues(prev => ({
                ...prev,
                label: /^\d+\s+\D+-.+$/.test(selectedLabel[0])?selectedLabel[0].split(' ')[1].split('-')[0]:selectedLabel[0].split(' ')[0],
                no_of_units: no_of_siblings,
                label_format: sameFormat ? (!isNaN(Number(selectedLabel[0].split(' ')[1].split('-')[0])))||(/^\d+\s+\D+-.+$/.test(selectedLabel[0])) ? '1-2-3' : 'a-b-c' : 'Mixed',
                isEndNodeChecked:endNode!==''?true:false,
                EndNodeLabel: endNode.split(' ').length === 1 ? endNode === 'Mixed' ? endNode :
                    endNode.slice(0, -1) : parseInt(endNode.split(' ')[0], 10) > 1 ? endNode.split(' ')[1].slice(0, -1) : endNode.split(' ')[1],
                no_of_rooms: parseInt(endNode.split(' ')[0], 10)
            }))
        } else {
            setNextFieldValues(prev => ({
                ...prev,
                label: 'Mixed',
                label_format: '1-2-3',
                isEndNodeChecked:endNode!==''?true:false,
                EndNodeLabel: endNode.split(' ').length === 1 ? endNode === 'Mixed' ? endNode :
                    endNode.slice(0, -1) : parseInt(endNode.split(' ')[0], 10) > 1 ? endNode.split(' ')[1].slice(0, -1) : endNode.split(' ')[1],
                no_of_rooms: parseInt(endNode.split(' ')[0], 10)
            }))
        }
    }

    const handleDelteNode = () => {
        const newTrie = cloneTrie(trie);
        const tableData: TableRow[] = []; 
        const deletedIds = deleteEndNodes(newTrie, pathOfSelectedLabel)
        deleteSelectedSiteNodes(deletedIds)
        generateTableData(newTrie.root, [], tableData, [], newTrie.root);
        const rowSpanMap = initializeRowSpanMap(tableData);
        calculateRowSpans(tableData, rowSpanMap);
        setCollapsedNodes(prev => prev.filter(collapsedPath => 
            !pathOfSelectedLabel.some(pathArray => 
                JSON.stringify(pathArray.path.slice(0,-1)) === JSON.stringify(collapsedPath)
            )
        ));

        setSelectedLabel([])
        setPathOfSelectedLabel([])
        setTableData(tableData);
        setTrie(newTrie);
        setRowSpanMap(rowSpanMap);

    }

    const siteStructureNodeApiCall = async (childrenOrSiblings:string) => {
        try {
            // Fetch hierarchyIds once by traversing in order
            const selectedLabelsPath = 
            childrenOrSiblings==='Add child branches'||
            pathOfSelectedLabel?.length===1||
            childrenOrSiblings==='Add End node'
                ?pathOfSelectedLabel:
                pathOfSelectedLabel.map(path=>({path:path.path.slice(0, -1)}))
            const hierarchyIds = getApiHierarchyIds(trie.root,selectedLabelsPath);  
            const createStructureResponse = await  createSiteStructure({
                variables: {
                    nodeIds:hierarchyIds,
                    siteId: siteActivateId!==''?siteActivateId:editedSiteId,
                    levelType:childrenOrSiblings==='Add child branches'||childrenOrSiblings==='Add End node'?SiteLevelType.Children:SiteLevelType.Siblings,
                    input: {
                        count: nextFieldValues.no_of_units,
                        label: nextFieldValues.label,
                        labelFormat: nextFieldValues.label_format==='1-2-3'?SiteStructureLabelFormat.Option_1_2_3:SiteStructureLabelFormat.OptionABC,
                        isEndNode: nextFieldValues.isEndNodeLabel
                    }
                },refetchQueries: editedSiteId!==''
                    ? [
                        {
                            query: GET_SITE_DATA,
                            variables: {
                                id: editedSiteId
                            }
                        }
                    ]
                    : []
            });
            if(createStructureResponse){
                const siteStructures = createStructureResponse?.data?.siteStructureCreate?.siteStructure ?? [];

                // **Loop through all site structure objects**
                for (const structure of siteStructures) {
                    const hierarchy = structure?.hierarchy ?? [];
                    const nodes = structure?.nodes?.map(item => ({ id: item.id, label: item.label })) ?? [];

                    // Update Trie with each hierarchy/nodes pair
                    updateTrieWithHierarchy(trie.root, hierarchy, nodes);
                }

                // **Check if we need to create End Nodes**
                if (nextFieldValues.isEndNodeChecked && nextFieldValues.EndNodeLabel && nextFieldValues.no_of_rooms) {
                // Extract recently added node IDs
                    const recentlyAddedNodeIds = siteStructures.flatMap(structure =>
                        structure?.nodes?.map(node => node.id) ?? []
                    );
                    // Second API Call - Add End Nodes
                    const endNodeResponse = await createSiteStructure({
                        variables: {
                            nodeIds: recentlyAddedNodeIds,  // Use new node IDs from the first call
                            siteId: siteActivateId !== '' ? siteActivateId : editedSiteId,
                            levelType: SiteLevelType.Children,  // Always add as children of the new nodes
                            input: {
                                count: nextFieldValues.no_of_rooms,  // Number of rooms to add
                                label: nextFieldValues.EndNodeLabel, // Room label
                                labelFormat: SiteStructureLabelFormat.Option_1_2_3, // Default format
                                isEndNode: true  // Mark as end node
                            }
                        }
                    });

                    // Process second API response
                    if (endNodeResponse) {
                        const endSiteStructures = endNodeResponse?.data?.siteStructureCreate?.siteStructure ?? [];

                        // Update Trie for all end node structures
                        for (const structure of endSiteStructures) {
                            const endHierarchy = structure?.hierarchy ?? [];
                            const endNodes = structure?.nodes?.map(item => ({ id: item.id, label: item.label })) ?? [];

                            updateTrieWithHierarchy(trie.root, endHierarchy, endNodes);
                        }
                    }
                }
            }
            
        } catch (err) {
            console.error("Error in API calls:", err);
        }
    };

    const siteStructureNodeEditApiCall = async () => {
        try {
            // Prepare inputs for batch update, keeping hierarchy as an empty array
            const selectedLabelsPath = pathOfSelectedLabel
            const hierarchyIds = getApiHierarchyIds(trie.root,selectedLabelsPath); 
            const updatedInputs = hierarchyIds.map(editedValueId => ({
                id: editedValueId, // API hierarchy ID of the node to update
                isEndNode: nextFieldValues.isEndNodeLabel,
                label:nextFieldValues.label,
                labelFormat: nextFieldValues.label_format === '1-2-3'
                    ? SiteStructureLabelFormat.Option_1_2_3
                    : SiteStructureLabelFormat.OptionABC
            }));
    
            // First API Call: Batch Update Site Structures
            const updateStructureResponse = await siteStructureUpdate({
                variables: {
                    siteId: siteActivateId !== '' ? siteActivateId : editedSiteId,
                    input: updatedInputs // Pass array of update objects
                }
            });
    
            if (updateStructureResponse) {
                // **Check if we need to create End Nodes**
                if (nextFieldValues.isEndNodeChecked && nextFieldValues.EndNodeLabel && nextFieldValues.no_of_rooms) {
                    // Extract recently added node IDs
                    const recentlyAddedNodeIds = hierarchyIds
                    // Second API Call - Add End Nodes
                    const endNodeResponse = await createSiteStructure({
                        variables: {
                            nodeIds: recentlyAddedNodeIds,  // Use new node IDs from the first call
                            siteId: siteActivateId !== '' ? siteActivateId : editedSiteId,
                            levelType: SiteLevelType.Children,  // Always add as children of the new nodes
                            input: {
                                count: nextFieldValues.no_of_rooms,  // Number of rooms to add
                                label: nextFieldValues.EndNodeLabel, // Room label
                                labelFormat: SiteStructureLabelFormat.Option_1_2_3, // Default format
                                isEndNode: true  // Mark as end node
                            }
                        }
                    });
                    
                    // Process second API response
                    if (endNodeResponse) {
                        const endSiteStructures = endNodeResponse?.data?.siteStructureCreate?.siteStructure ?? [];
                    
                        // Update Trie for all end node structures
                        for (const structure of endSiteStructures) {
                            const endHierarchy = structure?.hierarchy ?? [];
                            const endNodes = structure?.nodes?.map(item => ({ id: item.id, label: item.label })) ?? [];
                    
                            updateTrieWithHierarchy(trie.root, endHierarchy, endNodes);
                        }
                    }
                }
            }
        } catch (err) {
            console.error("Error in API update calls:", err);
        }
    };

    const deleteSelectedSiteNodes = async (deletedIds:string[]) =>{
        try{
            const deleteOperation = await deleteSiteStructureNodes({
                variables: {
                    siteId: siteActivateId!==''?siteActivateId:editedSiteId,
                    ids:deletedIds
                },
                refetchQueries: [
                    {
                        query: GET_SITE_DATA,
                        variables: {
                            id: siteActivateId!==''?siteActivateId:editedSiteId
                        }
                    }
                ]
            });
            if(deleteOperation?.data){
                console.warn("deleted", deleteOperation)
            }
        }catch(error){
            console.error(error)
        }
    }

    return (
        <>
            <FlexBox
                flexDirection="column" gap={24}>
                {isCustomEdit ?
                    <div key={"Custom site structure"} className={styles.previewGrid}
                        onClick={() => {
                            setSelectedPreset(previewPreset); setIsNextPreviewButtonCliked(true)
                        }}
                        style={selectedPreset === previewPreset ?
                            { border: "2px solid #0072DB", height: 'fit-content' } : { height: 'fit-content' }}>
                        <SiteStructurePreview previewPageClicked={previewPageClicked}
                        />
                    </div> : <div className={styles.dialogActions}>
                        <p>Custom site structure</p>
                        <FlexBox style={{
                            backgroundColor: "#00438A0F",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "180px",
                            borderRadius: "12px",
                            flexDirection: 'column'
                        }}>
                            <p>Create custom site structure</p>
                            <Button variant="primary" onPress=
                                {() => {
                                    setIsCustomSiteClicked('Custom site structure')
                                    setLevelValues([])
                                    setSelectedPreset('Custom site structure')
                                }}>
                                <Plus />
                                Custom
                            </Button>
                        </FlexBox>
                    </div>}
                <div className={styles.gridContainer}>
                    {presets.map(({ heading, templateUnits, footer, border }) => (
                        isCustomEdit && heading === previewPreset ? <></> : <div
                            key={heading}
                            className={styles.gridItem}
                            style={border ? { border } : {}}
                        >
                            <Preset
                                ref={presetRef}
                                heading={heading as "Preset 1" | "Preset 2" | "Preset 3" | "Open Structure"}
                                templateUnits={templateUnits}
                                footer={footer}
                                setIsCustomSiteClicked={setIsCustomSiteClicked}
                            />
                        </div>
                    ))}
                    {isCustomEdit && previewPreset !== 'Custom site structure' && <FlexBox style={{
                        backgroundColor: "#00438A0F",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "386px",
                        borderRadius: "12px",
                        flexDirection: 'column'
                    }}>
                        <p>Create custom site structure</p>
                        <Button variant="primary" onPress=
                            {() => {
                                setIsCustomSiteClicked('Custom site structure')
                                setLevelValues([])
                                setSelectedPreset('Custom site structure')
                            }}>
                            <Plus />
                            Custom
                        </Button>
                    </FlexBox>}
                </div>
            </FlexBox>
            <DialogContainer onDismiss={() => { dismiss(); handlePresetClick() }} isOpen={isCustomSiteClicked !== null}>
                <Dialog  minWidth={'80vw'} height={"873px"}>
                    <FlexBox justifyContent="space-between" alignItems={"center"}>
                        <DialogTitle aria-label="dialogTitle" style={{ marginTop: "28px" , marginLeft: "45px", paddingLeft: "0px" }}>
                            {isCustomSiteClicked === 'Custom site structure' ? 'Custom site structure' :
                                `Site Structure: ${isCustomSiteClicked}`}
                        </DialogTitle>
                        <Button
                            style={{ borderRadius: "10px", marginRight: "45px", marginTop: "22px" }}
                            variant={"quiet"}
                            onPress={dismiss}
                        >
                            <Cross />
                        </Button>
                    </FlexBox>
                    <hr className={dividerHorizontal} />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "45px",
                        paddingRight: "45px",
                        alignItems: "flex-start",
                        marginTop: "10px",
                        width: "100%", // Ensures it takes the full width of the Dialog
                    }}>
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "300",
                        }}>
                            Click on labels to activate actions.
                            End room nodes represent the last level where displays will be added.
                        </p>

                        <FlexBox
                            className={styles.flexboxContainer}
                        >
                            <MenuButton
                                buttonContent="Select level"
                                aria-label="actions"
                                onAction={handleLevelAction}
                                isDisabled={selectedLabel.length === 0}

                            >
                                <Item
                                    key="Unselect All">
                                    Unselect All</Item>
                                <Item
                                    key="Select whole level">
                                    Select whole level
                                </Item>
                                <Item
                                    key="Select siblings">
                                    Select siblings
                                </Item>
                            </MenuButton><MenuButton
                                buttonContent="Add next level"
                                aria-label="actions"
                                onAction={handleAction}
                                isDisabled={selectedLabel.length === 0}

                            >
                                <Item 
                                    key='Add siblings' 
                                    isDisabled={pathOfSelectedLabel.some(arr=>arr?.path?.length===1)}>Add siblings</Item>
                                <Item key='Add child branches'
                                    isDisabled={pathOfSelectedLabel.some(arr=>arr?.path?.length===5)||
                                    checkIfAnyEndOfWord(trie!,pathOfSelectedLabel)||
                                    (hasChildrenInAnyPath(trie,pathOfSelectedLabel))}>Add child branches</Item>
                                <Item 
                                    key='Add End node'
                                    isDisabled={pathOfSelectedLabel.some(arr=>arr?.path?.length===5)||
                                        checkIfAnyEndOfWord(trie!,pathOfSelectedLabel)
                                        ||(hasChildrenInAnyPath(trie,pathOfSelectedLabel))}>Add End node</Item>
                            </MenuButton>
                            <Button variant="secondary"
                                isDisabled={
                                    pathOfSelectedLabel.length <= 1||selectedLabel.length<=1} onPress={() => { handleOnEditClick() }}>Edit</Button>
                            <Button shape="square" signal="danger" onPress={handleDelteNode} isDisabled={(pathOfSelectedLabel.length===1&&
                                pathOfSelectedLabel[0].path.length===1&&pathOfSelectedLabel[0].path[0]===tableData[0].path[0])||(selectedLabel.length===0)}>
                                <Trashcan />
                                Delete
                            </Button>
                        </FlexBox>
                    </div>
                    <DialogContent
                        className='scrollable-table'
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width : "100%",
                            overflowX: "auto",
                            overflowY: "auto",
                            paddingLeft: "45px",
                            paddingRight: "0px"
                        }}
                    >
                        { <>
                            <TableComponent showPreviewTable={(selectedPreset===previewPreset&&previewTableData.length>0)?true:false}/>
                        </>}

                    </DialogContent>
                    <hr className={dividerHorizontal} />
                    <DialogActions className={styles.dialogActions}>
                        <Button variant="quiet" onPress={() => {
                            setIsCustomSiteClicked(null); setIsNextPreviewButtonCliked(false)
                        }}>
                            {"Back"}
                        </Button>
                        <Button variant="primary" onPress={onClickSave}>
                            {"Save"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContainer>
            <DialogContainer onDismiss={dismissNextLevel} isOpen={addNextLevel !== '' || isEditClicked}>
                <Dialog minWidth={"591px"} minHeight={'372px'}>
                    <FlexBox justifyContent="space-between" alignItems={"center"}>
                        <DialogTitle aria-label="dialogTitle" style={{ marginTop: "28px" }}>
                            {isEditClicked ? `Edit ${isLevelsClicked}` : addNextLevel}
                        </DialogTitle>
                        <Button
                            style={{ borderRadius: "10px", marginRight: "22px", marginTop: "22px" }}
                            variant={"quiet"}
                            onPress={dismissNextLevel}
                        >
                            <Cross />
                        </Button>
                    </FlexBox>
                    <hr className={dividerHorizontal} />
                    <DialogContent
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginTop: '10px'
                        }}
                    >
                        <AddNextLevelFields
                            addNextLevel={addNextLevel}
                            isEditClicked={isEditClicked}
                            errorLabelMessage={errorLabelMessage}
                            setErrorLabelMessage={setErrorLabelMessage}
                        />
                    </DialogContent>
                    <hr className={dividerHorizontal} />
                    <DialogActions>
                        <Button variant="quiet" onPress={dismissNextLevel} >
                            Cancel
                        </Button>
                        <Button variant="primary"
                            onPress={handleAddNodes}
                            isDisabled={nextFieldValues.label === '' ||
                                nextFieldValues.label_format === '' ||
                                isNaN(nextFieldValues.no_of_units) ||
                                (nextFieldValues.isEndNodeChecked &&
                                    (isNaN(nextFieldValues.no_of_rooms) ||
                                        nextFieldValues.EndNodeLabel === ''))}
                        >
                            {(isEditClicked && (isLevelsClicked === 'Siblings' || isLevelsClicked === 'Level')) ? 'Update' : addNextLevel === 'Add siblings' ? 'Add siblings' :
                                addNextLevel === 'Add End node' ? 'Add End Node' : 'Add child'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContainer>

        </>
    )
}