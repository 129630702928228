/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import "./App.css";
import NavigationRoutes from "./routes";
import { NotificationRenderer, NotificationsQueue } from "@filament/react";
import { getUserInfo } from "./CommonMethods/GetUserInfo";
import GlobalContext from "./components/Context/GlobalContext";



function App() {
    const {setUserInfo} = useContext(GlobalContext)

    useEffect(() => {
        const handleUserInteraction = () => {
            NotificationsQueue.clear() // Close all active notifications
        };
        // Listen for user interactions globally
        window.addEventListener("mousedown", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);
        window.addEventListener("touchstart", handleUserInteraction);
        try{
            const getUserDetails = async() =>{
                if(localStorage.getItem('access_token') !== null){
                    const response = await getUserInfo(localStorage.getItem('access_token')!)
                    if (response.ok) {
                        const userInfo = await response.json();
                        setUserInfo({
                            'family_name':userInfo.family_name,
                            "given_name": userInfo.given_name,
                            "userId": userInfo.sub,
                        })
                    } 
                }
            }
            getUserDetails()

        }catch(error){
            console.error("err",error)
        }

        
        return () => {
            // Cleanup event listeners
            window.removeEventListener("mousedown", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
        };
    }, []);

    const navigationPath = NavigationRoutes()
    return (
        <div>
            {navigationPath}
            <NotificationRenderer variant="toast" position="bottom" />

        </div>
    );
}

export default App;
