/* eslint-disable max-len */
import React,{ Context, createContext,ReactNode, useContext, useState } from "react";
const breakPoints = {'sm':'640','md':'790','lg':'1024','xl':'1280','2xl':'1560'}

type ScreenTypes = 'sm'|'md'|'lg'|'xl'|'2xl'

type userInfoType = {
    'family_name':string,
    'given_name':string,
    'userId':string
}


// Define the type for the context value
interface GlobalContextType {
    email:string;
    setEmail:React.Dispatch<React.SetStateAction<string>>;
    bp: (screenSize: ScreenTypes) => string; // bp function type
    userInfo : userInfoType,
    setUserInfo:React.Dispatch<React.SetStateAction<userInfoType>>;
    isOpen:boolean;
    setIsOpen:React.Dispatch<React.SetStateAction<boolean>>;
    visibleOrg:boolean;
    setVisibleOrg:React.Dispatch<React.SetStateAction<boolean>>;
    Tabss:string;
    SetTabs:React.Dispatch<React.SetStateAction<string>>
    
}
let globalRefetch:()=>void

export const setRefetch = (refetchFn:()=>void) => {
    globalRefetch = refetchFn;
};

export const triggerRefetch = () => {
    if (globalRefetch) {
        globalRefetch();
    } else {
        console.error("Refetch function not set");
    }
};

const GlobalContext: Context<GlobalContextType > = createContext<GlobalContextType >({
    bp: (screenSize) => `@media (min-width:${breakPoints[screenSize]}px)`,
    email:'',
    setEmail:()=>{},
    userInfo: {family_name:'',given_name:'',userId:''},
    setUserInfo:()=>{},
    isOpen:false,
    setIsOpen:()=>{},
    visibleOrg:true,
    setVisibleOrg:()=>{},
    Tabss:'',
    SetTabs:()=>{}
});

interface GlobalProviderProps {
    children: ReactNode; // Accepts React nodes as children
}

const GlobalProvider = ({children}:GlobalProviderProps) => {
    const [email,setEmail] = useState('')
    const [userInfo,setUserInfo] = useState({family_name:'',given_name:'',userId:''})
    const [isOpen,setIsOpen] = useState(false)
    const [visibleOrg, setVisibleOrg] = useState(true)
    const [Tabss,SetTabs] = useState('')
    /**
      * The function `bp` returns a media query string based on the input `screenSize`.
      * @returns The function `bp` is returning a string that represents a CSS media query with a
      * minimum width based on the `screenSize` parameter. The `${breakPoints?.screenSize}` is likely
      * intended to access a variable or object named `breakPoints` to get the specific screen size
      * value
      */
    const bp = (screenSize:ScreenTypes) =>{
        return `@media (min-width:${breakPoints[screenSize]}px`
    }
    

    return(
        <GlobalContext.Provider value={{email,
            setEmail,
            bp,
            userInfo,
            setUserInfo,
            isOpen,
            setIsOpen,
            visibleOrg,
            setVisibleOrg,
            Tabss,
            SetTabs
        }}>
            {children}
        </GlobalContext.Provider>
    )

}

export {GlobalProvider}
export const useGlobalData = () => useContext(GlobalContext)
export default GlobalContext