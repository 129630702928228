import { Checkbox, Dropdown, FlexBox, Item, Label, Stepper, Text, TextField, Tooltip } from "@filament/react";
import { Key } from "@filament/react/types";
import React from "react";
import { useUnifiedContexts } from "../../../../components/Context/Context";
import { checkIfAnyEndOfWord, hasChildren } from "./TrieDataStructure";

export type NextFiledTypes = {
    isEndNodeLabel:boolean,
    label:string,
    label_format:Key,
    no_of_units:number,
    isEndNodeChecked:boolean,
    EndNodeLabel:string,
    no_of_rooms:number

}
export const  AddNextLevelFields = ({addNextLevel,errorLabelMessage,setErrorLabelMessage,isEditClicked
}:{
    addNextLevel:string,
    errorLabelMessage:boolean,
    setErrorLabelMessage:React.Dispatch<React.SetStateAction<boolean>>
    isEditClicked:boolean,
    
}) =>{
    
    const {nextFieldValues,setNextFieldValues,isLevelsClicked,pathOfSelectedLabel,trie} = useUnifiedContexts()

    const handleSelection = (key: Key) => {
        setNextFieldValues(prev=>({...prev,label_format:key,no_of_units:key==='a-b-c'
            &&prev.no_of_units>26?26:prev.no_of_units}));
    }
    const maxValueForCount = nextFieldValues.label_format ==='a-b-c'?26:200
    return(
        <>
            <FlexBox gap={20}
                flexDirection="column">
                <FlexBox 
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto", // Labels take available space, inputs stay at auto size
                        alignItems: "center",
                        gap: "46px", // Controls spacing between label and input
                        width: "100%" // Ensures full width usage
                    }}
                >
                    <Label>{addNextLevel==='Add siblings'||(isEditClicked&&isLevelsClicked==='Siblings')?'Sibling Label':
                        (isEditClicked&&isLevelsClicked==='Level')?'Label':
                            addNextLevel==='Add End node'?'Node Label':'Child Label'}</Label>
                    <div style={{ position: "relative", width: "400px" }}>
                        <TextField 
                            width={400} 
                            isDisabled={addNextLevel==='Add siblings'||
                                (pathOfSelectedLabel.length===1&&hasChildren(trie,pathOfSelectedLabel[0].path))}
                            value={nextFieldValues.label}
                            onChange={(e)=>{
                                setNextFieldValues(prev=>({...prev,label:e}))
                            }}
                            placeholder={addNextLevel==='Add End node'?'Type Label':`Give a label like Tower, Zone, Floor etc.`}
                            onBlur={() => {
                                setNextFieldValues(prev=>({...prev,label:prev.label.trim()}))
                            }}
                            isInvalid={errorLabelMessage}
                            onFocus={()=>{setErrorLabelMessage(false)}}
                            errorMessages={errorLabelMessage?[`Label already exists!`]:[]}
                            aria-label="level_label"
                            showClearButton
                        />
                        {nextFieldValues.label.trim()!==''&&<FlexBox
                            style={{
                                backgroundColor: "#00438A2E",
                                height: "32px",
                                width:"32px",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10px",
                                position: "absolute",
                                right: "45px", // Adjust to fit inside TextField
                                top: "12%",
                                zIndex:"1",
                                borderRadius: "6px",
                                border: "1px solid #7D7D7D",
                                display: "flex", // Flexbox for the FlexBox itself
                            }}
                        >
                            <p
                                style={{
                                    color: "#0052A3",
                                    margin: "0", // Removes any default margin on the paragraph
                                }}
                            >
                                {nextFieldValues.label.charAt(0)?.toUpperCase()}
                            </p>
                        </FlexBox>}
                    </div>
                </FlexBox>  
                <FlexBox 
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto", // Labels take available space, inputs stay at auto size
                        alignItems: "center",
                        gap: "46px", // Controls spacing between label and input
                        width: "100%" // Ensures full width usage
                    }}
                >
                    <Label>Label Suffix</Label>
                    <div style={{ position: "relative", width: "400px" }}>
                        {addNextLevel==='Add End node'||addNextLevel==='Add siblings'||
                        (pathOfSelectedLabel.length===1&&hasChildren(trie,pathOfSelectedLabel[0].path))||
                        checkIfAnyEndOfWord(trie,pathOfSelectedLabel)?
                            <TextField 
                                width={400} 
                                value={nextFieldValues.label_format.toString()}
                                isDisabled={true}      
                                aria-label="level_label"
                            />:<Dropdown
                                placeholder="Select label Suffix"
                                aria-label="level_dropdown"
                                width="400px"
                                selectedKey={nextFieldValues.label_format}
                                onSelectionChange={handleSelection}
                            >
                                <Item key="1-2-3">1-2-3</Item>
                                <Item key="a-b-c">A-B-C</Item>
                            </Dropdown>}
                        
                    </div>
                </FlexBox>  
                <FlexBox 
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto", // Same structure as above
                        alignItems: "center",
                        gap: "46px",
                        width: "100%"
                    }}
                >
                    <Label>{`No. of ${addNextLevel==='Add siblings'||(isEditClicked&&isLevelsClicked==='Siblings')?'siblings':
                        (isEditClicked&&isLevelsClicked==='Level')?'Levels':
                            addNextLevel==='Add End node'?'Nodes':'childs'}`}</Label>
                    <Stepper 
                        aria-label="zones" 
                        placeholder={`${addNextLevel==='Add siblings'
                            ||(isEditClicked&&isLevelsClicked==='Siblings')?'Enter number of siblings':
                            (isEditClicked&&isLevelsClicked==='Level')?'Enter number of Levels':
                                addNextLevel==='Add End node'?'Open - OR- Type a no.':'Enter number of childs'}`}
                        value={nextFieldValues.no_of_units}
                        onChange={(e)=>{
                            if(Number(e)<=maxValueForCount && Number(e)>=1){
                                setNextFieldValues(prev=>({...prev, no_of_units:((e===undefined)?NaN:Number(e))}))
                            }
                        }}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>)=>{
                            if(Number(e.target.value)<=maxValueForCount && Number(e.target.value)>=1){
                                setNextFieldValues(prev=>({...prev, 
                                    no_of_units:((e.target.value==='')?NaN:
                                        Number(e.target.value))}))
                            }
                        }}
                        minValue={1}
                        maxValue={maxValueForCount}
                        style={{paddingLeft:"20px"}} 
                        width={400} />
                </FlexBox>
                {(addNextLevel==='Add child branches'||
                (isEditClicked&&pathOfSelectedLabel.every(path=>path.path.length<5)&&
                !checkIfAnyEndOfWord(trie,pathOfSelectedLabel)))&&<Checkbox  
                    isSelected={nextFieldValues.isEndNodeChecked}
                    onChange={()=>{setNextFieldValues(prev=>({...prev,isEndNodeChecked:!prev.isEndNodeChecked}))}} >
                    {isEditClicked?'End the branches with End node':'End the child branches with End node'}
                </Checkbox>}
                {nextFieldValues.isEndNodeChecked&&<>
                    <FlexBox 
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto", // Labels take available space, inputs stay at auto size
                            alignItems: "center",
                            gap: "46px", // Controls spacing between label and input
                            width: "100%" // Ensures full width usage
                        }}
                    >
                        <Label>End node Label</Label>
                        <div style={{ position: "relative", width: "400px" }}>
                            <TextField 
                                width={400} 
                                value={nextFieldValues.EndNodeLabel}
                                onChange={(e)=>{
                                    setNextFieldValues(prev=>({...prev,EndNodeLabel:e}))
                                }}
                                placeholder="Type Label"
                            
                                aria-label="level_label"
                            />
                            
                        </div>
                    </FlexBox> 
                    <FlexBox 
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto", // Same structure as above
                            alignItems: "center",
                            gap: "46px",
                            width: "100%"
                        }}
                    >
                        <Label>No. of {''}
                            {nextFieldValues.EndNodeLabel?.trim()==='' ? (
                                'rooms'
                            ) : nextFieldValues.EndNodeLabel?.trim().length<=5?nextFieldValues.EndNodeLabel?.trim()+'s':(
                                <Tooltip
                                    content={
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "normal",
                                                paddingTop: "4px",
                                                paddingBottom: "4px",
                                                wordBreak: "break-word",
                                                overflowWrap: "break-word",
                                                whiteSpace: "normal",
                                            }}
                                        >
                                            {nextFieldValues.EndNodeLabel?.trim()?.
                                                match(/.{1,100}/g)?.join("\n")+'s'}
                                        </span>
                                    }
                                    placement="bottom"
                                >
                                    <Text>
                                        {nextFieldValues.EndNodeLabel?.trim()?.slice(0, 5) + "...s"}
                                    </Text>
                                </Tooltip>
                            )}</Label>
                        <Stepper 
                            aria-label="rooms" 
                            placeholder={'Open - OR- Type a no. '}
                            value={nextFieldValues.no_of_rooms}
                            onChange={(e)=>{
                                if(Number(e)<=(200) && Number(e)>=1){
                                    setNextFieldValues(prev=>({...prev, 
                                        no_of_rooms:((e===undefined)?NaN:Number(e))}))
                                }
                            }}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>)=>{
                                if(Number(e.target.value)<=(200) && Number(e.target.value)>=1){
                                    setNextFieldValues(prev=>({...prev, 
                                        no_of_rooms:((e.target.value==='')?NaN:
                                            Number(e.target.value))}))
                                }
                            }}
                            minValue={1}
                            maxValue={200}
                            style={{paddingLeft:"20px"}} 
                            width={400} />
                    </FlexBox>
                </>}
            </FlexBox>

        </>
    )
}