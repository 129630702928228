import { FileUploadArea, FileUploadButton, FlexBox } from "@filament/react";
import React from "react";
import { useSiteImagesCreateMutation } from "../../../graphql/generated";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";


type SiteUploadImagesProps =
    | {
        file: File | null; fileList?: never; SetFileList?: never;
        SetFile: React.Dispatch<React.SetStateAction<File | null>>;
    }// If `file` is provided, `fileList` must be `never`
    | {
        file?: never; fileList: File[]; SetFileList: React.Dispatch<React.SetStateAction<File[] | null>>
        SetFile?: never;
    }


const SiteUploadImages: React.FC<SiteUploadImagesProps> = (props) => {
    const [siteImageUpload] = useSiteImagesCreateMutation()
    const siteActivateId = useSelector((state:RootState)=>state.persistedState.siteIdToActivate)

    const { SetFileList, fileList, SetFile } = props

    const handleFileChange = (files: FileList | null) => {
        if (!files) return;

        const allowedTypes = ["image/svg+xml", "image/png", "image/jpeg"];

        // Convert FileList to an array and filter valid files
        const filesArray = Array.from(files).filter(file => {
            
            return allowedTypes.includes(file.type);
        });

        if (filesArray.length === 0) {
           
            return;
        }
        if ("file" in props) {
            SetFile!(files[0])

        }

        else if ("fileList" in props) {

            const filesArray = Array.from(files);
            SetFileList!((prev) => [...filesArray, ...(prev || [])]);
        }
        console.log("fff",filesArray)
        handleUpload(filesArray[0]);
        const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    }

    const handleUpload = async (file: File) => {
        try {
            // 1. Call mutation to get the pre-signed URL
            const { data } = await siteImageUpload({
                variables:{
                    id:siteActivateId
                }
            });
            if(data){
                console.log("dataaa",data)
                const { key, url } = data.siteImagesCreate;
    
                // 2. Upload file to S3
                const uploadResponse = await fetch(url, {
                    method: "PUT",
                    body: file,
                    headers: {
                        "Content-Type": file.type, // Ensure correct MIME type
                    },
                });
        
                if (!uploadResponse.ok) {
                    throw new Error("Upload failed");
                }
        
                console.log(`File uploaded successfully: ${key}`);
            }
            
    
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };


    return (
        <FlexBox flexDirection={'column'} gap={16} >
            <FileUploadArea
                key={Date.now()}
                onChange={handleFileChange}

                allowMultipleFiles={fileList && fileList.length > 0}
                style={{ gap: '0.5rem', textAlign: 'center' }}
                acceptedFileTypes={['image/svg+xml', 'image/png', 'image/jpeg']}

            >
                Drag and drop the file{fileList ? '(s)' : ''}
                <br />
                or
                <br />
                <FileUploadButton shape="square"
                    acceptedFileTypes={['image/svg+xml', 'image/png', 'image/jpeg']}
                >Browse</FileUploadButton>
            </FileUploadArea>
        </FlexBox>
    )
}

export default SiteUploadImages