import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StorePrivilegeRoles } from "../../../redux/PrivilageTypeState";
import { FlexBox, Label, CheckboxGroup, Checkbox, Text } from "@filament/react";
import { RootState } from "../../../redux/store";
import { useGetRolesQuery } from "../../../graphql/generated";
import Loading from "../../../components/Loading";

const UserPrevilageAndRoles = ({ selectedRoles, setSelectedRoles }:
    { selectedRoles: string[], setSelectedRoles: React.Dispatch<React.SetStateAction<string[]>> }) => {
    const dispatch = useDispatch();
    const previlageType = useSelector((state: RootState) => state.PrivilageTypeState?.privlageValue)

    const { data, loading } = useGetRolesQuery();

    type Role = { label: string; value: string };
    let roles: Role[] = [];

    // Mapping display labels to FeatureEnum values
    if (previlageType === "Select Feature") {
        roles = [
            { label: "Site Management", value: "SiteManagement" },
            { label: "User Management", value: "UserManagement" }
        ];
    } else if (previlageType === "Select Task" && data?.roles?.length) {
        roles = data.roles.map(role => ({
            label: role.description,
            value: role.name
        }));
    }

    const handleChange = (values: string[]) => {
        const features = values as string[]
        setSelectedRoles(features);
        dispatch(StorePrivilegeRoles([...features])); // Dispatching the selected roles
    };

    if (loading) {
        return <Loading message={"Loading privileges..."}/>;
    }

    return (
        <FlexBox flexDirection={'column'} gap={6} >
            <Label htmlFor="Select Features" style={{ fontFamily: 'bold', fontSize: '20px' }}>
                <Text variant='heading-xs'>{previlageType === "Select Task" ? "Select User Tasks" : "Select Features"}</Text>
            </Label>
            <CheckboxGroup value={selectedRoles} onChange={handleChange}  >
                {roles.map((role) => (
                    <Checkbox value={role.value} key={role.value}>
                        {role.label}
                    </Checkbox>
                ))}
            </CheckboxGroup>
        </FlexBox>
    );
};

export default UserPrevilageAndRoles;
