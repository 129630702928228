/* eslint-disable max-len */
import { Cross } from '@filament-icons/react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FlexBox,
    Text,
    Avatar,
    TabContext,
    Tabs,
    Item,
    TabPanels,
    Label,
    TextField,
    NotificationsQueue,
    Tooltip,
} from '@filament/react';
import { atomicBorderBottom as borderBottom, atomicBorderTop as borderTop } from '@filament/react/styles';
import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { GoogleApiKey } from "../../../../config";
import TaskAssociated from './TaskAssociated';
import EditOrganization from '../EditOrganisation';
import OrgDetails from './OrgDetails';
import { useUnifiedContexts } from '../../../../components/Context/Context';
// import { Selection } from '@filament/react/types';
import { OrgEntry, OrgType, useGetOrganizationQuery, useOrganizationLogoCreateMutation, useUpdateOrganizationMutation
    // OrgStatus,
} from '../../../../graphql/generated';
import Loading from '../../../../components/Loading';
import OrgPreview from './OrgPreview';
import { initalOrgValues } from '../../../../components/Context/OrganizationContext';
import { ApolloError } from '@apollo/client';
import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import { triggerRefetch } from '../../../../components/Context/GlobalContext';



export default function ViewOrganization({ handleViewDetailsCloseClick, org }:
    { handleViewDetailsCloseClick: () => void, org: never }) {
    const [isEditCicked, setIsEditClicked] = useState(false)
    const [editOrg] = useUpdateOrganizationMutation()
    // const vatPattern: RegExp = /^(?=.*[0-9])[A-Z0-9]+$/;
    const dunPattern: RegExp = /^[1-9][0-9]{8}$/;
    const { setOrgValues, setFormData, formData } = useUnifiedContexts();
    const [organizationLogoCreateMutation] = useOrganizationLogoCreateMutation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isUploading, setIsUploading] = useState(false);

    const {
        setInputValue,
        setMapValues,
        country,
        // inputValue,
        orgValues,
        mapValues,
        valueType,
        setValueType,
        setCountry,
    } = useUnifiedContexts();

    const { data, loading, error } = useGetOrganizationQuery({
        variables: { id: org },
    });

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!,
    });

    const [mobileCode, setMobileCode] = useState<Selection>(new Set(['000']));
    const [phoneNumberorEmailInvalid, setPhoneNumberorEmailInvalid] = useState({
        phoneNumber: false,
        email: false
    });

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (data !== undefined && data.organization !== undefined && data.organization !== null) {

            // const orgTypesArray = ['Chain_of_Hotels', 'Hotels',
            //     'Distributer_Reseller',
            //     'Healthcare',
            //     'Retail',
            //     'System_Integration_Management',
            //     'Device_Management',
            //     'Content_Management',
            //     'Organization_Management',
            //     'Branch_Management',]
            // const notInOrgTypes = data.organization.orgType.filter(item => !orgTypesArray.includes(item));
            // if (data.organization.orgType.every(orgType => orgTypesArray.includes(orgType))) {
            //     //donothing
            // } else {
            //     setOrgValues(prev => ({
            //         ...prev,
            //         OthersTextArea: notInOrgTypes[0]
            //     }))
            // }
            setOrgValues((prev) => ({
                ...prev,
                // orgTypeValue: (notInOrgTypes[0] !== '' && notInOrgTypes[0] !== undefined) ?
                //     [...data.organization!.organizationType!.filter((type): type is string => type !== null), 'Others'] :
                //     data.organization!.organizationType!.filter((type): type is string => type !== null),
                // countryCode: data.organization!.countryCode,
                // imgurl: '/images/Logo.svg',
                // phoneNumber: data.organization!.phonenumber,
                // emailId: data.organization!.email,
                // croppedImg: '/images/Logo.svg',
                // status: data.organization?.orgStatus === 'active' ? OrgStatus.Active : OrgStatus.Inactive,
                orgTypeValue: data.organization!.orgType || [],
                imgurl: data.organization.logo?.url || '/images/Logo.svg',
                phoneNumber: data.organization!.phonenumber,
                emailId: data.organization!.email,
                phoneCode: data.organization!.phoneCode,
            }));

            setFormData((prev) => ({
                ...prev,
                city: data.organization!.city!,
                // state: data.organization!.state!,
                duns: data.organization!.dunsNumber!,
                vat: data.organization!.vatId!,
                organizationAddress: data.organization!.address!,
                organizationName: data.organization!.name!,
                organizationType: data.organization!.orgType!,
                placeId: data.organization!.address!,
                postcode: data.organization!.postCode!,
                organizationWebsite: data.organization!.website!,
            }));

            setInputValue(data.organization!.address);
            setMobileCode(new Set([data.organization!.phoneCode]));
            setMapValues(prev => ({
                ...prev,
                center: {
                    lat: Number(data.organization!.orgLatitude!),
                    lng: Number(data.organization!.orgLongitude!)
                },
                zoom: 21
            }));
            setValueType({ SelectAddType: data.organization.entryType });
            setCountry(data.organization!.country);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) {
        return <Loading message={"Loading Organization data..."}/>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const orgData = data?.organization;

    const mapContainerStyle = {
        width: "100%",
        height: "271px",
        borderRadius: "12px",
    };

    const center = {
        lat: Number(orgData?.orgLatitude) || 0,
        lng: Number(orgData?.orgLongitude) || 0,
    };

    // function isSelectionWithAnchorKey(value: unknown): value is { anchorKey: string } {
    //     return typeof value === "object" && value !== null && "anchorKey" in value;
    // }
    
    const zoom = 21; // Zoom level

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    const SelectTasks: JSX.Element[] = [
        <EditOrganization key="1" />,
        <OrgDetails key="2"
            // handleInputChange={handleInputChange}
            mobileCode={mobileCode}
            setMobileCode={setMobileCode}
            emailError={phoneNumberorEmailInvalid.email}
            error={phoneNumberorEmailInvalid.phoneNumber}
            setError={setPhoneNumberorEmailInvalid}
            Region="EU"
            isEdit={true}
        />,
        <OrgPreview key='3' formData={formData} mobileCode={mobileCode} isEdit={true} />
    ];

    const onNextPress = async () => {
        if (index < 2) {
            setIndex(prev => prev + 1);
        }
        if (index === 2) {
            try {
                const codevalue = Array.from(mobileCode)?.[0]?.toString().split(':')[1]?.trim();

                const editResponse = await editOrg({
                    variables: {
                        input: {
                            id: data!.organization!.id,
                            name: formData.organizationName,
                            taxIdentificationNumber: formData.vat,
                            orgType: orgValues.orgTypeValue.map(value => value as OrgType),
                            country: country.toString(),
                            entryType: valueType.SelectAddType as OrgEntry,
                            address: formData.organizationAddress,
                            dunsNumber: formData.duns,
                            city: formData.city,
                            postCode: formData.postcode,
                            orgLatitude: mapValues.center.lat,
                            orgLongitude: mapValues.center.lng,
                            website: formData.organizationWebsite,
                            email: orgValues.emailId,
                            phonenumber: orgValues.phoneNumber,
                            phoneCode: codevalue === undefined ? data!.organization!.phoneCode : codevalue,
                        }
                    }
                });

                if (orgValues.croppedImg && orgValues.croppedImg !== '/images/Logo.svg') {
                    setIsUploading(true);

                    try {
                        const logoResponse = await organizationLogoCreateMutation({
                            variables: {
                                input: {
                                    id: data!.organization!.id,
                                }
                            }
                        });

                        if (logoResponse.data?.organizationLogoCreate?.url) {
                            const base64Response = await fetch(orgValues.croppedImg);
                            const blob = await base64Response.blob();

                            const uploadResponse = await fetch(logoResponse.data.organizationLogoCreate.url, {
                                method: 'PUT',
                                body: blob,
                                headers: {
                                    'Content-Type': blob.type,
                                },
                            });

                            if (!uploadResponse.ok) {
                                throw new Error(`Upload failed with status ${uploadResponse.status}`);
                            }

                            setOrgValues(prev => ({
                                ...prev,
                                imgurl: logoResponse.data.organizationLogoCreate.url || '/images/Logo.svg'
                            }));
                        }
                    } catch (logoError) {
                        console.error("Logo upload failed:", logoError);
                        NotificationsQueue.toast(
                            ({ close }) => (
                                <ToastNotification
                                    close={close}
                                    message="Failed to upload organization logo"
                                />
                            ),
                            {
                                signal: 'error',
                                orientation: 'horizontal',
                                showCloseButton: true,
                                timeout: 6000,
                            }
                        );
                    } finally {
                        setIsUploading(false);
                    }
                }

                if (editResponse.data) {
                    NotificationsQueue.toast(
                        ({ close }) => <ToastNotification close={close} message="Organisation updated successfully" />,
                        {
                            signal: 'success',
                            orientation: 'horizontal',
                            showCloseButton: true,
                            timeout: 6000,
                        }
                    );
                    triggerRefetch();
                    handleViewDetailsCloseClick();
                }
            } catch (err) {
                let errorMessage = 'An error occurred while editing the organization.';

                if (err instanceof ApolloError) {
                    if (err.message === 'Response not successful: Received status code 409') {
                        errorMessage = 'Error! Organization already exists';
                    }
                }

                NotificationsQueue.toast(
                    ({ close }) => <ToastNotification close={close} message={errorMessage} />,
                    {
                        signal: 'error',
                        orientation: 'horizontal',
                        showCloseButton: true,
                        timeout: 6000,
                    }
                );
                handleViewDetailsCloseClick();
            }
        }
    };

    const isNextDisabled = () => {
        if (index === 0 && (orgValues.orgTypeValue.length === 0 ||
            (orgValues.orgTypeValue.includes('Others') &&
                orgValues.OthersTextArea.length === 0))) {
            return true
        }
        else if (index === 1) {
            // Check if SelectAddType is invalid
            if
            (valueType.SelectAddType !== OrgEntry.Duns &&
                valueType.SelectAddType !== OrgEntry.Tin &&
                valueType.SelectAddType !== OrgEntry.Address) {
                return true;
            }

            if (valueType.SelectAddType === OrgEntry.Duns &&
                (
                    // !formData.duns
                    !formData.duns || // Check if vat is not provided
                    !dunPattern.test(formData.duns) // Validate vat against the pattern
                )
            ) {
                return true; // DUNs is required but not filled
            }

            if (valueType.SelectAddType === OrgEntry.Tin &&
                (
                    // !formData.vat
                    !formData.vat || // Check if vat is not provided
                    !orgValues.countryRegex.test(formData.vat) || // Validate vat against the pattern
                    formData.vat.length < 9
                )
            ) {
                return true; // VAT is required but not filled
            }

            if (
                valueType.SelectAddType === OrgEntry.Address &&
                (

                    !formData.organizationName ||
                    // !formData.city ||
                    // !formData.state ||
                    !formData.postcode ||
                    !formData.organizationWebsite
                )
            ) {
                return true; // Address fields are required but not filled
            }
            if ((orgValues.phoneNumber === '') 
            // || (mobileCode.toString() === '')
            ) {
                return true
            }
        }
        return false;
    };

    const handleClose = () => {
        if (index !== 0) {
            setIndex(prev => prev - 1);
        }
        if (index === 0) {
            handleViewDetailsCloseClick();
            setFormData({
                organizationName: "",
                organizationAddress: "",
                city: "",
                state: "",
                postcode: "",
                organizationWebsite: "",
                vat: "",
                duns: "",
                placeId: ''
            });
            setOrgValues(initalOrgValues);
            setMapValues({
                center: {
                    lat: 40.7128,
                    lng: -74.006,
                },
                zoom: 17,
                suggestions: []
            });
            setCountry('');
            setValueType({ SelectAddType: OrgEntry.Address });
            setInputValue('');
        }
    };

    return (
        <>
            <Card width={'40%'} height={'100vh'} orientation="vertical"
                style={{ borderRadius: "10px 0 0 10px", position: 'fixed', top: 0, right: 0 }}>
                <CardHeader height={84} className={borderBottom} style={{ padding: 0 }} >
                    <FlexBox justifyContent='space-between' alignItems={'center'}
                        style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '32px', paddingRight: '22px' }} >
                        <Text variant='heading-s' weight="bold"> {
                            isEditCicked ? 'Edit Organisation' : 'Organisation information'} </Text>
                        <Button variant={'quiet'} onPress={handleViewDetailsCloseClick}>
                            <Cross width={'24px'} height={'24px'} />
                        </Button>
                    </FlexBox>
                </CardHeader>
                <CardBody style={{ padding: 0, gap: 0 }}>
                    {isEditCicked ?
                        <div style={{
                            paddingLeft: '24px',
                            paddingRight: "24px",
                            paddingBottom: '8px',
                            height: "calc(100vh - 185px)", overflowY: "auto"
                        }}>
                            {SelectTasks[index]}
                        </div> : <>
                            <FlexBox height={150} style={{ paddingLeft: '24px', }}>
                                <Avatar width={'128px'} height={'128px'} style={{ backgroundColor: 'transparent', }}>
                                    <img src={orgData?.logo?.url || '/images/Logo.svg'} alt="Org Logo" />
                                </Avatar>
                                <FlexBox style={{ paddingTop: '24px', paddingLeft: '24px', flexDirection: 'column' }}>
                                    <Tooltip
                                        content={
                                            <div
                                                style={{
                                                    width: 'auto',
                                                    textAlign: 'center',
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    fontSize: '16px', fontWeight: 'normal'
                                                }}
                                            >
                                                {orgData?.name || 'No name available'}
                                            </div>
                                        }
                                        placement="bottom"
                                    >
                                        <div
                                            style={{
                                                width: 300,
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Text variant="heading-m" weight="bold">
                                                {orgData?.name}
                                            </Text>
                                        </div>
                                    </Tooltip>
                                </FlexBox>
                            </FlexBox>
                            <FlexBox height="calc(100vh - 340px)"
                                className="scrollable-element"
                                style={{ flexDirection: 'column', overflow: 'auto' }}>
                                <FlexBox style={{ flexDirection: 'column', paddingLeft: '24px', paddingRight: '24px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '12px',
                                            flexWrap: 'wrap',
                                            paddingBottom: '16px',
                                            paddingTop: '2px',
                                        }}
                                    >
                                        {Array.isArray(orgData?.orgType) ? (
                                            <>
                                                {Array.from(new Set(orgData.orgType))
                                                    .filter((type) => type?.toLowerCase() !== 'others')
                                                    .slice(0, 3)
                                                    .map((type, index) => (
                                                        <Text
                                                            key={index}
                                                            style={{
                                                                border: '1px solid #6B8094',
                                                                borderRadius: '8px',
                                                                padding: '2px 22px',
                                                            }}
                                                        >
                                                            {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                        </Text>
                                                    ))}
                                                {Array.from(new Set(orgData.orgType))
                                                    .filter((type) => type?.toLowerCase() !== 'others').length > 3 && (
                                                    <Tooltip
                                                        content={
                                                            <div
                                                                style={{
                                                                    display: 'grid',
                                                                    width: '300px',
                                                                    gridTemplateColumns: '1fr',
                                                                    rowGap: '0.25rem',
                                                                }}
                                                            >
                                                                {Array.from(new Set(orgData.orgType))
                                                                    .filter((type) => type?.toLowerCase() !== 'others')
                                                                    .slice(3)
                                                                    .map((type, index) => (
                                                                        <Label
                                                                            key={index}
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'normal',
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            {type.replace(/Or/g, '/').replace(/ /g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                                        </Label>
                                                                    ))}
                                                            </div>
                                                        }
                                                        placement="bottom"
                                                    >
                                                        <Text
                                                            style={{
                                                                border: '1px solid #6B8094',
                                                                borderRadius: '8px',
                                                                paddingTop: '2px',
                                                                paddingBottom: '7px',
                                                                paddingLeft: '8px',
                                                                paddingRight: '8px',
                                                            }}
                                                        >
                                                            ...
                                                        </Text>
                                                    </Tooltip>
                                                )}
                                            </>
                                        ) : (
                                            <Text
                                                style={{
                                                    border: '1px solid #6B8094',
                                                    borderRadius: '8px',
                                                    padding: '2px 22px',
                                                }}
                                            >
                                                {orgData?.orgType || '-'}
                                            </Text>
                                        )}
                                    </div>
                                </FlexBox>

                                <TabContext
                                    // selectedKey={activeTab} onSelectionChange={(key) => setActiveTab(key as string)}
                                >
                                    <Tabs id='tabs' >
                                        <Item key="details" ><span color='secondary'>Details</span></Item>
                                        <Item key="tasks"><span color='secondary'>Task Associated Users</span></Item>
                                        <Item key="sites"><span color='secondary'>Sites</span></Item>
                                    </Tabs>
                                    <TabPanels>
                                        <Item key="details" >
                                            <div
                                                style={{
                                                    paddingLeft: '32px',
                                                    paddingRight: '32px',
                                                    paddingTop: '32px',
                                                    paddingBottom: '32px',
                                                }}>
                                                {valueType.SelectAddType == OrgEntry.Address &&
                                                <FlexBox style={{ flexDirection: 'column', gap: "16px" }}>
                                                    <Label >Organization Location</Label>
                                                    <GoogleMap
                                                        mapContainerStyle={mapContainerStyle}
                                                        zoom={zoom}
                                                        center={center}
                                                        options={{
                                                            zoomControl: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            fullscreenControl: false,
                                                            keyboardShortcuts: false,
                                                            draggable: false,
                                                            scrollwheel: false,
                                                        }}
                                                    >
                                                        <MarkerF
                                                            position={center}
                                                            cursor='default'
                                                            icon={{
                                                                url: "/images/gis_pin.svg",
                                                                scaledSize: new window.google.maps.Size(40, 40),
                                                                origin: new window.google.maps.Point(0, 0),
                                                                anchor: new window.google.maps.Point(20, 40),
                                                            }}
                                                        />
                                                    </GoogleMap>
                                                </FlexBox>
                                                }

                                                <FlexBox style={{ flexDirection: "column", gap: '24px', paddingTop: '24px' }}>

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                        <Label>Organization Address</Label>
                                                        <TextField value={orgData?.address || '-'} isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    {/* Might be asked by the clients so will be commenting it till clarity is given. */}
                                                    {/* <FlexBox style={{ gap: "24px", width: "100%" }}>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>City</Label>
                                                            <TextField value={orgData?.city || '-'} isFullWidth isReadOnly />
                                                        </FlexBox>

                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>State</Label>
                                                            <TextField value={orgData?.state || '-'} isFullWidth isReadOnly />
                                                        </FlexBox>
                                                    </FlexBox> */}

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                        <Label>Postcode</Label>
                                                        <TextField value={orgData?.postCode || '-'}
                                                            isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>Country</Label>
                                                        <TextField
                                                            value={orgData?.country?.split(':')?.[0]?.toString() || '-'}
                                                            isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>Organization Website</Label>
                                                        <TextField value={orgData?.website || '-'} isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    {/* <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>DUNs Number</Label>
                                                        <TextField value={orgData?.dunsNumber || '-'} isFullWidth isReadOnly />
                                                    </FlexBox> */}
                                                    <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>{formData.vat !== '' ? 'Tax Identification Number' : 'DUNs Number'}</Label>
                                                        <TextField aria-label="vat"
                                                            value={formData.duns === '' ?
                                                                formData.vat : formData.duns || formData.vat === '' ?
                                                                    formData.duns : formData.vat || '-'}
                                                            isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    <FlexBox style={{ gap: "24px", width: "100%" }}>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>Phone Number</Label>
                                                            <TextField
                                                                value={`+${orgData?.phoneCode} | ${orgData?.phonenumber}`}
                                                                isFullWidth isReadOnly />
                                                        </FlexBox>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>Email Address</Label>
                                                            <TextField value={orgData?.email || '-'} isFullWidth isReadOnly />
                                                        </FlexBox>
                                                    </FlexBox>

                                                    {/* <Label>Account status</Label>
                                            <FlexBox gap={10}>
                                                {[OrgStatus.Active, OrgStatus.Inactive].map((item) => (
                                                    <Button
                                                        key={item}
                                                        shape="square"
                                                        isFullWidth
                                                        width={'20%'}
                                                        style={{
                                                            backgroundColor: `${item === orgValues.status ? '#0072DB' : ''}`,
                                                            color: `${item === orgValues.status ? 'white' : ''}`,
                                                            borderRadius: `${item === OrgStatus.Active ? '8px 0 0 8px' : '0 8px 8px 0'}`,
                                                            pointerEvents: 'none',
                                                            fontSize: "clamp(10px, 2vw, 16px)",
                                                            textAlign: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            whiteSpace: "nowrap",
                                                        }}

                                                    >
                                                        {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                                                    </Button>
                                                ))}
                                            </FlexBox> */}
                                                </FlexBox>
                                            </div>
                                        </Item>

                                        <Item key="tasks">
                                            <TaskAssociated />
                                        </Item>

                                        <Item key="sites">
                                            Sites
                                        </Item>

                                    </TabPanels>
                                </TabContext>
                            </FlexBox>
                        </>}
                </CardBody>
                <CardFooter height={'72px'} className={borderTop}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingTop: '14px',
                        paddingBottom: '14px'
                    }} >
                    {isEditCicked ?
                        <><Button onPress={handleClose}>
                            {index === 0 ? 'Cancel' : 'Back'}
                        </Button> <Button
                            variant={'primary'}
                            onPress={onNextPress}
                            isDisabled={isNextDisabled()}
                        >
                            {index === 2 ? 'Save' : 'Next'}
                        </Button></> :
                        <Button onPress={() => setIsEditClicked(true)} variant="primary" >
                            <Text variant='body-m' weight='bold' style={{ color: 'white' }}>Edit</Text>
                        </Button>}

                </CardFooter>
            </Card>
        </>
    );
}