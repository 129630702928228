import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userValues } from '../pages/Organization/Components/UserTab';

// Define the type for the new initial state


// Define the initial state for user values

interface VerificationValues{
    verificationEmail:boolean,
    verificationOrganization:boolean,
    displayPreview:boolean,
}
const initialUserState: userValues = {
    email: "",
    firstName: "",
    lastName: "",
    Organization: "",
    Department: "",
    JobTitle: "",
    MFA: true,
};

//change it to false later
const initalSaveState:boolean=true

// Define the initial state for verification values
const initialVerificationState: VerificationValues = {
    verificationEmail:true,
    verificationOrganization:true,
    displayPreview:true,
};

// Create the slice
const userTabSlice = createSlice({
    name: "userTabSlice",
    initialState: {
        user: initialUserState,
        verification: initialVerificationState,
        displayPreview:true,
        SaveState:initalSaveState

    },
    reducers: {
        StoreUserTab: (state, action: PayloadAction<userValues>) => {
            state.user.email = action.payload.email;
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
            state.user.Organization = action.payload.Organization;
            state.user.Department = action.payload.Department;
            state.user.JobTitle = action.payload.JobTitle;
            state.user.MFA = action.payload.MFA;
        },
        StoreVerificationUserTab: (state, action: PayloadAction<VerificationValues>) => {
            state.verification.verificationEmail = action.payload.verificationEmail;
            state.verification.verificationOrganization = action.payload.verificationOrganization;
            state.verification.displayPreview=action.payload.displayPreview

        },
        handleSaveState: (state, action: { payload: boolean; type: string }) => {
            state.SaveState = action.payload;
        },
        
    },
});

// Export actions and reducer
export const { StoreUserTab, StoreVerificationUserTab,handleSaveState } = userTabSlice.actions;

export default userTabSlice.reducer;
