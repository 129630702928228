import React, { useEffect } from 'react'
import SiteUploadImages from './SiteUploadImages'
import { SiteData } from '../../../components/Context/SiteContext'
import Cropper, { Area } from 'react-easy-crop'
import { Avatar, Button, FlexBox, RangeSlider, Text } from '@filament/react'
import getCroppedImg from '../../Organization/AllOrganisations/Components/GetCroppedImage'
import { Edit, Trashcan } from '@filament-icons/react'



const SiteLogo = () => {
    const { File, setFile, imgUrl, crop, zoom, setZoom, setCrop, initalCrop,
        setInitalCrop, setImgUrl, croppedAreaPixels, setCroppedAreaPixels, croppedImage, setCroppedImage,
        SiteinitialCropImage, SitesetInitialCropImage } = SiteData()
    const normalizeSlider = (event: number | number[]): number => Array.isArray(event) ? event[1] : event

    useEffect(() => {
        if (File) {
            setImgUrl(URL.createObjectURL(File))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [File])



    const handleMediaLoaded = (mediaSize: { naturalWidth: number; naturalHeight: number }) => {
        if (crop.x !== initalCrop.x || crop.y !== initalCrop.y) {
            return;
        }

        const cropWidth = 200; // Assume crop area width
        const cropHeight = 200; // Assume crop area height

        const imageWidth = mediaSize.naturalWidth;
        const imageHeight = mediaSize.naturalHeight;

        // Center the image
        const xOffset = (imageWidth - cropWidth) / 2;
        const yOffset = (imageHeight - cropHeight) / 2;

        if (crop.x !== initalCrop.x && crop.y !== initalCrop.y) {

            setCrop({
                x: initalCrop.x,
                y: initalCrop.y,
            });
        }
        else {
            setCrop({
                x: -xOffset / zoom,
                y: -yOffset / zoom,
            });
            setInitalCrop({
                x: -xOffset / zoom,
                y: -yOffset / zoom,
            });

        }


        let multiplyValue = 1
        if (imageWidth > 50) {
            multiplyValue = 2
        } else {
            multiplyValue = 1.5
        }


        // Optionally adjust zoom if needed
        setZoom(Math.max(cropWidth / imageWidth, cropHeight / imageHeight) * multiplyValue);


    };

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {

        setCroppedAreaPixels(croppedAreaPixels)

    }

    const showCroppedImage = async () => {
        try {
            let imageSource: string = '';

            // Determine the image source
            if (File && typeof File === 'object' && 'name' in File) {
                // Use the File object and create an Object URL
                imageSource = URL.createObjectURL(File);
            } else if (imgUrl) {
                // Use the provided image URL if available
                imageSource = imgUrl;
            }

            if (imageSource && croppedAreaPixels) {

                // Call getCroppedImg with the determined image source
                const croppedImage = await getCroppedImg(imageSource, croppedAreaPixels, 0);

                // Update state with the cropped image

                setCroppedImage(croppedImage as string)
                SitesetInitialCropImage(croppedImage as string)
            } else {
                console.warn("No valid image source or cropped area pixels available.");
            }
        } catch (error) {
            console.error("Error while cropping the image:", error);
        }
    }

    const handleRemoveLogo = () => {
        if (SiteinitialCropImage.length === 0) {
            setFile(null)
        }
        else{
            setCroppedImage(SiteinitialCropImage)
            
        }

    }

    return (
        <div className="site-logo">
            {!File && <SiteUploadImages file={File} SetFile={setFile} />}

            {File && !croppedImage && <><div className="App" >
                <div className="crop-container" style={{ minWidth: '400px', }} ><Cropper
                    image={imgUrl}
                    crop={crop}
                    rotation={0}
                    zoom={zoom}
                    cropSize={{ width: 200, height: 200 }}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    zoomWithScroll={true}
                    cropShape="round"
                    zoomSpeed={0.1}
                    onMediaLoaded={handleMediaLoaded}
                    objectFit="contain"
                    style={{
                        containerStyle: { width: '100%', },
                        cropAreaStyle: { color: '#00000033', display: "flex", alignItems: "center", justifyContent: "center", },
                    }}
                    maxZoom={50}
                /></div>
            </div> <FlexBox flexDirection="column" alignItems="center" padding={24} paddingTop={8}  >
                <FlexBox justifyContent="space-between" width={'400px'}  >
                    <Text>Zoom</Text>
                    <Text>{Math.round(zoom)}</Text>
                </FlexBox>
                <RangeSlider maxValue={50}
                    minValue={1}
                    onChange={(event) => setZoom((normalizeSlider(event)))}
                    value={[1, zoom]} width={'400px'} />
            </FlexBox><FlexBox gap={8} justifyContent="flex-end">
                <Button variant="quiet" onPress={handleRemoveLogo}>Cancel</Button>
                <Button onPress={showCroppedImage}>Apply</Button>
            </FlexBox></>}

            {croppedImage && <FlexBox
                alignItems='center' gap={32}><Avatar width={'140px'} height={'200px'} style={{ backgroundColor: 'transparent', }}>
                    <img src={croppedImage || ''} alt="hotel logo"
                        style={{ borderRadius: '100%', objectFit: 'contain' }}
                    />

                </Avatar><FlexBox
                    flexDirection={'column'}
                    gap={16} >
                    <Button variant="primary" onPress={() => setCroppedImage('')} >
                        <Edit />
                        Edit Logo
                    </Button>
                    <Button onPress={() => { setCroppedImage(''); setFile(null); SitesetInitialCropImage('');}}>
                        <Trashcan />Remove Logo
                    </Button>
                </FlexBox>
            </FlexBox>}
        </div>
    )
}

export default SiteLogo